<div class="row section-one">
  <div class="pageWrapper maxWidth d-flex p-24">
    <div class="col-lg-6 section-one-text d-flex justify-content-center align-items-center">
      <p>We are a new age technology start-up that specializes in providing Cloud native solutions to various
        enterprises. We specialize in building cloud native SAAS products, providing consulting services for
        establishing nearshore and offshore operations, handling large scale IT change management projects and providing
        blueprints for agile and devops way of working for legacy teams to name a few. </p>
    </div>
    <div class="col-lg-6">
      <img src="/assets/images/about-us/section-1.png" alt="Section 1">
    </div>
  </div>
</div>

<div class="row section-two">
  <div class="pageWrapper maxWidth d-flex p-24 justify-content-between align-items-center">
    <div class="col-lg-4">
      <img src="/assets/images/about-us/section-2.png" alt="Section 2">
    </div>
    <div class="col-lg-7 section-two-text">
      <p style="font-weight:bold">Having worked for over <span style="color: #5D87FF">15 years</span> for a clientele
        ranging from Asia to Americas delivering technology solutions, our small team has now embarked on a new
        independent journey to provide services that matter.</p>
      <p style="opacity: 0.7">We admit that we are new to the market so we possibly lack the infrastructure and
        workforce that our peers can provide. However, what we lack in size, we more than make up for it in skills. We
        have seen the industry up close and are aware about it's short comings. We in Daedal are well equipped to handle
        the demon that has always been in the details.</p>
    </div>
  </div>
</div>

<div class="row section-three">
  <div class="pageWrapper maxWidth m-t-10">
    <wmt-dumb-card></wmt-dumb-card>
  </div>
</div>
