export interface ValueCard {
  id: number
  title: string
  description: string
  imgSrc: string
  releaseName: string
}

export const valueCards: ValueCard[] = [
  {
    id: 1,
    title: 'AI powered tax assistant',
    description: 'Uses next-gen AI technology to answer dutch tax and accounting queries. '
      + 'Model is contantly updated based on user feedbacks and new tax laws. ',
    imgSrc: '/assets/images/partner-value-cards/ai-tax-assistant.jpg',
    releaseName: 'Jan2025: StormWind',
  },
  {
    id: 2,
    title: 'Accountant marketplace',
    description: 'Search and easily compare accountants and their service offerings, all from one place. '
      + 'Option to directly contact the accountant and see reviews for selected accountants.',
    imgSrc: '/assets/images/partner-value-cards/accountant-marketplace.jpg',
    releaseName: 'Jan2025: StormWind',
  },
  {
    id: 3,
    title: 'Multi-currency transaction manager',
    description: 'Allows the user to load multiple bank transactions in it\'s respective currency and convert '
      + 'them to the pre-assigned base reporting currency.',
    imgSrc: '/assets/images/partner-value-cards/multi-currency.jpg',
    releaseName: 'June 2025: Orgrimmar',
  },
  {
    id: 4,
    title: 'Account snapshot',
    description: 'Check your balance sheet and PnL.'
      + ' Compare and see history of your books.'
      + ' Export to excel and other popular file formats.',
    imgSrc: '/assets/images/partner-value-cards/account-snapshot.jpg',
    releaseName: 'June 2025: Orgrimmar',
  },
  {
    id: 5,
    title: 'SensInvoice',
    description: 'Automate invoice creation.'
      + ' Possibility to create both standalone and recurring invoices.'
      + ' Translate documents.',
    imgSrc: '/assets/images/partner-value-cards/sens-invoice.jpg',
    releaseName: 'Jan 2026: Ironforge',
  },
  {
    id: 6,
    title: 'CloudCabinet',
    description: 'Cloud native intelligent document sharing platform.'
      + ' Store indefinitely and also share with accountant.',
    imgSrc: '/assets/images/partner-value-cards/cloud-cabinet.jpg',
    releaseName: 'Jan 2026: Ironforge',
  },
];
