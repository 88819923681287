@if (!submitted) {
<form class="m-t-24" [formGroup]="consentForm">
  <div class="row">

    <div class="bg-extra-light wmt-form-accent">

      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="agreeCorrectness">
            {{ correctnessText }}<span class="wmt-required">*</span>
            @if (fv.hasError('agreeCorrectness')) {
              <small *ngFor="let error of fv.getFormErrors('agreeCorrectness')"
                     class="e-error">{{ error }}</small>
            }
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="agreeOnValidation">
            {{ validationText }}<span class="wmt-required">*</span>
            @if (fv.hasError('agreeOnValidation')) {
              <small *ngFor="let error of fv.getFormErrors('agreeOnValidation')"
                     class="e-error">{{ error }}</small>
            }
          </mat-checkbox>
        </div>
      </div>

      <div class="row m-b-10">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="agreeOnRemoveListing">
            {{ removeListingText }}<span class="wmt-required">*</span>
            @if (fv.hasError('agreeOnRemoveListing')) {
              <small *ngFor="let error of fv.getFormErrors('agreeOnRemoveListing')"
                     class="e-error">{{ error }}</small>
            }
          </mat-checkbox>
        </div>
      </div>

      <div class="row m-b-10">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="agreeOnModeration">
            {{ moderationText }}<span class="wmt-required">*</span>
            @if (fv.hasError('agreeOnModeration')) {
              <small *ngFor="let error of fv.getFormErrors('agreeOnModeration')"
                     class="e-error">{{ error }}</small>
            }
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="agreeOnChangeOfTos">
            {{ tosText }}<span class="wmt-required">*</span>
            @if (fv.hasError('agreeOnChangeOfTos')) {
              <small *ngFor="let error of fv.getFormErrors('agreeOnChangeOfTos')"
                     class="e-error">{{ error }}</small>
            }
          </mat-checkbox>
        </div>
      </div>

    </div>

  </div>

  <div class="d-flex justify-content-between m-t-16">
    <button mat-flat-button color="warn" matStepperPrevious>Back</button>
    <button mat-flat-button color="primary" [disabled]="!consentForm.valid"
            (click)="onSubmit()" type="submit">Save & Send
    </button>
  </div>
</form>
} @else {
  <div class="row">
    <div class="col-12 text-center m-b-30 m-t-30">
      <i-tabler name="send" class="wmt-sent-icon"></i-tabler>
      <h6 class="f-s-20 f-w-600">Thank you for submitting your application!</h6>
      <p class="mb-0">We have received your application and will get back to you shortly.</p>
      <p class="mb-0">Check out our <a href="/faq">FAQ</a> page for any immediate queries.</p>
    </div>
  </div>
}
