import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private isDialogOpen = false;

  constructor(@Inject(PLATFORM_ID) private platformId: NonNullable<unknown>) {
  }

  public toggle() {
    if (isPlatformBrowser(this.platformId)) {
      this.isDialogOpen = !this.isDialogOpen;
      this.toggleScrolling(this.isDialogOpen);
    }
  }

  private toggleScrolling(isOpen: boolean) {
    const html = document.querySelector('html');
    if (isOpen) {
      html?.classList.add('no-scroll');
    }
    else {
      html?.classList.remove('no-scroll');
    }
  }
}
