import { Component } from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';

@Component({
  selector: 'wmt-cookie-policy',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
  ],
  templateUrl: './cookie-policy.component.html',
  styleUrl: './cookie-policy.component.scss',
})
export class CookiePolicyComponent {

}
