import { z } from 'zod';

const preprocessNumber = (value: unknown) => {
  if (typeof value === 'string') {
    return value ? parseFloat(value) : null;
  }
  return value;
};

export const TaxRateSchema = z.object({
  taxRateId: z.string().max(10),
  taxCategoryId: z.string().max(10),
  tier: z.number().int(),
  taxRate: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  taxAmount: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  taxCreditRate: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  taxCreditAmount: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  minTier: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  maxTier: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  taxRateHead: z.string().max(40).nullable(),
  taxCreditRateHead: z.string().max(40).nullable(),
  taxRateHeadAdjAmount: z.string().max(40).nullable(),
  taxCreditRateHeadAdjAmount: z.string().max(40).nullable(),
  isActive: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type TaxRate = z.infer<typeof TaxRateSchema>;
