import { z } from 'zod';

export const TaxCategorySchema = z.object({
  taxCategoryId: z.string().max(10),
  taxId: z.string().max(10),
  categoryCode: z.string().max(3),
  categoryDescription: z.string().max(99),
  isActive: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type TaxCategory = z.infer<typeof TaxCategorySchema>;
