import { Component } from '@angular/core';
import { valueCards } from './value-cards-data';
import { MatCard, MatCardContent, MatCardImage, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../../ui/icons/icons.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'wmt-partner-value-cards',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardTitle,
    IconsModule,
    MatCardImage,
    MatCardSubtitle,
    NgOptimizedImage,
  ],
  providers: [],
  templateUrl: './partner-value-cards.component.html',
  styleUrl: './partner-value-cards.component.scss',
})
export class PartnerValueCardsComponent {
  protected readonly valueCards = valueCards;
}
