import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MatDrawerToggleResult, MatSidenav } from '@angular/material/sidenav';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private isSideNavOpen = false;
  public onRefresh = new EventEmitter<void>();
  private mobileSideNav!: MatSidenav;

  private menuToggleSubject = new BehaviorSubject<void>(undefined);
  public menuToggleObservable = this.menuToggleSubject.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: NonNullable<unknown>) {
  }

  refresh() {
    this.onRefresh.emit();
  }

  public toggle(): Promise<MatDrawerToggleResult> {
    if (isPlatformBrowser(this.platformId)) {
      this.isSideNavOpen = !this.isSideNavOpen;
      this.toggleScrolling(this.isSideNavOpen);
    }

    return this.mobileSideNav.toggle();
  }

  private toggleScrolling(isOpen: boolean) {
    const html = document.querySelector('html');
    if (isOpen) {
      html?.classList.add('no-scroll');
    }
    else {
      html?.classList.remove('no-scroll');
    }
  }

  setMobileSideNav(sidenav: MatSidenav) {
    this.mobileSideNav = sidenav;
  }
}
