import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { Tax, TaxCategory, TaxDepreciation, TaxRate } from '@wmt/shared';

@Injectable({
  providedIn: 'root',
})
export class TaxService {
  private readonly taxUrl;
  private readonly taxCategoryUrl;
  private readonly taxRateUrl;
  private readonly taxDepreciationUrl;

  constructor(
    private http: HttpClient,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
  ) {
    this.taxUrl = this.environment.apiBaseUrl + '/taxes';
    this.taxCategoryUrl = this.environment.apiBaseUrl + '/tax-categories';
    this.taxRateUrl = this.environment.apiBaseUrl + '/tax-rates';
    this.taxDepreciationUrl = this.environment.apiBaseUrl + '/tax-depreciations';
  }

  getAllTaxes(): Observable<Tax[]> {
    return this.http.get<Tax[]>(this.taxUrl);
  }

  getAllTaxCategories(): Observable<TaxCategory[]> {
    return this.http.get<TaxCategory[]>(this.taxCategoryUrl);
  }

  getAllTaxRates(): Observable<TaxRate[]> {
    return this.http.get<TaxRate[]>(this.taxRateUrl);
  }

  getAllTaxDepreciations(): Observable<TaxDepreciation[]> {
    return this.http.get<TaxDepreciation[]>(this.taxDepreciationUrl);
  }
}
