import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { map, startWith, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private isMobileSubject = new BehaviorSubject<boolean>(true);

  constructor(@Inject(PLATFORM_ID) private platformId: NonNullable<unknown>) {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'resize')
        .pipe(
          map(() => window.innerWidth < 768),
          startWith(window.innerWidth < 768),
          distinctUntilChanged(),
        )
        .subscribe((isMobile) => {
          this.isMobileSubject.next(isMobile);
        });
    }
  }

  get isMobile$(): Observable<boolean> {
    return this.isMobileSubject.asObservable();
  }
}
