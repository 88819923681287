<div class="spacer">
  <mat-card class="cardWithShadow wmt-bg-card-primary overflow-hidden">
    <mat-card-content class="p-24">
      <div class="row justify-content-around">
        <div class="col-sm-7 p-t-8">
          <mat-card-title style="font-size: 24px">Find Your Perfect Accounting Partner</mat-card-title>
          <mat-card-subtitle style="font-size: 14px; line-height: 22px" class="mat-body-1 m-t-16 m-b-24">
            Easily browse and connect with top accountants and their services all in one place, ensuring you find the
            right expert to meet your specific needs
          </mat-card-subtitle>
          <button mat-flat-button color="primary" [routerLink]="['/accountant-overview']">Explore Now</button>
        </div>
        <div class="col-sm-2">
          <img src="assets/images/svgs/explore-card.svg" alt="Find accountant" width="211" height="174" />
        </div>
      </div>
    </mat-card-content>
    <div class="gradient-circle" id="circle1"></div>
    <div class="gradient-circle" id="circle2"></div>
    <div class="gradient-circle" id="circle3"></div>
  </mat-card>
</div>
