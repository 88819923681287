import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef, Injector,
  Input, OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor, FormControl, FormControlDirective, FormControlName, FormGroupDirective,
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatError, MatFormField, MatHint, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { PositiveInputDirective } from '../../common/directives/positive-input.directive';
import { IconsModule } from '../icons/icons.module';
import { JsonPipe, NgClass, NgForOf } from '@angular/common';
import { FormValidationService } from '../../services/utility/form-validation.service';

@Component({
  selector: 'wmt-numeric-input',
  templateUrl: './wmt-numeric-input.component.html',
  styleUrls: ['./wmt-numeric-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormField,
    MatInput,
    FormsModule,
    MatIconButton,
    MatIcon,
    MatPrefix,
    MatSuffix,
    IconsModule,
    PositiveInputDirective,
    MatButton,
    NgClass,
    MatError,
    MatHint,
    NgForOf,
    JsonPipe,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WmtNumericInputComponent),
      multi: true,
    },
  ],
})
export class WmtNumericInputComponent implements ControlValueAccessor, AfterViewInit, OnInit {
  @Input() minDensity = false;
  @Input() formWidth = '100%';
  @Input() min = 0;
  @Input() step = 0.01;
  @Input() placeholder = '0.00';
  @Input() allowDecimals = true;
  @Input() chevronsEnabled = false;
  @Input() iconName!: string;
  @Input() hint!: string;
  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  ngControl!: NgControl;
  formControl!: FormControl;

  value = '';
  onChange?: (value: string) => void;
  onTouched?: () => void;

  isDisabled = false;

  constructor(private injector: Injector, public fv: FormValidationService) {
  }

  ngOnInit() {
    this.ngControl = this.injector.get(NgControl);

    if (this.ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(this.ngControl);
    }
    else {
      this.formControl = (this.ngControl as FormControlDirective).form as FormControl;
    }
  }

  ngAfterViewInit() {
    this.setDisabledState(this.isDisabled);
  }

  writeValue(value: string): void {
    this.value = value;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.inputElement && this.inputElement.nativeElement) {
      this.inputElement.nativeElement.disabled = isDisabled;
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onBlur(): void {
    this.onTouched?.();
  }

  updateValue(val: string): void {
    this.value = val;
    this.onChange?.(val);
    this.onTouched?.();
  }

  increment(): void {
    const currentValue = this.value ? parseFloat(this.value.replace(',', '.')) : 0;
    this.updateValue((currentValue + this.step).toFixed(2));
  }

  decrement(): void {
    let currentValue = parseFloat(this.value.replace(',', '.')) || 0;
    const newValue = (currentValue - this.step).toFixed(2);
    currentValue = Math.max(this.min, parseFloat(newValue));
    this.updateValue((currentValue).toFixed(2));
  }

  isDecrementDisabled(): boolean {
    const currentValue = this.value ? parseFloat(this.value.replace(',', '.')) : 0;
    return currentValue <= this.min;
  }

  get hasError(): boolean {
    return this.fv.hasError(this.ngControl?.name as string);
  }

  get errors(): string[] {
    return this.fv.getFormErrors(this.ngControl?.name as string);
  }
}
