import { Component, inject, OnInit } from '@angular/core';
import { InsightsService } from '../../../services/api/insights.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCard, MatCardContent, MatCardImage, MatCardTitle } from '@angular/material/card';
import { MatChip } from '@angular/material/chips';
import { IconsModule } from '../../../ui/icons/icons.module';
import { MatDivider } from '@angular/material/divider';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'wmt-insights-detail',
  standalone: true,
  imports: [
    MatCard,
    MatCardImage,
    MatChip,
    MatCardContent,
    MatCardTitle,
    IconsModule,
    MatDivider,
    MatFormField,
    MatInput,
    MatButton,
    MatMiniFabButton,
    MatTooltip,
    DatePipe,
  ],
  templateUrl: './insights-detail.component.html',
  styleUrl: './insights-detail.component.scss',
})
export class InsightsDetailComponent implements OnInit {
  insightsService = inject(InsightsService);
  slug: string | null = null;
  ghostPost: any | null = null;

  isToggleReply = true;

  toggleReply() {
    this.isToggleReply = !this.isToggleReply;
  }

  // activeRoute: any = this.router.url.split('/').pop();

  constructor(
    public router: Router,
    activatedRouter: ActivatedRoute,
  ) {
    this.slug = activatedRouter.snapshot.paramMap.get('slug');
  }

  async ngOnInit(): Promise<void> {
    this.ghostPost = await this.insightsService.getGhostPostBySlug(this.slug as string);
    console.log(this.ghostPost);
    if (this.ghostPost) {
      this.insightsService.setCurrentBlogTitle(this.ghostPost.title);
    }
  }
}
