import { z } from 'zod';

export const AccountantFeedbackSchema = z.object({
  actFeedbackId: z.string().uuid().optional(),
  visitorId: z.string().uuid(),
  accountantId: z.string().uuid(),
  stars: z.number(),
  feedback: z.string(),
  isAnonymous: z.boolean().default(false),
  consent: z.boolean().default(false),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AccountantFeedback = z.infer<typeof AccountantFeedbackSchema>;
