import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DetailDataBoundEventArgs, GridModule, DetailRowService } from '@syncfusion/ej2-angular-grids';
import { Tax, TaxCategory } from '@wmt/shared';
import { DetailViewTaxesComponent } from '../detail-view-taxes/detail-view-taxes.component';
import { taxCategoryStoreQuery } from '../../../common/stores/tax-category.store';

@Component({
  selector: 'wmt-detail-view',
  standalone: true,
  imports: [
    GridModule,
    DetailViewTaxesComponent,
  ],
  providers: [DetailRowService],
  templateUrl: './detail-view.component.html',
  styleUrl: './detail-view.component.scss',
})
export class DetailViewComponent implements OnChanges {
  @Input() tax!: Tax;
  public taxCategories: TaxCategory[] = [];
  taxName = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tax'] && this.tax) {
      this.taxCategories = taxCategoryStoreQuery.getCategoriesByTaxId(this.tax.taxId);
      this.taxName = this.tax.taxName;
    }
  }

  detailDataBound(e: DetailDataBoundEventArgs) {
    // const taxCategory = e.data as TaxCategory;

    if (e.detailElement) {
      e.detailElement.setAttribute('colspan', '3');
    }
  }
}
