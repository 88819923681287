<div class="toggle-button">
  <p>{{ totalAccountants }} accountants</p>
  <!--<button mat-icon-button (click)="toggleLayout()">
    <i-tabler [name]="isSingleColumn ? 'layout-columns' : 'layout-rows'"></i-tabler>
  </button>-->
</div>

<div class="row">
  @if (accountants.length === 0) {
    <div class="col-12 text-center">
      <p>No accountants found.</p>
    </div>
  } @else {
    @for (accountant of accountants; track accountant.accountantId) {
      <div class="col-12 col-md-6">
        <mat-card class="accountantCard overflow-hidden">
          <mat-card-header class="wmt-accountant-title"
                           [ngClass]="isSingleColumn ? 'layout-columns' : 'layout-rows'">
            <mat-card-title class="title m-b-0 f-s-14">
              <div class="company-info" [ngClass]="isSingleColumn ? 'col-md-5 col-sm-12' : 'col-sm-12'">
                <h3 class="mat-subtitle-2 f-s-24 f-w-600 company-name">
                  {{ accountant.companyName }}
                  <span>
                  <img *ngIf="accountant.langEn" src="assets/images/svgs/flags/en.svg" alt="en flag" class="flag">
                  <img *ngIf="accountant.langNl" src="assets/images/svgs/flags/nl.svg" alt="nl flag" class="flag">
                  <img *ngIf="accountant.langFr" src="assets/images/svgs/flags/fr.svg" alt="fr flag" class="flag">
                  <img *ngIf="accountant.langDe" src="assets/images/svgs/flags/de.svg" alt="de flag" class="flag">
                  <img *ngIf="accountant.langOther" src="assets/images/svgs/flags/{{accountant.langOther}}.svg"
                       alt="{{accountant.langOther}} flag" class="flag">
                </span>
                </h3>
                <p class="contact-details">
                  <i-tabler name="mail" class="icon-25"></i-tabler>
                  {{ accountant.companyEmail }}
                </p>
                <p class="contact-details">
                  <i-tabler name="phone" class="icon-25"></i-tabler>
                  {{ accountant.companyPhone }}
                </p>
                <p class="contact-details">
                  <i-tabler name="map-pin" class="icon-25"></i-tabler>
                  <span>{{ accountant.addressLine1 }}, {{ accountant.city }},<br>
                    {{ accountant.state }}, {{ accountant.postcode }}, {{ accountant.country }}
                    </span>
                </p>
                <mat-divider></mat-divider>
              </div>
            </mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <div class="col-12">
              <div class="section-consultation d-flex justify-content-between">
                <div class="consultation-label">
                  <p>First consultation free:</p>
                  <p>Free consultation for clients:</p>
                  <p *ngIf="accountant.consultationFeePrivate">Private consultation Fee:</p>
                  <p *ngIf="accountant.consultationFeeBusiness">Business consultation Fee:</p>
                  <p *ngIf="accountant.consultationFeeUsFiling">US filing fee:</p>
                </div>
                <div class="consultation-value">
                  <p>
                    <i-tabler name="{{accountant.isFirstConsultationFree ? 'check' : 'x'}}" class="icon-25"
                              [ngClass]="accountant.isFirstConsultationFree ? 'checked' : 'unchecked'"></i-tabler>
                  </p>
                  <p>
                    <i-tabler name="{{accountant.freeConsultationForClients ? 'check' : 'x'}}" class="icon-25"
                              [ngClass]="accountant.freeConsultationForClients ? 'checked' : 'unchecked'"></i-tabler>
                  </p>
                  <p *ngIf="accountant.consultationFeePrivate">
                    {{ accountant.consultationFeePrivate | currency:'EUR' }}</p>
                  <p *ngIf="accountant.consultationFeeBusiness">
                    {{ accountant.consultationFeeBusiness | currency:'EUR' }}</p>
                  <p *ngIf="accountant.consultationFeeUsFiling">
                    {{ accountant.consultationFeeUsFiling | currency:'EUR' }}</p>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="col-12">
                <div class="service-table">
                  <div class="row service-header">
                    <div class="col-4"><strong>Service</strong></div>
                    <div class="col-4"><strong>Price</strong></div>
                    <div class="col-4"><strong>Frequency</strong></div>
                  </div>
                  @if (accountant.services) {
                    <div class="row service-row">
                      <div class="col-4">Income Tax</div>
                      @if (accountant.services.incomeTax) {
                        <div class="col-4">{{ accountant.services.incomeTax.price | currency:'EUR' }}</div>
                        <div class="col-4">{{ accountant.services.incomeTax.frequency }}</div>
                      } @else {
                        <div class="col-4">-</div>
                        <div class="col-4">N/A</div>
                      }
                    </div>
                    <div class="row service-row">
                      <div class="col-4">Payroll</div>
                      @if (accountant.services.payroll) {
                        <div class="col-4">{{ accountant.services.payroll.price | currency:'EUR' }}</div>
                        <div class="col-4">{{ accountant.services.payroll.frequency }}</div>
                      } @else {
                        <div class="col-4">-</div>
                        <div class="col-4">N/A</div>
                      }
                    </div>
                    <div class="row service-row">
                      <div class="col-4">Corporate Tax</div>
                      @if (accountant.services.corporateTax) {
                        <div class="col-4">{{ accountant.services.corporateTax.price1 | currency:'EUR' }}</div>
                        <div class="col-4">{{ accountant.services.corporateTax.frequency }}</div>
                      } @else {
                        <div class="col-4">-</div>
                        <div class="col-4">N/A</div>
                      }
                    </div>
                    <div class="row service-row">
                      <div class="col-4">US Tax</div>
                      @if (accountant.services.usTax) {
                        <div class="col-4">
                          {{ accountant.services.usTax.priceUsFederalTaxFiling | currency:'EUR' }}
                        </div>
                        <div class="col-4">{{ accountant.services.usTax.frequency }}</div>
                      } @else {
                        <div class="col-4">-</div>
                        <div class="col-4">N/A</div>
                      }
                    </div>
                  } @else {
                    <div class="row service-row">
                      <div class="col-12">No services offered</div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div class="row m-t-30">
              <div class="col-12 d-flex align-items-center">
                <div class="col-7 d-flex align-items-center wmt-gap-1">
                  <div>
                    @for (star of maxStars; track star) {
                      <i-tabler name="star" class="icon-25 star-icon"
                                [ngClass]="{'filled': accountant.feedback
                        && star < accountant.feedback.averageStars, 'no-review': !accountant.feedback}">
                      </i-tabler>
                    }
                  </div>
                  @if (accountant.feedback) {
                    <span class="review-count">
                      <strong style="padding-right:3px">{{ accountant.feedback.averageStars }}</strong>
                      ({{ accountant.feedback.totalFeedbacks }})
                    </span>
                  }
                </div>
                <div class="col-5 d-flex align-items-center justify-content-end f-s-16">
                  @if (accountant.feedback) {
                    <p class="link" style="font-size: 16px; margin-block: 0" (click)="openCommentsDialog(accountant)">
                      View all reviews
                    </p>
                  } @else {
                    <p style="font-style: italic; font-size: 16px; margin-block: 0">No reviews yet</p>
                  }
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions class="p-y-30 p-x-16">
            <div class="col-12 d-flex justify-content-between">
              <a mat-stroked-button color="primary" (click)="openReviewFormDialog(accountant)" class="action-button">
                <span class="d-flex align-items-center wmt-gap-1">
                  <i-tabler name="writing" class="icon-20"></i-tabler>
                  Write review</span>
              </a>
              <a mat-flat-button color="primary" (click)="openRequestFormDialog(accountant)" class="action-button">
                <span class="d-flex align-items-center wmt-gap-1">
                  <i-tabler name="mail" class="icon-20"></i-tabler>
                  Get in touch</span>
              </a>
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    }
  }
</div>

<div class="pagination-controls">
  <button mat-icon-button (click)="onPageChange(currentPage - 1)" [disabled]="currentPage === 1">
    <i-tabler name="chevron-left" class="icon-20"></i-tabler>
  </button>
  <span>Page {{ currentPage }} of {{ totalPages }}</span>
  <button mat-icon-button (click)="onPageChange(currentPage + 1)" [disabled]="currentPage === totalPages">
    <i-tabler name="chevron-right" class="icon-20"></i-tabler>
  </button>
</div>

