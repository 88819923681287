import { z } from 'zod';

const preprocessNumber = (value: unknown) => {
  if (typeof value === 'string') {
    return value ? parseFloat(value) : null;
  }
  return value;
};

export const TaxDepreciationSchema = z.object({
  taxDepId: z.string().max(10),
  taxCountry: z.string().max(4).default('UKWN'),
  depreciationFor: z.string().max(40),
  periodType: z.string().max(20),
  from: z.number().int().optional(),
  to: z.number().int().optional().nullable(),
  rate: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  unitIncrement: z.preprocess(preprocessNumber, z.union([z.number(), z.null()])),
  isActive: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type TaxDepreciation = z.infer<typeof TaxDepreciationSchema>;
