import { HttpRequest, HttpEvent, HttpInterceptorFn, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services/api/auth.service';

const addTokenHeader = (req: HttpRequest<any>, token: string): HttpRequest<any> => {
  return req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const authService = inject(AuthService);

  // Skip token injection for the init-auth endpoint
  if (req.url.endsWith('/init-auth')) {
    return next(req);
  }

  const token = authService.getToken();

  // For refresh-auth requests, include the expired token
  if (req.url.endsWith('/refresh-auth') && token) {
    req = addTokenHeader(req, token);
    return next(req);
  }

  if (token) {
    req = addTokenHeader(req, token);
  }

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        // Token might have expired, try refreshing it
        return authService.refreshToken().pipe(
          switchMap(({ token }) => {
            return next(addTokenHeader(req, token));
          }),
          catchError((err) => {
            // If refreshing also fails, there's not much we can do
            return throwError(() => err);
          }),
        );
      }
      else {
        // If it's not a 401, just forward the error
        return throwError(() => error);
      }
    }),
  );
};
