import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DOCUMENT, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { CarouselModule } from '@syncfusion/ej2-angular-navigations';
import { UiModule } from '../../../ui/ui.module';
import { IconsModule } from '../../../ui/icons/icons.module';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { Subscription } from 'rxjs';

interface SliderItem {
  id: number
  title: string
  alt: string
  imgSrc: string
}

@Component({
  selector: 'wmt-hero-accountant',
  standalone: true,
  imports: [IconsModule, UiModule, RouterLink, CarouselModule, NgOptimizedImage],
  templateUrl: './hero-accountant.component.html',
  styleUrl: './hero-accountant.component.scss',
})
export class HeroAccountantComponent implements OnInit, OnDestroy {
  screenSubscription!: Subscription;
  public isMobile = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private screenService: ScreenSizeService,
  ) {
  }

  sliderItems: SliderItem[] = [
    {
      id: 1,
      title: 'Most comprehensive dutch tax calculator',
      alt: 'Dutch tax calculator',
      imgSrc: 'https://placehold.co/600x470?text=Carousel+View+1',
    },
    {
      id: 2,
      title: 'Accountant marketplace',
      alt: 'Accountant marketplace',
      imgSrc: 'https://placehold.co/600x470?text=Carousel+View+2',
    },
    {
      id: 3,
      title: 'AI driven tax assistant',
      alt: 'Tax assistant',
      imgSrc: 'https://placehold.co/600x470?text=Carousel+View+3',
    },
  ];

  ngOnInit() {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    if (!this.isMobile) {
      this.setVhProperty();
    }
  }

  setVhProperty() {
    if (isPlatformBrowser(this.platformId)) {
      const vh = window.innerHeight * 0.01;
      this.document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }

  resetVhProperty() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.documentElement.style.setProperty('--vh', '1vh');
    }
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
  }
}
