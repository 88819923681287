import { TcDataItem } from './tc-data-item';

const rawResults: Omit<TcDataItem, 'type'>[] = [
  { id: 'totalIncome', lineItems: 'Total income', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'businessExpenses', lineItems: 'Expenses that can be attributed for business', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'personalExpenses', lineItems: 'Expenses that are personal', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'grossSalaryYear', lineItems: 'Gross salary (expense adj ZZP income) per year', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'payRollTax', lineItems: 'Payroll tax (incl. Insurance)', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'generalTaxCredit', lineItems: 'General tax credit', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'labourTaxCredit', lineItems: 'Labour tax credit', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'netSalary', lineItems: 'Net salary', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'ebitda', lineItems: 'EBITDA', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'corporateTax', lineItems: 'Corporate tax', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'pat', lineItems: 'PAT', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'dividendTax', lineItems: 'Dividend tax', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'retainedEarnings', lineItems: 'Retained earnings for personal use', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'earningsAfterExpense', lineItems: 'Earnings after adj for personal expense', zzps: '-', bvs: '-', salaried: '-' },
  { id: 'incomePercentage', lineItems: 'Percentage of income', zzps: '-', bvs: '-', salaried: '-' },
];

export const exportResultTitles: { [key: string]: string } = {
  lineItems: 'Output fields',
  zzps: 'ZZPs',
  bvs: 'BVs',
  salaried: 'Salaried',
};

export const results: TcDataItem[] = rawResults.map(item => ({
  ...item,
  type: 'TC',
}));
