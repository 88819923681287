import { Component, inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { AiVoteService } from '../../../services/api/ai-vote.service';
import { format } from 'date-fns';
import { IconsModule } from '../../../ui/icons/icons.module';
import { isPlatformBrowser, NgClass } from '@angular/common';

@Component({
  selector: 'wmt-vote',
  standalone: true,
  templateUrl: './vote.component.html',
  imports: [
    IconsModule,
    NgClass,
  ],
  styleUrls: ['./vote.component.scss'],
})
export class VoteComponent implements OnInit {
  @Input() machineId!: string | null;
  @Input() displayColumn = false;
  private platformId = inject(PLATFORM_ID);
  private readonly aiVoteService = inject(AiVoteService);
  voted = false;
  voteDate: string | null = null;
  totalUniqueVotes = 0;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.machineId) {
        this.fetchVoteInfo();
      }
      else {
        console.warn('Machine ID is not available.');
      }
    }
  }

  vote() {
    if (!this.voted) {
      this.aiVoteService.voteForSubscription(this.machineId).subscribe({
        next: (response) => {
          this.voted = response.hasVoted;
          this.totalUniqueVotes = response.totalUniqueVotes;
          this.voteDate = response.voteDate ? format(response.voteDate, 'dd/MM/yyyy') : null;
        },
        error: (error) => {
          console.error('Error voting for subscription:', error);
        },
      });
    }
  }

  fetchVoteInfo(): void {
    this.aiVoteService.getVoteInfo(this.machineId).subscribe({
      next: (response) => {
        this.voted = response.hasVoted;
        this.voteDate = response.voteDate ? format(response.voteDate, 'dd/MM/yyyy') : null;
        this.totalUniqueVotes = response.totalUniqueVotes ?? 0;
      },
      error: (error) => {
        console.error('Error fetching vote info:', error);
      },
    });
  }
}
