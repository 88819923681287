import { z } from 'zod';
import { FrequencyEnum } from '../enums/frequency';
import { ServiceCategoryEnum } from '../enums/service-category';

export const IncomeTaxServiceSchema = z.object({
  itServiceId: z.string().uuid().optional(),
  accountantId: z.string().uuid(),
  serviceCategory: ServiceCategoryEnum,
  price: z.number().optional().nullable(),
  currency: z.string().max(5).default('EUR'),
  frequency: FrequencyEnum,
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type IncomeTaxService = z.infer<typeof IncomeTaxServiceSchema>;
