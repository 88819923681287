<div class="wmt-news-cards bg-light spacer">
  <div class="pageWrapper maxWidth">
    <h2>What's new with Taxelos ΤΞ?</h2>
    <div [ngClass]="{ 'row': isMobile, 'grid': !isMobile}">

      @for (news of newsPosts; track news.id) {
        <mat-card class="cardWithShadow card2 position-relative card-hover cursor-pointer"
                  [style.grid-row]="news.id"
                  [ngStyle]="isMobile ? { 'margin': '0 15px 30px 15px' } : {}">
          <img mat-card-image src="{{ news.imgSrc }}" alt="news-cards" />

          <mat-card-content class="p-y-24">
            <mat-card-title class="mat-headline-6">{{ news.title }}</mat-card-title>
            <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 m-b-24 f-s-16">{{ news.description }}
            </mat-card-subtitle>
            <div class="d-flex m-t-24">
                <span class="f-s-14 mat-subtitle-2 d-flex">
                  <i-tabler name="point" class="icon-14 m-r-4"></i-tabler>
                  {{ news.date }}</span>
            </div>
            <button (click)="navigate(news.url)" mat-flat-button color="primary"
                    class="w-100 m-t-16">{{ news.buttonTxt }}
            </button>
          </mat-card-content>
        </mat-card>
      }

    </div>
  </div>
</div>
