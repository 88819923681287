import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatCard, MatCardActions, MatCardContent, MatCardHeader, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { MatDivider } from '@angular/material/divider';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { AccountantWithServices } from '@wmt/shared';
import { CurrencyPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { AccountantReviewFormComponent } from '../accountant-review-form/accountant-review-form.component';
import { AccountantCommentsComponent } from '../accountant-comments/accountant-comments.component';
import { AccountantRequestFormComponent } from '../accountant-request-form/accountant-request-form.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'wmt-accountant-list',
  standalone: true,
  templateUrl: './accountant-list.component.html',
  imports: [
    MatCard,
    MatCardHeader,
    MatCardTitle,
    IconsModule,
    MatCardContent,
    MatDivider,
    MatCardActions,
    MatAnchor,
    RouterLink,
    MatIconButton,
    NgClass,
    NgIf,
    CurrencyPipe,
    MatButton,
    AccountantReviewFormComponent,
    NgForOf,
  ],
  styleUrls: ['./accountant-list.component.scss'],
})
export class AccountantListComponent {
  @Input() accountants: AccountantWithServices[] = [];
  @Input() totalAccountants = 0;
  @Input() totalPages = 0;
  @Output() pageChange = new EventEmitter<number>();
  private dialog = inject(MatDialog);

  currentPage = 1;
  maxStars: number[] = Array.from({ length: 5 }, (_, i) => i);
  isSingleColumn = false;

  toggleLayout() {
    this.isSingleColumn = !this.isSingleColumn;
  }

  onPageChange(page: number) {
    if (page > 0 && page <= this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit(this.currentPage);
    }
  }

  openReviewFormDialog(accountant: AccountantWithServices) {
    this.dialog.open(AccountantReviewFormComponent, {
      maxWidth: '658px',
      data: { accountant },
      disableClose: true,
    });
  }

  openCommentsDialog(accountant: AccountantWithServices) {
    this.dialog.open(AccountantCommentsComponent, {
      maxWidth: '658px',
      width: '658px',
      data: { accountant },
      disableClose: true,
    });
  }

  openRequestFormDialog(accountant: AccountantWithServices) {
    this.dialog.open(AccountantRequestFormComponent, {
      maxWidth: '658px',
      width: '658px',
      data: { accountant },
      disableClose: true,
    });
  }
}
