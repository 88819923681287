import { Injectable, Inject } from '@angular/core';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import {
  Workbook,
  Worksheet,
  Column,
  Row,
  Cell,
  CellStyle,
  Rows,
  Cells,
  HyperLink,
} from '@syncfusion/ej2-excel-export';
import { logoImageJpeg } from '../../common/components/logo/logo';
import { getFileNameWithDate } from '../../common/utils/file-name.utils';
import { ItDataItem } from '../../features/income-tax/it-data-item';
import { TcDataItem } from '../../features/tax-comparison/tc-data-item';

type ExportResultItem = ItDataItem | TcDataItem;

/*
* Syncfusion Xlsx Export Image Interface.
* See node_modules/@syncfusion/ej2-excel-export/src/image.d.ts
*/
interface XlsxImage {
  image: string
  row: number
  column: number
  lastRow: number
  lastColumn: number
  width: number
  height: number
  horizontalFlip: boolean
  verticalFlip: boolean
  rotation: number
  lastRowOffset: number
  lastColOffset: number
}

@Injectable({
  providedIn: 'root',
})
export class WmtXlsxExportService {
  appUrl = '';

  // Text contents
  disclaimer = 'Note: This calculator is for illustrative purposes only. '
  + 'No guarantee is made for the accuracy of the data provided. '
  + 'Consult a qualified tax services professional before making any decision.';

  userInputTitle = 'User inputs';
  userInputTableHeaderTitle = 'Input fields';
  userInputTableHeaderValue = 'Values';
  calculatedOutputTitle = 'Calculated output';
  moreText = 'Like to know more?';
  moreText2 = 'Please go to our homepage at';

  constructor(@Inject(LIB_ENVIRONMENT) private environment: LibEnvironment) {
    this.appUrl = this.environment.appUrl;
  }

  generateXlsx(
    inputs: Record<string, string>,
    inputTitles: Record<string, string>,
    resultTitles: Record<string, string>,
    results: ExportResultItem[],
    documentId: string,
    documentTitle: string,
  ): void {
    const sheet = this.createWorksheet(inputs, inputTitles, resultTitles, results, documentId, documentTitle);

    const workbook = new Workbook({
      worksheets: [sheet],
    }, 'xlsx');

    workbook.save(getFileNameWithDate(documentId, 'xlsx'));
  }

  private createWorksheet(
    inputs: Record<string, string>,
    inputTitles: Record<string, string>,
    resultTitles: Record<string, string>,
    results: ExportResultItem[],
    documentId: string,
    documentTitle: string): Worksheet {
    let rowIndex = 0;
    const worksheet = new Worksheet();
    worksheet.name = documentId;

    // Determine the number of columns needed
    const inputKeys = Object.keys(inputTitles);
    const resultKeys = Object.keys(resultTitles);
    const totalColumns = Math.max(inputKeys.length, resultKeys.length);

    // Setup columns dynamically based on the number of properties
    worksheet.columns = [];
    for (let i = 0; i < totalColumns; i++) {
      const column = new Column();
      column.index = i + 1;
      column.width = i === 0 ? 300 : 100; // First column wider
      worksheet.columns.push(column);
    }

    // Initialize rows
    worksheet.rows = new Rows();

    // Add logo image
    const image: XlsxImage = {
      image: logoImageJpeg,
      row: 1,
      column: 1,
      width: 60,
      height: 60,
      lastRow: 0, // ???
      lastColumn: 0, // ???
      horizontalFlip: false,
      verticalFlip: false,
      rotation: 0,
      lastRowOffset: 0, // ???
      lastColOffset: 0, // ???
    };
    worksheet.images = [image];
    rowIndex = 1;

    // Add a row for the title next to the logo
    const titleRow = new Row();
    titleRow.height = 60;
    titleRow.index = rowIndex;
    titleRow.cells = new Cells();
    // Create a cell for the document title
    const titleCell = new Cell();
    titleCell.index = 2; // Adjust the index to place next to the logo
    titleCell.value = documentTitle;
    titleCell.colSpan = Object.keys(resultTitles).length - 1;
    titleCell.cellStyle = new CellStyle();
    // titleCell.cellStyle.name = 'testStyle';
    // titleCell.cellStyle.fontName = 'Calibri';
    titleCell.cellStyle.fontSize = 14;
    // titleCell.cellStyle.fontColor = '#333333';
    // titleCell.cellStyle.bold = true;
    // titleCell.cellStyle.hAlign = 'center ';
    // titleCell.cellStyle.vAlign = 'center';
    // titleCell.cellStyle.index = 1;
    // titleCell.styleIndex = 1;
    titleRow.cells.add(titleCell);
    worksheet.rows.add(titleRow);

    // Input Header Title
    const inputHeaderTitleRow = new Row();
    rowIndex += 3;
    inputHeaderTitleRow.index = rowIndex;
    inputHeaderTitleRow.cells = new Cells();

    const inputHeaderTitleCell1 = new Cell();
    inputHeaderTitleCell1.index = 1;
    inputHeaderTitleCell1.value = this.userInputTitle;
    inputHeaderTitleCell1.colSpan = Object.keys(resultTitles).length;
    inputHeaderTitleRow.cells.add(inputHeaderTitleCell1);
    worksheet.rows.add(inputHeaderTitleRow);

    // Input Data Header
    const inputDataHeaderRow = new Row();
    rowIndex += 2;
    inputDataHeaderRow.index = rowIndex;
    inputDataHeaderRow.cells = new Cells();

    const inputDataHeaderCell1 = new Cell();
    inputDataHeaderCell1.index = 1;
    inputDataHeaderCell1.value = this.userInputTableHeaderTitle;
    inputDataHeaderRow.cells.add(inputDataHeaderCell1);

    const inputDataHeaderCell2 = new Cell();
    inputDataHeaderCell2.index = 2;
    inputDataHeaderCell2.value = this.userInputTableHeaderValue;
    inputDataHeaderRow.cells.add(inputDataHeaderCell2);

    worksheet.rows.add(inputDataHeaderRow);

    // Input Data Rows
    rowIndex++;
    Object.entries(inputs).forEach(([key, value]) => {
      const row = new Row();
      row.index = rowIndex++;
      row.cells = new Cells();

      const cell1 = new Cell();
      cell1.index = 1;
      cell1.value = inputTitles[key] || key;
      row.cells.add(cell1);

      const cell2 = new Cell();
      cell2.index = 2;
      cell2.value = value;
      row.cells.add(cell2);

      worksheet.rows.add(row);
    });

    // Result Header Title
    const resultHeaderTitleRow = new Row();
    rowIndex += 2;
    resultHeaderTitleRow.index = rowIndex;
    resultHeaderTitleRow.cells = new Cells();

    const resultHeaderTitleCell1 = new Cell();
    resultHeaderTitleCell1.index = 1;
    resultHeaderTitleCell1.value = this.calculatedOutputTitle;
    resultHeaderTitleCell1.colSpan = Object.keys(resultTitles).length;
    resultHeaderTitleRow.cells.add(resultHeaderTitleCell1);
    worksheet.rows.add(resultHeaderTitleRow);

    // Result Data Header
    const resultDataHeaderRow = new Row();
    rowIndex += 2;
    resultDataHeaderRow.index = rowIndex;
    resultDataHeaderRow.cells = new Cells();

    // Dynamically adding header cells based on resultTitles
    Object.keys(resultTitles).forEach((key, idx) => {
      const headerCell = new Cell();
      headerCell.index = idx + 1;
      headerCell.value = resultTitles[key];
      resultDataHeaderRow.cells.add(headerCell);
    });

    worksheet.rows.add(resultDataHeaderRow);

    // Result Data Rows
    results.forEach((result) => {
      const row = new Row();
      row.index = ++rowIndex;
      row.cells = new Cells();

      Object.keys(resultTitles).forEach((key, idx) => {
        if (Object.prototype.hasOwnProperty.call(result, key)) { // Ensure the result has the key
          const dataCell = new Cell();
          dataCell.index = idx + 1;
          dataCell.value = result[key as keyof typeof result];
          row.cells.add(dataCell);
        }
      });

      worksheet.rows.add(row);
    });

    // Disclaimer
    const disclaimerRow = new Row();
    rowIndex += 2;
    disclaimerRow.index = rowIndex;
    disclaimerRow.cells = new Cells();

    const disclaimerCell = new Cell();
    disclaimerCell.index = 1;
    disclaimerCell.value = this.disclaimer;
    disclaimerRow.cells.add(disclaimerCell);

    worksheet.rows.add(disclaimerRow);

    // More info
    const moreInfoRow = new Row();
    rowIndex++;
    moreInfoRow.index = rowIndex;
    moreInfoRow.cells = new Cells();

    const moreInfoCell = new Cell();
    moreInfoCell.index = 1;
    moreInfoCell.value = this.moreText;
    moreInfoRow.cells.add(moreInfoCell);

    worksheet.rows.add(moreInfoRow);

    // More info 2
    const moreInfo2Row = new Row();
    rowIndex++;
    moreInfo2Row.index = rowIndex;
    moreInfo2Row.cells = new Cells();

    const moreInfo2Cell = new Cell();
    moreInfo2Cell.index = 1;
    moreInfo2Cell.value = this.moreText2;
    moreInfo2Row.cells.add(moreInfo2Cell);

    worksheet.rows.add(moreInfo2Row);

    // Add hyperlink to the homepage
    const homepageRow = new Row();
    rowIndex++;
    homepageRow.index = rowIndex;
    homepageRow.cells = new Cells();

    const homepageCell = new Cell();
    homepageCell.index = 1;
    homepageCell.value = this.appUrl;
    homepageRow.cells.add(homepageCell);

    worksheet.rows.add(homepageRow);

    const hyperlink = new HyperLink();
    hyperlink.ref = `A${homepageRow.index + 1}`;
    hyperlink.location = this.appUrl;
    hyperlink.display = 'Visit Our HomePage';
    hyperlink.toolTip = 'Click to visit our homepage';
    hyperlink.target = '_blank';
    hyperlink.type = 'url';

    if (!worksheet.hyperLinks) {
      worksheet.hyperLinks = [];
    }
    worksheet.hyperLinks.push(hyperlink);

    return worksheet;
  }
}
