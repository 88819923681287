import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { LIB_ENVIRONMENT } from '../../../index';

@Injectable({
  providedIn: 'root',
})
export class AiVoteService {
  private environment = inject(LIB_ENVIRONMENT) as LibEnvironment;
  private http = inject(HttpClient);
  private apiUrl = this.environment.apiBaseUrl;

  voteForSubscription(machineId: string | null): Observable<any> {
    const voteData = machineId ? { machineId } : {};
    return this.http.post(`${this.apiUrl}/ai/vote`, voteData);
  }

  getVoteInfo(machineId: string | null): Observable<any> {
    return this.http.get(`${this.apiUrl}/ai/vote/info/${machineId}`, {});
  }
}
