import { Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelTitle,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import { RouterLink } from '@angular/router';
import { MatAnchor, MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { LogoComponent } from '../../logo/logo.component';
import { MenuService } from '../../../../services/utility/menu.service';
import { AppContextService } from '../../../../services/utility/app-context.service';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { MenuItemsService } from '../menu-items.service';

@Component({
  selector: 'wmt-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrl: './mobile-menu.component.scss',
  standalone: true,
  imports: [
    MatSidenav,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelHeader,
    RouterLink,
    MatAnchor,
    LogoComponent,
    MatMiniFabButton,
    MatButton,
    MatIconButton,
    IconsModule,
  ],
})
export class MobileMenuComponent {
  isWebNl: boolean;
  taxApps;
  helpMenuItems;

  constructor(
    private menuService: MenuService,
    private ACS: AppContextService,
    private menuItemService: MenuItemsService,
  ) {
    this.isWebNl = this.ACS.isWebNl();
    this.taxApps = this.menuItemService.getTaxApps();
    this.helpMenuItems = this.menuItemService.getHelpMenuItems();
  }

  onToggleSidenav() {
    this.menuService.toggle();
  }
}
