import { z } from 'zod';

export const TaxQuerySchema = z.object({
  taxQueryId: z.string().uuid().optional(),
  sessionDate: z.date().optional(),
  userIp4: z.string().max(40),
  userMachineId: z.string().max(32),
  conversationId: z.string().optional().nullable(),
  conversationSummary: z.string().optional().nullable(),
  questionCount: z.number().int().optional().nullable(),
  needsPaidSubscription: z.boolean().optional().nullable(),
  voteDate: z.date().optional().nullable(),
  isActive: z.boolean().default(true),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type TaxQuery = z.infer<typeof TaxQuerySchema>;
