import { Directive, ElementRef, HostListener } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Directive({
  selector: '[wmtPhoneInput]',
  standalone: true,
})
export class PhoneInputDirective {
  private phoneUtil = PhoneNumberUtil.getInstance();

  constructor(private el: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let value = this.el.nativeElement.value;

    if (value.startsWith('00')) {
      value = '+' + value.substring(2);
    }

    // Allow digits, plus, hyphens, parentheses and spaces
    value = value.replace(/[^\d+\-() ]/g, '');

    if (this.el.nativeElement.value !== value) {
      this.el.nativeElement.value = value;
      event.stopPropagation();
    }
  }

  @HostListener('blur') onBlur() {
    const initialValue = this.el.nativeElement.value;
    try {
      const number = this.phoneUtil.parse(initialValue);
      const isValid = this.phoneUtil.isValidNumber(number);

      if (isValid) {
        this.el.nativeElement.value = this.phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
      }
    }
    catch (error) {
      console.error('Error parsing phone number:', error);
    }
  }
}
