<div id="wmt-card-section" class="wmt-bg-secondary spacer">
  <div class="pageWrapper maxWidth">
    <h2>Try our tax calculator for free</h2>

    <!--<img *ngIf="!this.isMobile" class="wmt-logo-extended" ngSrc="assets/images/logo-extended.svg" height="90"
         width="525"
         alt="Logo extended">-->

    <div class="row justify-content-center">
      @for (service of services; track service.title) {
        <div class="col-sm-6 col-lg-4">
          <mat-card class="card-hover">
            <mat-card-content class="p-y-24">
              <img src="{{ service.image }}" alt="user" width="54"/>
              <mat-card-title class="m-t-16 m-b-16">{{ service.title }}</mat-card-title>
              <div class="d-flex align-items-center">
                <span class="f-s-14" [innerHTML]="service.description"></span>
              </div>
              <button mat-stroked-button color="primary" class="w-100 m-t-24"
                      (click)="calculateTax(service.id)">{{ service.buttonLabel }}
              </button>
            </mat-card-content>
          </mat-card>
        </div>
      }
    </div>

    <!--<div *ngIf="!this.isMobile" class="row ads">
      <div class="col-lg-12 col-sm-12 col-12">
        <mat-card class="text-center bg-light-primary shadow-none b1">
          <mat-card-content class="p-32">
            <span><i-tabler name="ad-2" class="text-accent m-r-8"></i-tabler></span>
            <h4 class="mat-subtitle-2 f-w-600 text-primary m-t-8">Advertisement</h4>
            <h6 class="m-t-4 mat-subtitle-1 f-w-600 text-primary m-t-8">This will be used to show advertisement or
              custom
              banners on Desktop</h6>
          </mat-card-content>
        </mat-card>
      </div>
    </div>-->

    <!--<div *ngIf="this.isMobile" class="row ads">
      <div class="col-lg-12 col-sm-12 col-12">
        <mat-card class="text-center bg-light-primary shadow-none">
          <mat-card-content class="p-32">
            <span><i-tabler name="ad-2" class="text-accent m-r-8"></i-tabler></span>
            <h4 class="mat-subtitle-2 f-w-600 text-primary m-t-8">Advertisement</h4>
            <h6 class="m-t-4 mat-subtitle-1 f-w-600 text-primary m-t-8">This will be used to show advertisement or
              custom
              banners on Mobile</h6>
          </mat-card-content>
        </mat-card>
      </div>
    </div>-->

  </div>
</div>
