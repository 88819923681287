@if (isConsumerFeedback) {
  <mat-card class="cardWithShadow card2 position-relative cursor-pointer text-center wmt-bg-2">
    <mat-card-content class="p-24">
      <mat-card-title class="mat-headline-6">How did we do ?</mat-card-title>
      <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 m-b-24 f-s-16 wmt-text-height">
        Please take a few minutes to give feedback on how you find Taxelos. It helps us immensely to make the best
        version of the product for you.
      </mat-card-subtitle>
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <a href="{{productFeedBackLink}}" target="_blank">
            <button mat-flat-button color="primary" class="w-100 m-t-16">Click here to give feedback</button>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
} @else if (isAccountantFeedback) {
  <mat-card class="cardWithShadow card2 position-relative cursor-pointer text-center wmt-bg-2">
    <mat-card-content class="p-24">
      <mat-card-title class="mat-headline-6">You matter!!!</mat-card-title>
      <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 m-b-24 f-s-16 wmt-text-height">
        To build the best possible product offering we rely on you. <br>Please give your ernest views on our product and
        send us any feature requests. We are always listening.
      </mat-card-subtitle>
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <a href="{{accountantFeedbackLink}}" target="_blank">
            <button mat-flat-button color="primary" class="w-100 m-t-16">Fill up our 6 question survey</button>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
}
