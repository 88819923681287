<form class="m-t-24" [formGroup]="companyServicesForm">
  <p class="text-center">Please fill up the services that you provide and mention the charges you levy for the same.</p>

  <div class="bg-extra-light wmt-form-accent">
    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block">Do you provide paid tax consultation services?
              @if (fv.hasError('hasTaxConsultation')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasTaxConsultation')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2">
            <mat-radio-group aria-label="Select an option" formControlName="hasTaxConsultation">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  @if (fv.f?.['hasTaxConsultation']?.value === 'yes') {
    <h4 class="f-s-18">Consultation Fees:</h4>
    <div class="row">

      <div class="col-lg-4 p-b-16">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-between p-0" style="margin-left: 5px">
            <mat-checkbox formControlName="isFirstConsultationFree" color="primary">
              First consultation meeting is free of charge
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="col-lg-8">
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-between p-0" style="margin-left: 5px">
            <mat-checkbox formControlName="freeConsultationForClients" color="primary">
              All future consultations are free for onboarded clients
            </mat-checkbox>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>For individuals/expats</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="consultation-for-individuals-price" [iconName]="'currency-euro'"
                               formControlName="consultationForIndividualsPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>For businesses</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="consultation-for-businesses-price" [iconName]="'currency-euro'"
                               formControlName="consultationForBusinessesPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>US tax consulting</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="consultation-for-us-tax-price" [iconName]="'currency-euro'"
                               formControlName="consultationForUsTaxPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

    </div>
  }

  <h4 class="f-s-18">Tax filing charges (charged per filing):</h4>
  <div class="row">

    <div class="col-lg-3">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>For individuals<span class="wmt-required">*</span></span>
          </mat-label>
        </div>
        <div class="col-sm-12 p-r-0">
          <wmt-numeric-input placeholder="0.00" id="tax-individuals-price" [iconName]="'currency-euro'"
                             formControlName="taxForIndividualsPrice"></wmt-numeric-input>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>w/ partner</span>
          </mat-label>
        </div>
        <div class="col-sm-12">
          <wmt-numeric-input placeholder="0.00" id="tax-w-partner-price" [iconName]="'currency-euro'"
                             formControlName="taxWPartnerPrice"></wmt-numeric-input>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>For freelancers/ZZPs<span class="wmt-required">*</span></span>
          </mat-label>
        </div>
        <div class="col-sm-12">
          <wmt-numeric-input placeholder="0.00" id="tax-freelancers-price" [iconName]="'currency-euro'"
                             formControlName="taxForFreelancersPrice"></wmt-numeric-input>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>For all other entities<span class="wmt-required">*</span></span>
          </mat-label>
        </div>
        <div class="col-sm-12">
          <wmt-numeric-input placeholder="0.00" id="tax-other-entities-price" [iconName]="'currency-euro'"
                             formControlName="taxForOthersEntitiesPrice"></wmt-numeric-input>
        </div>
      </div>
    </div>

  </div>

  <div class="bg-extra-light wmt-form-accent">
    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block">Do you provide book keeping services?
              @if (fv.hasError('hasBookKeepingServices')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasBookKeepingServices')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2">
            <mat-radio-group aria-label="Select an option" formControlName="hasBookKeepingServices">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  @if (fv.f?.['hasBookKeepingServices']?.value === 'yes') {
    <h4 class="f-s-18">Book keeping charges for a company including quarterly VAT filings (charged per quarter):</h4>

    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block m-b-16">
              Do you have two prices depending on total monthly transactions (mutations)?
              @if (fv.hasError('hasBookKeepingPriceLimit')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasBookKeepingPriceLimit')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2 m-b-16">
            <mat-radio-group aria-label="Select an option" formControlName="hasBookKeepingPriceLimit">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              @if (fv.f?.['hasBookKeepingPriceLimit']?.value === 'yes') {
                Quarterly charges under the limit:
              } @else {
                Quarterly charges:
              }
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="book-keeping-price" [iconName]="'currency-euro'"
                               formControlName="bookKeepingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>
      @if (fv.f?.['hasBookKeepingPriceLimit']?.value === 'yes') {
        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Total monthly transactions limit:<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0" id="book-keeping-monthly-transaction-limit" [allowDecimals]="false"
                                 formControlName="bookKeepingMonthlyTransactionsLimit"></wmt-numeric-input>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                Quarterly charges above the limit:
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0.00" id="book-keeping-price-above" [iconName]="'currency-euro'"
                                 formControlName="bookKeepingPriceAbove"></wmt-numeric-input>
            </div>
          </div>
        </div>
      }

    </div>
  }

  <div class="bg-extra-light wmt-form-accent">
    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block">Do you provide employee payroll services?
              @if (fv.hasError('hasPayrollServices')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasPayrollServices')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2">
            <mat-radio-group aria-label="Select an option" formControlName="hasPayrollServices">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  @if (fv.f?.['hasPayrollServices']?.value === 'yes') {
    <h4 class="f-s-18">Employee payroll charges (charged per quarter):</h4>
    <div class="row">

      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600  d-flex justify-content-between w-100 m-b-16">
              DGA (directeur-grootaandeelhouder) payroll charges:
            </mat-label>
          </div>
          <div class="col-sm-8">
            <wmt-numeric-input placeholder="0.00" id="employee-dga-payroll-price" [iconName]="'currency-euro'"
                               formControlName="employeeDgaPayrollPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600  d-flex justify-content-between w-100 m-b-16 wmt-sm-error">
              Other employee payroll services:
            </mat-label>
          </div>
        </div>
      </div>

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block m-b-16">
              Do you give a subsidized rate after a certain threshold no. of employees?
              @if (fv.hasError('hasPayrollPriceThreshold')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasPayrollPriceThreshold')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2 m-b-16">
            <mat-radio-group aria-label="Select an option" formControlName="hasPayrollPriceThreshold">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              @if (fv.f?.['hasPayrollPriceThreshold']?.value === 'yes') {
                Charges per employee below threshold:
              } @else {
                Charges per employee:
              }
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="employee-payroll-price" [iconName]="'currency-euro'"
                               formControlName="employeePayrollPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      @if (fv.f?.['hasPayrollPriceThreshold']?.value === 'yes') {
        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Threshold employee count:<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0" id="employee-payroll-threshold" [allowDecimals]="false"
                                 formControlName="employeePayrollThreshold"></wmt-numeric-input>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                Charges per employee above threshold:
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0.00" id="employee-payroll-price-above" [iconName]="'currency-euro'"
                                 formControlName="employeePayrollPriceAbove"></wmt-numeric-input>
            </div>
          </div>
        </div>
      }

    </div>
  }

  <div class="bg-extra-light wmt-form-accent">
    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block">Does your company file US taxes for american expats?
              @if (fv.hasError('hasServicesForUsExpats')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasServicesForUsExpats')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2">
            <mat-radio-group aria-label="Select an option" formControlName="hasServicesForUsExpats">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  @if (fv.f?.['hasServicesForUsExpats']?.value === 'yes') {
    <h4 class="f-s-18">US tax filing charges for Americans living in the Netherlands (charged per filing):</h4>
    <div class="row">

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>Federal taxes</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="us-federal-tax-filing-price" [iconName]="'currency-euro'"
                               formControlName="usFederalTaxFilingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>State taxes (1st state)</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="us-state-tax-filing-price" [iconName]="'currency-euro'"
                               formControlName="usStateTaxFilingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>Additional states</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="us-additional-states-filing-price" [iconName]="'currency-euro'"
                               formControlName="usAdditionalStatesFilingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>F-bar filing</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="us-fbar-filing-price" [iconName]="'currency-euro'"
                               formControlName="usFbarFilingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>Form 5471</span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="us-form-5471-filing-price" [iconName]="'currency-euro'"
                               formControlName="usForm5471FilingPrice"></wmt-numeric-input>
          </div>
        </div>
      </div>

    </div>
  }

  <div class="bg-extra-light wmt-form-accent">
    <div class="row">

      <div class="col-lg-12">
        <div class="row justify-content-between">
          <div class="col-sm-10 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 d-block">Do you provide combined packages for companies
              (single price per quarter that includes book keeping, VAT filing , corporate tax filing and one director's
              annual tax and salary management)?
              @if (fv.hasError('hasCombinedPackages')) {
                <mat-error *ngFor="let error of fv.getFormErrors('hasCombinedPackages')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </mat-label>
          </div>
          <div class="col-sm-2">
            <mat-radio-group aria-label="Select an option" formControlName="hasCombinedPackages">
              <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
              <mat-radio-button value="no" color="primary">No</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  @if (fv.f?.['hasCombinedPackages']?.value === 'yes') {
    <h4 class="f-s-18">Packages price (charged per quarter):</h4>

    <div class="col-lg-12 p-0">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 wmt-sm-error">
            <span>Please check the services that you offer in the package<span class="wmt-required">*</span></span>
            <!--@if (companyProfileForm.hasError('atLeastOneLanguage')) {
              <mat-error>
                {{ companyProfileForm.getError('atLeastOneLanguage')?.message }}
              </mat-error>
            }-->
          </mat-label>
        </div>
        <div class="col-sm-12 d-flex justify-content-between p-0" style="margin-left: 5px">
          <mat-checkbox formControlName="packageHasAnnualCorporateTaxFiling" color="primary">
            Annual corporate tax filing
          </mat-checkbox>
          <mat-checkbox formControlName="packageHasTaxAdvisoryServices" color="primary">
            Tax advisory services
          </mat-checkbox>
          <mat-checkbox formControlName="packageHasVatFilingsPerQuarter" color="primary">
            VAT filings per quarter
          </mat-checkbox>
          <mat-checkbox formControlName="packageHasCompanyDgaSalaryAndFiling" color="primary">
            Company DGA salary management & his personal tax filing
          </mat-checkbox>
          <mat-checkbox formControlName="packageHasBookKeeping" color="primary">
            Book-keeping service
          </mat-checkbox>
        </div>
      </div>
    </div>

    @if (fv.f?.['packageHasBookKeeping']?.value) {
      <div class="row">

        <div class="col-lg-12">
          <div class="row justify-content-between">
            <div class="col-sm-10 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 d-block m-b-5 m-t-16">
                Do you have different prices based on total monthly transactions (mutations)?
                @if (fv.hasError('hasPackagePriceLimit')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('hasPackagePriceLimit')"
                             style="display: inline" class="f-w-600">{{ error }}
                  </mat-error>
                }
              </mat-label>
            </div>
            <div class="col-sm-2 m-t-15">
              <mat-radio-group aria-label="Select an option" formControlName="hasPackagePriceLimit">
                <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                <mat-radio-button value="no" color="primary">No</mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </div>

      </div>
    }

    <div class="row">

      <div class="col-lg-4">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-16">
              @if (fv.f?.['hasPackagePriceLimit']?.value === 'yes') {
                Package price below the limit:
              } @else {
                Package Price:
              }
            </mat-label>
          </div>
          <div class="col-sm-12">
            <wmt-numeric-input placeholder="0.00" id="package-price" [iconName]="'currency-euro'"
                               formControlName="packagePrice"></wmt-numeric-input>
          </div>
        </div>
      </div>
      @if (fv.f?.['hasPackagePriceLimit']?.value === 'yes') {
        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-16">
                <span>Total monthly transactions limit:<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0" id="package-monthly-transaction-limit" [allowDecimals]="false"
                                 formControlName="packageMonthlyTransactionsLimit"></wmt-numeric-input>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-16">
                Package price above the limit:
              </mat-label>
            </div>
            <div class="col-sm-12">
              <wmt-numeric-input placeholder="0.00" id="package-price-above" [iconName]="'currency-euro'"
                                 formControlName="packageAbovePrice"></wmt-numeric-input>
            </div>
          </div>
        </div>
      }

    </div>
  }

  <div class="row">

    <div class="col-sm-12 d-flex align-items-center">
      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-24 m-t-24 f-s-18">
        Other services and capabilities:
        @if (fv.hasError('otherServices')) {
          <mat-error *ngFor="let error of fv.getFormErrors('otherServices')">
            {{ error }}
          </mat-error>
        }
      </mat-label>
    </div>
    <div class="col-sm-12">
      <mat-form-field appearance="outline" class="w-100">
                      <textarea rows="5" matInput
                                placeholder="{{otherServicesPlaceholder}}"
                                formControlName="otherServices"></textarea>
        <mat-icon matPrefix class="op-5">
          <i-tabler name="message-2" class="icon-20 d-flex"></i-tabler>
        </mat-icon>
      </mat-form-field>
    </div>

  </div>


  <div class="d-flex align-items-center justify-content-between">
    <button mat-flat-button color="warn" matStepperPrevious>Back</button>
    <button mat-flat-button color="primary" matStepperNext [disabled]="!companyServicesForm.valid">Next</button>
  </div>
</form>
