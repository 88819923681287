import { Inject, Injectable } from '@angular/core';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';

@Injectable({
  providedIn: 'root',
})
export class AppContextService {
  constructor(@Inject(LIB_ENVIRONMENT) private environment: LibEnvironment) {
  }

  isWebNl(): boolean {
    return this.environment.appName === 'web-nl';
  }

  isWebGlobal(): boolean {
    return this.environment.appName === 'web-global';
  }

  isProduction(): boolean {
    return this.environment.mode === 'production';
  }

  isDevelopment(): boolean {
    return this.environment.mode === 'development';
  }

  isLocal(): boolean {
    return this.environment.mode === 'local';
  }

  isStaging(): boolean {
    return this.environment.mode === 'staging';
  }
}
