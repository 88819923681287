<div class="logo" (click)="goToHomepage()">
  <!--<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 61 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.0523 25.0376V50.0002H43.1106V36.2266L38.4024 50.0002H32.5952L27.8516 36.1203V50.0002H20.8538V25.0376H29.3016L35.5697 41.2539L41.696 25.0376H50.0523Z" fill="#004AAD"/>
    <path d="M0 25.0375V0H6.97364V13.8445L11.7066 0H17.5422L22.3107 13.9508V0H29.3007V25.0375H20.8536L14.5535 8.78884L8.39886 25.0375H0Z" fill="#00BF63"/>
    <path d="M31.2974 0.000892529L41.696 0.000893155V2.22691V4.45293L31.2974 4.45204V0.000892529Z" fill="#FFD700"/>
    <path d="M41.696 0.000893155H50.0509V25.0369H41.696V0.000893155Z" fill="#FFD700"/>
    <path d="M50.0509 0.000893155L60.305 0V4.45114L50.0509 4.45204V0.000893155Z" fill="#FFD700"/>
  </svg>-->
  <img [src]="isHovering ? logoPath : logoSmPath" alt="Taxelos logo" [height]="height" [width]="width"
       (mouseover)="onMouseOver()" (mouseout)="onMouseOut()">
</div>
