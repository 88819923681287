<div class="wmt-hero-section overflow-hidden banner-section">
  <div class="container">
    <!--<h1 class="banner-title m-0 p-b-50 text-center wmt-accountant-title">
      Introducing our flagship product <span class="text-primary">Taxelos ΤΞ!</span></h1>-->
    <div class="row align-items-start">
      <div class="col-lg-6 d-flex align-items-center" style="padding-top: 40px">
        <div>
              <span class="f-s-16 f-w-600 m-b-16 d-flex align-items-center">
                <i-tabler name="rocket" class="text-accent m-r-8"></i-tabler>Inviting individuals and businesses to ...</span>
          <h1 class="banner-title m-0">Our flagship product <span class="text-primary">Taxelos ΤΞ!</span></h1>
          <p class="f-s-18 p-y-24 m-t-12 m-0 banner-subtitle">
            No more struggling through various tools to make sense of your financials. No more diving
            to the depths of the internet to find the right accountant for your needs. No more worrying about the
            complexities of the Dutch tax system.
            Any interested accountants, please fill out the form below to get started.
          </p>
          <div class="m-t-24 hstack">
            <a mat-flat-button color="primary" [routerLink]="['/partner-form']" class="btn-custom">Partner form -
              Accountants</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 wmt-carousel-container">
        <ejs-carousel [autoPlay]="true" indicatorsType="Progress" cssClass="wmt-carousel">
          <e-carousel-items>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero-accountant/card01.svg" width="458" height="440"
                         alt="Accountant marketplace" />
                  </div>
                </figure>
              </ng-template>
            </e-carousel-item>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero-accountant/card01.svg" width="458" height="440"
                         alt="Accountant marketplace" />
                  </div>
                </figure>
              </ng-template>
            </e-carousel-item>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero-accountant/card01.svg" width="458" height="440"
                         alt="Accountant marketplace" />
                  </div>
                </figure>
              </ng-template>
            </e-carousel-item>
          </e-carousel-items>
        </ejs-carousel>
      </div>
    </div>
  </div>
</div>
