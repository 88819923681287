import { Component, Input } from '@angular/core';
import { MatCard, MatCardContent, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../../ui/icons/icons.module';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'wmt-partner-card',
  standalone: true,
  imports: [
    MatCardContent,
    MatCard,
    MatCardTitle,
    MatCardSubtitle,
    IconsModule,
    DatePipe,
  ],
  providers: [],
  templateUrl: './partner-card.component.html',
  styleUrl: './partner-card.component.scss',
})
export class PartnerCardComponent {
  @Input() partner!: { companyName: string, state: string, joinedDate: Date };
}
