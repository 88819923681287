<div class="layout-container justify-content-center wmt-card-first">
  <div class="pageWrapper maxWidth">
    <div class="col-lg-12 p-0">
      <mat-card class="shadow-none bg-light-primary overflow-hidden">
        <mat-card-content class="p-32">
          <div class="row justify-content-between">
            <div class="col-sm-7">
              <mat-card-title class="f-s-20">We are really sorry. We are not available in your country yet.</mat-card-title>
              <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 m-b-24 f-s-16">
                Taxelos is currently accessible only to residents of the Netherlands.<br><br>
                If you are trying to access our platform from a different country, we regret to inform you that our
                services are
                exclusively available to Dutch residents at this time.<br><br>To access the Dutch portal, please visit
                <a (click)="navigateToNl()">{{ getNlUrl() }}</a><br><br>
                We appreciate your understanding and look forward to serving you in the future as we expand our services
                to more regions.<br><br>Thank you for your interest in Taxelos.<br><br>
              </mat-card-subtitle>
              <button mat-flat-button color="primary" (click)="navigateToNl()">Go to Taxelos NL</button>
            </div>
            <div class="col-sm-4">
              <img
                src="/assets/images/svgs/explore-card.svg"
                alt="not-available"
                width="400"
                class="not-available-img"
              />
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

