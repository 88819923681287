export const environment = {
  production: false,
  appName: 'web-nl',
  mode: 'development',
  gTagId: 'G-P343ZTTVLX',
  raiseDefectLink: 'https://daedalsystems.atlassian.net/servicedesk/customer/portal/1/group/1/create/9',
  featureRequestLink: 'https://daedalsystems.atlassian.net/servicedesk/customer/portal/1/group/1/create/5',
  productFeedBackLink: 'https://cambridge.eu.qualtrics.com/jfe/form/SV_bxvHTt0ulnFE4iW',
  accountantFeedbackLink: 'https://cambridge.eu.qualtrics.com/jfe/form/SV_0AJzcR2W9Wu4SUK',
  googleMapsApiKey: 'AIzaSyAZwsBKBpE5s9wJuESQYGUgoCeVQHOZbOw',
  apiBaseUrl: 'https://api.dev.taxelos.com',
  appUrl: 'https://nl.dev.taxelos.com',
  ghostApiUrl: 'https://insights.daedal.cloud',
  ghostApiKey: '76014f584a48a6781b8866efc9',
};
