<mat-form-field appearance="outline" class="w-100 wmt-numeric-input" [ngClass]="{'wmt-form-field-min': minDensity}"
                [style]="{width: formWidth}">
  <input #inputElement matInput [placeholder]="placeholder" [formControl]="formControl"
         type="text" inputmode="decimal" wmtPositiveInput [allowDecimals]="allowDecimals" (blur)="onBlur()">
  <!--<input #inputElement matInput [placeholder]="placeholder" [(ngModel)]="value" (ngModelChange)="updateValue($event)"
         type="text" inputmode="decimal" wmtPositiveInput [allowDecimals]="allowDecimals" (blur)="onBlur()"
         [disabled]="isDisabled" [formControl]="formControl">-->
  @if (iconName) {
    <mat-icon matPrefix class="op-5">
      <i-tabler [name]="iconName" class="icon-20 d-flex"></i-tabler>
    </mat-icon>
  }
  @if (hint) {
    <mat-hint align="end">{{ hint }}</mat-hint>
  }
  @if (fv.controlHasError(formControl)) {
    <mat-error *ngFor="let error of fv.getControlErrors(formControl)">
      {{ error }}
    </mat-error>
  }
  @if (chevronsEnabled) {
    <ng-container>
      <button mat-icon-button matSuffix (click)="increment()" aria-label="Increment value">
        <i-tabler name="chevron-up" class="wmt-numeric-input-icon"></i-tabler>
      </button>
      <button mat-icon-button matSuffix (click)="decrement()" [disabled]="isDecrementDisabled()"
              aria-label="Decrement value">
        <i-tabler name="chevron-down" class="wmt-numeric-input-icon"></i-tabler>
      </button>
    </ng-container>
  }
</mat-form-field>
