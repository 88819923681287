<h2 mat-dialog-title>{{ data.questionLimitErrorTitle }}</h2>
<mat-dialog-content>
  <p>
    You have reached the maximum number of questions you can ask the AI assistant.
    Please try again <strong>{{ countdown }}.</strong>
  </p>
  <wmt-vote [machineId]="data.machineId" [displayColumn]="true"></wmt-vote>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>
