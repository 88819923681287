<h2 class="m-t-10" style="font-size: 24px" mat-dialog-title>{{ data.accountant.companyName }}
  has {{ data.accountant.feedback ? data.accountant.feedback.totalFeedbacks : 'no reviews yet' }} reviews
</h2>
<button class="wmt-close-button" mat-icon-button mat-dialog-close aria-label="Close dialog">
  <i-tabler name="x" class="icon-20"></i-tabler>
</button>
<mat-dialog-content>
  @if (data.accountant.feedback) {
    @for (review of data.accountant.feedback.feedbacks; track review) {
      <div class="review-container">
        <div class="review-header d-flex justify-content-between align-items-center">
          <span class="reviewer-name">
            {{ review.firstName }} {{ review.lastName }}
          </span>
          <div class="star-rating">
            @for (star of maxStars; track star) {
              <i-tabler name="star" class="icon-20 star-icon"
                        [ngClass]="{'filled': review.stars > star}">
              </i-tabler>
            }
          </div>
        </div>
        <p class="review-date">
          {{ formatDate(review.createdAt) }}
        </p>
        <p class="review-feedback">
          @if (review.feedback) {
            {{ review.feedback }}
          } @else {
            <span class="text-muted">No comments provided</span>
          }
        </p>
      </div>
    }
  } @else {
    <p>
      This accountant has no reviews yet. <br>Please provide feedback to help us improve our service.
    </p>
  }
</mat-dialog-content>
