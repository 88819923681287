import { Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AccountantFeedback, AccountantVisitorFeedbackData, AccountantWithServices } from '@wmt/shared';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { isPlatformBrowser, NgClass, NgForOf } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { FeedbackService } from '../../../../services/api/feedback.service';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';

export interface DialogData {
  accountant: AccountantWithServices
}

@Component({
  selector: 'wmt-accountant-review-form',
  standalone: true,
  templateUrl: './accountant-review-form.component.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatIcon,
    IconsModule,
    NgForOf,
    MatInput,
    MatError,
    MatCheckbox,
    MatPrefix,
    NgClass,
    MatIconButton,
    MatRadioButton,
    MatRadioGroup,
  ],
  styleUrls: ['./accountant-review-form.component.scss'],
})
export class AccountantReviewFormComponent implements OnInit {
  private fb = inject(FormBuilder);
  private platformId = inject(PLATFORM_ID);
  private dialogRef = inject(MatDialogRef<AccountantReviewFormComponent>);
  public fv = inject(FormValidationService);
  public feedbackService = inject(FeedbackService);
  public data: DialogData = inject(MAT_DIALOG_DATA);

  submitted = false;
  feedbackExists = false;
  accountantReviewForm!: FormGroup;

  stars = Array(5).fill(0);
  selectedRating = 0;

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.dialogRef.afterClosed().subscribe(() => {
        this.resetForm();
      });
    }

    this.accountantReviewForm = this.fb.nonNullable.group({
      name: ['', { validators: [Validators.required, Validators.minLength(2)] }],
      firstName: [''],
      lastName: [''],
      email: ['', { validators: [Validators.required, Validators.email] }],
      stayAnonymous: ['No'],
      stars: [null, { validators: [Validators.required] }],
      feedback: [''],
      consent: [false],
    });

    this.fv.setCurrentForm(this.accountantReviewForm);
  }

  selectRating(rating: number) {
    this.selectedRating = rating;
    this.accountantReviewForm.get('stars')?.setValue(rating);
  }

  getLabel(index: number): string {
    const labels = ['Terrible', 'Bad', 'Ok', 'Good', 'Great'];
    return labels[index];
  }

  onSubmit() {
    const fullName = this.accountantReviewForm.value.name.trim();
    const nameParts = fullName.split(' ');

    if (this.accountantReviewForm.valid && this.data.accountant.accountantId) {
      const feedbackData: AccountantVisitorFeedbackData = {
        visitorFirstName: nameParts[0],
        visitorLastName: nameParts.length > 1 ? nameParts.slice(1).join(' ') : '',
        visitorEmail: this.accountantReviewForm.value.email,
        accountantId: this.data.accountant.accountantId,
        stars: this.accountantReviewForm.value.stars,
        feedback: this.accountantReviewForm.value.feedback,
        isAnonymous: this.accountantReviewForm.value.stayAnonymous === 'Yes',
        consent: this.accountantReviewForm.value.consent,
        createdAt: new Date(),
        updatedAt: new Date(),
      };

      this.feedbackService.createFeedback(feedbackData).subscribe({
        next: (response: AccountantFeedback | { status: 'exists' }) => {
          if ('actFeedbackId' in response) {
            this.submitted = true;
          }
        },
        error: (error) => {
          if (error.status === 409) {
            this.feedbackExists = true;
          }
          else {
            console.error('Error submitting feedback:', error);
          }
        },
      });
    }
    else {
      console.log('Form is invalid');
    }
  }

  resetForm() {
    this.submitted = false;
    this.selectedRating = 0;
    this.accountantReviewForm.reset();
  }
}
