import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ErrorMessages } from '@wmt/shared';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  private currentForm!: FormGroup;

  setCurrentForm(form: FormGroup): void {
    this.currentForm = form;
  }

  get f() {
    if (!this.currentForm) return null;
    return this.currentForm.controls;
  }

  getControl(controlName: string): FormControl {
    return this.currentForm?.get(controlName) as FormControl;
  }

  hasError(controlName: string): boolean {
    const control = this.currentForm?.get(controlName);
    if (!control) return false;
    return !!(control?.errors && (control?.dirty || control?.touched));
  }

  controlHasError(control: FormControl): boolean {
    if (!control) return false;
    return !!(control?.errors && (control?.dirty || control?.touched));
  }

  getErrorMessage(errorName: string): string {
    const errorMessages: ErrorMessages = {
      required: 'This field is required.',
      pattern: 'Invalid pattern.',
      min: 'Value is too low.',
      max: 'Value is too high.',
      minlength: 'Value is too short.',
      email: 'Please enter a valid email.',
    };
    return errorMessages[errorName] || 'Unknown error';
  }

  getFormErrors(controlName: string): string[] {
    const control = this.currentForm?.get(controlName);
    if (control && control.errors) {
      return Object.keys(control.errors).map((errorKey) => {
        const error = control.errors ? control.errors[errorKey] : null;
        if (error && typeof error === 'object' && 'message' in error) {
          return error.message;
        }
        else {
          return this.getErrorMessage(errorKey);
        }
      });
    }
    return [];
  }

  getControlErrors(control: FormControl): string[] {
    if (control && control.errors) {
      return Object.keys(control.errors).map((errorKey) => {
        const error = control.errors ? control.errors[errorKey] : null;
        if (error && typeof error === 'object' && 'message' in error) {
          return error.message;
        }
        else {
          return this.getErrorMessage(errorKey);
        }
      });
    }
    return [];
  }
}
