export function generateUUID(): string {
  const uuidTimestamp = new Date().getTime();
  const uuidSequence = generateUUIDSequence();
  return (
    hex(uuidTimestamp & 0xffffffff)
    + '-'
    + hex((uuidTimestamp >> 32) & 0xffff)
    + '-'
    + hex(((uuidTimestamp >> 48) & 0x0fff) | 0x4000)
    + '-'
    + hex((uuidSequence[0] << 8) | uuidSequence[1])
    + '-'
    + hex((uuidSequence[2] << 8) | uuidSequence[3])
    + hex((uuidSequence[4] << 8) | uuidSequence[5])
    + hex((uuidSequence[6] << 8) | uuidSequence[7])
    + hex((uuidSequence[8] << 8) | uuidSequence[9])
    + hex((uuidSequence[10] << 8) | uuidSequence[11])
  );
}

function generateUUIDSequence(): Uint8Array {
  const uuidSequence = new Uint8Array(12);
  for (let i = 0; i < uuidSequence.length; i++) {
    uuidSequence[i] = Math.floor(Math.random() * 256);
  }
  return uuidSequence;
}

function hex(value: number): string {
  return ('0' + value.toString(16)).slice(-4);
}

/**
 * Removes 'thread_' prefix from the given threadId if present.
 * @param threadId The thread ID with or without the 'thread_' prefix.
 * @returns The thread ID without the 'thread_' prefix.
 */
export function stripPrefix(threadId: string): string {
  return threadId.startsWith('thread_')
    ? threadId.slice('thread_'.length)
    : threadId;
}

/**
 * Adds 'thread_' prefix to the given threadId if not already present.
 * @param threadId The thread ID without the 'thread_' prefix.
 * @returns The thread ID with the 'thread_' prefix.
 */
export function addPrefix(threadId: string): string {
  return threadId.startsWith('thread_') ? threadId : `thread_${threadId}`;
}
