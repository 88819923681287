import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TaxRate } from '@wmt/shared';
import { Subscription } from 'rxjs';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField, MatHint, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { IconsModule } from '../../ui/icons/icons.module';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { SeparatorComponent } from '../../ui/separator/separator.component';
import { DisclaimerComponent } from '../../common/components/disclaimer/disclaimer.component';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { Column, GridComponent, GridModule, SelectionSettingsModel } from '@syncfusion/ej2-angular-grids';
import { ItDataItem } from './it-data-item';
import { positiveNumberVal } from '../../common/validators/positive-number.validator';
import { exportResultTitles, results } from './results';
import { exportInputTitles, getExportInputs, getIV, updateInputValues } from './input-values';
import { FormValidationService } from '../../services/utility/form-validation.service';
import { PositiveInputDirective } from '../../common/directives/positive-input.directive';
import { WmtNumericInputComponent } from '../../ui/wmt-numeric-input/wmt-numeric-input.component';
import { StoreService } from '../../services/api/store.service';
import { WmtPdfExportService } from '../../services/utility/pdf-export.service';
import { WmtXlsxExportService } from '../../services/utility/xlsx-export-service';
import { ClipboardService } from '../../services/utility/clipboard.service';

@Component({
  selector: 'wmt-income-tax',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    NgOptimizedImage,
    CardsComponent,
    SeparatorComponent,
    DisclaimerComponent,
    MatCard,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCardContent,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    MatSelect,
    MatOption,
    MatSlideToggle,
    MatCheckbox,
    MatRadioButton,
    MatRadioGroup,
    GridModule,
    IconsModule,
    MatError,
    MatHint,
    PositiveInputDirective,
    WmtNumericInputComponent,
  ],
  providers: [],
  templateUrl: './income-tax.component.html',
  styleUrl: './income-tax.component.scss',
})
export class IncomeTaxComponent implements OnInit, OnDestroy {
  documentTitle = 'Income Tax Calculation';
  public isMobile = true;
  private screenSubscription!: Subscription;
  incomeTaxForm!: FormGroup;
  lineItemsWidth = '154';
  valueWidth = '195';
  isFrozenColumn = false;
  public data: ItDataItem[] = [];
  incomeTaxPeriods: string[] = ['Year', 'Month'];
  workFromHomeDays: number[] = [0, 1, 2, 3, 4, 5];
  @ViewChild('grid') public grid?: GridComponent;
  public selectionOptions: SelectionSettingsModel = { type: 'Multiple' };
  exportColumns: Partial<Column>[] = [
    { field: 'lineItems', headerText: 'Description' },
    { field: 'input', headerText: 'Input', textAlign: 'Right' },
    { field: 'value', headerText: 'Output', textAlign: 'Right' },
  ];

  additionPrivateUseCar = 0;
  additionPrivateUseBike = 0;

  constructor(
    private screenService: ScreenSizeService,
    public fv: FormValidationService,
    private store: StoreService,
    private pdfService: WmtPdfExportService,
    private xlsxService: WmtXlsxExportService,
    private clipboardService: ClipboardService,
  ) {
  }

  ngOnInit() {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (isMobile) {
        this.lineItemsWidth = '140';
        this.valueWidth = '130';
        this.isFrozenColumn = true;
      }
      else {
        this.lineItemsWidth = '400';
      }
    });

    this.incomeTaxForm = new FormGroup({
      grossIncome: new FormControl(null, [Validators.required, positiveNumberVal()]),
      period: new FormControl('Year', [Validators.required]),
      ruling: new FormControl(false, [Validators.required]),
      rulingMonths: new FormControl({ value: '1st', disabled: true }, []),
      holidayAllowance: new FormControl('no', [Validators.required]),
      privateCarUse: new FormControl('no', [Validators.required]),
      carPrice: new FormControl(null, [Validators.required, positiveNumberVal()]),
      privateBikeUse: new FormControl('no', [Validators.required]),
      bikePrice: new FormControl(null, [Validators.required, positiveNumberVal()]),
      electric: new FormControl('no', [Validators.required]),
      workFromHomeDays: new FormControl('0', [
        Validators.required, Validators.min(1), Validators.max(5),
      ]),
      workKmPerMonth: new FormControl(null, [positiveNumberVal()]),
    });

    this.fv.setCurrentForm(this.incomeTaxForm);
    this.data = results;

    this.listenToFormChanges();
  }

  listenToFormChanges() {
    this.incomeTaxForm.get('privateCarUse')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.incomeTaxForm.get('workKmPerMonth')?.disable();
      }
      else {
        this.incomeTaxForm.get('workKmPerMonth')?.enable();
      }
    });
    this.incomeTaxForm.get('ruling')?.valueChanges.subscribe((value) => {
      if (value) {
        this.incomeTaxForm.get('rulingMonths')?.enable();
      }
      else {
        this.incomeTaxForm.get('rulingMonths')?.disable();
      }
    });
    this.incomeTaxForm.valueChanges.subscribe(() => {
      updateInputValues(this.incomeTaxForm);
      this.calculatePrivateUseOfAddition();
      this.calculateTax();
    });
  }

  calculatePrivateUseOfAddition() {
    // Rule 2: Addition: Private use of car
    const bijtellingTaxes: TaxRate[] = this.store.getTaxRatesByTaxName('Bijtelling');

    this.additionPrivateUseCar = 0;
    if (getIV().privateCarUseIn) {
      const bijtellingCarVat: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABV']);
      const vatRate = bijtellingCarVat[0].taxRate ?? 0;
      if (getIV().electricIn) {
        const electricBijtellingTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABE']);
        this.additionPrivateUseCar = this.incomeTaxAlgo(getIV().carPriceIn, electricBijtellingTaxes);
        this.additionPrivateUseCar = this.additionPrivateUseCar * (1 + vatRate);
      }
      else {
        const otherBijtellingTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABO']);
        otherBijtellingTaxes.forEach((taxRate) => {
          this.additionPrivateUseCar += getIV().carPriceIn * (taxRate.taxRate ?? 0);
        });
        this.additionPrivateUseCar = this.additionPrivateUseCar * (1 + vatRate);
      }

      this.grid?.setCellValue('privateUseOfCar', 'value', this.additionPrivateUseCar.toFixed(2));
    }
    else {
      const initialValue = 0;
      this.grid?.setCellValue('privateUseOfCar', 'value', initialValue.toFixed(2));
    }

    // Rule 3: Addition: Private use of bike
    this.additionPrivateUseBike = 0;
    if (getIV().privateBikeUseIn) {
      const fietsBijtellingBike: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['FIB']);
      fietsBijtellingBike.forEach((taxRate) => {
        this.additionPrivateUseBike += getIV().bikePriceIn * (taxRate.taxRate ?? 0);
      });

      this.grid?.setCellValue('privateUseOfBike', 'value', this.additionPrivateUseBike.toFixed(2));
    }
    else {
      const initialValue = 0;
      this.grid?.setCellValue('privateUseOfBike', 'value', initialValue.toFixed(2));
    }
  }

  calculateTax() {
    // Rule 1: Gross income
    const grossIncomeOut = getIV().periodIn === 'Year' ? getIV().grossIncomeIn : getIV().grossIncomeIn * 12;
    this.grid?.setCellValue('grossIncome', 'value', grossIncomeOut.toFixed(2));

    // Rule 4: Yearly gross holiday allowance and Taxable work related costs
    // const travelThreshold = 0.015 * grossIncomeOut;
    // const monthlyTravelExpense = Math.min(travelThreshold, workKmPerMonthIn * 12 * 0.21) / 12;
    const monthlyTravelExpense = Math.max(getIV().workKmPerMonthIn * 0.21, grossIncomeOut / 12 * 0.015);
    this.grid?.setCellValue('monthlyTravelExpense', 'value', monthlyTravelExpense.toFixed(2));

    // Taxable work related costs
    let taxableWorkCosts = 0;
    // const taxableWorkDifference = workKmPerMonthIn * 12 * 0.21 - grossIncomeOut * 0.015;
    // taxableWorkDifference > 0 ? taxableWorkCosts = taxableWorkDifference * 0.8 : taxableWorkCosts = 0;

    if (getIV().workKmPerMonthIn > 0) {
      taxableWorkCosts = Math.min(0, getIV().workKmPerMonthIn * 0.21 * 12 - grossIncomeOut * 0.015);
      this.grid?.setCellValue('taxableWorkCosts', 'value', taxableWorkCosts.toFixed(2));
    }
    else {
      this.grid?.setCellValue('taxableWorkCosts', 'value', '-');
    }
    this.grid?.setCellValue('taxableWorkCosts', 'value', taxableWorkCosts.toFixed(2));

    // Work from home allowance
    let workFromHomeAllowance = 0;
    workFromHomeAllowance = 52 * getIV().workFromHomeDaysIn * 2.15;
    this.grid?.setCellValue('workFromHomeAllowance', 'value', workFromHomeAllowance.toFixed(2));

    // Rule 5: Taxable Income
    const payrollTaxes: TaxRate[] = this.store.getTaxRatesByTaxName('Payroll Tax');
    let taxableIncome = 0;
    taxableIncome = grossIncomeOut + this.additionPrivateUseCar + this.additionPrivateUseBike + taxableWorkCosts;
    if (getIV().rulingIn) {
      const rulingMonths = getIV().rulingMonthsIn;
      if (rulingMonths === '1st') {
        taxableIncome = taxableIncome * 0.7;
      }
      else if (rulingMonths === '2nd') {
        taxableIncome = taxableIncome * 0.8;
      }
      else if (rulingMonths === 'last') {
        taxableIncome = taxableIncome * 0.9;
      }
    }
    this.grid?.setCellValue('taxableIncome', 'value', taxableIncome.toFixed(2));

    // Incl national insurance contrib
    let inclInsuranceContrib = 0;
    inclInsuranceContrib = 0.0543 * taxableIncome;
    this.grid?.setCellValue('inclInsuranceContrib', 'value', inclInsuranceContrib.toFixed(2));

    // Payroll tax Incl national insurance
    let payrollTaxInclInsurance = 0;
    payrollTaxInclInsurance = this.incomeTaxAlgo(taxableIncome, payrollTaxes);
    this.grid?.setCellValue('payrollTaxInclInsurance', 'value', payrollTaxInclInsurance.toFixed(2));

    // Rule 6: Tax credits
    let generalTaxCredit = 0;
    const taxCredits: TaxRate[] = this.store.getTaxRatesByTaxName('Tax Credits');
    const generalTaxCreditsTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(taxCredits, ['GTC']);
    generalTaxCredit = this.taxCreditAlgo(taxableIncome, generalTaxCreditsTaxes);
    this.grid?.setCellValue('generalTaxCredit', 'value', generalTaxCredit.toFixed(2));

    // Rule 7: Labour tax credit
    let labourTaxCredit = 0;
    const labourTaxCreditsTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(taxCredits, ['LTC']);
    labourTaxCredit = this.taxCreditAlgo(taxableIncome, labourTaxCreditsTaxes);
    this.grid?.setCellValue('labourTaxCredit', 'value', labourTaxCredit.toFixed(2));

    // Rule 8: Monthly gross income
    let monthlyGrossIncome = 0;
    monthlyGrossIncome = getIV().holidayAllowanceIn ? taxableIncome / 13 : taxableIncome / 12;
    this.grid?.setCellValue('monthlyGrossIncome', 'value', monthlyGrossIncome.toFixed(2));

    // Year Net Income (without adding travel expenses)
    let yearlyNetIncome = 0;
    yearlyNetIncome = taxableIncome + payrollTaxInclInsurance + generalTaxCredit + labourTaxCredit + workFromHomeAllowance;
    this.grid?.setCellValue('yearlyNetIncome', 'value', yearlyNetIncome.toFixed(2));

    // Rule 9: Monthly net income (including travel expense)
    let monthlyNetIncomeInclExpense = 0;
    if (getIV().holidayAllowanceIn) {
      monthlyNetIncomeInclExpense = yearlyNetIncome / 13 + monthlyTravelExpense;
    }
    else {
      monthlyNetIncomeInclExpense = yearlyNetIncome / 12 + monthlyTravelExpense;
    }
    this.grid?.setCellValue('monthlyNetIncomeInclExpense', 'value', monthlyNetIncomeInclExpense.toFixed(2));

    // 10: Net holiday allowance (paid out in May)
    let netHolidayAllowance = 0;
    if (getIV().holidayAllowanceIn) {
      netHolidayAllowance = monthlyNetIncomeInclExpense - monthlyTravelExpense;
    }
    else {
      netHolidayAllowance = 0;
    }
    this.grid?.setCellValue('netHolidayAllowance', 'value', netHolidayAllowance.toFixed(2));

    // Yearly gross holiday allowance
    let yearlyHolidayAllowance = 0;
    if (getIV().holidayAllowanceIn) {
      yearlyHolidayAllowance = monthlyGrossIncome;
    }
    else {
      yearlyHolidayAllowance = 0;
    }
    this.grid?.setCellValue('yearlyHolidayAllowance', 'value', yearlyHolidayAllowance.toFixed(2));

    // Effective tax rate
    let effectiveTaxRate = 0;
    if (grossIncomeOut > 0) {
      effectiveTaxRate = (monthlyNetIncomeInclExpense * 12 + netHolidayAllowance) / grossIncomeOut * 100;
    }
    else {
      effectiveTaxRate = 0;
    }

    this.grid?.setCellValue('effectiveTaxRate', 'value', effectiveTaxRate.toFixed(2) + '%');
  }

  taxCreditAlgo(taxableIncome: number, taxRates: TaxRate[]): number {
    let output = 0;

    for (const t of taxRates) {
      // Check if taxableIncome falls within the tier range
      if (t.minTier !== null && t.maxTier !== null
        && (taxableIncome >= t.minTier && (t.maxTier <= 0 || taxableIncome <= t.maxTier))) {
        // Calculate tax credit
        output = (t.taxCreditAmount ?? 0) + (t.taxCreditRate ?? 0) * (taxableIncome - t.minTier);
        // Exit the loop since we have found the applicable tax rate
        break;
      }
    }

    return output;
  }

  incomeTaxAlgo(taxableIncome: number, taxRates: TaxRate[]): number {
    let output = 0;

    for (const t of taxRates) {
      let formula = 0;
      if (t.minTier === 0 && t.taxRate !== null) {
        formula = Math.min(t.taxRate * taxableIncome, t.maxTier !== null ? t.taxRate * t.maxTier : 0);
      }
      else if (t.maxTier === null && t.taxRate !== null && t.minTier !== null) {
        formula = Math.max(t.taxRate * (taxableIncome - (t.minTier - 1)), 0);
      }
      else if (t.taxRate !== null && t.maxTier !== null && t.minTier !== null) {
        formula = Math.min(t.taxRate * taxableIncome - t.minTier, t.taxRate * t.maxTier - t.minTier);
      }
      output += formula;
    }

    return output;
  }

  exportXlsx() {
    this.xlsxService.generateXlsx(
      getExportInputs(),
      exportInputTitles,
      exportResultTitles,
      this.data,
      'income-tax',
      this.documentTitle,
    );
  }

  exportPdf() {
    this.pdfService.generatePdf(
      getExportInputs(),
      exportInputTitles,
      exportResultTitles,
      this.data,
      'income-tax',
      this.documentTitle,
    );
  }

  copyClipboard() {
    (this.grid as GridComponent).selectRowsByRange(0, 16);
    // (this.grid?.columns[2] as Column).visible = true;
    const results = (this.grid as GridComponent).getSelectedRecords();
    const inputs = getExportInputs();
    this.clipboardService.copyDataToClipboard(
      inputs,
      results,
      exportInputTitles,
      exportResultTitles,
      this.documentTitle,
    );
    // (this.grid?.columns[2] as Column).visible = false;
    (this.grid as GridComponent).clearSelection();
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
  }
}
