import { VucDataItem } from './vuc-data-item';

export function initializeDepreciationTable(vehicleType: string): VucDataItem[] {
  const vehicleYears: { [key: string]: number } = {
    'CAR': 9,
    'DELIVERY VAN': 5,
    'BIKE': 4,
  };

  const numberOfYears = vehicleYears[vehicleType.toUpperCase()] || vehicleYears['CAR']; // Default to 'CAR'
  const results: VucDataItem[] = [];

  for (let year = 1; year <= numberOfYears; year++) {
    results.push({ id: year, year: year, value: '-' });
  }

  return results;
}

// Default initialization for 'CAR'
export const results: VucDataItem[] = initializeDepreciationTable('CAR');
