import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd, RouterState, ActivatedRoute } from '@angular/router';
import { CookieConsentService } from '../utility/cookie-consent.service';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';

type GtagFunction = {
  (command: 'config', targetId: string, configObject?: Record<string, unknown>): void
  (command: 'event', eventName: string, eventParams?: Record<string, unknown>): void
  // Handle the initialization call
  (command: 'js', date: Date): void
};

declare global {
  interface Window {
    dataLayer: unknown[]
    gtag: GtagFunction
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  gTagId: string;
  private renderer: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private router: Router,
    private cookieConsentService: CookieConsentService,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this.gTagId = environment.gTagId;
    this.renderer = rendererFactory.createRenderer(null, null);
    this.subscribeToConsentChanges();
  }

  private subscribeToConsentChanges(): void {
    this.cookieConsentService.consentChanges$.subscribe((consent) => {
      if (isPlatformBrowser(this.platformId)) {
        if (this.cookieConsentService.isConsentSaved) {
          if (consent) {
            this.initGA();
            setTimeout(() => this.handleRouteEvents(), 1000);
          }
          else {
            this.disableGATracking();
          }
        }
        else {
          this.initGA();
          setTimeout(() => this.handleRouteEvents(), 1000);
        }
      }
    });
  }

  private disableGATracking(): void {
    // TODO: Handle revoking Google Analytics tracking here
    // Check cookies, localstorage, etc. and clear any GA tracking data ...
    window.gtag = () => {
      // Intentionally left blank to disable GA tracking
    };
    window.dataLayer = [];
    const gaScriptTag = document.querySelector('script[src*="googletagmanager.com/gtag/js?id="]');
    if (gaScriptTag) {
      gaScriptTag.remove();
    }
    const gaScriptBody = document.querySelector('script[data-ga-inline="true"]');
    if (gaScriptBody) {
      gaScriptBody.remove();
    }
  }

  private initGA(): void {
    const gaScriptTag = document.querySelector('script[src*="googletagmanager.com/gtag/js?id="]');
    const gaDomain = 'https://www.googletagmanager.com';
    if (!gaScriptTag) {
      const gaScript = this.renderer.createElement('script');
      this.renderer.setAttribute(gaScript, 'type', 'text/javascript');
      this.renderer.setAttribute(gaScript, 'async', 'true');
      this.renderer.setAttribute(gaScript, 'src', `${gaDomain}/gtag/js?id=${this.gTagId}`);
      this.renderer.appendChild(this.document.head, gaScript);

      const gaScriptBody = this.renderer.createElement('script');
      this.renderer.setAttribute(gaScriptBody, 'type', 'text/javascript');
      this.renderer.setAttribute(gaScriptBody, 'data-ga-inline', 'true');

      gaScript.onload = () => {
        gaScriptBody.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());

        gtag('config', '${this.gTagId}');
        `;
        this.renderer.appendChild(this.document.head, gaScriptBody);
      };
    }
  }

  handleRouteEvents() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        gtag('event', 'page_view', {
          page_title: title,
          page_path: event.urlAfterRedirects,
          page_location: this.document.location.href,
        });
      }
    });
  }

  getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data['title']);
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }

    return data;
  }
}
