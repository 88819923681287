import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EmailService } from '../../../services/api/email.service';
import { ErrorMessages, NewsletterSignup } from '@wmt/shared';
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { NgForOf, NgIf } from '@angular/common';
import { IconsModule } from '../../../../index';

@Component({
  selector: 'wmt-newsletter-card',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardContent,
    MatCardSubtitle,
    MatCardTitle,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatIcon,
    MatInput,
    MatPrefix,
    MatSuffix,
    NgForOf,
    NgIf,
    IconsModule,
  ],
  providers: [EmailService],
  templateUrl: './newsletter-card.component.html',
  styleUrl: './newsletter-card.component.scss',
})
export class NewsletterCardComponent implements OnInit {
  submitted = false;
  newsletterForm!: FormGroup;

  constructor(private fb: FormBuilder, private emailService: EmailService) {
  }

  ngOnInit() {
    this.newsletterForm = this.fb.nonNullable.group({
      fullName: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  onSubmit() {
    if (this.newsletterForm.valid) {
      const formValues: NewsletterSignup = this.newsletterForm.value;

      this.emailService.signupForNewsletter(formValues).subscribe({
        next: () => {
          this.submitted = true;
          setTimeout(() => {
            this.resetForm();
          }, 10000);
        },
        error: () => {
          console.log('Error submitting newsletter form');
        },
      });
    }
    else {
      console.log('Newsletter form is invalid');
    }
  }

  get f() {
    return this.newsletterForm.controls;
  }

  hasError(controlName: string) {
    const control = this.newsletterForm.get(controlName);
    return !!(control?.errors && (control?.dirty || control?.touched));
  }

  getErrorMessage(controlName: string, errorName: string) {
    const errorMessages: ErrorMessages = {
      required: 'This field is required.',
      pattern: 'Invalid pattern.',
    };
    return errorMessages[errorName] || 'Unknown error';
  }

  getFormErrors(controlName: string): string[] {
    const control = this.newsletterForm.get(controlName);
    if (control && control.errors) {
      return Object.keys(control.errors).map((errorName) => {
        return this.getErrorMessage(controlName, errorName);
      });
    }
    return [];
  }

  resetForm() {
    this.submitted = false;
    this.newsletterForm.reset();
  }
}
