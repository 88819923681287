import { Component } from '@angular/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { BreadcrumbComponent } from '../../common/components/breadcrumb/breadcrumb.component';
import { IconsModule } from '../../ui/icons/icons.module';
import { MatDivider } from '@angular/material/divider';
import { MatCard, MatCardContent, MatCardImage, MatCardTitle } from '@angular/material/card';
import { MatChip } from '@angular/material/chips';

@Component({
  selector: 'wmt-terms-and-conditions',
  standalone: true,
  imports: [
    ButtonModule,
    NgIf,
    RouterLink,
    BreadcrumbComponent,
    IconsModule,
    MatDivider,
    MatCardTitle,
    MatCardContent,
    MatChip,
    MatCardImage,
    MatCard,
  ],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss',
})
export class TermsAndConditionsComponent {

}
