import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AccountantVisitorRequest, NewsletterSignup, Visitor } from '@wmt/shared';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  private readonly mailUrl;
  private readonly newsletterUrl;

  constructor(
    private http: HttpClient,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
  ) {
    this.mailUrl = this.environment.apiBaseUrl + '/send-email';
    this.newsletterUrl = this.environment.apiBaseUrl + '/newsletter-signup';
  }

  sendEmail(formValues: Visitor | AccountantVisitorRequest) {
    return this.http.post<Visitor | AccountantVisitorRequest>(this.mailUrl, formValues, { observe: 'response' });
  }

  signupForNewsletter(formValues: NewsletterSignup) {
    return this.http.post<NewsletterSignup>(this.newsletterUrl, formValues, { observe: 'response' });
  }
}
