import {
  Component,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
  AfterViewInit,
  inject,
  Output,
  EventEmitter
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PartnerCardComponent } from '../partner-card/partner-card.component';
import { AccountantService } from '../../../services/api/accountant.service';

@Component({
  selector: 'wmt-partner-slider',
  standalone: true,
  imports: [PartnerCardComponent],
  providers: [],
  templateUrl: './partner-slider.component.html',
  styleUrl: './partner-slider.component.scss',
})
export class PartnerSliderComponent implements AfterViewInit {
  @ViewChild('slider') slider!: ElementRef;
  @Output() partnersLength: EventEmitter<number> = new EventEmitter<number>();
  private accountantService = inject(AccountantService);
  recentAccountants: { companyName: string, state: string, joinedDate: Date }[] = [];
  animationSpeed = 100;
  animationFrameId: number | null = null;
  isPaused = false;

  constructor(@Inject(PLATFORM_ID) private platformId: NonNullable<unknown>) {
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.accountantService.getRecentAccountants().subscribe((accountants) => {
        this.recentAccountants = accountants;
        this.partnersLength.emit(this.recentAccountants.length);
        setTimeout(() => {
          this.startSlider();
        }, 1000);
      });
    }
  }

  startSlider() {
    const sliderHtml = this.slider.nativeElement;
    const sliderWidth = sliderHtml.scrollWidth;
    const step = sliderWidth / (this.animationSpeed * 60);

    const animate = () => {
      if (!this.isPaused) {
        if (sliderHtml.scrollLeft >= sliderWidth / 2) {
          sliderHtml.scrollLeft = 0;
        }
        else {
          sliderHtml.scrollLeft += step;
        }
      }

      this.animationFrameId = requestAnimationFrame(animate);
    };

    this.animationFrameId = requestAnimationFrame(animate);
  }

  onMouseOver() {
    this.isPaused = true;
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  onMouseOut() {
    this.isPaused = false;
    this.startSlider();
  }
}
