import { z } from 'zod';
import { StatusEnum } from '../enums/status';

export const AccountantSchema = z.object({
  accountantId: z.string().uuid().optional(),
  companyName: z.string().max(100),
  companyEmail: z.string().email().max(80).optional().nullable(),
  companyPhone: z.string().max(15).optional().nullable(),
  companyGovtId: z.string().max(40),
  primaryContact: z.string().max(100),
  primaryEmail: z.string().email().max(50),
  secondaryContact: z.string().max(100).optional().nullable(),
  secondaryEmail: z.string().email().max(50).optional().nullable(),
  addressLine1: z.string().max(100).optional().nullable(),
  addressLine2: z.string().max(100).optional().nullable(),
  addressLine3: z.string().max(100).optional().nullable(),
  city: z.string().max(100).optional().nullable(),
  state: z.string().max(100).optional().nullable(),
  country: z.string().max(100).optional().nullable(),
  postcode: z.string().max(10).optional().nullable(),
  status: StatusEnum,
  langEn: z.boolean().default(false).optional().nullable(),
  langNl: z.boolean().default(false).optional().nullable(),
  langDe: z.boolean().default(false).optional().nullable(),
  langFr: z.boolean().default(false).optional().nullable(),
  langOther: z.string().max(20).optional().nullable(),
  consultationFeePrivate: z.number().optional().nullable(),
  consultationFeeBusiness: z.number().optional().nullable(),
  consultationFeeUsFiling: z.number().optional().nullable(),
  isFirstConsultationFree: z.boolean().default(false).optional().nullable(),
  freeConsultationForClients: z.boolean().default(false).optional().nullable(),
  currency: z.string().max(5).default('EUR').optional(),
  consent: z.boolean(),
  isActive: z.boolean(),
  isDeleted: z.boolean().default(false).optional(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type Accountant = z.infer<typeof AccountantSchema>;
