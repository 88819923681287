import { Component } from '@angular/core';
import { AccountantSearchComponent } from './accountant-search/accountant-search.component';
import { AccountantListComponent } from './accountant-list/accountant-list.component';
import { AccountantWithServices } from '@wmt/shared';
import { addDays, format } from 'date-fns';
import { RouterLink } from '@angular/router';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'wmt-accountant-overview',
  standalone: true,
  templateUrl: './accountant-overview.component.html',
  imports: [
    AccountantSearchComponent,
    AccountantListComponent,
    RouterLink,
    MatButton,
  ],
  styleUrls: ['./accountant-overview.component.scss'],
})
export class AccountantOverviewComponent {
  accountants: AccountantWithServices[] = [];
  totalAccountants = 0;
  totalPages = 0;
  currentPage = 1;
  releaseDateFormatted = format(addDays(new Date(), 15), 'do \'of\' MMMM yyyy');

  updateAccountantList(event: { accountants: AccountantWithServices[], totalAccountants: number, totalPages: number }) {
    this.accountants = event.accountants;
    this.totalAccountants = event.totalAccountants;
    this.totalPages = event.totalPages;
  }

  onPageChange(page: number): void {
    this.currentPage = page;
  }
}
