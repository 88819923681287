import { AbstractControl, ValidatorFn } from '@angular/forms';
import { PhoneNumberUtil } from 'google-libphonenumber';

export function phoneNumberValidator(): ValidatorFn {
  const phoneUtil = PhoneNumberUtil.getInstance();

  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      // Consider empty values as valid
      return null;
    }

    try {
      const number = phoneUtil.parseAndKeepRawInput(control.value);
      const isValid = phoneUtil.isValidNumber(number);

      return isValid
        ? null
        : {
            invalidPhoneNumber: {
              value: control.value,
              message: 'Please enter a valid phone number.',
            },
          };
    }
    catch (error) {
      // Catch parse errors and treats them as invalid numbers
      return {
        invalidPhoneNumber: {
          value: control.value,
          message: 'Please enter a valid phone number.',
        },
      };
    }
  };
}
