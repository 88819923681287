import { AbstractControl, ValidatorFn } from '@angular/forms';

export function kvkValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      // Consider empty values as valid
      return null;
    }
    // KVK number must be exactly 8 digits and start with a non-zero digit
    const valid = /^[1-9]\d{7}$/.test(control.value);
    return valid ? null : { invalidKVK: { value: control.value, message: 'Please enter a valid KVK number.' } };
  };
}
