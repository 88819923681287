import { Component, Inject, OnInit, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser, NgForOf, NgIf, ViewportScroller } from '@angular/common';
import { MaskedTextBoxModule, NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ButtonModule, CheckBoxModule, RadioButtonModule, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField, MatLabel, MatPrefix, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatAnchor, MatButton } from '@angular/material/button';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { GoogleMapsModule } from '@angular/google-maps';
import { Loader } from '@googlemaps/js-api-loader';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { IconsModule, LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { Visitor } from '@wmt/shared';
import { FeedbackCardComponent } from '../../common/components/feedback-card/feedback-card.component';
import { EmailService } from '../../services/api/email.service';
import { RouterLink } from '@angular/router';
import { FormValidationService } from '../../services/utility/form-validation.service';
import { PhoneInputDirective } from '../../common/directives/phone-input.directive';
import { phoneNumberValidator } from '../../common/validators/phone-number.validator';

@Component({
  selector: 'wmt-contact-us',
  standalone: true,
  imports: [
    CardsComponent,
    DropDownListModule,
    FormsModule,
    NgForOf,
    NgIf,
    NumericTextBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SwitchModule,
    TextBoxModule,
    MaskedTextBoxModule,
    ButtonModule,
    CheckBoxModule,
    MatCardSubtitle,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatLabel,
    MatFormField,
    MatInput,
    MatIcon,
    IconsModule,
    MatButton,
    MatCardTitle,
    MatSuffix,
    MatPrefix,
    MatRadioGroup,
    MatRadioButton,
    MatCheckbox,
    GoogleMapsModule,
    FeedbackCardComponent,
    MatAnchor,
    RouterLink,
    PhoneInputDirective,
    MatError,
  ],
  providers: [EmailService],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
})
export class ContactUsComponent implements OnInit {
  submitted = false;
  private renderer: Renderer2;
  raiseDefectLink!: string;
  featureRequestLink!: string;
  contactForm!: FormGroup;
  consentText: string;
  optionsNl: google.maps.MapOptions = {
    center: { lat: 52.087930, lng: 4.320100 },
    zoom: 12,
  };

  optionsUs: google.maps.MapOptions = {
    center: { lat: 35.231120, lng: -80.838300 },
    zoom: 12,
  };

  display!: google.maps.LatLngLiteral;

  constructor(
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private emailService: EmailService,
    private fb: FormBuilder,
    public fv: FormValidationService,
    private viewPortScroller: ViewportScroller,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.consentText = 'By submitting this form, I hereby give my consent to Daedal Inc to contact me via '
    + 'the provided email address and/or phone number. I understand that this contact may be in relation to '
    + 'the information I have requested or related services. I acknowledge that I have the right to withdraw my '
    + 'consent at any time by notifying Daedal Inc. via email at gdpr@daedal.codes.';
    this.raiseDefectLink = environment.raiseDefectLink;
    this.featureRequestLink = environment.featureRequestLink;

    if (isPlatformBrowser(this.platformId)) {
      new Loader({
        apiKey: environment.googleMapsApiKey,
        version: 'weekly',
      }).importLibrary('maps').then(() => {
        console.log('Google maps JS API loaded');
      });
    }
  }

  ngOnInit() {
    this.contactForm = this.fb.nonNullable.group({
      firstName: ['', { validators: [Validators.required, Validators.minLength(2)] }],
      lastName: ['', { validators: [Validators.required, Validators.minLength(2)] }],
      category: ['P', { validators: [Validators.required] }],
      companyName: [{ value: '', disabled: true }, { validators: [Validators.required] }],
      isAccountant: [false],
      email: ['', { validators: [Validators.required, Validators.email] }],
      phone: ['', { validators: [phoneNumberValidator()] }],
      subject: ['', { validators: [Validators.required, Validators.minLength(5)] }],
      description: ['', { validators: [Validators.required, Validators.minLength(10)] }],
      consent: [false, { validators: [Validators.requiredTrue] }],
    });

    this.contactForm.get('category')?.valueChanges.subscribe((value) => {
      if (value === 'B') {
        this.contactForm.get('companyName')?.enable();
        this.contactForm.get('companyName')?.setValidators([Validators.required]);
      }
      else {
        this.contactForm.get('companyName')?.disable();
      }
    });

    this.fv.setCurrentForm(this.contactForm);
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const formValues: Visitor = this.contactForm.value;

      this.emailService.sendEmail(formValues).subscribe({
        next: (response) => {
          if (response.ok) {
            this.submitted = true;
            this.viewPortScroller.scrollToPosition([0, 0]);
            setTimeout(() => {
              this.resetForm();
            }, 10000);
          }
        },
        error: (error) => {
          console.error('Error sending email', error);
        },
      });
    }
    else {
      console.error('Form is invalid');
    }
  }

  resetForm() {
    this.submitted = false;
    this.contactForm.reset();
  }

  moveMapNl(event: google.maps.MapMouseEvent) {
    this.optionsNl.center = (event.latLng?.toJSON());
  }

  moveMapUs(event: google.maps.MapMouseEvent) {
    this.optionsUs.center = (event.latLng?.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.display = event.latLng.toJSON();
    }
  }
}
