import { Injectable, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import {
  CookieConsentDialogComponent,
} from '../../common/components/cookie-consent/cookie-consent-dialog/cookie-consent-dialog.component';
import { ScreenSizeService } from './screen-size.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService implements OnDestroy {
  private consentSubject = new BehaviorSubject<boolean>(this.consent);
  consentChanges$ = this.consentSubject.asObservable();
  consentGiven = false;
  cookieDialogPosition = { bottom: '0' };
  screenSubscription!: Subscription;
  public isMobile = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private dialog: MatDialog,
    public overlay: Overlay,
    private screenService: ScreenSizeService,
  ) {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  checkAndDisplayConsent(): void {
    if (!this.isMobile) {
      this.cookieDialogPosition = { bottom: '20px' };
    }
    if (isPlatformBrowser(this.platformId)) {
      this.consentGiven = localStorage.getItem('wmt-cookie-consent') === 'true';
      if (localStorage.getItem('wmt-cookie-consent') === null) {
        this.displayConsentDialog();
      }
    }
  }

  get isConsentSaved() {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem('wmt-cookie-consent') !== null;
    }

    return false;
  }

  displayConsentDialog(): void {
    this.dialog.open(CookieConsentDialogComponent, {
      maxWidth: '1232px',
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      panelClass: 'cookie-consent-dialog',
      id: 'cookie-dialog',
      disableClose: true,
      hasBackdrop: false,
      position: this.cookieDialogPosition,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: { consentGiven: this.consentGiven },
    });
  }

  set consent(value: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('wmt-cookie-consent', value.toString());
      this.consentGiven = value;
      this.consentSubject.next(value);
    }
  }

  get consent(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const consentValue = localStorage.getItem('wmt-cookie-consent');
      return consentValue === 'true';
    }

    return false;
  }

  updateDialogPosition(footerHeight: number): void {
    const dialogRef = this.dialog.getDialogById('cookie-dialog');

    if (dialogRef) {
      const scrolledFromBottom = this.getScrolledFromBottom();

      const position = (scrolledFromBottom + 20 < footerHeight)
        ? (this.isMobile ? { bottom: '0' } : { bottom: `${footerHeight}px` })
        : this.cookieDialogPosition;

      setTimeout(() => dialogRef.updatePosition(position), 0);
    }
  }

  private getScrolledFromBottom(): number {
    const scrollTop = document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;

    return scrollHeight - (scrollTop + clientHeight);
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
  }
}
