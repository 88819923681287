import { z } from 'zod';

export const FeedbackResponseSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  feedback: z.string(),
  stars: z.number().min(1).max(5),
  createdAt: z.date(),
});

export const AccountantFeedbackResponseSchema = z.object({
  accountantId: z.string().uuid().optional(),
  totalFeedbacks: z.number(),
  averageStars: z.number(),
  feedbacks: z.array(FeedbackResponseSchema),
});

export type FeedbackResponse = z.infer<typeof FeedbackResponseSchema>;
export type AccountantFeedbackResponse = z.infer<typeof AccountantFeedbackResponseSchema>;
