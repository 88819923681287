import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { firstValueFrom, Observable } from 'rxjs';
import { TaxRate } from '@wmt/shared';

const initialState = {
  taxRates: [] as TaxRate[],
};

export const taxRateStore = createStore(
  { name: 'taxRates' },
  withProps<{ taxRates: TaxRate[] }>(initialState),
);

export function loadTaxRates(taxRates: TaxRate[]) {
  taxRateStore.update(state => ({
    ...state,
    taxRates,
  }));
}

export const taxRateStoreQuery = {
  getTaxRates: () => taxRateStore.query(state => state.taxRates),
  getTaxRatesByTaxCategoryId: (taxCategoryId: string) =>
    taxRateStore.query(state =>
      state.taxRates.filter(taxRate =>
        taxRate.taxCategoryId === taxCategoryId)),
};

export async function initializeTaxRateStore(fetchTaxRatesFromDB: () => Observable<TaxRate[]>) {
  const storedTaxRates = taxRateStoreQuery.getTaxRates();
  if (storedTaxRates.length === 0) {
    const taxRates = await firstValueFrom(fetchTaxRatesFromDB());
    loadTaxRates(taxRates);
  }
}

persistState(taxRateStore, {
  key: 'wmt-tax-rates',
  storage: localStorageStrategy,
});
