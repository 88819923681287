<div class="pageWrapper maxWidth">

  <mat-card-subtitle class="mat-body-1 p-b-24">Click on each tax to see the details</mat-card-subtitle>
  <div class="scroll-indicator">
    @if (!userHasScrolledTable) {
      <p>Swipe the table to scroll content</p>
      <i-tabler name="hand-move" class="icon-20 d-flex hand-icon"></i-tabler>
    }
  </div>

  <ejs-grid #grid [dataSource]="taxes" cssClass="wmt-grid">
    <e-columns>
      <e-column field='taxName' headerText='Tax name' [width]=lineItemsWidth [isFrozen]=isFrozenColumn>
        <ng-template #template let-data>
          <div class="wmt-grid-cell">
            {{ data["taxName"] }}
            <div class="wmt-grid-link" (click)="onMoreClick(data['taxId'])">See more</div>
          </div>
        </ng-template>
      </e-column>
      <e-column field='description' headerText='Description' width=208></e-column>

      <e-column field='category' headerText='Category' width=108>
        <ng-template #template let-data>
          @if (data["category"] === 'P') {
            Private
          } @else {
            Business
          }
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>

</div>

<ejs-dialog id='dialog' #wmtDialog [header]=dialogHeader [isModal]="true" [showCloseIcon]="true" [visible]="false"
            (overlayClick)="onOverlayClick()" width='918px' height='888px' (close)="toggleDialog()"
            (open)="toggleDialog()">
  <wmt-detail-view [tax]="tax"></wmt-detail-view>
</ejs-dialog>

<wmt-cards></wmt-cards>
