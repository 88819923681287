import { InjectionToken } from '@angular/core';

export const LIB_ENVIRONMENT = new InjectionToken('LIB_ENVIRONMENT');

/* UI */
export { IconsModule } from './lib/ui/icons/icons.module';
export * from './lib/ui/ui.module';

/* PAGES */
export { HomepageComponent } from './lib/pages/homepage/homepage.component';
export { AboutUsComponent } from './lib/pages/about-us/about-us.component';
export { ContactUsComponent } from './lib/pages/contact-us/contact-us.component';
export { PrivacyPolicyComponent } from './lib/pages/privacy-policy/privacy-policy.component';
export { CookiePolicyComponent } from './lib/pages/cookie-policy/cookie-policy.component';
export { TermsAndConditionsComponent } from './lib/pages/terms-and-conditions/terms-and-conditions.component';
export { NotAvailableComponent } from './lib/pages/not-available/not-available.component';
export { InsightsComponent } from './lib/pages/insights/insights.component';
export { InsightsDetailComponent } from './lib/pages/insights/insights-detail/insights-detail.component';

/* FEATURES */
export { AiChatbotComponent } from './lib/features/ai-chatbot/ai-chatbot.component';
export { VehicleUsageCostComponent } from './lib/features/vehicle-usage-cost/vehicle-usage-cost.component';
export { IncomeTaxComponent } from './lib/features/income-tax/income-tax.component';
export { TaxComparisonComponent } from './lib/features/tax-comparison/tax-comparison';
export { TaxTableComponent } from './lib/features/tax-table/tax-table.component';
export { Taxelos360Component } from './lib/features/taxelos-360/taxelos-360.component';
export { AccountantOverviewComponent } from './lib/features/taxelos-360/accountant-overview/accountant-overview.component';
export { AccountantSearchComponent } from './lib/features/taxelos-360/accountant-overview/accountant-search/accountant-search.component';
export { AccountantReviewFormComponent } from './lib/features/taxelos-360/accountant-overview/accountant-review-form/accountant-review-form.component';
export { AccountantCommentsComponent } from './lib/features/taxelos-360/accountant-overview/accountant-comments/accountant-comments.component';
export { AccountantRequestFormComponent } from './lib/features/taxelos-360/accountant-overview/accountant-request-form/accountant-request-form.component';

/* SERVICES */
export { MenuService } from './lib/services/utility/menu.service';
export { ScreenSizeService } from './lib/services/utility/screen-size.service';
export { GoogleAnalyticsService } from './lib/services/external/analytics.service';
export { StoreService } from './lib/services/api/store.service';
export { AuthService } from './lib/services/api/auth.service';

/* COMMON */
export { HeroComponent } from './lib/common/components/hero/hero.component';
export { CardsComponent } from './lib/common/components/cards/cards.component';
export { CookieConsentComponent } from './lib/common/components/cookie-consent/cookie-consent.component';
export { DisclaimerComponent } from './lib/common/components/disclaimer/disclaimer.component';
export { FooterComponent } from './lib/common/components/footer/footer.component';
export { MenuComponent } from './lib/common/components/header/menu/menu.component';
export { MobileMenuComponent } from './lib/common/components/header/mobile-menu/mobile-menu.component';
export { LogoComponent } from './lib/common/components/logo/logo.component';
export { SeparatorComponent } from './lib/ui/separator/separator.component';
export { BreadcrumbComponent } from './lib/common/components/breadcrumb/breadcrumb.component';
export { PartnerFormComponent } from './lib/common/components/partner-form/partner-form.component';
export { ReleaseRoadmapComponent } from './lib/common/components/release-roadmap/release-roadmap.component';

/* INTERCEPTORS */
export { authInterceptor } from './lib/common/interceptors/auth.interceptor';

/* GUARDS */
export { validateChatId } from './lib/common/guards/chat-route.guard';
