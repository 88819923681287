<div class="pageWrapper maxWidth justify-content-center">
  <div class="row">
    <div class="col-12">
      @if (isLoading) {
        <p>Loading...</p>
      } @else {
        <mat-card class="mat-card-top chat-app cardWithShadow">
          <mat-sidenav-container
            [ngClass]="{'side-panel-opened': sidePanelOpened,'side-panel-closed': !sidePanelOpened}">
            <!-- ---------------------------------------------------- -->
            <!-- sidebar -->
            <!-- ---------------------------------------------------- -->
            <mat-sidenav #chatnav [mode]="isOver() ? 'over' : 'side'" [opened]="chatState.sidebarOpen$ | async"
                         (opened)="chatState.setSidebarOpen(true)" (closed)="chatState.setSidebarOpen(false)">
              <ng-scrollbar class="position-relative" style="height: 100%">
                <div class="d-flex align-items-center p-24">
                  <button mat-flat-button class="w-100" color="accent" (click)="newThread()">
                    <mat-icon>
                      <i-tabler name="message-plus" class="icon-20"></i-tabler>
                    </mat-icon>
                    New Chat
                  </button>
                </div>

                <div class="p-x-24">
                  <!-- search -->
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput placeholder="Search chats" #searchInput
                           (input)="setSearchTerm(searchInput.value)" />
                    <mat-icon matSuffix>
                      <i-tabler name="search" class="icon-20"></i-tabler>
                    </mat-icon>
                  </mat-form-field>
                  <!-- dropdown -->
                  <button mat-button [matMenuTriggerFor]="recentMenu">
                    <div class="d-flex align-items-center">
                      Recent Chats
                      <i-tabler name="chevron-down" class="icon-20"></i-tabler>
                    </div>
                  </button>
                  <mat-menu #recentMenu="matMenu" class="cardWithShadow">
                    <button mat-menu-item (click)="sortChats('time', 'desc')">
                      <span>Sort by Newest First (default)</span>
                    </button>
                    <button mat-menu-item (click)="sortChats('time', 'asc')">
                      <span>Sort by Oldest First</span>
                    </button>
                    <button mat-menu-item (click)="sortChats('alpha', 'asc')">
                      <span>Sort Alphabetically: A-Z</span>
                    </button>
                    <button mat-menu-item (click)="sortChats('alpha', 'desc')">
                      <span>Sort Alphabetically: Z-A</span>
                    </button>
                  </mat-menu>
                </div>
                <mat-nav-list class="chat-listing">
                  @for (chat of chats$ | async; track chat.id) {
                    <mat-list-item role="listitem" (click)="onSelect(chat)"
                                   [class.bg-light-primary]="chat.id === chatState.getCurrentChatId()" lines="2">
                      <div class="d-flex align-items-center justify-content-between p-l-16 p-b-4 h-100"
                           matListItemTitle>
                        <p class="sidebar-title f-s-14 m-t-4 m-b-0">
                          {{ chat.title }}
                        </p>
                        <button mat-icon-button [matMenuTriggerFor]="chatMenu" (click)="$event.stopPropagation()">
                          <div class="d-flex align-items-center">
                            <i-tabler name="dots-vertical" class="icon-20"></i-tabler>
                          </div>
                        </button>
                        <mat-menu #chatMenu="matMenu" xPosition="before" class="cardWithShadow">
                          <button mat-menu-item (click)="softDeleteChat(chat.id)">
                            <div class="d-flex align-items-center thread-menu">
                              <i-tabler class="icon-20 m-r-32 thread-del-icon" name="trash"></i-tabler>
                              Delete Chat
                            </div>
                          </button>
                        </mat-menu>
                      </div>
                    </mat-list-item>
                  } @empty {
                    <p class="text-center secondary-text">
                      No chats yet.<br>
                      Start by clicking "New Chat".
                    </p>
                  }
                </mat-nav-list>
              </ng-scrollbar>
            </mat-sidenav>
            <!-- --------------------------------------------->
            <!-- details -->
            <!-- --------------------------------------------->
            <mat-toolbar class="no-shadow chat-right-panel bg-white d-flex align-items-center p-y-8 b-b-1">
              <button (click)="toggleSidebar()" class="m-r-10" mat-icon-button>
                <mat-icon>
                  <i-tabler name="menu-deep" class="icon-20 chat-icons"></i-tabler>
                </mat-icon>
              </button>
              <div class="d-flex align-items-center justify-content-center w-100">
                <div class="mat-subtitle-1 topbar-title f-s-16 f-w-600 m-b-0 w-100 text-center">
                  {{ chatState.getCurrentChatSync()?.title }}
                </div>
              </div>
              @if (isDevMode) {
                <span style="font-style: italic; font-size: 10px">Dev only menu</span>
                <button [matMenuTriggerFor]="masterMenu" class="m-l-auto" mat-icon-button>
                  <mat-icon>
                    <i-tabler name="dots-vertical" class="icon-20 chat-icons"></i-tabler>
                  </mat-icon>
                </button>
                <mat-menu #masterMenu="matMenu" xPosition="before" class="cardWithShadow">
                  <button mat-menu-item (click)="softDeleteAllChats()">
                    <div class="d-flex align-items-center thread-menu">
                      <i-tabler class="icon-20 m-r-32 thread-del-icon" name="trash"></i-tabler>
                      Delete All (local)
                    </div>
                  </button>
                  <button mat-menu-item (click)="hardDeleteAllChats()">
                    <div class="d-flex align-items-center thread-menu">
                      <i-tabler class="icon-20 m-r-32 thread-del-icon" name="trash"></i-tabler>
                      Delete All (local + remote)
                    </div>
                  </button>
                </mat-menu>
              }
            </mat-toolbar>

            <!-- ------------------------------------------- -->
            <!-- chat content -->
            <!-- ------------------------------------------- -->
            <ng-scrollbar #chatScrollbar
                          [ngStyle]="{ height: (currentChat$ | async) ? 'calc(100vh - 360px)' : 'calc(100vh - 360px)' }"
                          class="position-relative">
              <mat-card-content class="chat-middle-box p-24">
                @if (isThreadEmpty(currentChat$ | async)) {
                  <div class="text-center">
                    <div>
                      <img src="/assets/images/svgs/ai-icon.svg" alt="tax-djinn" width="100" class="robot-icon">
                      <!--<i-tabler name="robot" class="robot-icon"></i-tabler>-->
                      <h3>Tax Djinn NL</h3>
                    </div>
                    <p>Dedicated guide for Dutch tax and accounting queries.
                      <i-tabler #tooltip="matTooltip" [matTooltip]="helpTooltipText" matTooltipPosition="below"
                                matTooltipHideDelay="200" name="help" matTooltipClass="chatbot-help-tooltip"
                                class="help-icon"></i-tabler>
                    </p>
                    <wmt-vote *ngIf="machineId" [machineId]="machineId"></wmt-vote>
                  </div>
                  <div class="chat-demo-container">
                    @for (chat of demoChats; track chat.id) {
                      <div class="chat-demo-item" (click)="initializeDemoChat(chat.id)">
                        <div class="chat-demo-text">
                          <p>{{ chat.text }}</p>
                        </div>
                        <div class="chat-demo-icon-wrapper">
                          <i-tabler class="chat-demo-icons" name="arrow-up"></i-tabler>
                        </div>
                      </div>
                    }
                  </div>
                }
                @if (currentChat$ | async; as currentChat) {
                  @if (currentChat.messages && currentChat.messages.length > 0) {
                    @for (m of currentChat.messages; track index; let index = $index) {

                      <ng-template [ngIf]="m.role === 'system' || m.role === 'assistant'" [ngIfElse]="userBlock">
                        <div class="chat-list odd">
                          <img src="/assets/images/svgs/ai-icon.svg" alt="tax-djinn" class="system-icon">
                          <div class="chat-message m-b-15">
                            <div class="chat-block bg-light-primary p-10 rounded d-flex align-items-center">
                          <span class="mat-subheading-1 m-b-0 f-w-400 lh-md">
                            @if (m.role === 'assistant') {
                              <div markdown katex [katexOptions]="katexOptions" class="markdown-content"
                                   [data]="m.content | escapeBackslashes"></div>
                            } @else () {
                              {{ m.content }}
                            }
                          </span>
                            </div>
                            @if (m.role === 'assistant') {
                              <p class="copy-chat text-muted mat-h5 f-s-12 m-t-5 m-b-0"
                                 (click)="copyChatResponse(m.content)">
                                <i-tabler name="copy" class="icon-20 chat-icons"></i-tabler>
                                Copy text
                              </p>
                            }
                          </div>
                        </div>
                      </ng-template>
                      <ng-template #userBlock>
                        <div class="chat-list even">
                          <span class="system-icon">You</span>
                          <div class="chat-message m-b-15">
                            <div
                              class="chat-block bg-light-accent lh-md p-10 rounded d-flex align-items-center mat-subheading-1 f-w-400 m-b-0">
                              {{ m.content }}
                            </div>
                            <!--<p class="chat-date text-muted mat-h5 m-0">TEST2</p>-->
                          </div>
                        </div>
                      </ng-template>
                    }
                  } @else {
                    <p class="text-center secondary-text">
                      No messages yet.<br>
                      Start by typing a message.
                    </p>
                  }
                }
              </mat-card-content>
            </ng-scrollbar>
            <div class="bg-light-warning chat-disclaimer"
                 [ngStyle]="{ visibility: isThreadEmpty(currentChat$ | async) ? 'hidden' : 'visible' }">
              <p [innerHTML]="disclaimerText"></p>
            </div>
            <mat-divider></mat-divider>
            <div class="p-t-20 p-l-30 p-r-30">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Write your request here</mat-label>
                <input matInput placeholder="Send message" [(ngModel)]="msg" #chatInput (keyup.enter)="newMessage()"
                       [disabled]="lastRunStatus === 'in_progress'" />
                <button mat-icon-button matSuffix color="primary" (click)="newMessage()" [disabled]="!msg">
                  <div class="spinner-container" *ngIf="lastRunStatus === 'in_progress'">
                    <mat-spinner diameter="20" class="spinner"></mat-spinner>
                  </div>
                  <mat-icon *ngIf="lastRunStatus !== 'in_progress'">
                    <i-tabler name="send-2" class="icon-20 chat-icons"></i-tabler>
                  </mat-icon>
                </button>
              </mat-form-field>
            </div>
          </mat-sidenav-container>
        </mat-card>
      }
    </div>
  </div>
</div>
