import { AfterViewInit, Component, inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { MatAnchor, MatButton, MatIconButton } from '@angular/material/button';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MarkdownComponent, provideMarkdown, MARKED_OPTIONS, KatexOptions } from 'ngx-markdown';
import { MatIcon } from '@angular/material/icon';
import { IconsModule } from '../../ui/icons/icons.module';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatListItem, MatNavList } from '@angular/material/list';
import { MatToolbar } from '@angular/material/toolbar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, DatePipe, isPlatformBrowser, NgClass, NgIf, NgStyle, ViewportScroller } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { BehaviorSubject, combineLatest, Observable, shareReplay, Subscription } from 'rxjs';
import { ChatFacadeService } from '../../services/utility/chat-facade.service';
import { getCurrentId } from '../../common/utils/current-id.utils';
import { Chat } from '@wmt/shared';
import { Beta } from 'openai/resources';
import Thread = Beta.Thread;
import { addPrefix, stripPrefix } from '../../common/utils/uuid.utils';
import { Threads } from 'openai/resources/beta';
import RunStatus = Threads.RunStatus;
import { map } from 'rxjs/operators';
import { MatTooltip } from '@angular/material/tooltip';
import { DEMO_CHATS } from './demo-chats';
import { AppContextService } from '../../services/utility/app-context.service';
import { EscapeBackslashesPipe } from '../../common/pipes/escape-backslashes.pipe';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MachineIdService } from '../../services/utility/machine-id.service';
import { QuestionLimitDialogComponent } from './question-limit-dialog/question-limit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AiVoteService } from '../../services/api/ai-vote.service';
import { VoteComponent } from './vote/vote.component';

@Component({
  selector: 'wmt-ai-chatbot',
  standalone: true,
  imports: [
    MatAnchor,
    RouterLink,
    MatCard,
    MatSidenavContainer,
    MatFormField,
    MatIcon,
    IconsModule,
    MatMenu,
    MatNavList,
    MatListItem,
    MatToolbar,
    MatMenuTrigger,
    MatCardContent,
    NgIf,
    DatePipe,
    MatDivider,
    MatLabel,
    MatSuffix,
    FormsModule,
    MatInput,
    MatIconButton,
    MatMenuItem,
    MatSidenav,
    NgClass,
    NgScrollbarModule,
    MatButton,
    AsyncPipe,
    MarkdownComponent,
    ClipboardModule,
    MatTooltip,
    NgStyle,
    EscapeBackslashesPipe,
    MatProgressSpinner,
    VoteComponent,
  ],
  providers: [
    provideMarkdown({
      markedOptions: { provide: MARKED_OPTIONS, useValue: { gfm: true, breaks: true } },
    }),
  ],
  templateUrl: './ai-chatbot.component.html',
  styleUrl: './ai-chatbot.component.scss',
})
export class AiChatbotComponent implements OnInit, OnDestroy, AfterViewInit {
  private platformId = inject(PLATFORM_ID);
  private readonly clipboard = inject(Clipboard);
  private readonly router = inject(Router);
  private readonly ACS = inject(AppContextService);
  private readonly machineIdService = inject(MachineIdService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly facade = inject(ChatFacadeService);
  readonly chatState = this.facade.chatStateService;
  private readonly aiVoteService = inject(AiVoteService);
  private readonly currentChatId$: Observable<string>;
  private readonly viewportScroller = inject(ViewportScroller);
  private screenSizeService = inject(ScreenSizeService);
  private subs = new Subscription();

  chats$!: Observable<Chat[]>;
  currentChat$: Observable<Chat | undefined> = this.chatState.currentChat$.pipe(shareReplay(1));
  machineId: string | null = null;
  voted = false;
  demoChats = DEMO_CHATS;
  lastRunStatus: RunStatus | null = null;
  isDevMode = false;
  isLoading = false;

  katexOptions: KatexOptions = {
    displayMode: false,
    throwOnError: true,
    errorColor: '#cc0000',
  };

  sidePanelOpened = true;
  msg = '';
  private searchTerms = new BehaviorSubject<string>('');
  private sortCriteria = new BehaviorSubject<{ criteria: string, order: 'asc' | 'desc' }>({
    criteria: 'time',
    order: 'desc',
  });

  @ViewChild('chatScrollbar') private chatScrollbar!: NgScrollbar;

  helpTooltipText = 'In order to manage performance expectations, a single user is allowed maximum 20 '
  + 'questions per day (across conversations) from the free account. \n\n We are working on a business model which '
  + 'enable users to upgrade to a paid plan enabling them unlimited access to the Tax Djinn. \n\n'
  + 'Please bear with us till then.';

  disclaimerText = 'For financial decisions or complex VAT scenarios, consulting with a tax professional '
  + 'is advisable. <br> This information is for indicative purposes and not intended '
  + 'for making financial decisions on its own.';

  constructor(public dialog: MatDialog) {
    this.currentChatId$ = getCurrentId(this.router, this.activatedRoute);
  }

  ngOnInit() {
    this.isDevMode = this.ACS.isLocal();
    if (isPlatformBrowser(this.platformId)) {
      this.machineIdService.getMachineId().then((machineId) => {
        this.machineId = machineId;
      }).catch((error) => {
        console.error('Error getting machine ID:', error);
      });
    }
    this.subs.add(
      this.chatState.sidebarOpen$.subscribe((sidebarOpen) => {
        this.sidePanelOpened = sidebarOpen;
      }),
    );
    this.subs.add(
      this.currentChatId$.subscribe((chatId) => {
        if (chatId && chatId !== this.chatState.getCurrentChatId()) {
          this.chatState.setCurrentChat(chatId);
          this.scrollChatToBottom();
        }
      }),
    );
    this.chats$ = combineLatest([
      this.chatState.chats$,
      this.searchTerms.asObservable(),
      this.sortCriteria.asObservable(),
    ]).pipe(
      map(([chats, searchTerm, { criteria, order }]) => {
        const filteredChats = chats.filter(chat =>
          chat.title.toLowerCase().includes(searchTerm.toLowerCase()),
        );

        return filteredChats.sort((a, b) => {
          switch (criteria) {
            case 'time': {
              const dateA = new Date(a.createdAt).getTime();
              const dateB = new Date(b.createdAt).getTime();
              return order === 'desc' ? dateB - dateA : dateA - dateB;
            }
            case 'alpha': {
              const titleA = a.title.toLowerCase();
              const titleB = b.title.toLowerCase();
              return order === 'desc' ? (titleA < titleB ? 1 : -1) : (titleA > titleB ? 1 : -1);
            }
            default:
              return 0;
          }
        });
      }),
    );
    this.loadInitialChats();
  }

  ngAfterViewInit() {
    // this.isLoading = false;
    this.scrollChatToBottom();
  }

  setSearchTerm(term: string): void {
    this.searchTerms.next(term);
  }

  sortChats(criteria: string, order: 'asc' | 'desc') {
    this.sortCriteria.next({ criteria, order });
  }

  loadInitialChats(): void {
    this.scrollChatToBottom();
    // this.chatState.loadInitialChats([]);
  }

  toggleSidebar(): void {
    this.chatState.toggleChatSidebar();
  }

  isOver(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return window.matchMedia(`(max-width: 960px)`).matches;
    }

    return false;
  }

  isThreadEmpty(currentChat: Chat | null | undefined): boolean {
    return !currentChat || (currentChat.messages?.length ?? 0) <= 1;
  }

  newThread(): void {
    this.facade.createThread(this.machineId).subscribe({
      next: (thread: Thread) => {
        const threadId = stripPrefix(thread.id);
        this.chatState.newChat(threadId);
        this.router.navigate(['tax-djinn', threadId]);
        if (window.innerWidth < 768) {
          this.toggleSidebar();
        }
      },
      error: error => console.error(error),
    });
  }

  initializeDemoChat(id: string) {
    const selectedDemoChat = this.demoChats.find(chat => chat.id === id);
    if (selectedDemoChat) {
      if (!this.chatState.getCurrentChatId()) {
        this.newThread();
      }
      setTimeout(() => {
        this.newMessage(selectedDemoChat.text);
      }, 1000);
    }
  }

  onSelect(chat: Chat): void {
    // this.currentChat = chat;
    // this.currentChatId = chat.id;
    // this.chatState.setCurrentChat(chat.id);
    this.router.navigate(['tax-djinn', chat.id]);
  }

  newMessage(message?: string): void {
    if (message) this.msg = message;
    if (!this.msg) return;

    if (this.chatState.getCurrentChatId()) {
      this.addNewMessage(this.msg);
      this.msg = '';
    }
    else {
      this.facade.createThread(this.machineId).subscribe({
        next: (thread: Thread) => {
          const threadId = stripPrefix(thread.id);
          this.chatState.newChat(threadId);
          this.router.navigate(['tax-djinn', threadId])
            .then(() => {
              this.chatState.setCurrentChat(threadId);
              this.addNewMessage(this.msg);
              this.msg = '';
              this.scrollChatToBottom();
            }).catch(error => console.error('Navigation error:', error));
        },
        error: error => console.error(error),
      });
    }

    // this.chatInput.nativeElement.value = '';
    // this.msg = '';
  }

  addNewMessage(message: string) {
    const currentChatId = this.chatState.getCurrentChatId();
    if (message.length > 0 && currentChatId) {
      const threadId = addPrefix(currentChatId as string);
      let messageId = '';
      let runStatus: RunStatus;
      this.chatState.newChatMessage(message, messageId, this.chatState.getCurrentChatId() as string);
      this.scrollChatToBottom();

      this.facade.startStreamingResponse(threadId, { content: message, role: 'user' }, this.machineId).subscribe({
        next: (stream) => {
          switch (stream.type) {
            case 'messageCreated':
              runStatus = stream.data.status;
              messageId = stream.data.id;
              this.chatState.newChatMessage(
                '',
                messageId,
                this.chatState.getCurrentChatId() as string,
                'assistant',
                runStatus,
                stream.data['run-id'],
              );
              this.lastRunStatus = runStatus;
              this.scrollChatToBottom();
              break;
            case 'messageDelta':
              this.chatState.newChatMessageChunk(
                stream.data.content[0].text.value,
                messageId,
                this.chatState.getCurrentChatId() as string,
              );
              this.scrollChatToBottom();
              break;
            case 'messageDone':
              runStatus = stream.data.status;
              this.chatState.newChatMessageChunk(
                '',
                messageId,
                this.chatState.getCurrentChatId() as string,
                runStatus,
              );
              this.lastRunStatus = runStatus;
              break;
          }
        },
        error: (error) => {
          console.error(error);
          if (error.status === 429) {
            this.openDialog(error.error.message);
          }
        },
        complete: () => {
          this.generateChatTitle(message);
        },
      });
    }
  }

  generateChatTitle(message: string) {
    const currentMessages = this.chatState.getCurrentChatSync()?.messages;
    if (currentMessages && currentMessages.length === 3) {
      const assistantMessage = currentMessages.slice(-1)[0].content;
      if (!assistantMessage) return;
      this.chatState.updateTitle(this.chatState.getCurrentChatId() as string, message);

      /* this.facade.generateChatTitle(message, assistantMessage).subscribe({
        next: (title) => {
          console.log('Title:', title);
          this.chatState.updateTitle(this.chatState.getCurrentChatId() as string, title);
        },
        error: (error) => console.error(error)
      }); */
    }
  }

  copyChatResponse(content: string): void {
    this.clipboard.copy(content);
  }

  softDeleteChat(chatId: string): void {
    this.chatState.softDeleteThread(chatId);
    const currentChatId = this.chatState.getCurrentChatId();

    if (currentChatId) {
      this.router.navigate(['tax-djinn', currentChatId]);
    }
    else {
      this.router.navigate(['tax-djinn']);
    }
  }

  softDeleteAllChats(): void {
    this.chatState.softDeleteAllThreads();
  }

  hardDeleteAllChats(): void {
    console.log('Delete all chats');
  }

  private scrollChatToBottom(): void {
    if (this.chatScrollbar && isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.chatScrollbar.scrollTo({ bottom: 0, duration: 500 });
      }, 0);
    }
  }

  openDialog(dialogData: string) {
    this.dialog.open(QuestionLimitDialogComponent, {
      maxWidth: '520px',
      data: { questionLimitErrorTitle: dialogData, machineId: this.machineId },
      disableClose: true,
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
