import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[wmtNlZipCode]',
  standalone: true,
})
export class NlZipCodeDirective {
  constructor(private el: ElementRef<HTMLInputElement>) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'End', 'Home',
    ];
    const key = event.key.toUpperCase();

    // Allow control keys and restrict character keys
    if (!allowedKeys.includes(event.key) && (
      (this.el.nativeElement.value.length < 4 && !/\d/.test(key))
      || (this.el.nativeElement.value.length >= 4 && !/[A-Z]/.test(key))
      || this.el.nativeElement.value.length >= 7
    )) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let value = this.el.nativeElement.value.toUpperCase();
    value = value.replace(/[^0-9A-Z]/g, '');

    if (value.length > 4) {
      value = value.slice(0, 4) + ' ' + value.slice(4, 6);
    }

    this.el.nativeElement.value = value;
  }

  @HostListener('blur') onBlur() {
    let value = this.el.nativeElement.value.toUpperCase();

    if (value.length === 6 && !value.includes(' ')) {
      value = value.slice(0, 4) + ' ' + value.slice(4, 6);
    }

    this.el.nativeElement.value = value;
  }
}
