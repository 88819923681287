<ejs-grid #grid [dataSource]='taxCategories' height='auto' width='auto' cssClass="wmt-grid wmt-detail-grid"
          (detailDataBound)='detailDataBound($event)'>
  <e-columns>
    <e-column headerText="Tax name" width='140'>
      <ng-template #template>
        {{taxName}}
      </ng-template>
    </e-column>
    <e-column field="categoryDescription" headerText='Category description' width='150'></e-column>
  </e-columns>
  <ng-template #detailTemplate let-data>
    <div class='custom-grid'></div>
    <wmt-detail-view-taxes [taxCategoryId]="data['taxCategoryId']"></wmt-detail-view-taxes>
  </ng-template>
</ejs-grid>
