import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { DatePipe, NgClass } from '@angular/common';
import { AccountantWithServices } from '@wmt/shared';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { formatDistanceToNow } from 'date-fns';

export interface DialogData {
  accountant: AccountantWithServices
}

@Component({
  selector: 'wmt-accountant-comments',
  standalone: true,
  templateUrl: './accountant-comments.component.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    DatePipe,
    IconsModule,
    NgClass,
    MatIconButton,
  ],
  styleUrls: ['./accountant-comments.component.scss'],
})
export class AccountantCommentsComponent {
  maxStars: number[] = Array.from({ length: 5 }, (_, i) => i);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
  ) {
  }

  formatDate(date: Date): string {
    return formatDistanceToNow(date, { addSuffix: true });
  }
}
