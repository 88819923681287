import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { LogoComponent } from '../logo/logo.component';
import { isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { AppContextService } from '../../../services/utility/app-context.service';
import { CookieConsentService } from '../../../services/utility/cookie-consent.service';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmt-footer',
  standalone: true,
  imports: [
    LogoComponent,
    NgOptimizedImage,
    RouterLink,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent implements OnInit, AfterViewInit, OnDestroy {
  consentSaved = false;
  public isMobile = true;
  private screenSubscription!: Subscription;
  private consentSubscription!: Subscription;
  logoWidth = '61px';
  logoHeight = '50px';
  isWebNl: boolean;
  daedalLogo = 'assets/images/daedal-logo.png';
  daedalLogoMono = 'assets/images/daedal-logo-mono.png';
  isHovering = false;

  constructor(
    private router: Router,
    private ACS: AppContextService,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
    private screenService: ScreenSizeService,
    private cookieConsentService: CookieConsentService) {
    this.isWebNl = this.ACS.isWebNl();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkWindowSize(window.innerWidth);
    }
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.consentSubscription = this.cookieConsentService.consentChanges$.subscribe(() => {
      this.consentSaved = this.cookieConsentService.isConsentSaved;
    });
  }

  ngAfterViewInit() {
    this.updateConsentDialogPosition();
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    this.updateConsentDialogPosition();
  }

  private updateConsentDialogPosition(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.isMobile) {
        this.cookieConsentService.updateDialogPosition(0);
      }
      else {
        const footerHeight = this.getFooterHeight();
        this.cookieConsentService.updateDialogPosition(footerHeight);
      }
    }
  }

  private getFooterHeight(): number {
    const footerElement = document.querySelector('.wmt-footer');
    return footerElement ? footerElement.clientHeight + 20 : 0;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkWindowSize((event.target as Window).innerWidth);
  }

  editCookieSetting() {
    this.cookieConsentService.displayConsentDialog();
    this.updateConsentDialogPosition();
  }

  private checkWindowSize(width: number): void {
    this.isMobile = width >= 768;
    if (this.isMobile) {
      this.logoHeight = '50px';
      this.logoWidth = 'auto';
    }
    else {
      this.logoHeight = '50px';
      this.logoWidth = '61px';
    }
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }

    if (this.consentSubscription) {
      this.consentSubscription.unsubscribe();
    }
  }
}
