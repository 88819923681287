<mat-sidenav-container [dir]="'ltr'">
  <mat-sidenav #mobileNavRight mode="over" position="end" class="filter-sidebar" fixedInViewport="true">
    <wmt-mobile-menu></wmt-mobile-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <wmt-header style="display: block; height: 94px"></wmt-header>
    <wmt-breadcrumb></wmt-breadcrumb>
    <router-outlet></router-outlet>
    <wmt-footer></wmt-footer>
    <wmt-cookie-consent></wmt-cookie-consent>
  </mat-sidenav-content>
</mat-sidenav-container>
