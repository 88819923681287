import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiModule } from '../../../ui/ui.module';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { IconsModule } from '../../../ui/icons/icons.module';

interface Service {
  id: string
  image: string
  title: string
  description: string
  buttonLabel: string
}

@Component({
  selector: 'wmt-cards',
  standalone: true,
  imports: [NgForOf, NgOptimizedImage, NgIf, UiModule, IconsModule],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss',
})
export class CardsComponent implements OnInit, OnDestroy {
  public isMobile = true;
  private subscription!: Subscription;
  currentRoute = '';

  constructor(
    private router: Router,
    private screenService: ScreenSizeService) {
  }

  /* {
  id: 'income-tax',
  image: 'assets/icons/payroll-icon-filled.png',
  title: 'Payroll tax overview - Individuals',
  description: 'Find out your net annual salary adjusted for dutch taxes. ' +
  'Robust calculator with 30% ruling, private use of company vehicle options. ' +
  'Option to download or share the results',
  buttonLabel: 'See my taxes'
}, */

  services: Service[] = [
    {
      id: 'income-tax',
      image: 'assets/images/svgs/taxes.svg',
      title: 'Payroll tax overview - Individuals',
      description: 'Find out your net annual salary adjusted for dutch taxes. '
        + 'Robust calculator with 30% ruling, private use of company vehicle options. '
        + 'Option to download or share the results',
      buttonLabel: 'See my taxes',
    },
    {
      id: 'tax-comparison',
      image: 'assets/images/svgs/decision.svg',
      title: 'Corporate tax overview - Businesses',
      description: 'Comparitive overview of taxes depending on type of company. '
        + 'See your tax schedules based ZZP and B.V. '
        + 'A side by side comparison of revenue earned as employee or as a company',
      buttonLabel: 'Compare my earnings',
    },
    {
      id: 'vehicle-usage-cost',
      image: 'assets/images/svgs/auto.svg',
      title: 'Private use or corporate car',
      description: 'See how much is added to your salary if you use a company car. '
        + 'Check your vehicle depreciation table. '
        + '<br><br>All data are based on 2024 regulations',
      buttonLabel: 'Tell me more',
    },
  ];

  ngOnInit(): void {
    this.subscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.currentRoute = this.router.url;
    this.services = this.services.filter((service) => {
      return `/${service.id}` !== this.currentRoute;
    });
  }

  calculateTax(serviceId: string) {
    // Logic to calculate tax or navigate to the service
    this.router.navigate([serviceId]);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
