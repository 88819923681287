import epoch from '../lib/epoch.js';
import isObject from '../lib/is_object.js';
import secs from '../lib/secs.js';
function validateInput(label, input) {
  if (!Number.isFinite(input)) {
    throw new TypeError(`Invalid ${label} input`);
  }
  return input;
}
export class ProduceJWT {
  constructor(payload = {}) {
    if (!isObject(payload)) {
      throw new TypeError('JWT Claims Set MUST be an object');
    }
    this._payload = payload;
  }
  setIssuer(issuer) {
    this._payload = {
      ...this._payload,
      iss: issuer
    };
    return this;
  }
  setSubject(subject) {
    this._payload = {
      ...this._payload,
      sub: subject
    };
    return this;
  }
  setAudience(audience) {
    this._payload = {
      ...this._payload,
      aud: audience
    };
    return this;
  }
  setJti(jwtId) {
    this._payload = {
      ...this._payload,
      jti: jwtId
    };
    return this;
  }
  setNotBefore(input) {
    if (typeof input === 'number') {
      this._payload = {
        ...this._payload,
        nbf: validateInput('setNotBefore', input)
      };
    } else if (input instanceof Date) {
      this._payload = {
        ...this._payload,
        nbf: validateInput('setNotBefore', epoch(input))
      };
    } else {
      this._payload = {
        ...this._payload,
        nbf: epoch(new Date()) + secs(input)
      };
    }
    return this;
  }
  setExpirationTime(input) {
    if (typeof input === 'number') {
      this._payload = {
        ...this._payload,
        exp: validateInput('setExpirationTime', input)
      };
    } else if (input instanceof Date) {
      this._payload = {
        ...this._payload,
        exp: validateInput('setExpirationTime', epoch(input))
      };
    } else {
      this._payload = {
        ...this._payload,
        exp: epoch(new Date()) + secs(input)
      };
    }
    return this;
  }
  setIssuedAt(input) {
    if (typeof input === 'undefined') {
      this._payload = {
        ...this._payload,
        iat: epoch(new Date())
      };
    } else if (input instanceof Date) {
      this._payload = {
        ...this._payload,
        iat: validateInput('setIssuedAt', epoch(input))
      };
    } else if (typeof input === 'string') {
      this._payload = {
        ...this._payload,
        iat: validateInput('setIssuedAt', epoch(new Date()) + secs(input))
      };
    } else {
      this._payload = {
        ...this._payload,
        iat: validateInput('setIssuedAt', input)
      };
    }
    return this;
  }
}