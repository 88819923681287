<div class="pageWrapper maxWidth">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">

      <h2 class="mat-headline-5">Introduction</h2>
      <p class="mat-body-1 m-t-16">
        taxelos.com, owned by Daedal Inc. with registered offices in Charlotte, NC, United States of America, and The
        Hague, Netherlands, is committed to protecting your personal data and respecting your privacy. This Cookie
        Policy explains how we use cookies and similar technologies on our website.
      </p>

      <h2 class="mat-headline-5 m-t-20">What Are Cookies?</h2>
      <p class="mat-body-1 m-t-16">
        Cookies are small text files placed on your device to collect standard internet log information and visitor
        behavior information. They help us provide a better user experience, understand how our site is used, and
        improve our services.
      </p>

      <h2 class="mat-headline-5 m-t-20">Types of Cookies We Use</h2>
      <ol>
        <li><span class="f-w-600">Strictly Necessary Cookies: </span>These are essential for you to browse our website
          and use its features.
        </li>
        <li><span class="f-w-600">Performance Cookies: </span>These cookies collect information about how visitors use
          our website, like which pages are most visited. They help us improve the functionality of our site.
        </li>
        <li><span class="f-w-600">Functionality Cookies: </span>These are used to recognize you when you return to our
          website, enabling personalization of content.
        </li>
        <li><span class="f-w-600">Targeting Cookies: </span>These cookies record your visit, the pages you have visited,
          and the links you have followed. We may use this information to make our website more relevant to your
          interests.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Consent and Control</h2>
      <p class="mat-body-1 m-t-16">
        Under the GDPR, we require your consent to use non-essential cookies. When you visit our website, a cookie
        consent banner will appear, allowing you to choose your preferences. You can change your cookie settings at any
        time.
      </p>

      <h2 class="mat-headline-5 m-t-20">Third-party Cookies</h2>
      <p class="mat-body-1 m-t-16">
        We may also use third-party cookies to help us analyze and understand how you use our website. These third
        parties have their own privacy policies and cookie policies.
      </p>

      <h2 class="mat-headline-5 m-t-20">Data Transfer Outside the EU</h2>
      <p class="mat-body-1 m-t-16">
        As Daedal Inc. operates in both the USA and the EU, data collected by cookies may be transferred to the United
        States. We ensure that appropriate safeguards are in place for such transfers as per GDPR regulations.
      </p>

      <h2 class="mat-headline-5 m-t-20">Your Rights</h2>
      <p class="mat-body-1 m-t-16">
        Under the GDPR, you have the right to access, rectify, delete, or restrict the processing of your personal data.
        Please contact us if you wish to exercise these rights.
      </p>

      <h2 class="mat-headline-5 m-t-20">Changes to This Policy</h2>
      <p class="mat-body-1 m-t-16">
        We may update this policy from time to time. Any changes will be posted on this page.
      </p>

      <h2 class="mat-headline-5 m-t-20">Contact Us</h2>
      <p class="mat-body-1 m-t-16">
        If you have any questions about our Cookie Policy, please contact our Data Protection Officer at <a
        href="mailto:gdpr@daedal.codes"> gdpr&#64;daedal.codes</a>.
      </p>
      <p class="wmt-text-updated">Effective date: 2-APR-2024</p>

    </mat-card-content>
  </mat-card>
</div>
