@if (!excludedRoutes.includes(this.currentPageRoute)) {
  <div class="pageWrapper maxWidth">
    <! --m-b-30-->
    <div class="bg-light-primary rounded p-y-30 p-x-24 overflow-hidden">
      <div class="row">
        <div class="col-sm-8">
          <h4 class="page-title m-0 f-s-20 f-w-600 m-b-16">
            {{ pageInfo['title'] }}
          </h4>
          <div class="d-flex align-items-center overflow-hidden">
            <ul class="breadcrumb">
              @for (url of pageInfo['urls']; track index; let index = $index, last = $last, first = $first) {
                @if (first) {
                  <li class="breadcrumb-item" [routerLink]="url.url">
                    <a [routerLink]="url.url">{{ url.title }}</a>
                  </li>
                } @else if (index === 1 && !last) {
                  <li class="breadcrumb-item" [routerLink]="url.url">
                    <i-tabler name="circle-filled" class="icon-8"></i-tabler>
                  </li>
                  <li [routerLink]="url.url" class="breadcrumb-item active cursor-pointer">{{ url.title }}</li>
                } @else {
                  <li class="breadcrumb-item">
                    <i-tabler name="circle-filled" class="icon-8"></i-tabler>
                  </li>
                  <li class="breadcrumb-item active">{{ url.title }}</li>
                }
              }
            </ul>
          </div>
        </div>
        <div class="col-sm-4 text-right position-relative">
          <div class="breadcrumb-icon">
            <img class="bread-logo" src="/assets/images/breadcrumb/breadLogo.png" alt="breadcrumb" width="140" />
          </div>
        </div>
      </div>
    </div>
  </div>
}
@if (secondaryRoutes.includes(this.currentPageRoute)) {
  <div class="breadcrumb-section">
    <div class="breadcrumb-overlay">
      <div class="pageWrapper maxWidth">
        <div class="breadcrumb-text-container">
          @if (currentPageRoute === 'about-us') {
            <h1 class="banner-title">About Us</h1>
            <p>We are a new age technology start-up delivering innovative cloud-native solutions to enterprises across
              diverse industries.</p>
          }
        </div>
      </div>
    </div>
  </div>
}
