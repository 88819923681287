<mat-card class="cardWithShadow">
  <mat-card-content class="p-x-24 p-y-0">
    <form [formGroup]="searchForm">
      <div class="row justify-content-between">
        <div class="col-12 col-md-3">
          <h4>Filter by</h4>
          <mat-form-field appearance="outline" class="w-100 hide-hint">
            <mat-label>Search by accountant name</mat-label>
            <input matInput formControlName="filterByAccountantName" />
            <mat-icon matSuffix>
              <i-tabler name="search" class="icon-20"></i-tabler>
            </mat-icon>
            @if (fv.hasError('filterByAccountantName')) {
              <mat-error *ngFor="let error of fv.getFormErrors('filterByAccountantName')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
        <div class="col-6 col-md-2">
          <h4 class="no-show">-</h4>
          <mat-form-field appearance="outline" class="w-100 hide-hint">
            <mat-label>State</mat-label>
            <mat-select formControlName="filterByState" id='state-filter' multiple>
              <mat-option *ngFor="let state of states" [value]="state">{{ state }}</mat-option>
            </mat-select>
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="col-6 col-md-2">
          <h4 class="no-show">-</h4>
          <mat-form-field appearance="outline" class="w-100 hide-hint">
            <mat-label>Service offered</mat-label>
            <mat-select formControlName="filterByServices" id='service-filter' multiple>
              <mat-option *ngFor="let service of servicesMulti" [value]="service">{{ service }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-8 col-md-3">
          <h4>Sort by service price</h4>
          <mat-form-field appearance="outline" class="w-100 hide-hint">
            <mat-label>Service offered</mat-label>
            <mat-select formControlName="sortByService" id='service-price-sort1'>
              <mat-option *ngFor="let service of services" [value]="service">{{ service }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 col-md-2">
          <h4 style="visibility: hidden">-</h4>
          <mat-button-toggle-group formControlName="sortByServicePrice" aria-label="Sort by service price">
            <mat-button-toggle value="asc" matTooltip="Sort Ascending">
              <mat-icon><i-tabler name="sort-ascending" class="icon-20"></i-tabler></mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="desc" matTooltip="Sort Descending">
              <mat-icon><i-tabler name="sort-descending" class="icon-20"></i-tabler></mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
