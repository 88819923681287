<div class="pageWrapper maxWidth">

  <div class="row">
    <div class="col-lg-12 col-sm-12">

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">
            Segregation of costs and depreciation on using company vehicle
          </mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            A detailed costs overview for owning a company car and shows you the costs associated with it.
            All prices shown are in Euros
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">
          <form [formGroup]="vehicleUsageCostForm" class="form-horizontal">
            <div class="row">

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">List price of the vehicle</mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="vehicle-list-price" formControlName="vehicleListPrice"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">VAT paid</mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="vat-paid" formControlName="vatPaid"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Is it 100% electric?
                      <mat-radio-group aria-label="Select an option" formControlName="isElectricCar"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                        <mat-radio-button value="no" color="primary">No</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('isElectricCar')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('isElectricCar')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Type of vehicle
                      <mat-radio-group aria-label="Select an option" formControlName="vehicleType"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="BIKE" color="primary">Bike</mat-radio-button>
                        <mat-radio-button value="CAR" color="primary">Car</mat-radio-button>
                        <mat-radio-button value="DELIVERY VAN" color="primary">Delivery van</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('vehicleType')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('vehicleType')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Purchase month
                      @if (fv.hasError('purchaseMonth')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('purchaseMonth')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select value="" formControlName="purchaseMonth" id='purchase-month'>
                        <mat-option *ngFor="let month of purchaseMonths" value="{{month}}">{{ month }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matPrefix class="op-5">
                        <i-tabler name="calendar-month" class="icon-20 d-flex"></i-tabler>
                      </mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>


            </div>
          </form>
        </mat-card-content>
      </mat-card>

      <mat-card class="cardWithShadow">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">
            Results
          </mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">

          <div class="row p-t-24 p-b-24">

            <div class="col-lg-6">
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">
                    Amount added to gross salary for private use of company vehicle
                  </mat-label>
                </div>
                <div class="col-sm-12">
                  <wmt-numeric-input placeholder="0.00" id="gross-salary-private-use-addition"
                                     [iconName]="'currency-euro'"
                                     [formControl]="fv.getControl('grossSalaryPrivateUseAddition')">
                  </wmt-numeric-input>
                </div>
              </div>
            </div>

            <div class="col-lg-6">
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">
                    VAT charged for private use of company car
                  </mat-label>
                </div>
                <div class="col-sm-12">
                  <wmt-numeric-input placeholder="0.00" id="vat-charged-on-private-use" [iconName]="'currency-euro'"
                                     [formControl]="fv.getControl('vatChargedOnPrivateUse')">
                  </wmt-numeric-input>
                </div>
              </div>
            </div>

          </div>

          <div class="b-t-1 b-b-1 m-b-24">

            <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
              Total depreciable cost of the vehicle:
            </mat-card-subtitle>

            <mat-card-title class="m-b-0 f-s-24 text-center">
              Depreciation table
            </mat-card-title>

            <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center">
              Amount that can be offset against profits by end of that year.
            </mat-card-subtitle>

          </div>

          <ejs-grid #grid cssClass="wmt-grid" [dataSource]="this.data">
            <e-columns>
              <e-column field="id" [isPrimaryKey]="true" [visible]="false"></e-column>
              <e-column field='year' headerText='Year' [width]=lineItemsWidth
                        [isFrozen]=isFrozenColumn textAlign="Right"></e-column>
              <e-column field='value' headerText='Value' textAlign="Right" [width]=valueWidth></e-column>
            </e-columns>
          </ejs-grid>

          <div class="row p-t-24">

            <div class="col-lg-8">
              <div class="row">
              </div>
            </div>

            <div class="col-lg-4">
              <div class="row">
                <div class="col-sm-12 d-flex align-items-center">
                  <mat-label class="mat-subtitle-2 f-w-600 d-block m-b-16">
                    Salvage value
                  </mat-label>
                </div>
                <div class="col-sm-12">
                  <wmt-numeric-input placeholder="0.00" id="salvage-value" [iconName]="'currency-euro'"
                                     [formControl]="fv.getControl('salvageValue')"
                                     [hint]="'depreciable cost - final years depreciated value'">
                  </wmt-numeric-input>
                </div>
              </div>
            </div>

          </div>

        </mat-card-content>
      </mat-card>

      <wmt-disclaimer width="1232px" [showExports]="false"></wmt-disclaimer>

    </div>
  </div>
</div>

<wmt-cards></wmt-cards>
