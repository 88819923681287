import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent, MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { LIB_ENVIRONMENT } from '../../../../index';
import { LibEnvironment } from '../../../common/interfaces/lib-environment';
import { formatDistanceToNow } from 'date-fns';
import { isPlatformBrowser } from '@angular/common';
import { VoteComponent } from '../vote/vote.component';

export interface DialogData {
  questionLimitErrorTitle: string
  machineId: string
}

@Component({
  selector: 'wmt-question-limit-dialog',
  standalone: true,
  templateUrl: './question-limit-dialog.component.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    VoteComponent,
  ],
  styleUrls: ['./question-limit-dialog.component.scss'],
})
export class QuestionLimitDialogComponent implements OnInit {
  countdown = '';
  resetTime: Date;
  private intervalId?: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
    private dialogRef: MatDialogRef<QuestionLimitDialogComponent>,
    @Inject(PLATFORM_ID) private platformId: NonNullable<unknown>,
  ) {
    const now = new Date();
    this.resetTime = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1, 0, 0, 0),
    );
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.updateCountdown();
      this.intervalId = window.setInterval(() => {
        this.updateCountdown();
      }, 1000) as unknown as number;

      this.dialogRef.afterClosed().subscribe(() => {
        clearInterval(this.intervalId);
      });
    }
  }

  updateCountdown() {
    this.countdown = formatDistanceToNow(this.resetTime, { addSuffix: true, includeSeconds: true });
  }
}
