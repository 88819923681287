<div class="pageWrapper maxWidth">
  <mat-card class="cardWithShadow">
    <img mat-card-image src="{{ ghostPost?.feature_image }}" alt="{{ ghostPost?.feature_image_alt }}" height="440" />
    <div class="card-overlay h-100 d-flex align-items-end justify-content-end">
      <mat-chip class="f-s-12 f-w-600 m-y-16 m-r-16 bg-white">
        {{ insightsService.getReadingTimeText(ghostPost?.reading_time) }}
      </mat-chip>
    </div>
    <mat-card-content class="p-24 b-b-1">
      <div class="user-category">
        <div>
          <img src="{{ ghostPost?.primary_author?.profile_image }}" class="rounded-circle" width="40" />
        </div>
        <div class="d-flex wmt-tags">
        @for (tag of ghostPost?.tags; track tag) {
          @if (tag.name !== 'taxelos') {
          <mat-chip class="f-s-12 m-y-16 f-w-600">
            {{ tag?.name  ?? 'none' }}
          </mat-chip>
          }
        }
        </div>
      </div>

      <mat-card-title class="m-t-16 m-b-24 blog-title">{{ ghostPost?.title }}
      </mat-card-title>
      <div class="d-flex align-items-center justify-content-center m-t-24">
        <div class="d-flex align-items-center">
          <span class="f-s-14 mat-subtitle-2 d-flex align-items-center">
            <i-tabler name="message-2" class="icon-18 m-r-4"></i-tabler>
            {{ ghostPost?.comments ? 10 : 0 }}
          </span>
        </div>
        <span class="m-l-auto f-s-14 mat-subtitle-2 d-flex align-items-center">
        <i-tabler name="point" class="icon-14 m-r-4"></i-tabler>
          {{ ghostPost?.created_at | date:'dd/MM/yyyy, HH:mm' }}
      </span>
      </div>
    </mat-card-content>
    <mat-card-content class="p-24" [innerHTML]="ghostPost?.html"></mat-card-content>
  </mat-card>

  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">
      <mat-card-title class="m-b-24 f-s-21">Post Comments</mat-card-title>
      <form>
        <mat-form-field appearance="outline" class="w-100">
          <textarea matInput rows="5"></textarea>
        </mat-form-field>
        <button mat-flat-button color="primary">Post Button</button>
      </form>

      <mat-card-title class="m-t-30 f-s-21 p-t-24"
      >Comments
        <span
          class="text-primary p-x-12 p-y-8 rounded bg-light-primary f-w-600 m-l-8 f-s-18"
        >4</span
        ></mat-card-title
      >

      &lt;!&ndash; comment &ndash;&gt;
      <div class="bg-light rounded p-24 m-t-24">
        <div class="d-flex align-items-center">
          <img
            src="/assets/images/profile/user-2.jpg"
            alt="user"
            width="35"
            class="rounded-circle"
          />
          <span class="mat-body-2 f-w-600 m-l-12">Charlie Hamilton</span>
          <span class="m-l-12 f-s-12 d-flex align-items-center">
          <i-tabler
            class="icon-7 op-5 d-flex m-r-4"
            name="circle-filled"
          ></i-tabler>
          now
        </span>
        </div>
        <p class="mat-body-1 m-t-16">
          Jigho tabkubho nira carudi ganlac milza dekpo putog iptodok tuhral canse
          mi rega ujnuf kukfag osailu bis oca. Gegeholo hata sogi kod bihdelsa
          nege evinog mes loz perdutace kehlondip im fep wiven fefu fi tigfiso.
        </p>

        <button
          mat-mini-fab
          color="primary"
          class="icon-30 m-t-16"
          matTooltip="Reply"
          (click)="toggleReply()"
        >
          <i-tabler name="arrow-back-up" class="icon-18"></i-tabler>
        </button>
      </div>
      &lt;!&ndash; add comment &ndash;&gt;
      <div [hidden]="isToggleReply">
        <form class="d-flex align-items-center m-y-24">
          <img
            src="/assets/images/profile/user-1.jpg"
            alt="user"
            width="35"
            class="rounded-circle"
          />
          <mat-form-field appearance="outline" class="w-100 hide-hint m-l-16">
            <input matInput placeholder="Reply" />
          </mat-form-field>
          <button mat-flat-button color="primary" class="m-l-16">Reply</button>
        </form>
      </div>
      &lt;!&ndash; comment &ndash;&gt;
      <div class="bg-light rounded p-24 m-t-24 m-l-24">
        <div class="d-flex align-items-center">
          <img
            src="/assets/images/profile/user-3.jpg"
            alt="user"
            width="35"
            class="rounded-circle"
          />
          <span class="mat-body-2 f-w-600 m-l-12">Ethan Gordon</span>
          <span class="m-l-12 f-s-12 d-flex align-items-center">
          <i-tabler
            class="icon-7 op-5 d-flex m-r-4"
            name="circle-filled"
          ></i-tabler>
          now
        </span>
        </div>
        <p class="mat-body-1 m-t-16">
          Diprow wir hilohi ilmi fumow oc co cop iv gi ize tamiv kulok. Bam ci
          urkati ul negu ovga hivwe toubugof gok imro ale sujoh saput.
        </p>

        <button
          mat-mini-fab
          color="primary"
          class="icon-30 m-t-16"
          matTooltip="Reply"
          (click)="toggleReply()"
        >
          <i-tabler name="arrow-back-up" class="icon-18"></i-tabler>
        </button>
      </div>
    </mat-card-content>
  </mat-card>

</div>
