import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { LIB_ENVIRONMENT } from '../../../index';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private http = inject(HttpClient);
  private environment: LibEnvironment = inject(LIB_ENVIRONMENT) as LibEnvironment;

  private apiBaseUrl = this.environment.apiBaseUrl;
  private token: string | null = null;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  requestToken(): void {
    this.http.get<{ token: string }>(`${this.apiBaseUrl}/init-auth`, {
      observe: 'response',
    }).pipe(
      tap({
        next: (response) => {
          this.token = response.body?.token || null;
        },
        error: (error) => {
          console.error('Error initiating jwt session:', error);
        },
      }),
    ).subscribe();
  }

  refreshToken(): Observable<{ token: string }> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.http.get<{ token: string }>(`${this.apiBaseUrl}/refresh-auth`).pipe(
        tap((newToken) => {
          this.token = newToken.token;
          this.isRefreshing = false;
          this.refreshTokenSubject.next(newToken.token);
        }),
        catchError((error) => {
          console.error('Failed to refresh token', error);
          this.isRefreshing = false;
          return throwError(() => new Error('Failed to refresh token'));
        }),
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap((token) => {
        return new Observable<{ token: string }>((observer) => {
          observer.next({ token });
          observer.complete();
        });
      }),
    );
  }

  getToken(): string | null {
    return this.token;
  }
}
