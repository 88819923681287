import { z } from 'zod';
import { CategoryEnum } from '../enums/category';

export const TaxSchema = z.object({
  taxId: z.string().max(10),
  taxName: z.string().max(40),
  description: z.string().max(99),
  category: CategoryEnum,
  taxCountry: z.string().length(4),
  taxCurrency: z.string().length(4),
  isActive: z.boolean(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Tax = z.infer<typeof TaxSchema>;
