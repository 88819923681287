import { z } from 'zod';
import { CategoryEnum } from '../enums/category';

export const VisitorSchema = z.object({
  visitorId: z.string().uuid(),
  firstName: z.string().max(40),
  lastName: z.string().max(40),
  email: z.string().email().max(80),
  category: CategoryEnum,
  companyName: z.string().max(150).optional().nullable(),
  phone: z.string().max(15).optional().nullable(),
  subject: z.string().max(400).optional().nullable(),
  description: z.string().optional().nullable(),
  isAccountant: z.boolean().optional().default(false),
  consent: z.boolean().optional(),
  isDeleted: z.boolean().optional().default(false),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type Visitor = z.infer<typeof VisitorSchema>;
