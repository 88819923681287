import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AccountantSignupData, AccountantWithServices } from '@wmt/shared';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { Observable } from 'rxjs';

interface AccountantSignupResponse {
  message: string
}

@Injectable({
  providedIn: 'root',
})
export class AccountantService {
  private readonly apiBaseUrl;
  private readonly signupUrl;

  constructor(
    private http: HttpClient,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
  ) {
    this.apiBaseUrl = this.environment.apiBaseUrl;
    this.signupUrl = this.apiBaseUrl + '/accountant-signup';
  }

  processAccountantSignup(accountantSignupData: AccountantSignupData): Observable<HttpResponse<AccountantSignupResponse>> {
    return this.http.post<AccountantSignupResponse>(this.signupUrl, accountantSignupData, { observe: 'response' });
  }

  getAccountants(
    page: number,
    pageSize: number,
    accountantName = '',
    states: string[] = [],
    services: string[] = [],
    sortByService = '',
    sortByServicePrice: 'asc' | 'desc' = 'asc',
  ): Observable<{ accountants: AccountantWithServices[], totalAccountants: number }> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('size', pageSize.toString());
    if (accountantName) params.append('accountantName', accountantName);
    if (states.length) params.append('states', states.join(','));
    if (services.length) params.append('services', services.join(','));
    if (sortByService) params.append('sortByService', sortByService);
    params.append('sortByServicePrice', sortByServicePrice);

    return this.http.get<{
      accountants: AccountantWithServices[]
      totalAccountants: number
    }>(`${this.apiBaseUrl}/accountants?${params.toString()}`);
  }

  getRecentAccountants(): Observable<{ companyName: string, state: string, joinedDate: Date }[]> {
    return this.http.get<{ companyName: string, state: string, joinedDate: Date }[]>(`${this.apiBaseUrl}/accountants/recent`);
  }
}
