import { FormGroup } from '@angular/forms';

const inputValues = {
  totalIncomeIn: 0,
  directorsSalaryIn: 0,
  companyExpensesIn: 0,
  personalExpensesIn: 0,
};

export const exportInputTitles: { [key: string]: string } = {
  totalIncomeIn: 'Total income/revenue',
  directorsSalaryIn: 'Director\'s salary',
  companyExpensesIn: 'Company expense incl 21% VAT',
  personalExpensesIn: 'Personal expense incl VAT',
};

export function updateInputValues(form: FormGroup) {
  const totalIncome = form.get('totalIncome')?.value;
  inputValues.totalIncomeIn = totalIncome ? parseFloat(totalIncome) : 0;
  const directorsSalary = form.get('directorsSalary')?.value;
  inputValues.directorsSalaryIn = directorsSalary ? parseFloat(directorsSalary) : 0;
  const companyExpenses = form.get('companyExpenses')?.value;
  inputValues.companyExpensesIn = companyExpenses ? parseFloat(companyExpenses) : 0;
  const personalExpenses = form.get('personalExpenses')?.value;
  inputValues.personalExpensesIn = personalExpenses ? parseFloat(personalExpenses) : 0;
}

export function getIV() {
  return inputValues;
}

type ExportInputType = {
  totalIncomeIn: string
  directorsSalaryIn: string
  companyExpensesIn: string
  personalExpensesIn: string
};

export function getExportInputs(): ExportInputType {
  return {
    totalIncomeIn: inputValues.totalIncomeIn.toFixed(2),
    directorsSalaryIn: inputValues.directorsSalaryIn.toFixed(2),
    companyExpensesIn: inputValues.companyExpensesIn.toFixed(2),
    personalExpensesIn: inputValues.personalExpensesIn.toFixed(2),
  };
}
