<div class="accountant-search-section-title">
  <div class="pageWrapper maxWidth">
    <div class="d-flex align-items-center justify-content-between">
      <div class="col-sm-9 p-t-8">
        <h2 style="font-size: 40px; margin-top: -60px;">Discover Trusted Accountants Near You</h2>
      </div>
      <div class="col-sm-3">
        <img src="assets/images/svgs/explore-card.svg" alt="Find accountant" width="300" />
      </div>
    </div>
  </div>
</div>

<div class="pageWrapper maxWidth">
  <div class="accountant-search-section">
    <wmt-accountant-search
      (searchResults)="updateAccountantList($event)"
      [pageChange]="currentPage">
    </wmt-accountant-search>
  </div>
</div>

<div class="pageWrapper list maxWidth">
  @if (totalAccountants > 0) {
    <wmt-accountant-list
      [accountants]="accountants"
      [totalAccountants]="totalAccountants"
      [totalPages]="totalPages"
      (pageChange)="onPageChange($event)">
    </wmt-accountant-list>
  } @else {
    <div class="text-center" style="margin-bottom: 100px">
      <p class="f-s-18 m-b-32">
        We are still in the process of adding accountants to our platform.
        <br>
        Next big release of the product with accountants onboarded will be on
        <strong>{{ releaseDateFormatted }}.</strong>
        <br><br>
        Meanwhile if you are an accountant and are willing to register with us, you can do so via the
        <a style="text-decoration: none" [routerLink]="['/partner-form']">partner form page</a>.
        <br>
        If you want to see how a sample page looks like, you can visit our
        <a href="https://nl.beta.taxelos.com/accountant-overview" target="_blank">beta site</a>.
      </p>
      <button mat-flat-button color="primary" [routerLink]="['/']">Return to Homepage</button>
    </div>
  }
</div>
