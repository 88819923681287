import { Component } from '@angular/core';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { FooterComponent } from '../../common/components/footer/footer.component';
import { HeroComponent } from '../../common/components/hero/hero.component';
import { NewsCardsComponent } from '../../common/components/news-cards/news-cards.component';
import {
  NewsletterCardComponent,
} from '../../common/components/newsletter-card/newsletter-card.component';

@Component({
  selector: 'wmt-homepage',
  standalone: true,
  imports: [
    CardsComponent,
    FooterComponent,
    HeroComponent,
    NewsCardsComponent,
    NewsletterCardComponent,
  ],
  templateUrl: './homepage.component.html',
  styleUrl: './homepage.component.scss',
})
export class HomepageComponent {
}
