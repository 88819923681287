import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { LIB_ENVIRONMENT } from '../../../index';
import { TSGhostContentAPI } from '@ts-ghost/content-api';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  private platformId = inject(PLATFORM_ID);
  private ghostApi!: TSGhostContentAPI;
  private environment: LibEnvironment = inject(LIB_ENVIRONMENT) as LibEnvironment;

  ghostPosts: any[] | null = null;
  detailId = '';

  private currentBlogTitleSubject = new BehaviorSubject<string | null>(null);
  currentBlogTitle$ = this.currentBlogTitleSubject.asObservable();

  constructor() {
    this.ghostApi = new TSGhostContentAPI(
      this.environment.ghostApiUrl || '',
      this.environment.ghostApiKey || '',
      'v5.96',
    );
  }

  async getGhostPosts(limit = 11, tag = 'taxelos') {
    if (isPlatformBrowser(this.platformId)) {
      const res = await this.ghostApi.posts
        .browse({ limit, filter: `tags:${tag}` }).include({ authors: true, tags: true }).fetch();

      if (!res.success) {
        console.error('Error fetching posts:', res.errors.join(', '));
        throw new Error(res.errors.join(', '));
      }

      this.ghostPosts = res.data;

      return this.ghostPosts;
    }
    return null;
  }

  async getGhostPostBySlug(slug: string) {
    if (isPlatformBrowser(this.platformId)) {
      const res = await this.ghostApi.posts
        .read({ slug }).include({ authors: true, tags: true }).fetch();

      if (!res.success) {
        throw new Error(res.errors.join(', '));
      }

      return res.data;
    }
    return null;
  }

  setCurrentBlogTitle(title: string | null) {
    this.currentBlogTitleSubject.next(title);
  }

  getReadingTimeText(readingTime: number | undefined): string {
    if (readingTime === undefined || isNaN(readingTime)) {
      return 'Unknown reading time';
    }
    return `${readingTime} ${readingTime <= 1 ? 'min' : 'mins'} Read`;
  }
}
