import { inject, Injectable } from '@angular/core';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { Message } from '@wmt/shared';
import { HttpClient } from '@angular/common/http';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { LIB_ENVIRONMENT } from '../../../index';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private environment = inject(LIB_ENVIRONMENT) as LibEnvironment;
  private http = inject(HttpClient);
  private apiBaseUrl = this.environment.apiBaseUrl;

  public newChatMessage(messages: Message[], temperature = 0.5): Observable<string> {
    return this.sendMessage({ messages, temperature });
  }

  public generateChatTitle(userMessage: string, assistantMessage: string): Observable<string> {
    const messages: Message[] = [
      { content: userMessage, role: 'user' },
      { content: assistantMessage, role: 'assistant' },
      {
        content:
          'What would be a short and relevant title for this chat? '
          + 'You must strictly answer with only the title max 3 words, no other text is allowed, not even quotes.'
          + 'Try at least to provide a title with 2 words related to the user request.',
        role: 'user',
      },
    ];
    return this.sendMessage({ messages });
  }

  public sendMessage(payload: any): Observable<string> {
    return this.http.post<{ sessionId: string }>(`${this.apiBaseUrl}/ai/chat/new-message`, payload)
      .pipe(
        switchMap(({ sessionId }) => this.streamChatCompletion(sessionId)),
        catchError(error =>
          throwError(() => new Error('Failed to initiate chat session')),
        ),
      );
  }

  public streamChatCompletion(sessionId: string): Observable<string> {
    return new Observable((observer) => {
      const eventSource = new EventSource(
        `${this.apiBaseUrl}/ai/chat/stream-response?sessionId=${sessionId}`,
      );

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        observer.next(data.text);
      };

      eventSource.addEventListener('stream-complete', () => {
        observer.complete();
        eventSource.close();
      });

      eventSource.onerror = (error) => {
        observer.error('Stream encountered an error');
        eventSource.close();
      };

      return () => eventSource.close();
    });
  }
}
