@if (!submitted) {
  <h2 class="m-t-10" style="font-size: 24px" mat-dialog-title>
    Request for information - {{ data.accountant.companyName }}
  </h2>
  <button class="wmt-close-button" mat-icon-button mat-dialog-close aria-label="Close dialog">
    <i-tabler name="x" class="icon-20"></i-tabler>
  </button>
  <mat-dialog-content style="overflow: hidden">
    <form [formGroup]="accountantRequestForm">
      <div class="row">

        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Name<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput placeholder="John Doe" id="name" formControlName="name" />
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('name')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('name')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Email<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput placeholder="john.doe@mail.com" type="email" formControlName="email" />
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('email')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('email')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                Services you are interested in:
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <mat-select value="" formControlName="services" id='company-state' multiple>
                  <mat-option *ngFor="let service of getAccountantServices()" value="{{service}}">{{ service }}
                  </mat-option>
                </mat-select>
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="info-square" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('services')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('services')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Details</span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <textarea rows="5" matInput
                          placeholder="Provide a background and information you seek from {{data.accountant.companyName}}"
                          formControlName="message"></textarea>
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="message-2" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('message')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('message')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

      </div>
    </form>

  </mat-dialog-content>
  <mat-dialog-actions class="m-x-15 m-b-15">
    <div class="col-12">
      <div class="row justify-content-between">
        <button class="action-button reset" type="reset" mat-flat-button (click)="resetForm()">Cancel</button>
        <button class="action-button" (click)="onSubmit()" mat-flat-button color="primary" type="submit"
                [disabled]="!accountantRequestForm.valid">
          Submit
        </button>
      </div>
    </div>
  </mat-dialog-actions>
} @else {
  <h2 class="m-t-10" style="font-size: 24px" mat-dialog-title>
    Your message has been sent to {{ data.accountant.companyName }}
  </h2>
  <button class="wmt-close-button" mat-icon-button mat-dialog-close aria-label="Close dialog">
    <i-tabler name="x" class="icon-20"></i-tabler>
  </button>
  <mat-dialog-content style="overflow: hidden">
    <div class="row m-t-20">
      <div class="col-12 text-center">
        <i-tabler name="send" class="wmt-sent-icon"></i-tabler>
        <p class="mb-0">{{ data.accountant.companyName }} will get back to you shortly.</p>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button class="action-button" mat-flat-button color="primary" (click)="resetForm()">Send another message</button>
  </mat-dialog-actions>
}
