import { ApplicationConfig, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { registerLicense } from '@syncfusion/ej2-base';
import { LIB_ENVIRONMENT } from '@wmt/web';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptor } from '@wmt/web';
import { isPlatformBrowser, registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { devTools } from '@ngneat/elf-devtools';

// Registering Syncfusion license key
// registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5fd3VRRmNYUkd0WEA=');
registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX9Td0diXXpecHJRQGVU');
// Registering Dutch locale
registerLocaleData(localeNl);

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

if (environment.mode === 'local' && isPlatformBrowser(PLATFORM_ID)) {
  devTools({
    name: 'wmt-nl',
    maxAge: 25,
  });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideRouter(appRoutes, withInMemoryScrolling(scrollConfig)),
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptors([
      authInterceptor,
    ])),
    { provide: LIB_ENVIRONMENT, useValue: environment },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
  ],
};
