import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { firstValueFrom, Observable } from 'rxjs';
import { Tax } from '@wmt/shared';

const initialState = {
  taxes: [] as Tax[],
};

export const taxStore = createStore(
  { name: 'taxes' },
  withProps<{ taxes: Tax[] }>(initialState),
);

export function loadTaxes(taxes: Tax[]) {
  taxStore.update(state => ({
    ...state,
    taxes,
  }));
}

export const taxStoreQuery = {
  getTaxes: () => taxStore.query(state => state.taxes),
  getTaxById: (taxId: string) =>
    taxStore.query(state =>
      state.taxes.find(t =>
        t.taxId === taxId)),
};

export async function initializeTaxStore(fetchTaxesFromDB: () => Observable<Tax[]>) {
  const storedTaxes = taxStoreQuery.getTaxes();
  if (storedTaxes.length === 0) {
    const taxes = await firstValueFrom(fetchTaxesFromDB());
    loadTaxes(taxes);
  }
}

persistState(taxStore, {
  key: 'wmt-taxes',
  storage: localStorageStrategy,
});
