import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wmtKvkNumber]',
  standalone: true,
})
export class KvkNumberDirective {
  constructor(private el: ElementRef<HTMLInputElement>, private renderer: Renderer2) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'End', 'Home',
    ];
    const key = event.key;

    // Allow control keys and restrict non-digit keys
    if (!allowedKeys.includes(event.key) && !/^\d$/.test(key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let value = this.el.nativeElement.value;

    // Remove all non-digit characters
    value = value.replace(/\D/g, '');

    // Limit to 8 digits
    if (value.length > 8) {
      value = value.slice(0, 8);
    }

    this.el.nativeElement.value = value;
  }

  @HostListener('blur') onBlur() {
    const value = this.el.nativeElement.value;

    // Check if the value is a valid KVK number
    if (/^[1-9]\d{7}$/.test(value)) {
      // Remove any invalid indication if the value is valid
      this.renderer.removeClass(this.el.nativeElement, 'invalid');
    }
    else {
      // Add an invalid indication if the value is not valid
      this.renderer.addClass(this.el.nativeElement, 'invalid');
    }
  }
}
