@if (!submitted) {
  <h2 class="m-t-10" style="font-size: 24px" mat-dialog-title>Rate {{ data.accountant.companyName }}</h2>
  <button class="wmt-close-button" mat-icon-button mat-dialog-close aria-label="Close dialog">
    <i-tabler name="x" class="icon-20"></i-tabler>
  </button>
  <mat-dialog-content style="overflow: hidden">
    <form [formGroup]="accountantReviewForm">
      <div class="row">
        <div class="col-12">
          <div class="rating-container">
            @for (star of stars; track star; let i = $index) {
              <div class="rating-star">
                <i-tabler name="star" class="icon-40 cursor-pointer star-icon"
                          [ngClass]="{'filled': i < selectedRating}"
                          (click)="selectRating(i + 1)">
                </i-tabler>
                <span class="rating-label" [ngClass]="{'selected-label': i === selectedRating - 1}">
                {{ getLabel(i) }}
              </span>
              </div>
            }
          </div>
          @if (fv.hasError('stars')) {
            <mat-error *ngFor="let error of fv.getFormErrors('stars')">
              {{ error }}
            </mat-error>
          }
        </div>
      </div>

      <div class="row">

        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Name<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput placeholder="John Doe" id="name" formControlName="name" />
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('name')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('name')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Email<span class="wmt-required">*</span></span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput placeholder="john.doe@mail.com" type="email" formControlName="email" />
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('email')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('email')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="row">
            <div class="col-sm-4 d-flex p-t-10">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100">
                <span>Stay anonymous</span>
              </mat-label>
            </div>
            <div class="col-sm-6 anonymous-radio-group">
              <mat-radio-group aria-label="Select an option" formControlName="stayAnonymous">
                <mat-radio-button value="Yes" color="primary">Yes</mat-radio-button>
                <mat-radio-button value="No" color="primary">No</mat-radio-button>
              </mat-radio-group>
              @if (fv.hasError('stayAnonymous')) {
                <mat-error *ngFor="let error of fv.getFormErrors('stayAnonymous')"
                           style="display: inline" class="f-w-600">{{ error }}
                </mat-error>
              }
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 d-flex align-items-center">
              <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                <span>Comments</span>
              </mat-label>
            </div>
            <div class="col-sm-12">
              <mat-form-field appearance="outline" class="w-100">
                <textarea rows="5" matInput
                          placeholder="Provide any feedback (good or bad) you had with {{data.accountant.companyName}}"
                          formControlName="feedback"></textarea>
                <mat-icon matPrefix class="op-5">
                  <i-tabler name="message-2" class="icon-20 d-flex"></i-tabler>
                </mat-icon>
                @if (fv.hasError('feedback')) {
                  <mat-error *ngFor="let error of fv.getFormErrors('feedback')">
                    {{ error }}
                  </mat-error>
                }
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-sm-12 d-flex align-items-center p-b-10">
          <mat-checkbox color="primary" formControlName="consent">
            You can contact me for a follow-up regarding my review.
            @if (fv.hasError('consent')) {
              <mat-error *ngFor="let error of fv.getFormErrors('consent')"
                         style="display: inline" class="f-w-600">{{ error }}
              </mat-error>
            }
          </mat-checkbox>
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          @if (feedbackExists) {
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-center w-100 align-items-center">
              <span class="wmt-required">You have already provided feedback for this accountant.</span>
            </mat-label>
          } @else {
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100"
                       style="visibility: hidden">
              <span>NOTE: You can only rate each accountant once.</span>
            </mat-label>
          }
        </div>
      </div>
    </form>

  </mat-dialog-content>
  <mat-dialog-actions class="m-x-15 m-b-15">
    <div class="col-12">
      <div class="row justify-content-between">
        <button class="action-button reset" type="reset" mat-flat-button (click)="resetForm()">Cancel</button>
        <button class="action-button" (click)="onSubmit()" mat-flat-button color="primary" type="submit"
                [disabled]="!accountantReviewForm.valid">
          Submit
        </button>
      </div>
    </div>
  </mat-dialog-actions>
} @else {
  <h2 class="m-t-10" style="font-size: 24px; margin-right: 100px" mat-dialog-title>
    You have rated {{ data.accountant.companyName }}
  </h2>
  <button class="wmt-close-button" mat-icon-button mat-dialog-close aria-label="Close dialog">
    <i-tabler name="x" class="icon-20"></i-tabler>
  </button>
  <mat-dialog-content style="overflow: hidden">
    <div class="row m-t-20">
      <div class="col-12">
        <div class="rating-container justify-content-center">
          @for (star of stars; track star; let i = $index) {
            <div class="rating-star">
              <i-tabler name="star" class="icon-40 star-icon" [ngClass]="{'filled': i < selectedRating}"></i-tabler>
              <span class="rating-label" [ngClass]="{'selected-label': i === selectedRating - 1}">
                {{ getLabel(i) }}
              </span>
            </div>
          }
        </div>
      </div>
    </div>
  </mat-dialog-content>
}
