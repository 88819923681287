<div class="pageWrapper maxWidth">

  <div class="row">
    <div class="col-lg-12 col-sm-12">

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">Dutch Income Tax Calculator</mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            Please input your gross income and allocate a period
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">
          <form [formGroup]="incomeTaxForm" class="form-horizontal">
            <div class="row">

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Gross income
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="gross-income" formControlName="grossIncome"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Period
                      @if (fv.hasError('period')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('period')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select value="" formControlName="period" id='period'>
                        <mat-option *ngFor="let taxPeriod of incomeTaxPeriods" value="{{taxPeriod}}">{{ taxPeriod }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matPrefix class="op-5">
                        <i-tabler name="calendar" class="icon-20 d-flex"></i-tabler>
                      </mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-8">
                      <mat-slide-toggle color="primary" id="ruling" formControlName="ruling">
                        <strong>I have 30% ruling</strong>
                      </mat-slide-toggle>
                      @if (fv.hasError('ruling')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('ruling')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" *ngIf="fv.f?.['ruling']?.value">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Ruling months
                      <mat-radio-group aria-label="Select an option" formControlName="rulingMonths"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="1st" color="primary">1st 20 mths</mat-radio-button>
                        <mat-radio-button value="2nd" color="primary">2nd 20 mths</mat-radio-button>
                        <mat-radio-button value="last" color="primary">Last 20 mths</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('rulingMonths')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('rulingMonths')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6" *ngIf="fv.f?.['ruling']?.value"></div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Holiday allowance included?
                      <mat-radio-group aria-label="Select an option" formControlName="holidayAllowance"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                        <mat-radio-button value="no" color="primary">No</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('holidayAllowance')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('holidayAllowance')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Private use of company car?
                      <mat-radio-group aria-label="Select an option" formControlName="privateCarUse"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                        <mat-radio-button value="no" color="primary">No</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('privateCarUse')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('privateCarUse')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Electric?
                      <mat-radio-group aria-label="Select an option" formControlName="electric"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                        <mat-radio-button value="no" color="primary">No</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('electric')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('electric')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-8">
                      Enter sales price of car including btw:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="car-price" formControlName="carPrice"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label
                      class="mat-subtitle-2 f-w-600 d-flex align-items-center justify-content-between w-100 m-b-16">
                      Private use of company bike?
                      <mat-radio-group aria-label="Select an option" formControlName="privateBikeUse"
                                       class="wmt-mobile-flex-column">
                        <mat-radio-button value="yes" color="primary">Yes</mat-radio-button>
                        <mat-radio-button value="no" color="primary">No</mat-radio-button>
                      </mat-radio-group>
                      @if (fv.hasError('privateBikeUse')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('privateBikeUse')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16 m-t-8">
                      Enter sales price of bike including btw:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="bike-price" formControlName="bikePrice"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      WFH days per week:
                      @if (fv.hasError('workFromHomeDays')) {
                        <mat-error *ngFor="let error of fv.getFormErrors('workFromHomeDays')">
                          {{ error }}
                        </mat-error>
                      }
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-select value="" formControlName="workFromHomeDays" id='work-from-home-days'>
                        <mat-option *ngFor="let workDays of workFromHomeDays" value="{{workDays}}">{{ workDays }}
                        </mat-option>
                      </mat-select>
                      <mat-icon matPrefix class="op-5">
                        <i-tabler name="calendar" class="icon-20 d-flex"></i-tabler>
                      </mat-icon>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Kilometers traveled for work avg per month*?:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="work-km-per-month" formControlName="workKmPerMonth"
                                       [iconName]="'calendar-plus'"></wmt-numeric-input>
                    <mat-hint align="start" class="wmt-form-hint">*tax free limit is 1.5% of salary costs</mat-hint>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </mat-card-content>
      </mat-card>

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">Result</mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            Calculated ...
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">
          <ejs-grid #grid cssClass="wmt-grid" [dataSource]="this.data" [selectionSettings]="selectionOptions">
            <e-columns>
              <e-column field="id" [isPrimaryKey]="true" [visible]="false"></e-column>
              <e-column field='lineItems' headerText='Line Items' [width]=lineItemsWidth
                        [isFrozen]=isFrozenColumn textAlign="Right"></e-column>
              <e-column field='value' headerText='Value' textAlign="Right" [width]=valueWidth></e-column>
            </e-columns>
          </ejs-grid>
        </mat-card-content>
      </mat-card>

      <wmt-disclaimer width="1232px"
                      (exportPdfEvent)="exportPdf()"
                      (exportExcelEvent)="exportXlsx()"
                      (copyClipboardEvent)="copyClipboard()"
                      [pageName]="'income-tax'">
      </wmt-disclaimer>

    </div>
  </div>

</div>

<wmt-cards></wmt-cards>
