import { Inject, inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Chat, Message, Role } from '@wmt/shared';
import {
  chatStore,
  addNewChat,
  loadChats,
  toggleSidebar,
  updateChatMessage,
  updateChatTitle,
  setSidebarState,
  setCurrentChatId,
  addNewChatMessage,
  softDeleteChat, clearCurrentChatId,
} from '../../common/stores/chat.store';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { Threads } from 'openai/resources/beta';
import RunStatus = Threads.RunStatus;
import { ThreadService } from '../api/thread.service';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';

@Injectable({
  providedIn: 'root',
})
export class ChatStateService implements OnDestroy {
  private screenSizeService = inject(ScreenSizeService);
  private subs = new Subscription();
  private threadService = inject(ThreadService);
  private platformId = inject(PLATFORM_ID);
  private latestRunStatus: RunStatus | null = null;

  constructor(@Inject(LIB_ENVIRONMENT) private environment: LibEnvironment) {
    this.subs.add(
      this.screenSizeService.isMobile$.subscribe((isMobile) => {
        // this.setSidebarOpen(!isMobile);
      }),
    );
  }

  // Create observables by querying the store
  get sidebarOpen$(): Observable<boolean> {
    return chatStore.pipe(map(state => state.sidebarOpen));
  }

  get chats$(): Observable<Chat[]> {
    return chatStore.pipe(map(state => state.chats));
  }

  get currentChat$(): Observable<Chat | undefined> {
    return chatStore.pipe(map(state => state.chats.find(chat => chat.id === state.currentChatId)));
  }

  get sendOnEnter$(): Observable<boolean> {
    return chatStore.pipe(map(state => state.sendOnEnter));
  }

  // Actions to manipulate the store
  public loadInitialChats(chats: Chat[]): void {
    loadChats(chats);
  }

  public toggleChatSidebar(): void {
    toggleSidebar();
  }

  public setSidebarOpen(open: boolean): void {
    setSidebarState(open);
  }

  public setCurrentChat(chatId: string | null): void {
    setCurrentChatId(chatId);
  }

  public getCurrentChatId(): string | null {
    return chatStore.getValue().currentChatId;
  }

  public getCurrentChat(): Chat | undefined {
    const currentChatId = this.getCurrentChatId();
    if (currentChatId) {
      return this.getChatById(currentChatId);
    }
    return undefined;
  }

  getCurrentChatSync(): Chat | undefined {
    const currentChatId = this.getCurrentChatId();
    return this.getAllChats().find(chat => chat.id === currentChatId);
  }

  public newChat(uuid: string, chat?: Chat): void {
    const now = new Date();
    const newChat: Chat = chat || {
      title: 'New Chat',
      messages: [
        {
          content: 'Tax Djinn at your service!',
          role: 'system',
        },
      ],
      systemMessage: 'Tax Djinn at your service!',
      id: uuid,
      createdAt: now,
      updatedAt: now,
    };

    addNewChat(newChat);
  }

  public newChatMessage(
    message: string,
    messageId: string,
    currentChatId: string,
    role: Role = 'user',
    runStatus?: RunStatus,
    runId?: string): void {
    const currentChat: Chat | undefined = this.getChatById(currentChatId);

    if (!currentChat) return;

    const newMessage: Message = {
      content: message,
      role,
      id: messageId,
      runStatus,
      runId,
    };

    addNewChatMessage(currentChatId, newMessage);
  }

  public newChatMessageChunk(chunk: string, messageId: string, currentChatId: string, runStatus?: RunStatus): void {
    if (runStatus) this.latestRunStatus = runStatus;
    updateChatMessage(currentChatId, messageId, chunk, runStatus);
  }

  public updateMessage(chatId: string, messageId: string, contentUpdate: string): void {
    updateChatMessage(chatId, messageId, contentUpdate);
  }

  public updateTitle(chatId: string, newTitle: string): void {
    updateChatTitle(chatId, newTitle);
  }

  public softDeleteThread(chatId: string): void {
    softDeleteChat(chatId);
    const chats = this.getAllChats();
    if (chats.length > 0) {
      const lastChatId = chats[chats.length - 1].id;
      this.setCurrentChat(lastChatId);
    }
    else {
      clearCurrentChatId();
    }
  }

  public softDeleteAllThreads(): void {
    chatStore.update((state) => {
      return {
        ...state,
        chats: [],
      };
    });
  }

  public hardDeleteAllThreads(): void {
    this.softDeleteAllThreads();
    this.threadService.deleteThread(this.getCurrentChatId() as string);
  }

  // Direct store queries
  public getAllChats(): Chat[] {
    return chatStore.getValue().chats;
  }

  public getChatById(chatId: string): Chat | undefined {
    const chats = chatStore.getValue().chats;
    return chats.find(chat => chat.id === chatId);
  }

  public getLatestRunStatus(): RunStatus | null {
    return this.latestRunStatus;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
