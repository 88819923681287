import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { Observable } from 'rxjs';
import { AccountantFeedback, AccountantFeedbackResponse, AccountantVisitorFeedbackData } from '@wmt/shared';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private readonly feedbackUrl;

  constructor(
    private http: HttpClient,
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
  ) {
    this.feedbackUrl = this.environment.apiBaseUrl + '/feedback';
  }

  getFeedbackByAccountantId(accountantId: string): Observable<AccountantFeedbackResponse[]> {
    return this.http.get<AccountantFeedbackResponse[]>(`${this.feedbackUrl}/${accountantId}`);
  }

  getFeedbackByAccountantIds(accountantIds: string[]): Observable<AccountantFeedbackResponse[]> {
    return this.http.post<AccountantFeedbackResponse[]>(`${this.feedbackUrl}` + 's', { accountantIds });
  }

  createFeedback(feedback: AccountantVisitorFeedbackData): Observable<AccountantFeedback | { status: 'exists' }> {
    return this.http.post<AccountantFeedback>(this.feedbackUrl, feedback);
  }
}
