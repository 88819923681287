import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TaxRate } from '@wmt/shared';
import { CurrencyPipe, NgForOf } from '@angular/common';
import { CapitalizeFirstPipe } from '../../../common/pipes/capitalize-first';
import { ToPercentagePipe } from '../../../common/pipes/to-percentage';
import { taxRateStoreQuery } from '../../../common/stores/tax-rate.store';

@Component({
  selector: 'wmt-detail-view-taxes',
  standalone: true,
  imports: [
    NgForOf,
    CapitalizeFirstPipe,
    CurrencyPipe,
    ToPercentagePipe,
  ],
  templateUrl: './detail-view-taxes.component.html',
  styleUrl: './detail-view-taxes.component.scss',
})
export class DetailViewTaxesComponent implements OnChanges {
  @Input() taxCategoryId!: string;
  public taxRates: TaxRate[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['taxCategoryId']) {
      this.taxRates = taxRateStoreQuery.getTaxRatesByTaxCategoryId(this.taxCategoryId);
    }
  }
}
