import { Routes } from '@angular/router';
import {
  HomepageComponent,
  IncomeTaxComponent,
  TaxTableComponent,
  AiChatbotComponent,
  AboutUsComponent,
  ContactUsComponent,
  PrivacyPolicyComponent,
  CookiePolicyComponent,
  TermsAndConditionsComponent,
  Taxelos360Component,
  TaxComparisonComponent,
  VehicleUsageCostComponent,
  PartnerFormComponent,
  validateChatId, AccountantOverviewComponent, InsightsComponent, InsightsDetailComponent,
} from '@wmt/web';

export const appRoutes: Routes = [
  {
    path: '',
    component: HomepageComponent,
    data: { title: 'Home', url: '', urls: [{ title: 'Home', url: '' }] },
  },
  {
    path: 'income-tax',
    component: IncomeTaxComponent,
    data: { title: 'Income Tax', url: 'income-tax', urls: [{ title: 'Home', url: '' }, { title: 'Income Tax' }] },
  },
  {
    path: 'tax-comparison', component: TaxComparisonComponent,
    data: {
      title: 'Compare taxes', url: 'tax-comparison',
      urls: [{ title: 'Home', url: '' }, { title: 'Tax comparison' }],
    },
  },
  {
    path: 'vehicle-usage-cost', component: VehicleUsageCostComponent,
    data: {
      title: 'Company vehicle usage costs', url: 'vehicle-usage-cost',
      urls: [{ title: 'Home', url: '' }, { title: 'Company vehicle usage costs' }],
    },
  },
  {
    path: 'tax-table',
    component: TaxTableComponent,
    data: {
      title: 'Latest Tax table', url: 'tax-table',
      urls: [{ title: 'Home', url: '' }, { title: 'Latest Tax table' }],
    },
  },
  {
    path: 'taxelos-360',
    component: Taxelos360Component,
    data: {
      title: 'Taxelos ΤΞ', url: 'taxelos-360',
      urls: [{ title: 'Home', url: '' }, { title: 'Taxelos ΤΞ' }],
    },
  },
  {
    path: 'accountant-overview',
    component: AccountantOverviewComponent,
    data: {
      title: 'Accountant Overview',
      url: 'accountant-overview',
      urls: [{ title: 'Home', url: '' }, { title: 'Accountant Overview' }],
    },
  },
  {
    path: 'tax-djinn',
    children: [
      {
        path: '',
        component: AiChatbotComponent,
        data: { title: 'Tax Djinn', url: 'tax-djinn', urls: [{ title: 'Home', url: '' }, { title: 'Tax Djinn' }] },
      },
      {
        path: ':id',
        component: AiChatbotComponent,
        data: { title: 'Tax Djinn', url: 'tax-djinn', urls: [{ title: 'Home', url: '' }, { title: 'Tax Djinn' }] },
        canActivate: [validateChatId],
      },
    ],
  },
  {
    path: 'insights',
    children: [
      {
        path: '',
        component: InsightsComponent,
        data: {
          title: 'Insights', url: 'insights',
          urls: [{ title: 'Home', url: '' }, { title: 'Insights' }],
        },
      },
      {
        path: ':slug',
        component: InsightsDetailComponent,
        data: {
          title: 'Insights', url: 'insights/:slug',
          urls: [{ title: 'Home', url: '' }, { title: 'Insights', url: 'insights' }, { title: 'Insights' }],
        },
      },
    ],
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { title: 'About Us', url: 'about-us', urls: [{ title: 'Home', url: '' }, { title: 'About Us' }] },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy Policy', url: 'privacy-policy',
      urls: [{ title: 'Home', url: '' }, { title: 'Privacy Policy' }],
    },
  },
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    data: {
      title: 'Cookie Policy', url: 'cookie-policy',
      urls: [{ title: 'Home', url: '' }, { title: 'Cookie Policy' }],
    },
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    data: {
      title: 'Terms and Conditions', url: 'terms-and-conditions',
      urls: [{ title: 'Home', url: '' }, { title: 'Terms and Conditions' }],
    },
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { title: 'About Us', url: 'about-us', urls: [{ title: 'About Us', url: '' }, { title: 'About Us' }] },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: { title: 'Contact Us', url: 'contact-us', urls: [{ title: 'Home', url: '' }, { title: 'Contact Us' }] },
  },
  {
    path: 'partner-form',
    component: PartnerFormComponent,
    data: { title: 'Partner Form', url: 'partner-form', urls: [{ title: 'Home', url: '' }, { title: 'Partner Form' }] },
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];
