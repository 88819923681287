<form class="m-t-24" [formGroup]="companyProfileForm">
  <div class="row">

    <div class="col-lg-12">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>Company Name<span class="wmt-required">*</span></span>
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Please mention the name of your company" id="company-name"
                   formControlName="companyName" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="building-arch" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyName')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyName')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>KVK<span class="wmt-required">*</span></span>
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Provide the KVK number of your company" id="company-kvk"
                   formControlName="companyKvk" wmtKvkNumber />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="id" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyKvk')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyKvk')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            <span>Languages<span class="wmt-required">*</span></span>
            @if (companyProfileForm.hasError('atLeastOneLanguage')) {
              <mat-error>
                {{ companyProfileForm.getError('atLeastOneLanguage')?.message }}
              </mat-error>
            }
          </mat-label>
        </div>
        <div class="col-12 d-flex flex-wrap checkbox-container" style="padding-left: 5px">
          <div class="col-6 col-md-3 p-0">
            <mat-checkbox formControlName="speaksEnglish" color="primary">English</mat-checkbox>
          </div>
          <div class="col-6 col-md-3 p-0">
            <mat-checkbox formControlName="speaksDutch" color="primary">Dutch</mat-checkbox>
          </div>
          <div class="col-6 col-md-3 p-0">
            <mat-checkbox formControlName="speaksFrench" color="primary">French</mat-checkbox>
          </div>
          <div class="col-6 col-md-3 p-0">
            <mat-checkbox formControlName="speaksGerman" color="primary">German</mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            Display Email
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="john.doe@mail.com" type="email" formControlName="companyDisplayEmail" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyDisplayEmail')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyDisplayEmail')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            Phone
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput wmtPhoneInput placeholder="Office phone to be shown on company card (+31 6 12345678)"
                   formControlName="companyDisplayPhone" type="tel" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="phone" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyDisplayPhone')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyDisplayPhone')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-8">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            Address
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Street and house number" formControlName="companyAddress" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyAddress')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyAddress')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            Extension
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="House number and extension (if any)" formControlName="companyHouseNumber" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyHouseNumber')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyHouseNumber')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            City
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="" formControlName="companyCity" />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyCity')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyCity')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-4">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            State
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <mat-select value="" formControlName="companyState" id='company-state'>
              <mat-option *ngFor="let state of states" value="{{state}}">{{ state }}
              </mat-option>
            </mat-select>
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyState')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyState')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="col-lg-2">
      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
            Zip
          </mat-label>
        </div>
        <div class="col-sm-12">
          <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="1234 AB" formControlName="companyZip" wmtNlZipCode />
            <mat-icon matPrefix class="op-5">
              <i-tabler name="map-pin" class="icon-20 d-flex"></i-tabler>
            </mat-icon>
            @if (fv.hasError('companyZip')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyZip')">
                {{ error }}
              </mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>

  <div class="bg-extra-light wmt-form-accent">
    <h4 class="f-s-18">For internal use only:</h4>
    <div class="row">

      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>Primary contact full name<span class="wmt-required">*</span></span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Name of company's POC for us to contact" id="primary-contact-full-name"
                     formControlName="companyPrimaryContactFullName" />
              <mat-icon matPrefix class="op-5">
                <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
              @if (fv.hasError('companyPrimaryContactFullName')) {
                <mat-error *ngFor="let error of fv.getFormErrors('companyPrimaryContactFullName')">
                  {{ error }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              <span>Primary contact Email<span class="wmt-required">*</span></span>
            </mat-label>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Email of primary POC" type="email"
                     formControlName="companyPrimaryContactEmail" />
              <mat-icon matPrefix class="op-5">
                <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
              @if (fv.hasError('companyPrimaryContactEmail')) {
                <mat-error *ngFor="let error of fv.getFormErrors('companyPrimaryContactEmail')">
                  {{ error }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              Secondary contact full name
            </mat-label>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Name of company's 2nd POC for us to contact" id="secondary-contact-full-name"
                     formControlName="companySecondaryContactFullName" />
              <mat-icon matPrefix class="op-5">
                <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
              @if (fv.hasError('companySecondaryContactFullName')) {
                <mat-error *ngFor="let error of fv.getFormErrors('companySecondaryContactFullName')">
                  {{ error }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-sm-12 d-flex align-items-center">
            <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
              Secondary contact Email
            </mat-label>
          </div>
          <div class="col-sm-12">
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="Email of secondary POC" type="email"
                     formControlName="companySecondaryContactEmail" />
              <mat-icon matPrefix class="op-5">
                <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
              @if (fv.hasError('companySecondaryContactEmail')) {
                <mat-error *ngFor="let error of fv.getFormErrors('companySecondaryContactEmail')">
                  {{ error }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="companyPledge">
            {{ pledgeText }}<span class="wmt-required">*</span>
            @if (fv.hasError('companyPledge')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyPledge')"
                         style="display: inline" class="f-w-600"> {{ error }}
              </mat-error>
            }
          </mat-checkbox>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 d-flex align-items-center">
          <mat-checkbox color="primary" formControlName="companyConsent">
            {{ consentText }}<span class="wmt-required">*</span>
            @if (fv.hasError('companyConsent')) {
              <mat-error *ngFor="let error of fv.getFormErrors('companyConsent')"
                         style="display: inline" class="f-w-600"> {{ error }}
              </mat-error>
            }
          </mat-checkbox>
        </div>
      </div>

    </div>
  </div>

  <div class="row justify-content-end">
    <div class="col-6">
      <button mat-flat-button color="warn" (click)="stepper.reset()">Reset</button>
    </div>
    <div class="col-6 text-right">
      <button mat-flat-button color="primary" matStepperNext [disabled]="!companyProfileForm.valid">Next</button>
    </div>
  </div>
</form>
