import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toPercentage',
})
export class ToPercentagePipe implements PipeTransform {
  transform(value: number | null, digits?: number): string {
    const percentage = value ? value * 100 : 0;
    const formattedPercentage = digits !== undefined ? percentage.toFixed(digits) : percentage.toString();
    return value ? formattedPercentage + '%' : '-';
  }
}
