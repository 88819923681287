import { inject, Injectable } from '@angular/core';
import { ChatStateService } from '../state/chat-state.service';
import { ChatService } from '../api/chat.service';
import { ThreadService } from '../api/thread.service';
import { Observable } from 'rxjs';
import { Message, ThreadMessage } from '@wmt/shared';

@Injectable({
  providedIn: 'root',
})
export class ChatFacadeService {
  readonly chatStateService = inject(ChatStateService);
  readonly chatService = inject(ChatService);
  readonly threadService = inject(ThreadService);

  newChatMessage(messages: Message[]): Observable<string> {
    return this.chatService.newChatMessage(messages);
  }

  generateChatTitle(userMessage: string, assistantMessage: string): Observable<string> {
    return this.chatService.generateChatTitle(userMessage, assistantMessage);
  }

  createThread(machineId: string | null): Observable<any> {
    return this.threadService.createThread(machineId);
  }

  deleteThread(threadId: string): Observable<string> {
    return this.threadService.deleteThread(threadId);
  }

  createRun(threadId: string): Observable<any> {
    return this.threadService.createRun(threadId);
  }

  retrieveRun(threadId: string, runId: string): Observable<any> {
    return this.threadService.retrieveRun(threadId, runId);
  }

  listThreadMessages(threadId: string): Observable<any> {
    return this.threadService.listThreadMessages(threadId);
  }

  getLastMessageId(threadId: string): Observable<string> {
    return this.threadService.getLastMessageId(threadId);
  }

  getLastMessages(threadId: string, lastMessageId: string): Observable<any> {
    return this.threadService.getLastMessages(threadId, lastMessageId);
  }

  startStreamingResponse(threadId: string, threadMessage: ThreadMessage, machineId: string | null): Observable<any> {
    return this.threadService.startStreamingResponse(threadId, threadMessage, machineId);
  }
}
