<div class="pageWrapper maxWidth spacer">
  <div class="row align-items-center justify-content-center">
    <div class="col-sm-12">

      <mat-card class="cardWithShadow card2 position-relative bg-light-accent cursor-pointer text-center">
        <mat-card-content class="p-24">
          <mat-card-title
            class="mat-headline-6">{{ !submitted ? "Interested in knowing more ?" : "Thank you for subscribing !" }}
          </mat-card-title>
          <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 m-b-24 f-s-16 wmt-text-height">
            {{ !submitted ? "Subscribe to our newsletters." : "You will receive a confirmation email very soon." }}
          </mat-card-subtitle>

          @if (!submitted) {
            <div class="row justify-content-center">

              <form [formGroup]="newsletterForm" (ngSubmit)="onSubmit()" style="width: 80%">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <input matInput placeholder="John Doe" id="full-name" formControlName="fullName" />
                          <mat-icon matPrefix class="op-5">
                            <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
                          </mat-icon>
                          <div *ngIf="hasError('fullName')">
                            <small *ngFor="let error of getFormErrors('fullName')"
                                   class="e-error wmt-form-error">{{ error }}</small>
                          </div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="row">
                      <div class="col-sm-12">
                        <mat-form-field appearance="outline" class="w-100">
                          <input matInput placeholder="john.doe@mail.com" type="email" formControlName="email" />
                          <mat-icon matPrefix class="op-5">
                            <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
                          </mat-icon>
                          <div *ngIf="hasError('email')">
                            <small *ngFor="let error of getFormErrors('email')"
                                   class="e-error wmt-form-error">{{ error }}</small>
                          </div>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="row justify-content-center">
                  <div class="col-sm-3">
                    <button mat-flat-button color="primary" class="w-100 m-t-16" type="submit"
                            [disabled]="!newsletterForm.valid">Sign up
                    </button>
                  </div>
                </div>
              </form>

            </div>
          }
        </mat-card-content>
      </mat-card>

    </div>
  </div>
</div>
