<div>
  <div class="branding">
    <wmt-logo></wmt-logo>
    <button mat-icon-button (click)="onToggleSidenav()" class="d-flex d-lg-none">
      <i-tabler name="x" class="icon-20 d-flex"></i-tabler>
    </button>
  </div>
  @if (isWebNl) {
    <div class="p-x-16">
      <a [routerLink]="['']" (click)="onToggleSidenav()"
         class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
        <span class="f-s-15 f-w-500 m-l-8">Home</span>
      </a>
    </div>

    <mat-accordion>
      <mat-expansion-panel class="shadow-none">
        <mat-expansion-panel-header>
          <mat-panel-title class="f-s-16 f-w-500">Calculate your Taxes</mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <div class="row">
            @for (app of taxApps; track app.id) {
              <div class="col-12 text-hover-primary">
                <a [routerLink]="[app.link]" (click)="onToggleSidenav()"
                   class="d-flex align-items-center text-decoration-none m-b-24">
                  <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                    <img [src]="app.img" width="25" alt="temp" />
                  </button>

                  <div class="m-l-16">
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">{{ app.title }}</h5>
                    <span class="mat-body-1 f-s-12">{{ app.subtitle }}</span>
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="p-x-16">
      <a [routerLink]="['taxelos-360']" (click)="onToggleSidenav()"
         class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
        <span class="f-s-15 f-w-500 m-l-8 tx-360">Taxelos ΤΞ</span>
      </a>
      <a [routerLink]="['tax-djinn']" (click)="onToggleSidenav()"
         class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
        <span class="f-s-15 f-w-500 m-l-8">Tax Djinn</span>
      </a>
      <a [routerLink]="['insights']" (click)="onToggleSidenav()"
         class="d-flex align-items-center text-decoration-none mat-body-1 p-y-16">
        <span class="f-s-15 f-w-500 m-l-8">Insights</span>
      </a>
    </div>
  }

  <mat-accordion>
    <mat-expansion-panel class="shadow-none">
      <mat-expansion-panel-header>
        <mat-panel-title class="f-s-16 f-w-500">Feedback and support</mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <div class="row">
          @for (item of helpMenuItems; track item.id) {
            <div class="col-12 text-hover-primary">
              <a href="{{item.link}}" target="_blank" (click)="onToggleSidenav()"
                 class="d-flex align-items-center text-decoration-none m-b-24">
                <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                  <img [src]="item.img" width="25" alt="menu icon" />
                </button>

                <div class="m-l-16">
                  <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">{{ item.title }}</h5>
                  <span class="mat-body-1 f-s-12">{{ item.subtitle }}</span>
                </div>
              </a>
            </div>
          }
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

</div>
