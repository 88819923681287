import { Injectable, inject } from '@angular/core';
import { MenuItem } from '../../interfaces/menu-item';
import { LIB_ENVIRONMENT } from '../../../../index';
import { LibEnvironment } from '../../../common/interfaces/lib-environment';

@Injectable({
  providedIn: 'root',
})
export class MenuItemsService {
  private env = inject<LibEnvironment>(LIB_ENVIRONMENT);

  getTaxApps(): MenuItem[] {
    return [
      {
        id: 1,
        img: '/assets/images/svgs/taxes.svg',
        title: 'Income Tax',
        subtitle: 'your take home salary',
        link: '/income-tax',
      },
      {
        id: 2,
        img: '/assets/images/svgs/decision.svg',
        title: 'Compare Taxes - BV vs ZZP',
        subtitle: 'what structure is better for you',
        link: '/tax-comparison',
      },
      {
        id: 3,
        img: '/assets/images/svgs/auto.svg',
        title: 'Company vehicle usage costs',
        subtitle: 'how much is your car costing you',
        link: '/vehicle-usage-cost',
      },
    ];
  }

  getHelpMenuItems(): MenuItem[] {
    return [
      {
        id: 1,
        img: '/assets/images/svgs/bug.svg',
        title: 'Raise a defect',
        subtitle: 'functional or technical bugs',
        link: this.env.raiseDefectLink,
      },
      {
        id: 2,
        img: '/assets/images/svgs/enhance.svg',
        title: 'Enhancement request',
        subtitle: 'a new feature you would like to have',
        link: this.env.featureRequestLink,
      },
      {
        id: 3,
        img: '/assets/images/svgs/feedback.svg',
        title: 'Product feedback',
        subtitle: 'what do you think of Taxelos?',
        link: this.env.productFeedBackLink,
      },
    ];
  }
}
