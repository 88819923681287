import { Inject, Injectable } from '@angular/core';
import { LIB_ENVIRONMENT } from '../../../index';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import {
  PdfBitmap,
  PdfColor,
  PdfDocument,
  PdfFontFamily,
  PdfFontStyle,
  PdfGrid,
  PdfGridLayoutFormat,
  PdfGridRow,
  PdfLayoutBreakType,
  PdfLayoutType,
  PdfPage,
  PdfPen,
  PdfSolidBrush,
  PdfStandardFont,
  PdfStringFormat,
  PdfTextAlignment,
  PdfUriAnnotation,
  PdfVerticalAlignment,
  RectangleF,
} from '@syncfusion/ej2-pdf-export';
import { logoImageJpeg } from '../../common/components/logo/logo';
import { getFileNameWithDate } from '../../common/utils/file-name.utils';
import { ItDataItem } from '../../features/income-tax/it-data-item';
import { TcDataItem } from '../../features/tax-comparison/tc-data-item';

type ExportResultItem = ItDataItem | TcDataItem;

@Injectable({
  providedIn: 'root',
})
export class WmtPdfExportService {
  appUrl = '';

  // Text contents
  disclaimer = 'Note: This calculator is for illustrative purposes only. '
  + 'No guarantee is made for the accuracy of the data provided. '
  + 'Consult a qualified tax services professional before making any decision.';

  userInputTitle = 'User inputs';
  userInputTableHeaderTitle = 'Input fields';
  userInputTableHeaderValue = 'Values';
  calculatedOutputTitle = 'Calculated output';
  moreText = 'Like to know more?';
  moreText2 = 'Please go to our homepage at';

  /* STYLES */
  logo: PdfBitmap; // Initialized in constructor
  mainColor = new PdfColor(0, 0, 128);
  blackColor = new PdfColor(0, 0, 0);
  whiteColor = new PdfColor(255, 255, 255);
  linkColor = new PdfColor(57, 88, 182);
  lightGrayColor = new PdfColor(211, 211, 211);
  lighterGrayColor = new PdfColor(240, 240, 240);
  headerFont: PdfStandardFont;
  titleFont: PdfStandardFont;
  textFont: PdfStandardFont;
  textBoldItalicFont: PdfStandardFont;
  pen: PdfPen;
  mainBrush: PdfSolidBrush;
  blackBrush: PdfSolidBrush;
  linkBrush: PdfSolidBrush;
  headerFormat: PdfStringFormat;
  titleFormat: PdfStringFormat;
  cellHeaderFormat: PdfStringFormat;
  valueColumnFormat: PdfStringFormat;
  textLeftFormat: PdfStringFormat;
  textRightFormat: PdfStringFormat;
  textCenterFormat: PdfStringFormat;

  constructor(
    @Inject(LIB_ENVIRONMENT) private environment: LibEnvironment,
  ) {
    this.appUrl = this.environment.appUrl;
    this.logo = new PdfBitmap(logoImageJpeg);
    this.headerFont = new PdfStandardFont(PdfFontFamily.Helvetica, 15, PdfFontStyle.Bold);
    this.titleFont = new PdfStandardFont(PdfFontFamily.Helvetica, 12, PdfFontStyle.Bold);
    this.textFont = new PdfStandardFont(PdfFontFamily.Helvetica, 10, PdfFontStyle.Regular);
    this.textBoldItalicFont = new PdfStandardFont(PdfFontFamily.Helvetica, 12, PdfFontStyle.Italic | PdfFontStyle.Bold);
    this.pen = new PdfPen(this.whiteColor, 0);
    this.mainBrush = new PdfSolidBrush(this.mainColor);
    this.blackBrush = new PdfSolidBrush(this.blackColor);
    this.linkBrush = new PdfSolidBrush(this.linkColor);

    // Initialize string formats
    this.headerFormat = new PdfStringFormat();
    this.headerFormat.alignment = PdfTextAlignment.Right;
    this.headerFormat.lineAlignment = PdfVerticalAlignment.Middle;
    this.headerFormat.characterSpacing = 1;
    this.headerFormat.horizontalScalingFactor = 100;

    this.titleFormat = new PdfStringFormat();
    this.titleFormat.alignment = PdfTextAlignment.Center;

    this.cellHeaderFormat = new PdfStringFormat();
    this.cellHeaderFormat.alignment = PdfTextAlignment.Left;
    this.cellHeaderFormat.lineAlignment = PdfVerticalAlignment.Middle;

    this.valueColumnFormat = new PdfStringFormat();
    this.valueColumnFormat.alignment = PdfTextAlignment.Right;
    this.valueColumnFormat.lineAlignment = PdfVerticalAlignment.Middle;

    this.textLeftFormat = new PdfStringFormat();
    this.textLeftFormat.alignment = PdfTextAlignment.Left;

    this.textRightFormat = new PdfStringFormat();
    this.textRightFormat.alignment = PdfTextAlignment.Right;

    this.textCenterFormat = new PdfStringFormat();
    this.textCenterFormat.alignment = PdfTextAlignment.Center;
  }

  generatePdf(
    inputs: Record<string, string>,
    inputTitles: Record<string, string>,
    resultTitles: Record<string, string>,
    results: ExportResultItem[],
    documentId: string,
    documentTitle: string,
  ): void {
    // Create a new PDF document.
    const reportDocument: PdfDocument = new PdfDocument();
    // Add a page.
    const page1: PdfPage = reportDocument.pages.add();

    /* LAYOUT */
    const layoutFormat: PdfGridLayoutFormat = new PdfGridLayoutFormat();
    layoutFormat.layout = PdfLayoutType.OnePage;
    layoutFormat.break = PdfLayoutBreakType.FitPage;
    // Set pagination bounds of PDF grid
    layoutFormat.paginateBounds = new RectangleF(10, 120,
      page1.getClientSize().width - 20,
      page1.getClientSize().height - 100 - 40,
    );

    page1.graphics.drawString(this.userInputTitle, this.titleFont, this.pen, this.mainBrush, 10, 100, this.titleFormat);

    // Add INPUTS PDF grid and draw with layout format
    const inputsGrid: PdfGrid = new PdfGrid();
    inputsGrid.columns.add(2);
    inputsGrid.headers.add(1);
    inputsGrid.columns.getColumn(0).width = 200;
    inputsGrid.columns.getColumn(1).width = 100;
    inputsGrid.columns.getColumn(1).format = this.valueColumnFormat;
    const inputsGridHeader: PdfGridRow = inputsGrid.headers.getHeader(0);
    inputsGridHeader.cells.getCell(0).value = this.userInputTableHeaderTitle;
    inputsGridHeader.cells.getCell(0).stringFormat = this.cellHeaderFormat;
    inputsGridHeader.cells.getCell(0).style.textBrush = new PdfSolidBrush(this.mainColor);
    inputsGridHeader.cells.getCell(0).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
    inputsGridHeader.cells.getCell(0).style.backgroundBrush = new PdfSolidBrush(this.lighterGrayColor);
    inputsGridHeader.cells.getCell(1).value = this.userInputTableHeaderValue;
    inputsGridHeader.cells.getCell(1).style.textBrush = new PdfSolidBrush(this.mainColor);
    inputsGridHeader.cells.getCell(1).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
    inputsGridHeader.cells.getCell(1).style.backgroundBrush = new PdfSolidBrush(this.lighterGrayColor);

    Object.entries(inputs).forEach(([key, value]) => {
      const row: PdfGridRow = inputsGrid.rows.addRow();
      row.cells.getCell(0).value = inputTitles[key] || key;
      row.cells.getCell(0).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
      row.cells.getCell(1).value = value;
      row.cells.getCell(1).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
    });

    // Draw input grid into PDF page
    inputsGrid.draw(page1, new RectangleF((
      page1.getClientSize().width - this.getGridWidth(inputsGrid)) / 2, 120,
    page1.getClientSize().width - 20,
    page1.getClientSize().height - 100 - 40,
    ), layoutFormat,
    );

    page1.graphics.drawString(this.calculatedOutputTitle, this.titleFont, this.pen, this.mainBrush, 10, 320, this.titleFormat);

    // Add RESULTS PDF grid and draw with layout format
    const resultsGrid: PdfGrid = new PdfGrid();
    const resultItem = results[0];
    const propertyCount = Object.keys(resultItem).length - 2;
    resultsGrid.columns.add(propertyCount);
    resultsGrid.headers.add(1);
    resultsGrid.columns.getColumn(0).width = 200;
    for (let i = 1; i < propertyCount; i++) {
      resultsGrid.columns.getColumn(i).width = 100;
    }

    this.setupGridHeaders(resultsGrid, resultTitles, true);

    results.forEach((item) => {
      const row: PdfGridRow = resultsGrid.rows.addRow();
      const keys = Object.keys(item).filter(key => key !== 'id' && key !== 'type');

      keys.forEach((key, index) => {
        row.cells.getCell(index).value = item[key as keyof typeof item];
        row.cells.getCell(index).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
      });
    });
    // Draw results grid into PDF page
    resultsGrid.draw(page1, new RectangleF((
      page1.getClientSize().width - this.getGridWidth(resultsGrid)) / 2, 340,
    page1.getClientSize().width - 20,
    page1.getClientSize().height - 100 - 40,
    ), layoutFormat,
    );

    // Generate header and footer on all pages
    for (let i = 0; i < reportDocument.pages.count; i++) {
      const page: PdfPage = reportDocument.pages.getPageByIndex(i);
      page.graphics.drawLine(new PdfPen(this.mainColor), 0, 4, 685, 4);
      page.graphics.drawImage(this.logo, 0, 10, 60, 60);
      page.graphics.drawString(documentTitle, this.headerFont, this.pen, this.mainBrush, 0, 40, this.headerFormat);
      // Add URI annotation
      const annotation: PdfUriAnnotation = new PdfUriAnnotation(
        new RectangleF(0, 10, 60, 60),
      );
      annotation.uri = this.appUrl;
      page.annotations.add(annotation);
      page.graphics.drawString(
        'Generated on ' + new Date().toLocaleString() + ' on taxelos.com',
        this.textFont,
        this.pen,
        this.blackBrush,
        0,
        page1.getClientSize().height - 20,
        this.textLeftFormat,
      );
    }

    page1.graphics.drawString(this.disclaimer, this.textFont, this.pen, this.mainBrush, 0, 600, this.textLeftFormat);
    page1.graphics.drawString(this.moreText, this.textBoldItalicFont, this.pen, this.blackBrush, 0, 640, this.textCenterFormat);
    page1.graphics.drawString(this.moreText2, this.textBoldItalicFont, this.pen, this.blackBrush, -147, 660, this.textCenterFormat);
    page1.graphics.drawString(this.appUrl, this.textBoldItalicFont, this.pen, this.linkBrush, 147, 660, this.textCenterFormat);

    // save the document
    reportDocument.save(getFileNameWithDate(documentId, 'pdf'));
    // destroy the document
    reportDocument.destroy();
  }

  getGridWidth(grid: PdfGrid) {
    let gridWidth = 0;
    for (let i = 0; i < grid.columns.count; i++) {
      gridWidth += grid.columns.columns[i].columnWidth;
    }

    return gridWidth;
  }

  setupGridHeaders(grid: PdfGrid, titles: { [key: string]: string }, firstColumnSpecial: boolean) {
    const headerRow: PdfGridRow = grid.headers.getHeader(0);
    let columnIndex = 0;

    Object.keys(titles).forEach((key) => {
      grid.columns.getColumn(columnIndex).format = this.valueColumnFormat;
      headerRow.cells.getCell(columnIndex).value = titles[key];
      headerRow.cells.getCell(columnIndex).style.textBrush = new PdfSolidBrush(this.mainColor);
      headerRow.cells.getCell(columnIndex).style.borders.all = new PdfPen(this.lightGrayColor, 0.5);
      headerRow.cells.getCell(columnIndex).style.backgroundBrush = new PdfSolidBrush(this.lighterGrayColor);

      if (columnIndex === 0 && firstColumnSpecial) {
        grid.columns.getColumn(columnIndex).format = this.cellHeaderFormat;
        headerRow.cells.getCell(columnIndex).stringFormat = this.cellHeaderFormat;
      }

      columnIndex++;
    });
  }
}
