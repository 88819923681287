import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { IconsModule } from '../../ui/icons/icons.module';
import { positiveNumberVal } from '../../common/validators/positive-number.validator';
import { totalIncomeValidator } from '../../common/validators/total-income-validator';
import {
  ExcelExportProperties,
  ExcelExportService,
  GridComponent,
  GridModule,
  PdfExportProperties,
  PdfExportService,
  SelectionSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormValidationService } from '../../services/utility/form-validation.service';
import { getFileNameWithDate } from '../../common/utils/file-name.utils';
import { WmtNumericInputComponent } from '../../ui/wmt-numeric-input/wmt-numeric-input.component';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { DisclaimerComponent } from '../../common/components/disclaimer/disclaimer.component';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { TaxRate } from '@wmt/shared';
import { exportResultTitles, results } from './results';
import { TcDataItem } from './tc-data-item';
import { format } from '../../common/utils/to-fixed.utils';
import { StoreService } from '../../services/api/store.service';
import { WmtPdfExportService } from '../../services/utility/pdf-export.service';
import { WmtXlsxExportService } from '../../services/utility/xlsx-export-service';
import { ClipboardService } from '../../services/utility/clipboard.service';
import { exportInputTitles, getExportInputs } from './input-values';

@Component({
  selector: 'wmt-tax-comparison',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    NumericTextBoxModule,
    ReactiveFormsModule,
    DisclaimerComponent,
    CardsComponent,
    GridModule,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    IconsModule,
    MatError,
    WmtNumericInputComponent,
  ],
  providers: [PdfExportService, ExcelExportService],
  templateUrl: './tax-comparison.html',
  styleUrl: './tax-comparison.scss',
})
export class TaxComparisonComponent implements OnInit, OnDestroy {
  documentTitle = 'Tax Comparison';
  currentYear = new Date().getFullYear();
  public isMobile = true;
  private screenSubscription!: Subscription;
  orgSetupComparisonForm!: FormGroup;
  lineItemsWidth = '154';
  isFrozenColumn = false;
  public data: TcDataItem[] = [];
  @ViewChild('grid') public grid?: GridComponent;
  public selectionOptions: SelectionSettingsModel = { type: 'Multiple' };

  constructor(
    private screenService: ScreenSizeService,
    public fv: FormValidationService,
    private store: StoreService,
    private pdfService: WmtPdfExportService,
    private xlsxService: WmtXlsxExportService,
    private clipboardService: ClipboardService) {
  }

  ngOnInit(): void {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (isMobile) {
        this.lineItemsWidth = '154';
        this.isFrozenColumn = true;
      }
      else {
        this.lineItemsWidth = '400';
      }
    });

    this.orgSetupComparisonForm = new FormGroup({
      totalIncome: new FormControl(null, [Validators.required, positiveNumberVal()]),
      directorsSalary: new FormControl(null, [Validators.required, positiveNumberVal()]),
      companyExpenses: new FormControl(null, [Validators.required, positiveNumberVal()]),
      personalExpenses: new FormControl(null, [Validators.required, positiveNumberVal()]),
    }, { validators: totalIncomeValidator() });

    this.fv.setCurrentForm(this.orgSetupComparisonForm);
    this.data = results;

    this.listenToFormChanges();
  }

  listenToFormChanges() {
    this.orgSetupComparisonForm.valueChanges.subscribe(() => {
      this.calculateComparison();
    });
  }

  calculateComparison() {
    const totalIncome = +this.orgSetupComparisonForm.get('totalIncome')?.value;
    this.grid?.setCellValue('totalIncome', 'zzps', format(totalIncome));
    this.grid?.setCellValue('totalIncome', 'bvs', format(totalIncome));
    this.grid?.setCellValue('grossSalaryYear', 'salaried', format(totalIncome));

    const directorsSalary = +this.orgSetupComparisonForm.get('directorsSalary')?.value;
    this.grid?.setCellValue('grossSalaryYear', 'bvs', format(directorsSalary));

    const companyExpenses = +this.orgSetupComparisonForm.get('companyExpenses')?.value;
    const businessExpensesZzpsBvsOut = -1 * companyExpenses / 1.21;
    const businessExpensesSalariedOut = -1 * companyExpenses;
    this.grid?.setCellValue('businessExpenses', 'zzps', format(businessExpensesZzpsBvsOut));
    this.grid?.setCellValue('businessExpenses', 'bvs', format(businessExpensesZzpsBvsOut));
    this.grid?.setCellValue('businessExpenses', 'salaried', format(businessExpensesSalariedOut));

    const personalExpenses = +this.orgSetupComparisonForm.get('personalExpenses')?.value;
    const personalExpensesOut = -1 * personalExpenses;
    this.grid?.setCellValue('personalExpenses', 'zzps', format(personalExpensesOut));
    this.grid?.setCellValue('personalExpenses', 'bvs', format(personalExpensesOut));
    this.grid?.setCellValue('personalExpenses', 'salaried', format(personalExpensesOut));

    const grossSalaryYearZzpOut = totalIncome + businessExpensesZzpsBvsOut;
    this.grid?.setCellValue('grossSalaryYear', 'zzps', format(grossSalaryYearZzpOut));

    // Rule 5: Taxable Income
    const payrollTaxes: TaxRate[] = this.store.getTaxRatesByTaxName('Payroll Tax');
    let payrollTaxZzps = 0;
    let payrollTaxBvs = 0;
    let payrollTaxSalaried = 0;
    if (payrollTaxes.length > 0) {
      payrollTaxZzps = -this.incomeTaxAlgo(grossSalaryYearZzpOut, payrollTaxes);
      payrollTaxBvs = -this.incomeTaxAlgo(directorsSalary, payrollTaxes);
      payrollTaxSalaried = -this.incomeTaxAlgo(totalIncome, payrollTaxes);
    }
    this.grid?.setCellValue('payRollTax', 'zzps', format(payrollTaxZzps));
    this.grid?.setCellValue('payRollTax', 'bvs', format(payrollTaxBvs));
    this.grid?.setCellValue('payRollTax', 'salaried', format(payrollTaxSalaried));

    // Rule 6: General Tax Credit
    let generalTaxCreditZzps = 0;
    let generalTaxCreditBvs = 0;
    let generalTaxCreditSalaried = 0;
    const taxCredits: TaxRate[] = this.store.getTaxRatesByTaxName('Tax Credits');
    const generalTaxCreditsTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(taxCredits, ['GTC']);
    if (generalTaxCreditsTaxes.length > 0) {
      generalTaxCreditZzps = this.taxCreditAlgo(grossSalaryYearZzpOut, generalTaxCreditsTaxes);
      generalTaxCreditBvs = this.taxCreditAlgo(directorsSalary, generalTaxCreditsTaxes);
      generalTaxCreditSalaried = this.taxCreditAlgo(totalIncome, generalTaxCreditsTaxes);
    }
    this.grid?.setCellValue('generalTaxCredit', 'zzps', format(generalTaxCreditZzps));
    this.grid?.setCellValue('generalTaxCredit', 'bvs', format(generalTaxCreditBvs));
    this.grid?.setCellValue('generalTaxCredit', 'salaried', format(generalTaxCreditSalaried));

    // Rule 7: Labour Tax Credit
    let labourTaxCreditZzps = 0;
    let labourTaxCreditBvs = 0;
    let labourTaxCreditSalaried = 0;
    const labourTaxCreditsTaxes: TaxRate[] = this.store.getTaxRatesByCategoryCodes(taxCredits, ['LTC']);
    if (labourTaxCreditsTaxes.length > 0) {
      labourTaxCreditZzps = this.taxCreditAlgo(grossSalaryYearZzpOut, labourTaxCreditsTaxes);
      labourTaxCreditBvs = this.taxCreditAlgo(directorsSalary, labourTaxCreditsTaxes);
      labourTaxCreditSalaried = this.taxCreditAlgo(totalIncome, labourTaxCreditsTaxes);
    }
    this.grid?.setCellValue('labourTaxCredit', 'zzps', format(labourTaxCreditZzps));
    this.grid?.setCellValue('labourTaxCredit', 'bvs', format(labourTaxCreditBvs));
    this.grid?.setCellValue('labourTaxCredit', 'salaried', format(labourTaxCreditSalaried));

    // Net Salary
    let netSalaryZzps = 0;
    let netSalaryBvs = 0;
    let netSalarySalaried = 0;
    netSalaryZzps = grossSalaryYearZzpOut + payrollTaxZzps + generalTaxCreditZzps + labourTaxCreditZzps;
    netSalaryBvs = directorsSalary + payrollTaxBvs + generalTaxCreditBvs + labourTaxCreditBvs;
    netSalarySalaried = totalIncome + payrollTaxSalaried + generalTaxCreditSalaried + labourTaxCreditSalaried;
    this.grid?.setCellValue('netSalary', 'zzps', format(netSalaryZzps));
    this.grid?.setCellValue('netSalary', 'bvs', format(netSalaryBvs));
    this.grid?.setCellValue('netSalary', 'salaried', format(netSalarySalaried));

    // EBITDA BVS
    let ebitdaBvs = 0;
    ebitdaBvs = totalIncome + businessExpensesZzpsBvsOut - directorsSalary;
    this.grid?.setCellValue('ebitda', 'bvs', format(ebitdaBvs));

    // Corporate Tax BVS
    const corporateTax: TaxRate[] = this.store.getTaxRatesByTaxName('Corporate Tax');
    const generalTaxRatesCorporate: TaxRate[] = this.store.getTaxRatesByCategoryCodes(corporateTax, ['GEN']);
    let corporateTaxBvs = 0;
    corporateTaxBvs = this.corporateAndDividendTaxAlgo(ebitdaBvs, generalTaxRatesCorporate);
    this.grid?.setCellValue('corporateTax', 'bvs', format(corporateTaxBvs));

    // PAT BVS
    let patBvs = 0;
    patBvs = ebitdaBvs + corporateTaxBvs;
    this.grid?.setCellValue('pat', 'bvs', format(patBvs));

    // Dividend Tax BVS
    let dividendTaxBvs = 0;
    const dividendTax: TaxRate[] = this.store.getTaxRatesByTaxName('Dividend Tax');
    const generalTaxRatesDividend: TaxRate[] = this.store.getTaxRatesByCategoryCodes(dividendTax, ['GEN']);
    dividendTaxBvs = this.corporateAndDividendTaxAlgo(patBvs, generalTaxRatesDividend);
    this.grid?.setCellValue('dividendTax', 'bvs', format(dividendTaxBvs));

    // Retained Earnings ZZP
    let retainedEarningsZzp = 0;
    retainedEarningsZzp = netSalaryZzps;
    this.grid?.setCellValue('retainedEarnings', 'zzps', format(retainedEarningsZzp));

    // Retained Earnings Salary
    let retainedEarningsSalaried = 0;
    retainedEarningsSalaried = netSalarySalaried;
    this.grid?.setCellValue('retainedEarnings', 'salaried', format(retainedEarningsSalaried));

    // Retained Earnings BVS
    let retainedEarningsBvs = 0;
    retainedEarningsBvs = netSalaryBvs + patBvs - dividendTaxBvs;
    this.grid?.setCellValue('retainedEarnings', 'bvs', format(retainedEarningsBvs));

    // Earnings After Expense ZZP
    let earningsAfterExpenseZzp = 0;
    earningsAfterExpenseZzp = retainedEarningsZzp + personalExpensesOut;
    this.grid?.setCellValue('earningsAfterExpense', 'zzps', format(earningsAfterExpenseZzp));

    // Earnings After Expense BVS
    let earningsAfterExpenseBvs = 0;
    earningsAfterExpenseBvs = retainedEarningsBvs + personalExpensesOut;
    this.grid?.setCellValue('earningsAfterExpense', 'bvs', format(earningsAfterExpenseBvs));

    // Earnings After Expense Salaried
    let earningsAfterExpenseSalaried = 0;
    earningsAfterExpenseSalaried = retainedEarningsSalaried + personalExpensesOut + businessExpensesSalariedOut;
    this.grid?.setCellValue('earningsAfterExpense', 'salaried', format(earningsAfterExpenseSalaried));

    // Income Percentage
    let incomePercentageZzp = 0;
    let incomePercentageBvs = 0;
    let incomePercentageSalaried = 0;
    incomePercentageZzp = earningsAfterExpenseZzp / totalIncome * 100;
    incomePercentageBvs = earningsAfterExpenseBvs / totalIncome * 100;
    incomePercentageSalaried = earningsAfterExpenseSalaried / totalIncome * 100;
    this.grid?.setCellValue('incomePercentage', 'zzps', format(incomePercentageZzp) + '%');
    this.grid?.setCellValue('incomePercentage', 'bvs', format(incomePercentageBvs) + '%');
    this.grid?.setCellValue('incomePercentage', 'salaried', format(incomePercentageSalaried) + '%');
  }

  corporateAndDividendTaxAlgo(ebitdaOrPat: number, taxRates: TaxRate[]): number {
    let totalOutput = 0;

    for (const t of taxRates) {
      let output = 0;
      if (t.taxRate !== null && t.minTier !== null && t.maxTier !== null && t.maxTier > 0) {
        output = -1 * t.taxRate * (Math.min(ebitdaOrPat, t.maxTier) - t.minTier);
      }
      else if (t.taxRate !== null && t.minTier !== null && t.maxTier === null) {
        output = -1 * t.taxRate * (ebitdaOrPat - t.minTier);
      }

      totalOutput += output;
    }

    return totalOutput;
  }

  incomeTaxAlgo(taxableIncome: number, taxRates: TaxRate[]): number {
    let output = 0;

    for (const t of taxRates) {
      let formula = 0;
      if (t.minTier === 0 && t.taxRate !== null) {
        formula = Math.min(t.taxRate * taxableIncome, t.maxTier !== null ? t.taxRate * t.maxTier : 0);
      }
      else if (t.maxTier === null && t.taxRate !== null && t.minTier !== null) {
        formula = Math.max(t.taxRate * (taxableIncome - t.minTier), 0);
      }
      else if (t.taxRate !== null && t.maxTier !== null && t.minTier !== null) {
        formula = Math.min(t.taxRate * taxableIncome - t.minTier, t.taxRate * t.maxTier - t.minTier);
      }
      output += formula;
    }

    return output;
  }

  taxCreditAlgo(taxableIncome: number, taxRates: TaxRate[]): number {
    let output = 0;

    for (const t of taxRates) {
      // Check if taxableIncome falls within the tier range
      if (t.minTier !== null && t.maxTier !== null
        && (taxableIncome >= t.minTier && (t.maxTier <= 0 || taxableIncome <= t.maxTier))) {
        // Calculate tax credit
        output = (t.taxCreditAmount ?? 0) + (t.taxCreditRate ?? 0) * (taxableIncome - t.minTier);
        // Exit the loop since we have found the applicable tax rate
        break;
      }
    }

    return output;
  }

  exportPdf() {
    this.pdfService.generatePdf(
      getExportInputs(),
      exportInputTitles,
      exportResultTitles,
      this.data,
      'tax-comparison',
      this.documentTitle,
    );
  }

  exportXlsx() {
    this.xlsxService.generateXlsx(
      getExportInputs(),
      exportInputTitles,
      exportResultTitles,
      this.data,
      'tax-comparison',
      this.documentTitle,
    );
  }

  copyClipboard() {
    (this.grid as GridComponent).selectRowsByRange(0, 14);
    const results = (this.grid as GridComponent).getSelectedRecords();
    const inputs = getExportInputs();
    this.clipboardService.copyDataToClipboard(
      inputs,
      results,
      exportInputTitles,
      exportResultTitles,
      this.documentTitle,
    );
    (this.grid as GridComponent).clearSelection();
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
  }
}
