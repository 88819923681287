import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgClass, NgForOf, NgIf, NgOptimizedImage, NgStyle } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';
import { UiModule } from '../../../ui/ui.module';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { IconsModule } from '../../../ui/icons/icons.module';
import { MatChip } from '@angular/material/chips';

interface News {
  id: number
  imgSrc: string
  title: string
  description: string
  date: string
  buttonTxt: string
  url: string
}

@Component({
  selector: 'wmt-news-cards',
  standalone: true,
  imports: [NgForOf, NgOptimizedImage, NgIf, UiModule, IconsModule, MatChip, RouterLink, NgClass, NgStyle],
  templateUrl: './news-cards.component.html',
  styleUrl: './news-cards.component.scss',
})
export class NewsCardsComponent implements OnInit, OnDestroy {
  public isMobile = true;
  private subscription!: Subscription;
  currentRoute = '';

  constructor(
    private router: Router,
    private screenService: ScreenSizeService) {
  }

  newsPosts: News[] = [
    {
      id: 1,
      imgSrc: '/assets/images/news-cards/news-01.png',
      title: 'Our newest addition',
      description: 'Tax Djinn is an AI enabled tax assistant that is modelled to answer any dutch tax and accounting queries. '
        + 'You can test out the AI chatbot for free to answer upto 20 queries a day.',
      date: 'Last updated on 3rd September 2024',
      buttonTxt: 'Check it out',
      url: 'tax-djinn',
    },
    {
      id: 2,
      imgSrc: '/assets/images/news-cards/news-02.png',
      title: 'Our next big thing',
      description: 'The multi-currency transaction manager will allow user to '
        + 'upload transactions from multiple accounts in different currencies and have it converted into a selected base currency.'
        + 'It will also allow users to assign categories to transactions and also have them reviewed by a selected accountant',
      date: 'Last updated on 3rd September 2024',
      buttonTxt: 'Have a suggestion? Tell us!!',
      url: 'https://cambridge.eu.qualtrics.com/jfe/form/SV_bxvHTt0ulnFE4iW',
    },
  ];

  ngOnInit(): void {
    this.subscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
    this.currentRoute = this.router.url;
    this.newsPosts = this.newsPosts.filter((news) => {
      return `/${news.id}` !== this.currentRoute;
    });
  }

  navigate(route: string) {
    if (route && route.startsWith('http')) {
      window.open(route, '_blank');
    }
    else if (route) {
      this.router.navigate([route]);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
