<div class="partner-card">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-32">
      <mat-card-title class="mat-headline-6">{{ partner.companyName }}</mat-card-title>
      <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 f-s-16 wmt-text-height">
        <div class="d-flex partner-extra-info gap-10">
          <div class="d-flex">
            <i-tabler name="map-pin" class="icon-20 d-flex align-items-center m-r-5"></i-tabler>
            {{ partner.state }}
          </div>
          <div class="d-flex">
            <i-tabler name="calendar" class="icon-20 m-r-5"></i-tabler>
            {{ partner.joinedDate | date:'dd/MM/yyyy' }}
          </div>
        </div>
      </mat-card-subtitle>
    </mat-card-content>
  </mat-card>
</div>
