import { z } from 'zod';
import { AccountantRequestSchema } from '../data-models/schemas/accountant-request';
import { VisitorSchema } from '../data-models/schemas/visitor';

export const AccountantVisitorRequestSchema = z.object({
  accountantId: AccountantRequestSchema.shape.accountantId,
  firstName: VisitorSchema.shape.firstName,
  lastName: VisitorSchema.shape.lastName,
  email: VisitorSchema.shape.email,
  services: AccountantRequestSchema.shape.services,
  message: AccountantRequestSchema.shape.message,
});

export type AccountantVisitorRequest = z.infer<typeof AccountantVisitorRequestSchema>;
