import { Component } from '@angular/core';
import { MatCard, MatCardContent, MatCardImage, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../../ui/icons/icons.module';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'wmt-release-roadmap',
  standalone: true,
  imports: [
    MatCard,
    MatCardContent,
    MatCardTitle,
    IconsModule,
    MatCardImage,
    MatCardSubtitle,
    NgOptimizedImage,
  ],
  providers: [],
  templateUrl: './release-roadmap.component.html',
  styleUrl: './release-roadmap.component.scss',
})
export class ReleaseRoadmapComponent {

}
