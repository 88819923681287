import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

@Injectable({
  providedIn: 'root',
})
export class MachineIdService {
  private platformId = inject(PLATFORM_ID);
  private machineId: string | null = null;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.initializeFingerprint();
    }
  }

  private async initializeFingerprint() {
    this.machineId = await getFingerprint() as string;
  }

  getMachineId(): Promise<string | null> {
    return new Promise((resolve) => {
      if (this.machineId) {
        resolve(this.machineId);
      }
      else {
        const interval = setInterval(() => {
          if (this.machineId) {
            clearInterval(interval);
            resolve(this.machineId);
          }
        }, 100);
      }
    });
  }
}
