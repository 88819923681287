import { Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { A11yModule } from '@angular/cdk/a11y';
import { CookieConsentService } from '../../../../services/utility/cookie-consent.service';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'wmt-cookie-consent-dialog',
  templateUrl: 'cookie-consent-dialog.component.html',
  styleUrl: './cookie-consent-dialog.component.scss',
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
    RouterLink,
    A11yModule,
    NgIf,
    NgStyle,
    NgClass,
  ],
  standalone: true,
})
export class CookieConsentDialogComponent {
  consentGiven = false;
  consent = false;

  constructor(
    private cookieConsentService: CookieConsentService,
  ) {
    this.consentGiven = this.cookieConsentService.isConsentSaved;
    this.consent = this.cookieConsentService.consent;
  }

  acceptCookies() {
    this.cookieConsentService.consent = true;
  }

  rejectCookies() {
    this.cookieConsentService.consent = false;
  }
}
