import { isPlatformBrowser, NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { IconsModule } from '../../../ui/icons/icons.module';
import { AppContextService } from '../../../services/utility/app-context.service';
import { InsightsService } from '../../../services/api/insights.service';
import { Subscription, tap } from 'rxjs';

@Component({
  selector: 'wmt-breadcrumb',
  standalone: true,
  imports: [RouterModule, NgIf, IconsModule],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
})
export class BreadcrumbComponent implements OnDestroy {
  private insightsService = inject(InsightsService);
  private platformId = inject(PLATFORM_ID);
  pageInfo: Data | never = Object.create(null);
  countryCode: string;
  excludedRoutes = ['', 'taxelos-360', 'tax-djinn', 'about-us', '#wmt-card-section', 'accountant-overview'];
  secondaryRoutes = ['about-us'];
  currentPageRoute!: string;
  private blogTitleSubscription: Subscription | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private ACS: AppContextService,
  ) {
    if (this.ACS.isWebNl()) {
      this.countryCode = 'NL';
    }
    else {
      this.countryCode = 'Global';
    }
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe((event) => {
        this.setTitleAndBreadcrumb(event);
      });
  }

  private checkAndSubscribeToBlogTitle() {
    this.blogTitleSubscription = this.insightsService.currentBlogTitle$
      .subscribe((title) => {
        this.pageInfo['urls'][2] = { title: title || 'Insights', url: '' };
      });
  }

  setTitleAndBreadcrumb(event: Data) {
    if (isPlatformBrowser(this.platformId)) {
      this.pageInfo = event;
      this.titleService.setTitle(`${this.pageInfo['title']} - Taxelos | ${this.countryCode}`);
      this.currentPageRoute = this.pageInfo['url'];

      if (this.currentPageRoute === 'insights/:slug') {
        this.checkAndSubscribeToBlogTitle();
      }
      else {
        this.unsubscribeFromBlogTitle();
      }

      const fullPath = this.router.url.split('?')[0];
      this.currentPageRoute = fullPath.split('/')[1];
    }
  }

  private unsubscribeFromBlogTitle() {
    if (this.blogTitleSubscription) {
      this.blogTitleSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeFromBlogTitle();
  }
}
