import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';

export function atLeastOneLanguageValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control instanceof FormGroup) {
      const languages = ['speaksEnglish', 'speaksDutch', 'speaksFrench', 'speaksGerman'];
      const isAtLeastOneLanguageSelected = languages.some(language => control.get(language)?.value);

      return isAtLeastOneLanguageSelected
        ? null
        : {
            atLeastOneLanguage: {
              message: 'Please select at least one language.',
            },
          };
    }

    return null;
  };
}
