<div class="pageWrapper maxWidth">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">

      <h2 class="mat-headline-5">Introduction</h2>
      <p class="mat-body-1 m-t-16">
        This web application is developed and owned by Daedal Inc., located at 525 North Tryon Street, STE 1600,
        Charlotte, NC 28202, United States ("Company", "we", "our", or "us"). These Terms and Conditions govern your use
        of the taxelos.com website and its services.
      </p>

      <h2 class="mat-headline-5 m-t-20">Acceptance of Terms</h2>
      <p class="mat-body-1 m-t-16">
        By accessing and using taxelos.com, you agree to be bound by these Terms and Conditions and our Privacy
        Policy. If you disagree with any part of these terms, you must not use our web application.
      </p>

      <h2 class="mat-headline-5 m-t-20">Use of the Web Application</h2>
      <ol>
        <li><span class="f-w-600">Eligibility: </span>You must be at least 18 years old to use our web application.</li>
        <li><span class="f-w-600">License to Use: </span>We grant you a limited, non-exclusive, non-transferable, and
          revocable license to use taxelos.com for personal, non-commercial purposes.
        </li>
        <li><span class="f-w-600">Prohibited Use: </span>You agree not to use the web application for any unlawful
          purpose, to infringe on any third party's rights, or to upload harmful or malicious content.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">User Accounts</h2>
      <ol>
        <li><span class="f-w-600">Account Creation: </span>You may need to create an account to use some features of the
          web application. You agree to provide accurate, current, and complete information during the registration
          process.
        </li>
        <li><span class="f-w-600">Responsibility for Account: </span>You are responsible for maintaining the
          confidentiality of your account information and for all activities under your account.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Intellectual Property Rights</h2>
      <p class="mat-body-1 m-t-16">
        All content on taxelos.com, including text, graphics, logos, and software, is the property of Daedal Inc. or
        its licensors and is protected by intellectual property laws.
      </p>

      <h2 class="mat-headline-5 m-t-20">Disclaimers</h2>
      <ol>
        <li><span class="f-w-600">No Warranties: </span>The web application is provided "as is" and "as available"
          without any warranties, express or implied.
        </li>
        <li><span class="f-w-600">Accuracy of Information: </span>We strive to ensure that information on the web
          application is accurate but do not warrant its completeness or accuracy.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Limitation of Liability</h2>
      <p class="mat-body-1 m-t-16">
        Daedal Inc. shall not be liable for any direct, indirect, incidental, special, or consequential damages
        resulting from the use or inability to use the web application.
      </p>

      <h2 class="mat-headline-5 m-t-20">Indemnification</h2>
      <p class="mat-body-1 m-t-16">
        You agree to indemnify and hold harmless Daedal Inc. and its officers, directors, employees, and agents from any
        claims, damages, losses, liabilities, and expenses arising from your use of the web application.
      </p>

      <h2 class="mat-headline-5 m-t-20">Termination</h2>
      <p class="mat-body-1 m-t-16">
        We reserve the right to terminate or suspend your access to the web application without prior notice or
        liability, for any reason, including a breach of these Terms and Conditions.
      </p>

      <h2 class="mat-headline-5 m-t-20">Governing Law</h2>
      <p class="mat-body-1 m-t-16">
        These Terms and Conditions shall be governed by the laws of the State of North Carolina, United States, without
        regard to its conflict of law provisions.
      </p>

      <h2 class="mat-headline-5 m-t-20">Changes to Terms and Conditions</h2>
      <p class="mat-body-1 m-t-16">
        We reserve the right to modify these Terms and Conditions at any time. We will provide notice of any significant
        changes and indicate the date they were last revised at the top of the terms.
      </p>

      <h2 class="mat-headline-5 m-t-20">Contact Us</h2>
      <p class="mat-body-1 m-t-16">
        If you have any questions about these Terms and Conditions, please contact us at<a
        href="mailto:legal@daedal.codes"> legal&#64;daedal.codes</a>.
      </p>
      <p class="wmt-text-updated">Effective date: 2-APR-2024</p>

    </mat-card-content>
  </mat-card>
</div>
