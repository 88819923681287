import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatStepLabel, MatStepper, MatStepperPrevious } from '@angular/material/stepper';
import { MatButton } from '@angular/material/button';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { NgForOf, NgIf } from '@angular/common';
import { IconsModule } from '../../../../ui/icons/icons.module';

@Component({
  selector: 'wmt-partner-form-consent',
  standalone: true,
  imports: [
    MatCheckbox,
    MatStepLabel,
    MatButton,
    MatStepperPrevious,
    ReactiveFormsModule,
    NgForOf,
    NgIf,
    IconsModule,
  ],
  providers: [],
  templateUrl: './consent.component.html',
  styleUrl: './consent.component.scss',
})
export class ConsentComponent implements OnInit {
  @Input() stepper!: MatStepper;
  @Input() submitted = false;
  @Output() formSubmit = new EventEmitter<void>();
  consentForm!: FormGroup;
  correctnessText: string;
  validationText: string;
  removeListingText: string;
  moderationText: string;
  tosText: string;

  constructor(
    private fb: FormBuilder,
    public fv: FormValidationService,
  ) {
    this.correctnessText = 'I agree that the details provided for the aforementioned services are correct '
    + 'at the timing as per the submission date';
    this.validationText = 'This submitted data will only be validated to be published in Taxelos portal after '
    + 'a successful secondary verification';
    this.removeListingText = 'Taxelos is constantly working towards improving their platform based on user '
    + 'requests. If at some point in time I am no longer interested in being listed with Taxelos, '
    + 'I can send an e-mail to Taxelos helpdesk at accountant-assist@whatsmytax.app to remove my listing. '
    + 'Minimum time to remove the listing is 10 working days.';
    this.moderationText = 'Taxelos team has the rights to remove my listing by providing a 30 day e-mail notice '
    + 'to the primary & secondary e-mail contact provided. If my company is found guilty of any illegal activities, '
    + 'Taxelos has the rights to remove my listing immediately.';
    this.tosText = 'Presently the services to Taxelos portal are free. If in future, there is a change to this '
    + 'set up, Taxelos will send an e-mail to primary & secondary contacts with updated fee structure. '
    + 'If I don\'t respond back within 30 calender days, they have the right to remove my listing from the platform.';
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.consentForm = this.fb.nonNullable.group({
      agreeCorrectness: [false, { validators: [Validators.requiredTrue] }],
      agreeOnValidation: [false, { validators: [Validators.requiredTrue] }],
      agreeOnRemoveListing: [false, { validators: [Validators.requiredTrue] }],
      agreeOnModeration: [false, { validators: [Validators.requiredTrue] }],
      agreeOnChangeOfTos: [false, { validators: [Validators.requiredTrue] }],
    });
  }

  onSubmit() {
    if (this.consentForm.valid) {
      this.formSubmit.emit();
    }
  }

  get isComplete(): boolean {
    return this.consentForm.valid;
  }
}
