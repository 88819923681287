import { ItDataItem } from './it-data-item';

const rawResults: Omit<ItDataItem, 'type'>[] = [
  { id: 'grossIncome', lineItems: 'Gross income (excl additions and incl holiday all)', value: '-' },
  { id: 'privateUseOfCar', lineItems: 'Addition: Private use of car', value: '-' },
  { id: 'privateUseOfBike', lineItems: 'Addition: Private use of bike', value: '-' },
  { id: 'yearlyHolidayAllowance', lineItems: 'Yearly gross holiday allowance', value: '-' },
  { id: 'taxableWorkCosts', lineItems: 'Taxable work related costs', value: '-' },
  { id: 'taxableIncome', lineItems: 'Taxable Income', value: '-' },
  { id: 'payrollTaxInclInsurance', lineItems: 'Payroll tax Incl national insurance', value: '-' },
  { id: 'inclInsuranceContrib', lineItems: 'Incl national insurance contrib', value: '-' },
  { id: 'generalTaxCredit', lineItems: 'General tax credit', value: '-' },
  { id: 'labourTaxCredit', lineItems: 'Labour tax credit', value: '-' },
  { id: 'workFromHomeAllowance', lineItems: 'Work from home allowance (thuiswerkvergoeding)', value: '-' },
  { id: 'yearlyNetIncome', lineItems: 'Year Net Income (without adding travel expenses)', value: '-' },
  { id: 'monthlyGrossIncome', lineItems: 'Monthly gross income', value: '-' },
  { id: 'monthlyTravelExpense', lineItems: 'Monthly Travel expense (tax free)', value: '-' },
  { id: 'monthlyNetIncomeInclExpense', lineItems: 'Monthly net income (including travel expense)', value: '-' },
  { id: 'netHolidayAllowance', lineItems: 'Net holiday allowance (paid out in May)', value: '-' },
  { id: 'effectiveTaxRate', lineItems: 'Effective tax rate', value: '-' },
];

export const exportResultTitles: { [key: string]: string } = {
  lineItems: 'Output fields',
  value: 'Values',
};

export const results: ItDataItem[] = rawResults.map(item => ({
  ...item,
  type: 'IT',
}));
