import { AfterViewInit, Component, inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import {
  CookieConsentComponent,
  FooterComponent,
  MenuComponent,
  MenuService,
  MobileMenuComponent,
  GoogleAnalyticsService,
  AuthService,
} from '@wmt/web';
import { BreadcrumbComponent } from '@wmt/web';
import { StoreService } from '@wmt/web';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CookieConsentComponent,
    FooterComponent,
    MenuComponent,
    MatSidenavContainer,
    MatSidenavContent,
    MatSidenav,
    MobileMenuComponent,
    BreadcrumbComponent,
  ],
  selector: 'wmt-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit {
  private menuService = inject(MenuService);
  private gaService = inject(GoogleAnalyticsService);
  private storeService = inject(StoreService);
  private authService = inject(AuthService);
  private platformId = inject(PLATFORM_ID);
  private viewportScroller = inject(ViewportScroller);

  title = 'web-nl';
  @ViewChild('mobileNavRight') public sidenav!: MatSidenav;

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // TODO: Implement token storage and retrieval (Cookie or SessionStorage)
      this.authService.requestToken();
      await this.storeService.initializeStores();
      this.viewportScroller.setOffset([0, 94]);
    }
  }

  ngAfterViewInit() {
    this.menuService.setMobileSideNav(this.sidenav);
  }
}
