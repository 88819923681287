<table *ngFor="let rate of taxRates" style="width:100%; border-collapse: collapse;">
  <thead>
  <tr>
    <th scope="col" class="table-header col-one">Tax type</th>
    <th scope="col" class="table-header col-two">Tax rate</th>
    <th scope="col" class="table-header col-three">Tax amount</th>
    <th scope="col" class="table-header col-four">Tax credit rate</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <td class="table-cell">{{ rate.taxRateHead | capitalizeFirst }}</td>
    <td class="table-cell">{{ rate.taxRate | toPercentage:2 }}</td>
    @if (rate.taxAmount){
      <td class="table-cell">{{ rate.taxAmount | currency:'EUR' }}</td>
    } @else {
      <td class="table-cell">-</td>
    }
    <td class="table-cell">{{ rate.taxCreditRate | toPercentage:2 }}</td>
  </tr>
  </tbody>
  <thead>
  <tr>
    <th scope="col" class="table-header col-one">Tax credit amount</th>
    <th scope="col" class="table-header col-two">From</th>
    <th scope="col" class="table-header col-three">Until</th>
    <th scope="col" class="table-header col-four"></th>
  </tr>
  </thead>
  <tbody>
  <tr>
    @if (rate.taxCreditAmount){
      <td class="table-cell">{{ rate.taxCreditAmount| currency: 'EUR' }}</td>
    } @else {
      <td class="table-cell">-</td>
    }
    @if (rate.minTier) {
      <td class="table-cell">{{ rate.minTier | currency:'EUR' }}</td>
    } @else {
      <td class="table-cell">-</td>
    }
    @if (rate.maxTier) {
      <td class="table-cell">{{ rate.maxTier | currency:'EUR' }}</td>
    } @else {
      <td class="table-cell">-</td>
    }
    <td class="table-cell"></td>
  </tr>
  </tbody>
  <tbody>
  <tr>
    <td colspan="4" class="separator-cell">
      <hr class="wmt-detail-taxes-separator">
    </td>
  </tr>
  </tbody>

</table>
