import { Component } from '@angular/core';
import { DumbCardComponent } from '../../ui/dumb-card/dumb-card';

@Component({
  selector: 'wmt-about-us',
  standalone: true,
  imports: [
    DumbCardComponent,
  ],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss',
})
export class AboutUsComponent {

}
