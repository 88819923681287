import { Component, inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { isPlatformBrowser, NgForOf } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { FormGroup, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { AccountantVisitorRequest, AccountantWithServices } from '@wmt/shared';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { EmailService } from '../../../../services/api/email.service';
import { Subscription } from 'rxjs';

export interface DialogData {
  accountant: AccountantWithServices
}

@Component({
  selector: 'wmt-accountant-request-form',
  standalone: true,
  templateUrl: './accountant-request-form.component.html',
  imports: [
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
    MatCheckbox,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    NgForOf,
    ReactiveFormsModule,
    IconsModule,
    MatOption,
    MatSelect,
    MatIconButton,
  ],
  styleUrls: ['./accountant-request-form.component.scss'],
})
export class AccountantRequestFormComponent implements OnInit, OnDestroy {
  private platformId = inject(PLATFORM_ID);
  private dialogRef = inject(MatDialogRef<AccountantRequestFormComponent>);
  public fv = inject(FormValidationService);
  private emailService = inject(EmailService);
  public data: DialogData = inject(MAT_DIALOG_DATA);

  submitted = false;
  accountantRequestForm: FormGroup;
  private sub?: Subscription;

  constructor(private fb: NonNullableFormBuilder) {
    this.accountantRequestForm = this.fb.group({
      accountantId: [this.data.accountant.accountantId, { validators: [Validators.required] }],
      name: ['', { validators: [Validators.required, Validators.minLength(2)] }],
      firstName: [''],
      lastName: [''],
      email: ['', { validators: [Validators.required, Validators.email] }],
      services: [['N/A'], { validators: [Validators.required] }],
      message: ['', { validators: [Validators.required, Validators.minLength(10)] }],
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.dialogRef.afterClosed().subscribe(() => {
        this.resetForm();
      });
    }

    this.sub = this.accountantRequestForm.get('services')?.valueChanges.subscribe((selectedServices) => {
      this.updateServiceSelection(selectedServices);
    });

    this.fv.setCurrentForm(this.accountantRequestForm);
  }

  onSubmit() {
    const fullName = this.accountantRequestForm.value.name.trim();
    const nameParts = fullName.split(' ');

    if (this.accountantRequestForm.valid && this.data.accountant.accountantId) {
      const request: AccountantVisitorRequest = {
        accountantId: this.data.accountant.accountantId,
        firstName: nameParts[0],
        lastName: nameParts.length > 1 ? nameParts.slice(1).join(' ') : '',
        email: this.accountantRequestForm.value.email,
        services: this.accountantRequestForm.value.services,
        message: this.accountantRequestForm.value.message,
      };

      this.emailService.sendEmail(request).subscribe({
        next: (response) => {
          if (response.ok) {
            this.submitted = true;
            setTimeout(() => {
              this.resetForm();
            }, 10000);
          }
        },
        error: (error) => {
          console.error('Error sending email', error);
        },
      });
    }
    else {
      console.log('Form is invalid');
    }
  }

  resetForm() {
    this.submitted = false;
    this.accountantRequestForm.reset();
    setTimeout(() => {
      this.accountantRequestForm.get('accountantId')?.setValue(this.data.accountant.accountantId);
      this.accountantRequestForm.get('services')?.setValue(['N/A']);
    }, 1000);
  }

  updateServiceSelection(selectedServices: string[]) {
    const hasNA = selectedServices.includes('N/A');

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (selectedServices.length > 0) {
      if (hasNA) {
        const updatedServices = selectedServices.filter(service => service !== 'N/A');
        this.accountantRequestForm.get('services')?.setValue(updatedServices);
      }
    }
    else if (selectedServices.length === 0) {
      this.accountantRequestForm.get('services')?.setValue(['N/A']);
    }

    this.sub = this.accountantRequestForm.get('services')?.valueChanges.subscribe((selectedServices) => {
      this.updateServiceSelection(selectedServices);
    });
  }

  getAccountantServices(): string[] {
    const services: string[] = ['N/A', 'Private Consultation', 'Business Consultation'];

    if (this.data.accountant.services) {
      if (this.data.accountant.services.corporateTax) {
        services.push('Corporate Tax');
      }
      if (this.data.accountant.services.incomeTax) {
        services.push('Income Tax');
      }
      if (this.data.accountant.services.payroll) {
        services.push('Payroll');
      }
      if (this.data.accountant.services.usTax) {
        services.push('US Tax');
      }
    }

    return services;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
