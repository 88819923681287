import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root',
})
export class ClipboardService {
  constructor(private clipboard: Clipboard) {
  }

  copyDataToClipboard(
    inputs: any,
    results: any[],
    inputTitles: { [key: string]: string },
    resultTitles: { [key: string]: string },
    docTitle: string,
  ): void {
    let formattedText = docTitle + '\n\nUser inputs:\n\n';
    formattedText += this.formatTable(inputs, ['Input fields', 'Values'], inputTitles, false);
    formattedText += '\nCalculated output\n\n';
    formattedText += this.formatTable(results, ['Output fields', 'Values'], resultTitles, true);

    this.clipboard.copy(formattedText);
  }

  private formatTable(data: any[], headers: string[], titles: { [key: string]: string } | null, isResult = false): string {
    let tableString = '';

    if (isResult && titles) {
      // Use resultTitles for headers
      tableString += Object.keys(titles).map(key => titles[key]).map(title => title.padEnd(20)).join('  ') + '\n';
    }
    else {
      // Use default headers otherwise
      tableString += headers.map(header => header.padEnd(20)).join('  ') + '\n';
    }

    // Check if data is an object and not an array
    if (!Array.isArray(data)) {
      data = [data]; // Convert to array containing the single object for uniform processing
    }

    data.forEach((item) => {
      const rowValues: string[] = [];
      if (titles) {
        // Iterate over titles for ordered keys
        Object.keys(titles).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            // Each key-value pair gets its own line for non-results
            if (!isResult) {
              tableString += `${titles[key].padEnd(20)} ${item[key].toString().padEnd(20)}\n`;
            }
            else {
              // Results handling (if different)
              rowValues.push(`${item[key]}`.padEnd(20));
            }
          }
        });
        if (isResult && rowValues.length > 0) {
          // Only for results if different formatting is needed
          tableString += rowValues.join('  ') + '\n';
        }
      }
      else {
        // Handle no titles case
        Object.keys(item).forEach((key) => {
          rowValues.push(`${item[key]}`.padEnd(20)); // Default to using key order in object
          tableString += rowValues.join('  ') + '\n';
        });
      }
    });

    return tableString;
  }
}
