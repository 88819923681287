import { FormGroup } from '@angular/forms';

const inputValues = {
  grossIncomeIn: 0,
  periodIn: '',
  privateCarUseIn: false,
  carPriceIn: 0,
  privateBikeUseIn: false,
  bikePriceIn: 0,
  electricIn: false,
  workFromHomeDaysIn: 0,
  workKmPerMonthIn: 0,
  holidayAllowanceIn: false,
  rulingIn: false,
  rulingMonthsIn: '',
};

export const exportInputTitles: { [key: string]: string } = {
  grossIncomeIn: 'Gross income',
  periodIn: 'Period',
  privateCarUseIn: 'Private use of company car',
  carPriceIn: 'Sales price of car including btw',
  privateBikeUseIn: 'Private use of company bike',
  bikePriceIn: 'Sales price of bike including btw',
  electricIn: 'Electric',
  workFromHomeDaysIn: 'WFH days per week',
  workKmPerMonthIn: 'Kilometers traveled for work avg per month',
  holidayAllowanceIn: 'Holiday allowance included',
  rulingIn: 'I have 30% ruling',
  rulingMonthsIn: 'Ruling months',
};

export function updateInputValues(form: FormGroup) {
  const grossIncome = form.get('grossIncome')?.value;
  inputValues.grossIncomeIn = grossIncome ? parseFloat(grossIncome) : 0;
  inputValues.periodIn = form.get('period')?.value;
  inputValues.privateCarUseIn = form.get('privateCarUse')?.value !== 'no';
  const carPrice = form.get('carPrice')?.value;
  inputValues.carPriceIn = carPrice ? parseFloat(carPrice) : 0;
  inputValues.privateBikeUseIn = form.get('privateBikeUse')?.value !== 'no';
  const bikePrice = form.get('bikePrice')?.value;
  inputValues.bikePriceIn = bikePrice ? parseFloat(bikePrice) : 0;
  inputValues.electricIn = form.get('electric')?.value !== 'no';
  inputValues.workFromHomeDaysIn = parseInt(form.get('workFromHomeDays')?.value);
  const workKmPerMonth = form.get('workKmPerMonth')?.value;
  inputValues.workKmPerMonthIn = workKmPerMonth ? parseFloat(workKmPerMonth) : 0;
  inputValues.holidayAllowanceIn = form.get('holidayAllowance')?.value !== 'no';
  inputValues.rulingIn = form.get('ruling')?.value;
  inputValues.rulingMonthsIn = form.get('rulingMonths')?.value;
}

export function getIV() {
  return inputValues;
}

type ExportInputType = {
  grossIncomeIn: string
  periodIn: string
  privateCarUseIn: string
  carPriceIn: string
  privateBikeUseIn: string
  bikePriceIn: string
  electricIn: string
  workFromHomeDaysIn: string
  workKmPerMonthIn: string
  holidayAllowanceIn: string
  rulingIn: string
  rulingMonthsIn: string
};

export function getExportInputs(): ExportInputType {
  const exportInput: ExportInputType = {} as ExportInputType;
  let hasRuling = false;
  Object.entries(getIV()).forEach(([key, value]) => {
    const castKey = key as keyof ExportInputType;
    switch (key) {
      case 'grossIncomeIn':
      case 'carPriceIn':
      case 'bikePriceIn':
        if (typeof value === 'number') {
          exportInput[castKey] = value.toFixed(2);
        }
        break;
      case 'workKmPerMonthIn':
        if (typeof value === 'number') {
          exportInput[castKey] = value.toFixed(0);
        }
        break;
      case 'privateCarUseIn':
      case 'privateBikeUseIn':
      case 'electricIn':
      case 'holidayAllowanceIn':
      case 'rulingIn':
        exportInput[castKey] = value ? 'Yes' : 'No';
        hasRuling = value as boolean;
        break;
      case 'rulingMonthsIn':
        exportInput[castKey] = '-';
        if (hasRuling) {
          if (value === '1st') {
            exportInput[castKey] = '1st 20 mths';
          }
          else if (value === '2nd') {
            exportInput[castKey] = '2nd 20 mths';
          }
          else if (value === 'last') {
            exportInput[castKey] = 'Last 20 mths';
          }
        }
        break;
      default:
        exportInput[castKey] = value.toString();
        break;
    }
  });

  return exportInput;
}
