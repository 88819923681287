import { FormGroup } from '@angular/forms';

const inputValues = {
  vehicleListPrice: 0,
  vatPaid: 0,
  isElectricCar: false,
  vehicleType: '',
  purchaseMonth: '',
  grossSalaryPrivateUseAddition: 0,
  vatChargedOnPrivateUse: 0,
  salvageValue: 0,
};

export function updateInputValues(form: FormGroup) {
  const vehicleListPrice = form.get('vehicleListPrice')?.value;
  inputValues.vehicleListPrice = vehicleListPrice ? parseFloat(vehicleListPrice) : 0;
  const vatPaid = form.get('vatPaid')?.value;
  inputValues.vatPaid = vatPaid ? parseFloat(vatPaid) : 0;
  inputValues.isElectricCar = form.get('isElectricCar')?.value !== 'no';
  inputValues.vehicleType = form.get('vehicleType')?.value;
  inputValues.purchaseMonth = form.get('purchaseMonth')?.value;
  const grossSalaryPrivateUseAddition = form.get('grossSalaryPrivateUseAddition')?.value;
  inputValues.grossSalaryPrivateUseAddition = grossSalaryPrivateUseAddition ? parseFloat(grossSalaryPrivateUseAddition) : 0;
  const vatChargedOnPrivateUse = form.get('vatChargedOnPrivateUse')?.value;
  inputValues.vatChargedOnPrivateUse = vatChargedOnPrivateUse ? parseFloat(vatChargedOnPrivateUse) : 0;
  const salvageValue = form.get('salvageValue')?.value;
  inputValues.salvageValue = salvageValue ? parseFloat(salvageValue) : 0;
}

export function getIV() {
  return inputValues;
}
