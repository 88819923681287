import { z } from 'zod';

export const TaxServiceTypeEnum = z.enum(
  [
    'vat_filing',
    'book_keeping',
    'corporate_tax',
    'package',
    'other',
  ]);
