import { Component, OnDestroy, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { DOCUMENT, NgIf } from '@angular/common';
import { GridModule, DetailRowService, GridComponent } from '@syncfusion/ej2-angular-grids';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { EmitType } from '@syncfusion/ej2-base';
import { MatCardSubtitle } from '@angular/material/card';
import { Subscription } from 'rxjs';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { Tax } from '@wmt/shared';
import { taxStoreQuery } from '../../common/stores/tax.store';
import { IconsModule } from '../../ui/icons/icons.module';
import { DialogService } from '../../services/utility/dialog.service';

@Component({
  selector: 'wmt-tax-table',
  standalone: true,
  imports: [
    CardsComponent,
    GridModule,
    DialogModule,
    DetailViewComponent,
    NgIf,
    MatCardSubtitle,
    IconsModule,
  ],
  providers: [DetailRowService],
  templateUrl: './tax-table.component.html',
  styleUrl: './tax-table.component.scss',
})
export class TaxTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('wmtDialog') wmtDialog!: DialogComponent;
  @ViewChild('grid') public grid!: GridComponent;
  public userHasScrolledTable = false;
  public isMobile = true;
  lineItemsWidth = '154';
  isFrozenColumn = false;
  private screenSubscription!: Subscription;
  taxes: Tax[] = [];
  dialogHeader = '';
  tax!: Tax;

  constructor(
    private screenService: ScreenSizeService,
    private dialogService: DialogService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const gridElement: HTMLElement = this.grid.element;
      const scrollElement = gridElement.querySelector('.e-content');
      if (scrollElement) {
        const scrollListener = () => {
          this.userHasScrolledTable = true;

          // Remove event listener after user has scrolled
          scrollElement.removeEventListener('scroll', scrollListener);
        };

        scrollElement.addEventListener('scroll', scrollListener);
      }
    }, 0);
  }

  ngOnInit(): void {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
      if (isMobile) {
        this.lineItemsWidth = '154';
        this.isFrozenColumn = true;
      }
      else {
        this.lineItemsWidth = '128';
      }
    });
    this.taxes = taxStoreQuery.getTaxes();
  }

  onMoreClick(taxId: string) {
    const tax = taxStoreQuery.getTaxById(taxId);
    if (tax) {
      this.dialogHeader = tax.taxName;
      this.tax = tax;
      this.wmtDialog.show();
    }
  }

  public onOverlayClick: EmitType<object> = () => {
    this.wmtDialog.hide();
  };

  toggleDialog() {
    this.dialogService.toggle();
  }

  ngOnDestroy() {
    if (this.screenSubscription) {
      this.screenSubscription.unsubscribe();
    }
  }
}
