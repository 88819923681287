import { z } from 'zod';

export const AccountantRequestSchema = z.object({
  requestId: z.string().uuid(),
  visitorId: z.string().uuid(),
  accountantId: z.string().uuid(),
  services: z.array(z.string()),
  message: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type AccountantRequest = z.infer<typeof AccountantRequestSchema>;
