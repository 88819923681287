<mat-card class="cardWithShadow theme-card">

  <mat-card-content class="p-24">
    <mat-card-title class="mat-headline-6">Disclaimer</mat-card-title>
    <div class="col-sm-12 d-flex">
      <div class="row">
        <div [class]="showExports ? 'col-sm-8' : 'col-sm-12'">
          <div class="row">
            <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 f-s-16 wmt-text-height">
              @if (pageName === 'income-tax') {
                <p>1. If you are a director of a company, then please note that tax credits may not apply to you along
                  with benefits such as work from home allowances. Please consult your accountant for an accurate salary
                  card.</p>
                <p>2. This calculator is for illustrative purposes only. No guarantee is made for the accuracy of the
                  data provided. Consult a qualified tax services professional before making any decision.</p>
              } @else if (pageName === 'tax-comparison') {
                <p>1. This calculator is for illustrative purposes only. No guarantee is made for the accuracy of the
                  data provided. Consult a qualified tax services professional before making any decision.</p>
                <p>2. We know that the VAT varies between 0%, 9% and 21%. However, for illustrative purposes we have
                  shown only VAT as 21% as that has the maximum impact on taxes</p>
              } @else {
                <p>This calculator is for illustrative purposes only. No guarantee is made for the accuracy of the data
                  provided. Consult a qualified tax services professional before making any decision.</p>
              }
            </mat-card-subtitle>
          </div>
        </div>
        @if (showExports) {
          <div class="col-sm-4">
            <div class="row wmt-button-align">
              <div>
                <button mat-flat-button [matMenuTriggerFor]="save" color="primary" class="w-100 m-t-16">
                  <div class="d-flex align-items-center">
                    <i-tabler class="icon-20 m-r-5" name="download"></i-tabler>
                    Save results
                  </div>
                </button>
                <mat-menu #save="matMenu" class="cardWithShadow">
                  <button mat-menu-item (click)="triggerExportPdfEvent()">
                    <div class="d-flex align-items-center">
                      <i-tabler class="icon-20 m-r-5" name="file-type-pdf"></i-tabler>
                      Save as PDF
                    </div>
                  </button>
                  <button mat-menu-item (click)="triggerExportExcelEvent()">
                    <div class="d-flex align-items-center">
                      <i-tabler class="icon-20 m-r-5" name="file-spreadsheet"></i-tabler>
                      Save as excel
                    </div>
                  </button>
                  <button mat-menu-item (click)="triggerCopyClipboardEvent()">
                    <div class="d-flex align-items-center">
                      <i-tabler class="icon-20 m-r-5" name="clipboard"></i-tabler>
                      Copy to clipboard
                    </div>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </mat-card-content>

</mat-card>

