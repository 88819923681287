import { Component } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardImage, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'wmt-dumb-card',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardContent,
    MatCardImage,
    MatCardSubtitle,
    MatCardTitle,
    NgOptimizedImage,
    RouterLink,
  ],
  templateUrl: './dumb-card.html',
  styleUrl: './dumb-card.scss',
})
export class DumbCardComponent {
  goToLinkedIn() {
    window.open('https://www.linkedin.com/company/daedalinc/', '_blank');
  }
}
