import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { firstValueFrom, Observable } from 'rxjs';
import { TaxCategory } from '@wmt/shared';

const initialState = {
  taxCategories: [] as TaxCategory[],
};

export const taxCategoryStore = createStore(
  { name: 'taxCategories' },
  withProps<{ taxCategories: TaxCategory[] }>(initialState),
);

export function loadTaxCategories(taxCategories: TaxCategory[]) {
  taxCategoryStore.update(state => ({
    ...state,
    taxCategories,
  }));
}

export const taxCategoryStoreQuery = {
  getTaxCategories: () => taxCategoryStore.query(state => state.taxCategories),
  getCategoriesByTaxId: (taxId: string) =>
    taxCategoryStore.query(state =>
      state.taxCategories.filter(tc =>
        tc.taxId === taxId)),
};

export async function initializeTaxCategoryStore(fetchTaxCategoriesFromDB: () => Observable<TaxCategory[]>) {
  const storedTaxCategories = taxCategoryStoreQuery.getTaxCategories();
  if (storedTaxCategories.length === 0) {
    const taxCategories = await firstValueFrom(fetchTaxCategoriesFromDB());
    loadTaxCategories(taxCategories);
  }
}

persistState(taxCategoryStore, {
  key: 'wmt-tax-categories',
  storage: localStorageStrategy,
});
