import { AbstractControl, ValidatorFn } from '@angular/forms';

export function positiveNumberVal(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '') {
      // consider empty values as valid
      return null;
    }
    // Matches any positive number including zero, both integers and decimals
    // Starts with zero or more digits, followed by optional period or comma and more digits
    // Allows a single zero on its own
    const validNumber = /^(?:0|[1-9]\d*)(?:[.,]\d+)?$/.test(control.value);
    if (!validNumber) {
      return { invalidPattern: { value: control.value, message: 'Please enter a valid positive number.' } };
    }

    const numValue = parseFloat(control.value);

    if (numValue < 0) {
      return {
        negativeError: {
          value: numValue,
          message: 'This numeric value cannot be negative. Please adjust your entry.',
        },
      };
    }

    if (numValue > 5000000000000) { // 5 trillion
      return {
        exceedsMaxError: {
          value: numValue,
          message: 'This numeric value cannot exceed 5 trillion. Please adjust your entry.',
        },
      };
    }

    return null;
  };
}
