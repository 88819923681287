import { Component } from '@angular/core';
import { UiModule } from '../../ui/ui.module';
import { IconsModule } from '../../ui/icons/icons.module';
import { RouterLink } from '@angular/router';
import { AppContextService } from '../../services/utility/app-context.service';

@Component({
  selector: 'wmt-not-available',
  standalone: true,
  imports: [
    IconsModule,
    UiModule,
    RouterLink,
  ],
  templateUrl: './not-available.component.html',
  styleUrl: './not-available.component.scss',
})
export class NotAvailableComponent {
  constructor(private ACS: AppContextService) {
  }

  navigateToNl() {
    if (this.ACS.isProduction()) {
      window.open('https://nl.taxelos.com');
    }
    else if (this.ACS.isStaging()) {
      window.open('https://nl.beta.taxelos.com');
    }
    else if (this.ACS.isDevelopment()) {
      window.open('https://nl.dev.taxelos.com');
    }
    else if (this.ACS.isLocal()) {
      window.open('http://localhost:4301', '_blank');
    }
  }

  getNlUrl() {
    if (this.ACS.isProduction()) {
      return 'https://nl.taxelos.com';
    }
    else if (this.ACS.isStaging()) {
      return 'https://nl.beta.taxelos.com';
    }
    else if (this.ACS.isDevelopment()) {
      return 'https://nl.dev.taxelos.com';
    }
    else if (this.ACS.isLocal()) {
      return 'http://localhost:4301';
    }

    return '';
  }
}
