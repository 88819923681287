<wmt-hero-accountant></wmt-hero-accountant>

<div id="wmt-card-section" class="wmt-bg-secondary spacer">
  <div class="pageWrapper maxWidth">
    <wmt-partner-value-cards></wmt-partner-value-cards>
  </div>
</div>

<div class="pageWrapper maxWidth">
  <div class="spacer">
    <wmt-release-roadmap></wmt-release-roadmap>
  </div>
  <div class="row align-items-center justify-content-center">
    <div class="col-sm-12">
      <wmt-explore-card></wmt-explore-card>
    </div>
  </div>


  <div class="spacer">
    @if (partnersLength === null || showSlider) {
      <wmt-partner-slider (partnersLength)="onPartnersLength($event)"></wmt-partner-slider>
    }
  </div>


  <div class="row align-items-center justify-content-center">
    <div class="col-sm-12">
      <wmt-feedback-card [feedbackType]="'accountant'"></wmt-feedback-card>
    </div>
  </div>
</div>
