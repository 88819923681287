import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgClass, NgOptimizedImage, NgStyle } from '@angular/common';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { IconsModule } from '../../../ui/icons/icons.module';

@Component({
  selector: 'wmt-disclaimer',
  standalone: true,
  imports: [
    NgOptimizedImage,
    NgStyle,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatCard,
    MatCardContent,
    MatButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    IconsModule,
    NgClass,
  ],
  templateUrl: './disclaimer.component.html',
  styleUrl: './disclaimer.component.scss',
})
export class DisclaimerComponent implements OnInit, OnDestroy {
  @Input() width = '880px';
  @Input() showExports = true;
  @Input() pageName = '';
  @Output() exportPdfEvent = new EventEmitter<void>();
  @Output() exportExcelEvent = new EventEmitter<void>();
  @Output() copyClipboardEvent = new EventEmitter<void>();
  public isMobile = true;
  private subscription!: Subscription;

  constructor(private screenService: ScreenSizeService) {
  }

  ngOnInit(): void {
    this.subscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.isMobile = isMobile;
    });
  }

  triggerExportPdfEvent() {
    this.exportPdfEvent.emit();
  }

  triggerExportExcelEvent() {
    this.exportExcelEvent.emit();
  }

  triggerCopyClipboardEvent() {
    this.copyClipboardEvent.emit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
