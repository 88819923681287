<div class="wmt-hero-section overflow-hidden banner-section">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div>
              <span class="f-s-16 f-w-600 m-b-16 d-flex align-items-center">
                <i-tabler name="rocket" class="text-accent m-r-8"></i-tabler>Easy and transparent book keeping with</span>
          <h1 class="banner-title m-0">The most comprehensive
            <span class="text-primary">Tax and accounting </span> platform in the Netherlands</h1>
          <p class="f-s-18 p-y-24 m-t-12 m-0 banner-subtitle">
            Taxelos is a comprehensive tax and accounting platform that helps you with your tax and accounting needs.
            Manage your books, submit your tax returns, get in touch with accountants and AI enabled tax assistant; all in one place.
          </p>
          <div class="m-t-24 hstack">
            <a mat-flat-button color="primary" href="#wmt-card-section" class="btn-custom">Know your taxes</a>
            <a mat-stroked-button color="primary" [routerLink]="['taxelos-360']"
               class="btn-custom border-primary tx-360">Taxelos ΤΞ</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <ejs-carousel [autoPlay]="true" indicatorsType="Progress" cssClass="wmt-carousel">
          <e-carousel-items>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero/card01.svg" width="380" height="420" alt="Tax calculator" />
                  </div>
                  <figcaption class="img-caption">
                    <h4>Comprehensive book keeping and transaction management</h4>
                    <span>Load multi-currency transactions, categorize them per assets/expenses and even have them reviewed by an accountant, all in one place.</span>
                  </figcaption>
                </figure>
              </ng-template>
            </e-carousel-item>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero/card02.svg" width="380" height="420" alt="Accountant marketplace" />
                  </div>
                  <figcaption class="img-caption">
                    <h4>Accountant marketplace</h4>
                    <span>Marketplace that connects users to accountants and their services.</span>
                  </figcaption>
                </figure>
              </ng-template>
            </e-carousel-item>
            <e-carousel-item>
              <ng-template #template>
                <figure class="img-container">
                  <div class="img-wrapper">
                    <img src="/assets/images/hero/card03.svg" width="380" height="420" alt="Ai tax assistant" />
                  </div>
                  <figcaption class="img-caption">
                    <h4>AI driven tax assistant</h4>
                    <span>An AI tax assistant for Dutch tax matters.</span>
                  </figcaption>
                </figure>
              </ng-template>
            </e-carousel-item>
          </e-carousel-items>
        </ejs-carousel>
      </div>
    </div>
  </div>
</div>
