import { Component, inject, OnInit } from '@angular/core';
import { MatCard, MatCardContent, MatCardImage, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../ui/icons/icons.module';
import { MatChip } from '@angular/material/chips';
import { Router } from '@angular/router';
import { InsightsService } from '../../services/api/insights.service';
import { DatePipe, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'wmt-insights',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    IconsModule,
    MatChip,
    MatCardContent,
    MatCardImage,
    DatePipe,
    NgOptimizedImage,
  ],
  templateUrl: './insights.component.html',
  styleUrl: './insights.component.scss',
})
export class InsightsComponent implements OnInit {
  insightsService = inject(InsightsService);
  ghostPosts: any | null = null;

  constructor(
    public router: Router,
  ) {
  }

  selectBlog(slug: string) {
    this.insightsService.detailId = slug;
    this.router.navigate(['insights', slug]);
  }

  async ngOnInit(): Promise<void> {
    this.ghostPosts = await this.insightsService.getGhostPosts();
  }
}
