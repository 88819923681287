<div class="vote-container" [ngClass]="{'column-layout': displayColumn}">
  @if (!voted) {
    <p>Please thumb up if you would like a paid plan with unlimited access.</p>
    <div class="vote-wrapper">
      <i-tabler name="thumb-up" [class]="voted ? 'voted-thumb' : 'vote-thumb'" (click)="vote()">
      </i-tabler>
    </div>
  } @else {
    <p>You have voted on {{ voteDate }} for a paid subscription.&nbsp;</p>
  }
  <strong>Total votes so far: <span style="font-size: 16px">{{ totalUniqueVotes }}</span></strong>
</div>
