import { z } from 'zod';
import { FrequencyEnum } from '../enums/frequency';

export const UsTaxServiceSchema = z.object({
  ustServiceId: z.string().uuid().optional(),
  accountantId: z.string().uuid(),
  priceUsFederalTaxFiling: z.number().optional().nullable(),
  priceUsStateTaxFiling: z.number().optional().nullable(),
  priceUsStateTaxFilingExtra: z.number().optional().nullable(),
  priceFbarFiling: z.number().optional().nullable(),
  priceForm5471Filing: z.number().optional().nullable(),
  currency: z.string().max(5).default('EUR'),
  frequency: FrequencyEnum,
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type UsTaxService = z.infer<typeof UsTaxServiceSchema>;
