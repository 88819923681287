import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatStepLabel, MatStepper, MatStepperNext, MatStepperPrevious } from '@angular/material/stepper';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { positiveNumberVal } from '../../../validators/positive-number.validator';
import { MatIcon } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { WmtNumericInputComponent } from '../../../../ui/wmt-numeric-input/wmt-numeric-input.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmt-partner-form-company-services',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatInput,
    MatButton,
    MatStepperPrevious,
    MatStepperNext,
    MatStepLabel,
    MatIcon,
    MatPrefix,
    NgForOf,
    NgIf,
    IconsModule,
    MatCheckbox,
    MatRadioButton,
    MatRadioGroup,
    MatError,
    WmtNumericInputComponent,
  ],
  providers: [],
  templateUrl: './company-services.component.html',
  styleUrl: './company-services.component.scss',
})
export class CompanyServicesComponent implements OnInit, OnDestroy {
  @Input() stepper!: MatStepper;
  companyServicesForm!: FormGroup;
  otherServicesPlaceholder: string;

  private hasTaxConsultationSub?: Subscription;
  private hasBookKeepingSub?: Subscription;
  private hasBookKeepingPriceLimitSub?: Subscription;
  private hasPayrollServicesSub?: Subscription;
  private hasPayrollPriceThresholdSub?: Subscription;
  private hasServicesForUsExpatsSub?: Subscription;
  private hasCombinedPackagesSub?: Subscription;
  private hasPackageBookKeepingSub?: Subscription;
  private hasPackagePriceLimitSub?: Subscription;

  constructor(
    private fb: FormBuilder,
    public fv: FormValidationService,
  ) {
    this.otherServicesPlaceholder = 'Feel free to write any other services you provide along with their charges. '
    + '\nFor the services where you have a customized charges you can mention \'on request\'.';
  }

  ngOnInit() {
    this.initForm();
    this.setupHasTaxConsultationSub();
    this.setupHasBookKeepingSub();
    this.setupHasPayrollServicesSub();
    this.setupHasServicesForUsExpatsSub();
    this.setupHasCombinedPackagesSub();
  }

  initForm() {
    this.companyServicesForm = this.fb.nonNullable.group({
      hasTaxConsultation: ['no', { validators: [Validators.required] }],
      consultationForIndividualsPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      consultationForBusinessesPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      consultationForUsTaxPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      isFirstConsultationFree: [{ value: false, disabled: true }],
      freeConsultationForClients: [{ value: false, disabled: true }],

      taxForIndividualsPrice: [null, { validators: [Validators.required, positiveNumberVal] }],
      taxWPartnerPrice: [null, { validators: [positiveNumberVal] }],
      taxForFreelancersPrice: [null, { validators: [Validators.required, positiveNumberVal] }],
      taxForOthersEntitiesPrice: [null, { validators: [Validators.required, positiveNumberVal] }],

      hasBookKeepingServices: ['no', { validators: [Validators.required] }],
      hasBookKeepingPriceLimit: ['no', { validators: [Validators.required] }],
      bookKeepingMonthlyTransactionsLimit: [{
        value: null,
        disabled: true,
      }, { validators: [Validators.required, positiveNumberVal] }],
      bookKeepingPrice: [{ value: null, disabled: false }, { validators: [positiveNumberVal] }],
      bookKeepingPriceAbove: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],

      hasPayrollServices: ['no', { validators: [Validators.required] }],
      hasPayrollPriceThreshold: ['no', { validators: [Validators.required] }],
      employeeDgaPayrollPrice: [null, { validators: [positiveNumberVal] }],
      employeePayrollPrice: [null, { validators: [positiveNumberVal] }],
      employeePayrollThreshold: [{
        value: null,
        disabled: true,
      }, { validators: [Validators.required, positiveNumberVal] }],
      employeePayrollPriceAbove: [null, { validators: [positiveNumberVal] }],

      hasServicesForUsExpats: ['no', { validators: [Validators.required] }],
      usFederalTaxFilingPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      usStateTaxFilingPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      usAdditionalStatesFilingPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      usFbarFilingPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      usForm5471FilingPrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],

      hasCombinedPackages: ['no', { validators: [Validators.required] }],
      packageMonthlyTransactionsLimit: [{ value: null, disabled: true }, { validators: [Validators.required, positiveNumberVal] }],
      packagePrice: [{ value: null, disabled: true }, { validators: [Validators.required, positiveNumberVal] }],
      packageAbovePrice: [{ value: null, disabled: true }, { validators: [positiveNumberVal] }],
      hasPackagePriceLimit: ['no', { validators: [Validators.required] }],
      packageHasAnnualCorporateTaxFiling: [false],
      packageHasTaxAdvisoryServices: [false],
      packageHasVatFilingsPerQuarter: [false],
      packageHasCompanyDgaSalaryAndFiling: [false],
      packageHasBookKeeping: [false],

      otherServices: ['', { validators: [] }],
    });
  }

  setupHasTaxConsultationSub() {
    const consultationControls = [
      'consultationForIndividualsPrice', 'consultationForBusinessesPrice',
      'consultationForUsTaxPrice', 'isFirstConsultationFree', 'freeConsultationForClients'];
    this.hasTaxConsultationSub?.unsubscribe();
    this.hasTaxConsultationSub = this.companyServicesForm.get('hasTaxConsultation')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        consultationControls.forEach(control => this.companyServicesForm.get(control)?.enable());
      }
      else {
        consultationControls.forEach(control => this.companyServicesForm.get(control)?.disable());
      }
    });
  }

  setupHasBookKeepingSub() {
    this.hasBookKeepingSub?.unsubscribe();
    this.hasBookKeepingSub = this.companyServicesForm.get('hasBookKeepingServices')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.companyServicesForm.get('hasBookKeepingPriceLimit')?.enable();
        this.companyServicesForm.get('bookKeepingPrice')?.enable();
        this.setupHasBookKeepingPriceLimitSub();
      }
      else {
        this.companyServicesForm.get('hasBookKeepingPriceLimit')?.disable();
        this.companyServicesForm.get('bookKeepingMonthlyTransactionsLimit')?.disable();
        this.companyServicesForm.get('bookKeepingPrice')?.disable();
        this.companyServicesForm.get('bookKeepingPriceAbove')?.disable();
      }
    });
  }

  setupHasBookKeepingPriceLimitSub() {
    this.hasBookKeepingPriceLimitSub?.unsubscribe();
    this.hasBookKeepingPriceLimitSub = this.companyServicesForm.get('hasBookKeepingPriceLimit')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.companyServicesForm.get('bookKeepingMonthlyTransactionsLimit')?.enable();
        this.companyServicesForm.get('bookKeepingPriceAbove')?.enable();
      }
      else {
        this.companyServicesForm.get('bookKeepingMonthlyTransactionsLimit')?.disable();
        this.companyServicesForm.get('bookKeepingPriceAbove')?.disable();
      }
    });
  }

  setupHasPayrollServicesSub() {
    this.hasPayrollServicesSub?.unsubscribe();
    this.hasPayrollServicesSub = this.companyServicesForm.get('hasPayrollServices')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.companyServicesForm.get('hasPayrollPriceThreshold')?.enable();
        this.companyServicesForm.get('employeeDgaPayrollPrice')?.enable();
        this.companyServicesForm.get('employeePayrollPrice')?.enable();
        this.setupHasPayrollPriceThresholdSub();
      }
      else {
        this.companyServicesForm.get('hasPayrollPriceThreshold')?.disable();
        this.companyServicesForm.get('employeePayrollThreshold')?.disable();
        this.companyServicesForm.get('employeeDgaPayrollPrice')?.disable();
        this.companyServicesForm.get('employeePayrollPrice')?.disable();
        this.companyServicesForm.get('employeePayrollPriceAbove')?.disable();
      }
    });
  }

  setupHasPayrollPriceThresholdSub() {
    this.hasPayrollPriceThresholdSub?.unsubscribe();
    this.hasPayrollPriceThresholdSub = this.companyServicesForm.get('hasPayrollPriceThreshold')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.companyServicesForm.get('employeePayrollThreshold')?.enable();
        this.companyServicesForm.get('employeePayrollPriceAbove')?.enable();
      }
      else {
        this.companyServicesForm.get('employeePayrollThreshold')?.disable();
        this.companyServicesForm.get('employeePayrollPriceAbove')?.disable();
      }
    });
  }

  setupHasServicesForUsExpatsSub() {
    const usServiceControls = [
      'usFederalTaxFilingPrice', 'usStateTaxFilingPrice',
      'usAdditionalStatesFilingPrice', 'usFbarFilingPrice', 'usForm5471FilingPrice',
    ];
    this.hasServicesForUsExpatsSub?.unsubscribe();
    this.hasServicesForUsExpatsSub = this.companyServicesForm.get('hasServicesForUsExpats')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        usServiceControls.forEach(control => this.companyServicesForm.get(control)?.enable());
      }
      else {
        usServiceControls.forEach(control => this.companyServicesForm.get(control)?.disable());
      }
    });
  }

  setupHasCombinedPackagesSub() {
    const packageControls = [
      'packagePrice', 'packageHasAnnualCorporateTaxFiling', 'packageHasTaxAdvisoryServices',
      'packageHasVatFilingsPerQuarter', 'packageHasCompanyDgaSalaryAndFiling', 'packageHasBookKeeping',
    ];
    this.hasCombinedPackagesSub?.unsubscribe();
    this.hasCombinedPackagesSub = this.companyServicesForm.get('hasCombinedPackages')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        packageControls.forEach(control => this.companyServicesForm.get(control)?.enable());
        this.setupHasPackageBookKeepingSub();
      }
      else {
        packageControls.forEach(control => this.companyServicesForm.get(control)?.disable());
      }
    });
  }

  setupHasPackageBookKeepingSub() {
    this.hasPackageBookKeepingSub?.unsubscribe();
    this.hasPackageBookKeepingSub = this.companyServicesForm.get('packageHasBookKeeping')?.valueChanges.subscribe((value) => {
      if (value) {
        this.companyServicesForm.get('hasPackagePriceLimit')?.enable();
        this.setupHasPackagePriceLimitSub();
      }
      else {
        this.companyServicesForm.get('hasPackagePriceLimit')?.disable();
        this.companyServicesForm.get('packageMonthlyTransactionsLimit')?.disable();
        this.companyServicesForm.get('packageAbovePrice')?.disable();
        this.companyServicesForm.get('hasPackagePriceLimit')?.setValue('no');
      }
    });
  }

  setupHasPackagePriceLimitSub() {
    this.hasPackagePriceLimitSub?.unsubscribe();
    this.hasPackagePriceLimitSub = this.companyServicesForm.get('hasPackagePriceLimit')?.valueChanges.subscribe((value) => {
      if (value === 'yes') {
        this.companyServicesForm.get('packageMonthlyTransactionsLimit')?.enable();
        this.companyServicesForm.get('packageAbovePrice')?.enable();
      }
      else {
        this.companyServicesForm.get('packageMonthlyTransactionsLimit')?.disable();
        this.companyServicesForm.get('packageAbovePrice')?.disable();
      }
    });
  }

  get isComplete(): boolean {
    return this.companyServicesForm.valid;
  }

  ngOnDestroy() {
    if (this.hasTaxConsultationSub) {
      this.hasTaxConsultationSub.unsubscribe();
    }
    if (this.hasBookKeepingSub) {
      this.hasBookKeepingSub.unsubscribe();
    }
    if (this.hasBookKeepingPriceLimitSub) {
      this.hasBookKeepingPriceLimitSub.unsubscribe();
    }
    if (this.hasPayrollServicesSub) {
      this.hasPayrollServicesSub.unsubscribe();
    }
    if (this.hasPayrollPriceThresholdSub) {
      this.hasPayrollPriceThresholdSub.unsubscribe();
    }
    if (this.hasServicesForUsExpatsSub) {
      this.hasServicesForUsExpatsSub.unsubscribe();
    }
    if (this.hasCombinedPackagesSub) {
      this.hasCombinedPackagesSub.unsubscribe();
    }
    if (this.hasPackageBookKeepingSub) {
      this.hasPackageBookKeepingSub.unsubscribe();
    }
    if (this.hasPackagePriceLimitSub) {
      this.hasPackagePriceLimitSub.unsubscribe();
    }
  }
}
