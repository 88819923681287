import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RunParameters, ThreadMessage } from '@wmt/shared';
import { LibEnvironment } from '../../common/interfaces/lib-environment';
import { LIB_ENVIRONMENT } from '../../../index';

@Injectable({
  providedIn: 'root',
})
export class ThreadService {
  private environment = inject(LIB_ENVIRONMENT) as LibEnvironment;
  private http = inject(HttpClient);
  private apiUrl = this.environment.apiBaseUrl;

  createThread(machineId: string | null): Observable<any> {
    const threadData = machineId ? { machineId } : {};
    return this.http.post(`${this.apiUrl}/ai/threads/create`, threadData);
  }

  retrieveThread(threadId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/ai/threads/${threadId}`);
  }

  modifyThread(threadId: string, threadData: any): Observable<any> {
    return this.http.patch(`${this.apiUrl}/ai/threads/${threadId}`, threadData);
  }

  deleteThread(threadId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/ai/threads/${threadId}`, {
      observe: 'response',
    });
  }

  addMessageToThread(threadId: string, threadMessage: ThreadMessage, machineId: string | null): Observable<any> {
    const messageData = machineId ? { ...threadMessage, machineId } : threadMessage;
    return this.http.post(`${this.apiUrl}/ai/threads/${threadId}/messages`, messageData);
  }

  listThreadMessages(threadId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/ai/threads/${threadId}/messages/list`);
  }

  createRun(threadId: string, runParameters?: RunParameters): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/ai/threads/${threadId}/runs`,
      runParameters || {},
    );
  }

  startStreamingResponse(threadId: string, message: ThreadMessage, machineId: string | null): Observable<any> {
    return new Observable((observer) => {
      // First, post the message
      this.addMessageToThread(threadId, message, machineId).subscribe({
        next: (sessionId) => {
          // Now, start the SSE connection
          const streamUrl = `${this.apiUrl}/ai/threads/stream-response?sessionId=${sessionId}`;
          const eventSource = new EventSource(streamUrl);

          eventSource.addEventListener('messageCreated', (event) => {
            observer.next({ type: 'messageCreated', data: JSON.parse(event.data) });
          });

          eventSource.addEventListener('messageDelta', (event) => {
            observer.next({ type: 'messageDelta', data: JSON.parse(event.data) });
          });

          eventSource.addEventListener('messageDone', (event) => {
            observer.next({ type: 'messageDone', data: JSON.parse(event.data) });
          });

          eventSource.addEventListener('streamEnded', () => {
            observer.complete();
            eventSource.close();
          });

          eventSource.onerror = (error) => {
            observer.error(error);
            eventSource.close();
          };

          return () => eventSource.close();
        },
        error: (error) => {
          console.error('Error posting message:', error);
          observer.error(error);
        },
      });
    });
  }

  retrieveRun(threadId: string, runId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/ai/threads/${threadId}/runs/${runId}`);
  }

  getLastMessageId(threadId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/ai/threads/${threadId}/messages/lastId`);
  }

  getLastMessages(threadId: string, lastMessageId: string): Observable<any> {
    return this.http.get(
      `${this.apiUrl}/ai/threads/${threadId}/messages/lastMessages/${lastMessageId}`,
    );
  }

  cancelRun(threadId: string, runId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/ai/threads/${threadId}/runs/${runId}`);
  }
}
