<div class="pageWrapper maxWidth">
  <mat-card class="cardWithShadow">
    <mat-card-content class="p-24">

      <h2 class="mat-headline-5">Introduction</h2>
      <p class="mat-body-1 m-t-16">
        Daedal Inc., with registered offices in Charlotte, NC, United States of America, and The Hague, Netherlands,
        owns and operates the website taxelos.com. This Privacy Policy outlines our commitment to protecting the
        privacy and personal data of our users in compliance with the General Data Protection Regulation (GDPR).
      </p>

      <h2 class="mat-headline-5 m-t-20">Data Controller</h2>
      <p class="mat-body-1 m-t-16">
        Daedal Inc. is the data controller responsible for your personal data collected through taxelos.com.
      </p>

      <h2 class="mat-headline-5 m-t-20">Personal Data We Collect</h2>
      <ol>
        <li><span class="f-w-600">Information You Provide: </span>This includes data provided through forms, such as
          your name, email address, and any other information you choose to provide.
        </li>
        <li><span class="f-w-600">Automated Information: </span>We collect certain data automatically when you visit our
          website, such as your IP address, browser type, and usage details.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Purpose of Data Processing</h2>
      <p class="mat-body-1 m-t-16">We use your personal data for the following purposes:</p>
      <ol>
        <li><span class="f-w-600">To Provide Services: </span>To offer and maintain our website and services.</li>
        <li><span class="f-w-600">Customer Support: </span>To provide customer support and respond to inquiries.</li>
        <li><span class="f-w-600">Improvement and Development: </span>To improve and personalize the website and develop
          new features.
        </li>
        <li><span class="f-w-600">Compliance and Enforcement: </span>To comply with legal obligations and enforce our
          terms and conditions.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Legal Basis for Processing</h2>
      <ol>
        <li><span class="f-w-600">Consent: </span>We process certain data based on your consent, which you can withdraw
          at any time.
        </li>
        <li><span class="f-w-600">Contractual Necessity: </span>Processing is necessary for the performance of a
          contract with you.
        </li>
        <li><span class="f-w-600">Legal Obligations and Interests: </span>We may process data to comply with legal
          obligations or for legitimate interests, provided your rights do not override these interests.
        </li>
      </ol>

      <h2 class="mat-headline-5 m-t-20">Data Retention</h2>
      <p class="mat-body-1 m-t-16">
        Your personal data is retained only for as long as necessary for the purposes it was collected, in compliance
        with our data retention policies and regulations.
      </p>

      <h2 class="mat-headline-5 m-t-20">Data Subject Rights</h2>
      <p class="mat-body-1 m-t-16">
        Under the GDPR, you have the right to access, correct, delete, and restrict the processing of your personal
        data. You also have the right to data portability and to object to processing.
      </p>

      <h2 class="mat-headline-5 m-t-20">International Data Transfers</h2>
      <p class="mat-body-1 m-t-16">
        Your data may be transferred to and processed in the United States. We ensure appropriate safeguards and legal
        frameworks, such as Standard Contractual Clauses, are in place to secure these data transfers.
      </p>

      <h2 class="mat-headline-5 m-t-20">Third-party Access</h2>
      <p class="mat-body-1 m-t-16">
        We do not sell or rent personal data to third parties. However, we may share data with trusted third parties for
        the purposes outlined in this policy, under strict data processing agreements.
      </p>

      <h2 class="mat-headline-5 m-t-20">Security Measures</h2>
      <p class="mat-body-1 m-t-16">
        We implement robust technical and organizational measures to protect your personal data from unauthorized
        access, alteration, disclosure, or destruction.
      </p>

      <h2 class="mat-headline-5 m-t-20">Children's Privacy</h2>
      <p class="mat-body-1 m-t-16">
        Our website is not intended for children under the age of 16, and we do not knowingly collect data from
        children.
      </p>

      <h2 class="mat-headline-5 m-t-20">Changes to This Policy</h2>
      <p class="mat-body-1 m-t-16">
        We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated
        revision date.
      </p>

      <h2 class="mat-headline-5 m-t-20">Contact Us</h2>
      <p class="mat-body-1 m-t-16">
        For any questions or concerns about this policy or your personal data, please contact our Data Protection
        Officer at <a href="mailto:gdpr@daedal.codes"> gdpr&#64;daedal.codes</a>.
      </p>
      <p class="wmt-text-updated">Effective date: 2-APR-2024</p>

    </mat-card-content>
  </mat-card>
</div>
