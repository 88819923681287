import { z } from 'zod';
import { FrequencyEnum } from '../enums/frequency';
import { TaxServiceTypeEnum } from '../enums/tax-service-type';
import { CorporateServiceCategoryEnum } from '../enums/corporate-service-category';

export const CorporateTaxServiceSchema = z.object({
  ctServiceId: z.string().uuid().optional(),
  accountantId: z.string().uuid(),
  type: TaxServiceTypeEnum,
  category: CorporateServiceCategoryEnum,
  price1: z.number().optional().nullable(),
  transactionLimit: z.number().int().optional().nullable(),
  price2: z.number().optional().nullable(),
  currency: z.string().max(5).default('EUR'),
  frequency: FrequencyEnum.optional().nullable(),
  inclusions: z.string().max(400).optional().nullable(),
  createdAt: z.date().optional(),
  updatedAt: z.date().optional(),
});

export type CorporateTaxService = z.infer<typeof CorporateTaxServiceSchema>;
