import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../services/utility/menu.service';

@Component({
  selector: 'wmt-logo',
  standalone: true,
  imports: [],
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
})
export class LogoComponent {
  @Input() width = '69';
  @Input() height = '50';

  isHovering = false;
  logoSmPath = '/assets/logo-blue-sm-153x111.png';
  logoPath = '/assets/logo-blue-500x111.png';

  constructor(private router: Router, private menuService: MenuService) {
  }

  goToHomepage() {
    this.router.navigate(['/']).then(() => {
      this.menuService.refresh();
    });
  }

  onMouseOver() {
    this.isHovering = true;
    this.width = '226';
    this.height = '50';
  }

  onMouseOut() {
    this.isHovering = false;
    this.width = '69';
    this.height = '50';
  }
}
