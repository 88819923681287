import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { inject } from '@angular/core';
import { ChatFacadeService } from '../../services/utility/chat-facade.service';

export function validateChatId(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  const facade = inject(ChatFacadeService);
  const router = inject(Router);
  const chatId = route.params['id'];
  const chatExists = !!facade.chatStateService.getChatById(chatId);

  if (!chatExists) {
    router.navigate(['tax-djinn']); // Redirect to home if the chat ID is not valid
  }

  return chatExists;
}
