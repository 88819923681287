import { filter, map, mergeMap, shareReplay, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';

export const getCurrentId = (router: Router, activatedRoute: ActivatedRoute) => {
  return router.events.pipe(
    // Only process Scroll events containing a NavigationEnd
    filter((event): event is Scroll => event instanceof Scroll && event.routerEvent instanceof NavigationEnd),
    // Start with the root route and navigate down to the child route where the navigation ends
    map(() => {
      let route = activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    // Extract the params from the final route
    mergeMap(route => route.params),
    // Get the 'id' parameter or default to null if not present
    map(params => params['id'] || null),
    // tap(id => console.log('Extracted Chat ID in route:', id)),
    // Share a single subscription result among multiple subscribers
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );
};
