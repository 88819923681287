import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to escape backslashes in a string.
 *
 * This is useful for ensuring that backslashes are correctly interpreted in contexts where
 * they might otherwise be treated as escape characters, such as in LaTeX expressions.
 */
@Pipe({
  standalone: true,
  name: 'escapeBackslashes',
})
export class EscapeBackslashesPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value.replace(/\\/g, '\\\\') : value;
  }
}
