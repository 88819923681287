import { Injectable } from '@angular/core';
import { initializeTaxStore, taxStoreQuery } from '../../common/stores/tax.store';
import { initializeTaxCategoryStore, taxCategoryStoreQuery } from '../../common/stores/tax-category.store';
import { initializeTaxRateStore, taxRateStoreQuery } from '../../common/stores/tax-rate.store';
import { initializeTaxDepreciationStore } from '../../common/stores/tax-depreciation';
import { initializeChatStore } from '../../common/stores/chat.store';
import { TaxService } from './tax.service';
import { TaxRate } from '@wmt/shared';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  constructor(private taxService: TaxService) {
  }

  async initializeStores(): Promise<void> {
    initializeChatStore();
    await Promise.all([
      initializeTaxStore(this.taxService.getAllTaxes.bind(this.taxService)),
      initializeTaxCategoryStore(this.taxService.getAllTaxCategories.bind(this.taxService)),
      initializeTaxRateStore(this.taxService.getAllTaxRates.bind(this.taxService)),
      initializeTaxDepreciationStore(this.taxService.getAllTaxDepreciations.bind(this.taxService)),
    ]);
  }

  getTaxRatesByTaxName(taxName: string): TaxRate[] {
    const tax = taxStoreQuery.getTaxes().find(t => t.taxName === taxName);
    if (!tax) {
      console.log(`Tax ${taxName} not found`);
      return [];
    }

    const taxCategories = taxCategoryStoreQuery.getTaxCategories().filter(tc => tc.taxId === tax.taxId);
    if (taxCategories.length === 0) {
      console.log(`Tax categories for tax ${taxName} not found`);
      return [];
    }

    const taxRates: TaxRate[] = [];
    taxCategories.forEach((tc) => {
      const rates = taxRateStoreQuery.getTaxRates().filter(tr => tr.taxCategoryId === tc.taxCategoryId);
      taxRates.push(...rates);
    });

    if (taxRates.length === 0) {
      console.log(`Tax rates for tax ${taxName} not found`);
      return [];
    }

    return taxRates;
  }

  getTaxRatesByCategoryCodes(taxRates: TaxRate[], categoryCodes: string[]): TaxRate[] {
    const categoryIds = taxCategoryStoreQuery.getTaxCategories().filter(tc =>
      categoryCodes.includes(tc.categoryCode)).map(tc => tc.taxCategoryId);
    return taxRateStoreQuery.getTaxRates().filter(tr => categoryIds.includes(tr.taxCategoryId));
  }
}
