import { Component } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { MatAnchor, MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatToolbar } from '@angular/material/toolbar';
import { MenuService } from '../../../../services/utility/menu.service';
import { LogoComponent } from '../../logo/logo.component';
import { AppContextService } from '../../../../services/utility/app-context.service';
import { MenuItemsService } from '../menu-items.service';

@Component({
  selector: 'wmt-header',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
  standalone: true,
  imports: [
    RouterLink,
    MatExpansionPanelHeader,
    MatExpansionPanel,
    MatAccordion,
    MatSidenav,
    IconsModule,
    MatAnchor,
    MatIconButton,
    MatMenu,
    MatMenuTrigger,
    MatButton,
    MatToolbar,
    MatSidenavContent,
    MatSidenavContainer,
    MatExpansionPanelTitle,
    MatMiniFabButton,
    LogoComponent,
  ],
})
export class MenuComponent {
  isWebNl: boolean;
  taxApps;
  helpMenuItems;

  constructor(
    private scroller: ViewportScroller,
    private menuService: MenuService,
    private ACS: AppContextService,
    private menuItemService: MenuItemsService,
  ) {
    this.isWebNl = this.ACS.isWebNl();
    this.taxApps = this.menuItemService.getTaxApps();
    this.helpMenuItems = this.menuItemService.getHelpMenuItems();
  }

  onToggleSidenav() {
    this.menuService.toggle();
  }
}
