<div class="wmt-cookie-consent-header">
  <h5 mat-dialog-title class="mat-subtitle-1">Cookie and Privacy Policy</h5>
  <h5 *ngIf="consentGiven" mat-dialog-title class="mat-subtitle-1 wmt-cookie-consent-status"
      [ngClass]="{'accepted': consent, 'rejected': !consent}">
    You have {{consent ? 'accepted' : 'rejected' }} cookies
  </h5>
</div>
<div class="wmt-cookie-consent-content">
  <div mat-dialog-content class="mat-subtitle-2 lh-16">
    <p>This website uses cookies to ensure you get the best experience on our site and to personalize content and ads,
      to provide social media features, and to analyze our traffic.By clicking "Accept", you consent to the use of ALL
      cookies. Alternatively, you can choose to "Reject" or customize your cookie preferences. Learn more in our
      <a routerLink="/privacy-policy">Privacy Policy.</a></p>
  </div>
  <div mat-dialog-actions class="p-24 p-t-0">
    <button mat-flat-button color="warn" mat-dialog-close (click)="rejectCookies()">Reject</button>
    <button mat-flat-button color="primary" mat-dialog-close cdkFocusInitial
            (click)="acceptCookies()">Accept
    </button>
  </div>
</div>
