import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { CompanyServicesComponent } from './company-services/company-services.component';
import { ConsentComponent } from './consent/consent.component';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatStep, MatStepLabel, MatStepper, MatStepperIcon } from '@angular/material/stepper';
import { FormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { IconsModule } from '../../../ui/icons/icons.module';
import { FormValidationService } from '../../../services/utility/form-validation.service';
import { AccountantService } from '../../../services/api/accountant.service';
import { AccountantSignupData } from '@wmt/shared';
import { ScreenSizeService } from '../../../services/utility/screen-size.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wmt-partner-form',
  standalone: true,
  imports: [
    CompanyProfileComponent,
    CompanyServicesComponent,
    ConsentComponent,
    MatCard,
    MatCardHeader,
    MatCardContent,
    MatStepper,
    MatStep,
    MatStepLabel,
    MatCardTitle,
    MatCardSubtitle,
    RouterLink,
    MatStepperIcon,
    IconsModule,
  ],
  providers: [],
  templateUrl: './partner-form.component.html',
  styleUrl: './partner-form.component.scss',
})
export class PartnerFormComponent implements AfterViewInit, OnInit, OnDestroy {
  private screenSubscription!: Subscription;
  @ViewChild(CompanyProfileComponent) companyProfileComponent!: CompanyProfileComponent;
  @ViewChild(CompanyServicesComponent) companyServicesComponent!: CompanyServicesComponent;
  @ViewChild(ConsentComponent) consentComponent!: ConsentComponent;
  @ViewChild('stepper') stepper!: MatStepper;
  orientation: 'horizontal' | 'vertical' = 'horizontal';

  companyProfileForm!: FormGroup;
  companyServicesForm!: FormGroup;
  consentForm!: FormGroup;
  submitted = false;
  successMessage = '';

  constructor(
    private screenService: ScreenSizeService,
    public fv: FormValidationService,
    private accountantService: AccountantService,
  ) {
  }

  ngOnInit() {
    this.screenSubscription = this.screenService.isMobile$.subscribe((isMobile) => {
      this.orientation = isMobile ? 'vertical' : 'horizontal';
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.companyProfileForm = this.companyProfileComponent.companyProfileForm;
      this.companyServicesForm = this.companyServicesComponent.companyServicesForm;
      this.consentForm = this.consentComponent.consentForm;

      this.updateCurrentForm(this.stepper.selectedIndex);
      this.stepper.selectionChange.subscribe((change) => {
        this.updateCurrentForm(change.selectedIndex);
      });
    });
  }

  onFormSubmit() {
    if (this.companyProfileForm.valid && this.companyServicesForm.valid && this.consentForm.valid) {
      const combinedForm: AccountantSignupData = {
        ...this.companyProfileForm.value,
        ...this.companyServicesForm.value,
        ...this.consentForm.value,
      };

      this.accountantService.processAccountantSignup(combinedForm).subscribe({
        next: (res) => {
          if (res.ok) {
            this.submitted = true;
            this.successMessage = res.body ? res.body.message : 'No message received from server';
            setTimeout(() => {
              this.resetForm();
            }, 10000);
          }
        },
        error: (error) => {
          console.error(error);
        },
      });
    }
    else {
      this.stepper.selectedIndex = this.getFirstInvalidFormIndex();
    }
  }

  resetForm() {
    this.submitted = false;
    this.companyProfileForm.reset();
    this.companyServicesForm.reset();
    this.consentForm.reset();
    this.stepper.reset();
  }

  getFirstInvalidFormIndex(): number {
    if (this.companyProfileForm.invalid) return 0;
    if (this.companyServicesForm.invalid) return 1;
    if (this.consentForm.invalid) return 2;
    return -1;
  }

  get isCompanyProfileFormValid(): boolean {
    return this.companyProfileForm?.valid ?? false;
  }

  get isCompanyServicesFormValid(): boolean {
    return this.companyServicesForm?.valid ?? false;
  }

  get isConsentFormValid(): boolean {
    return this.consentForm?.valid ?? false;
  }

  updateCurrentForm(index: number) {
    let currentForm;

    switch (index) {
      case 0:
        currentForm = this.companyProfileForm;
        break;
      case 1:
        currentForm = this.companyServicesForm;
        break;
      case 2:
        currentForm = this.consentForm;
        break;
      default:
        return;
    }
    this.fv.setCurrentForm(currentForm);
  }

  ngOnDestroy() {
    this.screenSubscription.unsubscribe();
  }
}
