<div class="pageWrapper maxWidth">

  <div class="row">
    <div class="col-lg-12 col-sm-12">

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">We are here to help</mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            Please go to our dedicated pages to <a href="{{raiseDefectLink}}" target="_blank">log a defect</a> or to <a
            href="{{featureRequestLink}}" target="_blank">request a new feature</a>. <br>For any other queries, please
            fill up the form below and we'll get back to you.
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">
          @if (!submitted) {
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="row">

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>First Name<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="John" id="first-name" formControlName="firstName" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('firstName')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('firstName')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Last Name<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="Doe" id="last-name" formControlName="lastName" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="user" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('lastName')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('lastName')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Are you a business or individual?<span class="wmt-required">*</span></span>
                        @if (fv.hasError('category')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('category')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-radio-group aria-label="Select an option" formControlName="category">
                        <mat-radio-button value="P" color="primary">Private
                          Individual
                        </mat-radio-button>
                        <mat-radio-button value="B" color="primary">Business
                        </mat-radio-button>
                      </mat-radio-group>
                      <mat-checkbox class="m-b-16" color="primary" formControlName="isAccountant">
                        Are you an accountant interested to partner with us ?
                      </mat-checkbox>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row" *ngIf="fv.f?.['category']?.value === 'B'">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Company Name<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="ACME Corp" formControlName="companyName" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="building-arch" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('companyName')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('companyName')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Email<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="john.doe@mail.com" type="email" formControlName="email" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="mail" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('email')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('email')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        Phone
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput wmtPhoneInput placeholder="+31 6 12345678" formControlName="phone" type="tel" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="phone" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('phone')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('phone')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Subject<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                        <input matInput placeholder="Information request" formControlName="subject" />
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="tag" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('subject')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('subject')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                        <span>Message<span class="wmt-required">*</span></span>
                      </mat-label>
                    </div>
                    <div class="col-sm-12">
                      <mat-form-field appearance="outline" class="w-100">
                      <textarea rows="5" matInput placeholder="Hi, Do you have a moment ... ?"
                                formControlName="description"></textarea>
                        <mat-icon matPrefix class="op-5">
                          <i-tabler name="message-2" class="icon-20 d-flex"></i-tabler>
                        </mat-icon>
                        @if (fv.hasError('description')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('description')">
                            {{ error }}
                          </mat-error>
                        }
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row p-b-24">
                    <div class="col-sm-12 d-flex align-items-center">
                      <mat-checkbox color="primary" formControlName="consent">
                        <span class="wmt-required">*</span>
                        {{ consentText }}
                        @if (fv.hasError('consent')) {
                          <mat-error *ngFor="let error of fv.getFormErrors('consent')"
                                     style="display: inline" class="f-w-600">{{ error }}
                          </mat-error>
                        }
                      </mat-checkbox>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row justify-content-end">
                <div class="col-6">
                  <button type="reset" mat-flat-button color="warn">Reset</button>
                </div>
                <div class="col-6 text-right">
                  <button mat-flat-button color="primary" [disabled]="!contactForm.valid" type="submit">Send
                  </button>
                </div>
              </div>
            </form>
          } @else {
            <div class="row">
              <div class="col-12 text-center">
                <i-tabler name="send" class="wmt-sent-icon"></i-tabler>
                <h6 class="f-s-20 f-w-600 m-b-30">Thank you for reaching out to us!</h6>
                <p class="mb-0">We have received your message and will get back to you shortly.</p>
                <button type="reset" mat-flat-button color="primary" (click)="resetForm()">Send another message</button>
                <p class="mb-0">or check out our <a href="/faq">FAQ</a> page for any
                  immediate queries.</p>
              </div>
            </div>
          }
        </mat-card-content>
      </mat-card>
    </div>
  </div>

  <div class="row align-items-center justify-content-center">
    <div class="col-xl-12 col-lg-12 col-md-12 wmt-maps">

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 wmt-no-padding">
        <mat-card class="cardWithShadow theme-card">
          <mat-card-header class="justify-content-center">
            <mat-card-title class="m-b-0 f-s-24 text-center">Daedal Global Headquarters</mat-card-title>
            <mat-card-subtitle class="mat-body-1 p-t-12 p-b-6 text-center wmt-text-height">
              Zuid hollandlaan 7, 2596 AL, <br>Den Haag, Zuid Holland, Netherlands
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content class="b-t-1" style="height: 400px">
            <google-map class="cardWithShadow"
                        [options]="optionsNl"
                        height="100%"
                        width="100%"
                        (mapClick)="moveMapNl($event)"
                        (mapMousemove)="move($event)"></google-map>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 wmt-no-padding">
        <mat-card class="cardWithShadow theme-card">
          <mat-card-header class="justify-content-center">
            <mat-card-title class="m-b-0 f-s-24 text-center">Daedal North America Office</mat-card-title>
            <mat-card-subtitle class="mat-body-1 p-t-12 p-b-6 text-center wmt-text-height">
              525 North Tryon St, STE 1600, <br>Charlotte, NC, USA,28202
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content class="b-t-1" style="height: 400px">
            <google-map class="cardWithShadow"
                        [options]="optionsUs"
                        height="100%"
                        width="100%"
                        (mapClick)="moveMapUs($event)"
                        (mapMousemove)="move($event)"></google-map>
          </mat-card-content>
        </mat-card>
      </div>

    </div>
    <div class="col-sm-12">
      <wmt-feedback-card [feedbackType]="'consumer'"></wmt-feedback-card>
    </div>
  </div>
</div>

<wmt-cards></wmt-cards>
