import { NgModule } from '@angular/core';

import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

// TODO: Select some icons (use an object, not an array)
const icons = {
  /* IconCamera,
  IconHeart,
  IconBrandGithub */
};

@NgModule({
  declarations: [],
  imports: [
    TablerIconsModule.pick(TablerIcons),
  ],
  exports: [
    TablerIconsModule,
  ],
})
export class IconsModule { }
