<div class="pageWrapper maxWidth">

  <div class="row">
    @for (ghostPost of ghostPosts; track ghostPost.id) {
      @if (ghostPost.featured) {
        <div class="col-12 col-lg-4 col-xl-8" [class.col-lg-8]="ghostPost.featured">
          <mat-card
            class="cardWithShadow card2 position-relative card-hover featured-card overflow-hidden cursor-pointer"
            (click)="selectBlog(ghostPost.slug)">
            <img mat-card-image ngSrc="{{ ghostPost.feature_image }}" alt="{{ ghostPost.feature_image_alt }}"
                 width="850" height="420">
            <div class="featured-overlay h-100 p-24 d-flex wmt-flex-column align-items-end justify-content-start">
              <div class="d-flex align-items-center justify-content-between w-100">
                <img ngSrc="{{ ghostPost?.primary_author?.profile_image }}" class="rounded-circle"
                     width="40" height="40" alt="{{ ghostPost.primary_author?.name }}" />
                <span class="f-s-12 f-w-600 bg-primary text-white rounded p-x-8 p-y-4">
                  {{ ghostPost.primary_tag?.name }}
                </span>
              </div>
              <div class="m-t-auto w-100">
                <mat-card-title class="mat-headline-5 text-white">{{ ghostPost.title }}</mat-card-title>
                <div class="d-flex align-items-center justify-content-center m-t-24 text-white">
                  <div class="d-flex align-items-center">
              <span class="m-r-12 f-s-14 mat-subtitle-2 text-white d-flex align-items-center">
                <i-tabler name="eye" class="icon-18 m-r-4"></i-tabler>
                {{ 0 }}</span>
                    <span class="f-s-14 mat-subtitle-2 text-white d-flex align-items-center">
                      <i-tabler name="message-2" class="icon-18 m-r-4"></i-tabler>
                      {{ ghostPost.comments ? 10 : 0 }}</span>
                  </div>
                  <span class="m-l-auto f-s-14 mat-subtitle-2 text-white d-flex align-items-center">
              <i-tabler name="point" class="icon-14 m-r-4"></i-tabler>
                    {{ ghostPost.created_at | date:'dd/MM/yyyy, HH:mm' }}
            </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      } @else {
        <div class="col-sm-6 col-lg-4">
          <mat-card class="cardWithShadow card2 regular-card position-relative card-hover cursor-pointer"
                    (click)="selectBlog(ghostPost.slug)">
            <img mat-card-image ngSrc="{{ ghostPost.feature_image }}" alt="{{ ghostPost.feature_image_alt }}"
                 width="390" height="260" />
            <div class="card-overlay h-100 d-flex p-16 p-y-0 align-items-end justify-content-end">
              <!--<mat-chip class="f-s-12 m-y-16 f-w-600 bg-white">{{ ghostPost.primary_tag?.name ?? 'none' }}</mat-chip>-->
              <mat-chip class="f-s-12 f-w-600 m-y-16 bg-white">
                {{ insightsService.getReadingTimeText(ghostPost.reading_time) }}
              </mat-chip>
            </div>

            <mat-card-content class="p-y-24">
              <div class="user-category">
                <div>
                  <img ngSrc="{{ ghostPost.primary_author?.profile_image }}" class="rounded-circle"
                       width="40" height="40" alt="{{ ghostPost.primary_author?.name }}" />
                </div>
              </div>
              <mat-card-title class="mat-headline-6 m-t-15">{{ ghostPost.title }}</mat-card-title>
              <div class="d-flex align-items-center justify-content-center m-t-24">
                <div class="d-flex align-items-center">
                  <span class="f-s-14 mat-subtitle-2 d-flex align-items-center">
                    <i-tabler name="message-2" class="icon-18 m-r-4"></i-tabler>
                    {{ ghostPost.comments ? 10 : 0 }}
                  </span>
                </div>
                <span class="m-l-auto f-s-14 mat-subtitle-2 d-flex align-items-center">
            <i-tabler name="point" class="icon-14 m-r-4"></i-tabler>
                  {{ ghostPost.created_at | date:'dd/MM/yyyy, HH:mm' }}
          </span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      }
    }
  </div>

</div>
