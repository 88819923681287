import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatCard, MatCardContent, MatCardHeader, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import {IconsModule} from '../../ui/icons/icons.module';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ExcelExportService, GridComponent, GridModule, PdfExportService } from '@syncfusion/ej2-angular-grids';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ScreenSizeService } from '../../services/utility/screen-size.service';
import { DisclaimerComponent } from '../../common/components/disclaimer/disclaimer.component';
import { CardsComponent } from '../../common/components/cards/cards.component';
import { WmtNumericInputComponent } from '../../ui/wmt-numeric-input/wmt-numeric-input.component';
import { positiveNumberVal } from '../../common/validators/positive-number.validator';
import { FormValidationService } from '../../services/utility/form-validation.service';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { DepreciationResult, VucDataItem } from './vuc-data-item';
import { initializeDepreciationTable, results } from './results';
import { TaxRate } from '@wmt/shared';
import { getIV, updateInputValues } from './input-values';
import { format } from '../../common/utils/to-fixed.utils';
import { taxDepreciationStoreQuery } from '../../common/stores/tax-depreciation';
import { distinctUntilChanged } from 'rxjs/operators';
import { StoreService } from '../../services/api/store.service';

@Component({
  selector: 'wmt-vehicle-usage-cost',
  standalone: true,
  imports: [
    CardsComponent,
    DisclaimerComponent,
    GridModule,
    NgForOf,
    NgIf,
    NumericTextBoxModule,
    ReactiveFormsModule,
    RadioButtonModule,
    MatCard,
    MatCardContent,
    MatCardHeader,
    MatCardSubtitle,
    MatCardTitle,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    IconsModule,
    MatRadioButton,
    MatRadioGroup,
    WmtNumericInputComponent,
    MatError,
    MatOption,
    MatSelect,
  ],
  providers: [PdfExportService, ExcelExportService],
  templateUrl: './vehicle-usage-cost.component.html',
  styleUrl: './vehicle-usage-cost.component.scss',
})
export class VehicleUsageCostComponent implements OnInit, OnDestroy {
  public isMobile = true;
  private subscriptions = new Subscription();
  vehicleUsageCostForm!: FormGroup;
  lineItemsWidth = '154';
  valueWidth = '195';
  isFrozenColumn = false;
  public data: VucDataItem[] = [];
  purchaseMonths: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December',
  ];

  @ViewChild('grid') public grid?: GridComponent;

  constructor(
    private screenService: ScreenSizeService,
    public fv: FormValidationService,
    private store: StoreService,
  ) {
  }

  ngOnInit(): void {
    this.listenToScreenSizeChanges();
    this.setupForm();
    this.fv.setCurrentForm(this.vehicleUsageCostForm);
    this.data = results;

    this.listenToVehicleTypeChanges();
    this.listenToFormChanges();
  }

  listenToScreenSizeChanges() {
    this.subscriptions.add(
      this.screenService.isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.adjustUIForScreenSize(isMobile);
      }),
    );
  }

  adjustUIForScreenSize(isMobile: boolean) {
    if (isMobile) {
      this.lineItemsWidth = '140';
      this.valueWidth = '130';
      this.isFrozenColumn = true;
    }
    else {
      this.lineItemsWidth = '400';
      this.isFrozenColumn = false;
    }
  }

  setupForm() {
    this.vehicleUsageCostForm = new FormGroup({
      vehicleListPrice: new FormControl(null, [Validators.required, positiveNumberVal()]),
      vatPaid: new FormControl(null, [Validators.required, positiveNumberVal()]),
      isElectricCar: new FormControl('no', [Validators.required]),
      vehicleType: new FormControl('CAR', [Validators.required]),
      purchaseMonth: new FormControl('January', [Validators.required]),
      grossSalaryPrivateUseAddition: new FormControl({
        value: null,
        disabled: true,
      }, [Validators.required, positiveNumberVal()]),
      vatChargedOnPrivateUse: new FormControl({
        value: null,
        disabled: true,
      }, [Validators.required, positiveNumberVal()]),
      salvageValue: new FormControl({
        value: null,
        disabled: true,
      }, [Validators.required, positiveNumberVal()]),
    });
  }

  listenToFormChanges() {
    const formChanges = this.vehicleUsageCostForm.valueChanges.pipe(
      distinctUntilChanged(),
    );
    this.subscriptions.add(formChanges.subscribe(() => {
      updateInputValues(this.vehicleUsageCostForm);
      this.calculate();
    }));
  }

  listenToVehicleTypeChanges() {
    const vehicleTypeChanges = this.vehicleUsageCostForm.get('vehicleType')?.valueChanges.pipe(
      distinctUntilChanged(),
    );
    this.subscriptions.add(vehicleTypeChanges?.subscribe((newVehicleType) => {
      this.data = initializeDepreciationTable(newVehicleType);
    }));
  }

  calculate() {
    const bijtellingTaxes: TaxRate[] = this.store.getTaxRatesByTaxName('Bijtelling');
    const input = getIV();
    let totalBijtelling = 0;
    let totalVatChargedOnPrivateUse = 0;
    let salvageValue = 0.00;

    if (input.vehicleListPrice > 0) {
      const depreciableCost = input.vehicleListPrice + (input.vatPaid || 0);

      if (input.vehicleType === 'CAR' || input.vehicleType === 'DELIVERY VAN') {
        const bijtellingAuto: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABV']);

        if (input.isElectricCar) {
          const bijtellingElectric: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABE']);
          let val1 = 0;
          let val2 = 0;

          // First tier calculation
          if (bijtellingElectric[0].minTier === 0 && bijtellingElectric[0].maxTier && bijtellingElectric[0].taxRate) {
            val1 = Math.max(input.vehicleListPrice, bijtellingElectric[0].maxTier) * bijtellingElectric[0].taxRate;
          }

          // Second tier calculation
          if (bijtellingElectric[1].minTier && bijtellingElectric[1].taxRate) {
            val2 = Math.max(input.vehicleListPrice - bijtellingElectric[1].minTier, 0) * bijtellingElectric[1].taxRate;
          }

          totalBijtelling = val1 + val2;
        }
        else {
          const bijtellingOther: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['ABO']);

          if (bijtellingOther[0].taxRate) {
            totalBijtelling = input.vehicleListPrice * bijtellingOther[0].taxRate;
          }
        }

        if (bijtellingAuto[0].taxRate) {
          totalVatChargedOnPrivateUse = input.vehicleListPrice * bijtellingAuto[0].taxRate
          * this.getMonthNumber(input.purchaseMonth) / 12;
        }
      }
      else if (input.vehicleType === 'BIKE') {
        const bijtellingBike: TaxRate[] = this.store.getTaxRatesByCategoryCodes(bijtellingTaxes, ['FIB']);

        if (bijtellingBike[0].taxRate) {
          totalBijtelling = input.vehicleListPrice * bijtellingBike[0].taxRate;
        }

        totalVatChargedOnPrivateUse = 0;
      }

      // Depreciation calculation
      const depreciationResult = this.calculateDepreciation(depreciableCost, input.vehicleType);
      this.data = depreciationResult.results;
      salvageValue = depreciationResult.salvageValue;
    }

    this.vehicleUsageCostForm.get('grossSalaryPrivateUseAddition')?.setValue(
      format(totalBijtelling), { emitEvent: false },
    );
    this.vehicleUsageCostForm.get('vatChargedOnPrivateUse')?.setValue(
      format(totalVatChargedOnPrivateUse), { emitEvent: false });
    this.vehicleUsageCostForm.get('salvageValue')?.setValue(
      format(salvageValue), { emitEvent: false },
    );
  }

  calculateDepreciation(depreciableCost: number, vehicleType: string): DepreciationResult {
    const taxDepreciations = taxDepreciationStoreQuery.getTaxDepreciationsByVehicleType(vehicleType);
    const results: VucDataItem[] = [];
    let salvageValue = 0.00;

    if (['CAR', 'DELIVERY VAN'].includes(vehicleType)) {
      // Only consider entries where a multiple of 12 fits within the from-to range
      for (let year = 1; year <= 12; year++) {
        const monthIndex = year * 12; // Calculating month as a multiple of 12
        const applicableDepreciation = taxDepreciations.find(dep =>
          dep.periodType === 'months'
          && dep.from !== undefined
          && dep.to !== undefined
          && monthIndex >= dep.from
          && monthIndex <= (dep.to ?? 0), // Ensures depreciation periods are bounded
        );

        if (applicableDepreciation) {
          const rate = applicableDepreciation.rate ?? 0;
          const unitIncrement = applicableDepreciation.unitIncrement ?? 0;
          const excessMonths = monthIndex - (applicableDepreciation.to ?? 0);
          const value = depreciableCost * rate + Math.max(excessMonths, 0) * unitIncrement * depreciableCost;
          results.push({ id: year, year: year, value: value.toFixed(2) });
        }
      }

      salvageValue = vehicleType === 'CAR' ? depreciableCost - parseFloat(results[results.length - 1].value) : 0;
    }
    else if (vehicleType === 'BIKE') {
      // Process for bikes, using 'from' as the year directly
      taxDepreciations.filter(dep => dep.periodType === 'years' && (dep.from || 0) > 0)
        .forEach((dep) => {
          const rate = dep.rate ?? 0;
          const year = dep.from || 0; // Use 'from' field as the year for bikes
          const value = depreciableCost * rate;
          results.push({ id: year, year: year, value: value.toFixed(2) });
        });

      salvageValue = 0;
      results.sort((a, b) => a.year - b.year);
    }

    return { results, salvageValue };
  }

  getMonthNumber(monthName: string): number {
    return this.purchaseMonths.indexOf(monthName) + 1;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
