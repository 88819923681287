import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[wmtPositiveInput]',
  standalone: true,
})
export class PositiveInputDirective {
  @Input() allowDecimals = true;
  private previousValue = '';

  constructor(private el: ElementRef<HTMLInputElement>) {
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const allowedKeys = this.allowDecimals
      ? [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',',
          'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'End', 'Home',
        ]
      : [
          '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
          'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight', 'End', 'Home',
        ];

    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }

    // Prevent more than one '.' or ','
    if (this.allowDecimals
      && (event.key === '.' || event.key === ',')
      && this.el.nativeElement.value.includes(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event']) onInputChange(event: Event) {
    let value = this.el.nativeElement.value;

    // Remove all commas and decimal point if decimals are not allowed
    if (!this.allowDecimals) {
      value = value.replace(/[.,]/g, '');
    }

    // Replace commas with periods and remove all non-numeric characters except for the decimal point
    value = value.replace(/,/g, '.')
      .replace(this.allowDecimals ? /[^\d.]/g : /\D/g, '');

    if (this.allowDecimals) {
      // Add leading zero if input starts with a decimal point
      if (value.startsWith('.')) {
        value = '0' + value;
      }

      // Remove all decimal points after the first one
      const decimalSplit = value.split('.');
      if (decimalSplit.length > 2) {
        value = decimalSplit.shift() + '.' + decimalSplit.join('');
      }

      // Limit to two decimal places
      if (decimalSplit.length === 2) {
        value = decimalSplit[0] + '.' + decimalSplit[1].slice(0, 2);
      }

      // Check against the maximum value limit
      const numericValue = parseFloat(value);
      if (numericValue > 5000000000000) { // 5 trillion
        value = this.previousValue;
      }
      else {
        this.previousValue = value;
      }
    }

    this.el.nativeElement.value = value;
  }

  @HostListener('blur') onBlur() {
    // On blur, round the value to two decimal places and replace periods with commas if necessary
    let value = this.el.nativeElement.value;

    if (!value || !this.allowDecimals) {
      return;
    }

    if (value) {
      value = parseFloat(value).toFixed(2);
      this.el.nativeElement.value = value;
      this.el.nativeElement.dispatchEvent(new Event('input'));
    }
  }
}
