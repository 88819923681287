import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatAnchor } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    MatAnchor,
  ],
  exports: [
    MatButtonModule,
    MatIconModule,
    MatAnchor,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
  ],
})
export class UiModule {
}
