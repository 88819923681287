import { Component, Input, OnInit } from '@angular/core';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatStepLabel, MatStepper, MatStepperNext } from '@angular/material/stepper';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { phoneNumberValidator } from '../../../validators/phone-number.validator';
import { MatIcon } from '@angular/material/icon';
import { NgForOf, NgIf } from '@angular/common';
import { PhoneInputDirective } from '../../../directives/phone-input.directive';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption, MatSelect } from '@angular/material/select';
import { kvkValidator } from '../../../validators/kvk-number.validator';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { atLeastOneLanguageValidator } from '../../../validators/at-least-one-lang.validator';
import { NlZipCodeDirective } from '../../../directives/nl-zip-code.directive';
import { KvkNumberDirective } from '../../../directives/kvk-number.directive';
import { states } from '@wmt/shared';

@Component({
  selector: 'wmt-partner-form-company-profile',
  standalone: true,
  imports: [
    MatLabel,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatButton,
    MatStepperNext,
    MatStepLabel,
    MatIcon,
    MatPrefix,
    NgForOf,
    NgIf,
    IconsModule,
    PhoneInputDirective,
    MatCheckbox,
    MatSelect,
    MatOption,
    MatError,
    NlZipCodeDirective,
    KvkNumberDirective,
  ],
  providers: [],
  templateUrl: './company-profile.component.html',
  styleUrl: './company-profile.component.scss',
})
export class CompanyProfileComponent implements OnInit {
  @Input() stepper!: MatStepper;
  companyProfileForm!: FormGroup;
  pledgeText: string;
  consentText: string;
  states: string[] = states;

  constructor(
    private fb: FormBuilder,
    public fv: FormValidationService,
  ) {
    this.pledgeText = 'All the data provided by me are correct and true to the best of my knowledge';
    this.consentText = 'I allow executives from Taxelos to communicate with me '
    + 'via the communication channels provided above';
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.companyProfileForm = this.fb.nonNullable.group({
      companyName: ['', { validators: [Validators.required] }],
      companyKvk: ['', { validators: [Validators.required, kvkValidator()] }],
      speaksEnglish: [false],
      speaksDutch: [false],
      speaksFrench: [false],
      speaksGerman: [false],
      companyDisplayEmail: ['', { validators: [Validators.email] }],
      companyDisplayPhone: ['', { validators: [phoneNumberValidator()] }],
      companyAddress: ['', { validators: [] }],
      companyHouseNumber: ['', { validators: [] }],
      companyCity: ['', { validators: [] }],
      companyState: ['', { validators: [] }],
      companyZip: ['', { validators: [] }],
      companyPrimaryContactFullName: ['', { validators: [Validators.required] }],
      companyPrimaryContactEmail: ['', { validators: [Validators.required, Validators.email] }],
      companySecondaryContactFullName: ['', { validators: [] }],
      companySecondaryContactEmail: ['', { validators: [Validators.email] }],
      companyPledge: [false, { validators: [Validators.requiredTrue] }],
      companyConsent: [false, { validators: [Validators.requiredTrue] }],
    }, { validators: atLeastOneLanguageValidator() });
  }

  get isComplete(): boolean {
    return this.companyProfileForm.valid;
  }
}
