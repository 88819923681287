import { Component } from '@angular/core';
import { NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';
import { UiModule } from '../../../ui/ui.module';
import { CookieConsentService } from '../../../services/utility/cookie-consent.service';

@Component({
  selector: 'wmt-cookie-consent',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    UiModule,
  ],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss',
})
export class CookieConsentComponent {
  constructor(
    private cookieConsentService: CookieConsentService,
  ) {
    this.cookieConsentService.checkAndDisplayConsent();
  }
}
