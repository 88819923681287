<footer class="wmt-footer wmt-full-width" style="height: 100%">
  <div class="wmt-footer-content container full-width p-xs-6 p-24">
    <a routerLink="/about-us" class="wmt-daedal-logo">
      <img [src]="isHovering ? daedalLogo : daedalLogoMono" alt="daedal-logo" width="156" height="50"
           (mouseover)="isHovering = true" (mouseout)="isHovering= false">
    </a>
    <div class="wmt-footer-links">
      <div class="wmt-link-column">
        @if (isWebNl) {
          <a routerLink="/tax-table">Latest Tax table</a>
        }
        <a routerLink="/contact-us">Contact us</a>
        <a routerLink="/about-us">About us</a>
      </div>
    </div>
  </div>
  <div class="wmt-footer-base container full-width p-xs-6 p-24 p-b-12">
    <span style="display: block">© Daedal Inc, Charlotte, NC, United States. All rights reserved</span>
    <div class="wmt-footer-policies">
      <a routerLink="/privacy-policy">Privacy Policy</a>
      <a routerLink="/cookie-policy">Cookie Policy</a>
      @if (consentSaved) {
        <a class="link" (click)="editCookieSetting()">Edit consent</a>
      }
      <a routerLink="/terms-and-conditions">Terms and Conditions</a>
    </div>
  </div>
</footer>
