import { Component, Inject, Input } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatCard, MatCardContent, MatCardImage, MatCardSubtitle, MatCardTitle } from '@angular/material/card';
import { IconsModule } from '../../../ui/icons/icons.module';
import { LIB_ENVIRONMENT } from '../../../../index';
import { LibEnvironment } from '../../../common/interfaces/lib-environment';

@Component({
  selector: 'wmt-feedback-card',
  standalone: true,
  imports: [
    MatButton,
    MatCard,
    MatCardContent,
    MatCardImage,
    MatCardSubtitle,
    MatCardTitle,
    IconsModule,
  ],
  templateUrl: './feedback-card.component.html',
  styleUrl: './feedback-card.component.scss',
})
export class FeedbackCardComponent {
  @Input() feedbackType!: 'consumer' | 'accountant';
  productFeedBackLink: string;
  accountantFeedbackLink: string;

  constructor(@Inject(LIB_ENVIRONMENT) private environment: LibEnvironment) {
    this.productFeedBackLink = environment.productFeedBackLink;
    this.accountantFeedbackLink = environment.accountantFeedbackLink;
  }

  get isConsumerFeedback() {
    return this.feedbackType === 'consumer';
  }

  get isAccountantFeedback() {
    return this.feedbackType === 'accountant';
  }
}
