<mat-toolbar class="topbar topbar-xl m-auto">
  <div class="container full-width p-xs-6">

    <div class="branding p-x-0">
      <wmt-logo></wmt-logo>
    </div>

    <span class="flex-1-auto"></span>

    <div class="d-none d-lg-flex">
      @if (isWebNl) {
        <button mat-button class="m-x-8 f-s-16 f-w-500" [routerLink]="['']">Home</button>

        <button mat-button class="m-x-8 f-s-16 f-w-500" [matMenuTriggerFor]="taxMenu" aria-label="Notifications">
          <div class="d-flex align-items-center">Calculate your Taxes
            <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
          </div>
        </button>
        <mat-menu #taxMenu="matMenu" class="cardWithShadow shadow-none b-1">
          <div class="col-sm-12 p-l-0 p-r-0">
            <div class="p-16 p-b-0">
              @for (app of taxApps; track app.id) {
                <div class="col-sm p-0 text-hover-primary">
                  <a [routerLink]="[app.link]" class="d-flex align-items-center text-decoration-none m-b-24">
                    <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                      <img [src]="app.img" width="25" alt="menu icon" />
                    </button>

                    <div class="m-l-16">
                      <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                        {{ app.title }}
                      </h5>
                      <span class="mat-body-1 f-s-12">{{ app.subtitle }}</span>
                    </div>
                  </a>
                </div>
              }
            </div>
          </div>
        </mat-menu>

        <a mat-button class="m-x-8 f-s-16 f-w-500 tx-360" [routerLink]="['taxelos-360']">Taxelos ΤΞ</a>
        <a mat-button class="m-x-8 f-s-16 f-w-500" [routerLink]="['tax-djinn']">Tax Djinn</a>
        <a mat-button class="m-x-8 f-s-16 f-w-500" [routerLink]="['insights']">Insights</a>
      }

      <button mat-button class="m-x-8 f-s-16 f-w-500" [matMenuTriggerFor]="helpMenu" aria-label="Notifications">
        <div class="d-flex align-items-center">Feedback and support
          <i-tabler name="chevron-down" class="icon-16 m-l-4"></i-tabler>
        </div>
      </button>
      <mat-menu #helpMenu="matMenu" class="cardWithShadow shadow-none b-1">
        <div class="col-sm-12 p-l-0 p-r-0">
          <div class="p-16 p-b-0">
            @for (item of helpMenuItems; track item.id) {
              <div class="col-sm p-0 text-hover-primary">
                <a href="{{item.link}}" target="_blank" class="d-flex align-items-center text-decoration-none m-b-24">
                  <button mat-mini-fab class="text-primary bg-light-primary shadow-none rounded">
                    <img [src]="item.img" width="25" alt="" />
                  </button>

                  <div class="m-l-16">
                    <h5 class="f-s-14 f-w-600 m-0 textprimary mat-body-1 hover-text">
                      {{ item.title }}
                    </h5>
                    <span class="mat-body-1 f-s-12">{{ item.subtitle }}</span>
                  </div>
                </a>
              </div>
            }
          </div>
        </div>
      </mat-menu>

    </div>

    <!-- Mobile Menu Toggle-->
    <button mat-icon-button (click)="onToggleSidenav()" class="d-flex d-lg-none">
      <i-tabler name="menu-2" class="icon-20 d-flex"></i-tabler>
    </button>
  </div>
</mat-toolbar>
