import { Component } from '@angular/core';
import { HeroAccountantComponent } from '../../common/components/hero-accountant/hero-accountant.component';
import { FeedbackCardComponent } from '../../common/components/feedback-card/feedback-card.component';
import { PartnerSliderComponent } from '../../common/components/partner-slider/partner-slider.component';
import { PartnerValueCardsComponent } from '../../common/components/partner-value-cards/partner-value-cards.component';
import { ExploreCardComponent } from '../../common/components/explore-card/explore-card.component';
import { ReleaseRoadmapComponent } from '../../common/components/release-roadmap/release-roadmap.component';

@Component({
  selector: 'wmt-taxelos-360',
  standalone: true,
  imports: [
    HeroAccountantComponent,
    FeedbackCardComponent,
    PartnerSliderComponent,
    PartnerValueCardsComponent,
    ExploreCardComponent,
    ReleaseRoadmapComponent,
  ],
  templateUrl: './taxelos-360.component.html',
  styleUrl: './taxelos-360.component.scss',
})
export class Taxelos360Component {
  partnersLength: number | null = null;

  onPartnersLength(length: number) {
    this.partnersLength = length;
  }

  get showSlider(): boolean {
    return this.partnersLength !== null && this.partnersLength >= 3;
  }
}
