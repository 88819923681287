<h2 class="text-center">Our release roadmap</h2>
<div class="row justify-content-center">
  <div class="col-12">
    <mat-card class="cardWithShadow card2 position-relative card-hover">
      <div class="card-overlay h-100 d-flex align-items-end justify-content-end">
      </div>

      <mat-card-content class="p-t-24" style="padding-bottom: 0">
        <!--<mat-card-title>Roadmap item 1</mat-card-title>-->
        <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 f-s-16 wmt-text-height">
          <img src="/assets/images/release-roadmap/taxelos-releases-2.png" alt="Taxelos releases" width="100%"
               height="auto">
        </mat-card-subtitle>
      </mat-card-content>
    </mat-card>
  </div>
</div>
