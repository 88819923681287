<div class="pageWrapper maxWidth">

  <div class="row">
    <div class="col-lg-12 col-sm-12">

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">
            A comparative overview of corporate tax structures in the Netherlands
          </mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            You can check here your tax obligation based on a given gross revenue/income. The table below shows your
            taxes due if you have a B.V, ZZP (including director's salary) or are salaried
          </mat-card-subtitle>
        </mat-card-header>
        @if (orgSetupComparisonForm.errors?.['totalIncomeError']) {
          <mat-error class="mat-subtitle-2 f-w-600 d-flex justify-content-center w-100 m-b-16">
            {{ orgSetupComparisonForm.errors?.['totalIncomeError'].message }}
          </mat-error>
        }

        <mat-card-content class="b-t-1">

          <form [formGroup]="orgSetupComparisonForm" class="form-horizontal">
            <div class="row">

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Total income/revenue:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="total-income" formControlName="totalIncome"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Director's salary:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="directors-salary" formControlName="directorsSalary"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Company expense incl 21% VAT:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="company-expenses" formControlName="companyExpenses"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="row">
                  <div class="col-sm-12 d-flex align-items-center">
                    <mat-label class="mat-subtitle-2 f-w-600 d-flex justify-content-between w-100 m-b-16">
                      Personal expense incl VAT:
                    </mat-label>
                  </div>
                  <div class="col-sm-12">
                    <wmt-numeric-input placeholder="0.00" id="personal-expenses" formControlName="personalExpenses"
                                       [iconName]="'currency-euro'"></wmt-numeric-input>
                  </div>
                </div>
              </div>

            </div>
          </form>

        </mat-card-content>
      </mat-card>

      <mat-card class="cardWithShadow theme-card">
        <mat-card-header class="justify-content-center">
          <mat-card-title class="m-b-0 f-s-24 text-center">
            Result based latest text norm - as of year {{ currentYear }}
          </mat-card-title>
          <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
            Result ...
          </mat-card-subtitle>
        </mat-card-header>

        <mat-card-content class="b-t-1">
          <ejs-grid #grid cssClass="wmt-grid" width="100%"
                    [dataSource]="this.data"
                    [selectionSettings]="selectionOptions">
            <e-columns>
              <e-column field="id" [isPrimaryKey]="true" [visible]="false"></e-column>
              <e-column field='lineItems' headerText='Line Items' [width]=lineItemsWidth
                        [isFrozen]=isFrozenColumn textAlign="Right"></e-column>
              <e-column field='zzps' headerText='ZZPs' width=195 textAlign="Right"></e-column>
              <e-column field='bvs' headerText='BVs' width=160 textAlign="Right"></e-column>
              <e-column field='salaried' headerText='Salaried' width=140 textAlign="Right"></e-column>
            </e-columns>
          </ejs-grid>
        </mat-card-content>
      </mat-card>

      <wmt-disclaimer width="1232px"
                      (exportPdfEvent)="exportPdf()"
                      (exportExcelEvent)="exportXlsx()"
                      (copyClipboardEvent)="copyClipboard()"
                      [pageName]="'tax-comparison'">
      </wmt-disclaimer>

    </div>
  </div>

</div>

<wmt-cards></wmt-cards>
