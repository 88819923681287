function e(e, r, n, t) {
  return new (n || (n = Promise))(function (o, a) {
    function i(e) {
      try {
        c(t.next(e));
      } catch (e) {
        a(e);
      }
    }
    function u(e) {
      try {
        c(t.throw(e));
      } catch (e) {
        a(e);
      }
    }
    function c(e) {
      var r;
      e.done ? o(e.value) : (r = e.value, r instanceof n ? r : new n(function (e) {
        e(r);
      })).then(i, u);
    }
    c((t = t.apply(e, r || [])).next());
  });
}
function r(e, r) {
  var n,
    t,
    o,
    a,
    i = {
      label: 0,
      sent: function () {
        if (1 & o[0]) throw o[1];
        return o[1];
      },
      trys: [],
      ops: []
    };
  return a = {
    next: u(0),
    throw: u(1),
    return: u(2)
  }, "function" == typeof Symbol && (a[Symbol.iterator] = function () {
    return this;
  }), a;
  function u(u) {
    return function (c) {
      return function (u) {
        if (n) throw new TypeError("Generator is already executing.");
        for (; a && (a = 0, u[0] && (i = 0)), i;) try {
          if (n = 1, t && (o = 2 & u[0] ? t.return : u[0] ? t.throw || ((o = t.return) && o.call(t), 0) : t.next) && !(o = o.call(t, u[1])).done) return o;
          switch (t = 0, o && (u = [2 & u[0], o.value]), u[0]) {
            case 0:
            case 1:
              o = u;
              break;
            case 4:
              return i.label++, {
                value: u[1],
                done: !1
              };
            case 5:
              i.label++, t = u[1], u = [0];
              continue;
            case 7:
              u = i.ops.pop(), i.trys.pop();
              continue;
            default:
              if (!(o = i.trys, (o = o.length > 0 && o[o.length - 1]) || 6 !== u[0] && 2 !== u[0])) {
                i = 0;
                continue;
              }
              if (3 === u[0] && (!o || u[1] > o[0] && u[1] < o[3])) {
                i.label = u[1];
                break;
              }
              if (6 === u[0] && i.label < o[1]) {
                i.label = o[1], o = u;
                break;
              }
              if (o && i.label < o[2]) {
                i.label = o[2], i.ops.push(u);
                break;
              }
              o[2] && i.ops.pop(), i.trys.pop();
              continue;
          }
          u = r.call(e, i);
        } catch (e) {
          u = [6, e], t = 0;
        } finally {
          n = o = 0;
        }
        if (5 & u[0]) throw u[1];
        return {
          value: u[0] ? u[1] : void 0,
          done: !0
        };
      }([u, c]);
    };
  }
}
"function" == typeof SuppressedError && SuppressedError;
var n = {
  exclude: []
};
function t(e, r) {
  if (!["exclude", "permissions_to_check", "retries", "timeout"].includes(e)) throw new Error("Unknown option " + e);
  if (["exclude", "permissions_to_check"].includes(e) && (!Array.isArray(r) || !r.every(function (e) {
    return "string" == typeof e;
  }))) throw new Error("The value of the exclude and permissions_to_check must be an array of strings");
  if (["retries", "timeout"].includes(e) && "number" != typeof r) throw new Error("The value of retries must be a number");
  n[e] = r;
}
var o = {},
  a = {
    timeout: "true"
  },
  i = function (e, r) {
    "undefined" != typeof window && (o[e] = r);
  },
  u = function () {
    return Object.fromEntries(Object.entries(o).filter(function (e) {
      var r,
        t = e[0];
      return !(null === (r = null == n ? void 0 : n.exclude) || void 0 === r ? void 0 : r.includes(t));
    }).map(function (e) {
      return [e[0], (0, e[1])()];
    }));
  };
function c(e) {
  return e ^= e >>> 16, e = Math.imul(e, 2246822507), e ^= e >>> 13, e = Math.imul(e, 3266489909), (e ^= e >>> 16) >>> 0;
}
var s = new Uint32Array([597399067, 2869860233, 951274213, 2716044179]);
function l(e, r) {
  return e << r | e >>> 32 - r;
}
function f(e, r) {
  var n;
  if (void 0 === r && (r = 0), r = r ? 0 | r : 0, "string" == typeof e && (n = e, e = new TextEncoder().encode(n).buffer), !(e instanceof ArrayBuffer)) throw new TypeError("Expected key to be ArrayBuffer or string");
  var t = new Uint32Array([r, r, r, r]);
  !function (e, r) {
    for (var n = e.byteLength / 16 | 0, t = new Uint32Array(e, 0, 4 * n), o = 0; o < n; o++) {
      var a = t.subarray(4 * o, 4 * (o + 1));
      a[0] = Math.imul(a[0], s[0]), a[0] = l(a[0], 15), a[0] = Math.imul(a[0], s[1]), r[0] = r[0] ^ a[0], r[0] = l(r[0], 19), r[0] = r[0] + r[1], r[0] = Math.imul(r[0], 5) + 1444728091, a[1] = Math.imul(a[1], s[1]), a[1] = l(a[1], 16), a[1] = Math.imul(a[1], s[2]), r[1] = r[1] ^ a[1], r[1] = l(r[1], 17), r[1] = r[1] + r[2], r[1] = Math.imul(r[1], 5) + 197830471, a[2] = Math.imul(a[2], s[2]), a[2] = l(a[2], 17), a[2] = Math.imul(a[2], s[3]), r[2] = r[2] ^ a[2], r[2] = l(r[2], 15), r[2] = r[2] + r[3], r[2] = Math.imul(r[2], 5) + 2530024501, a[3] = Math.imul(a[3], s[3]), a[3] = l(a[3], 18), a[3] = Math.imul(a[3], s[0]), r[3] = r[3] ^ a[3], r[3] = l(r[3], 13), r[3] = r[3] + r[0], r[3] = Math.imul(r[3], 5) + 850148119;
    }
  }(e, t), function (e, r) {
    var n = e.byteLength / 16 | 0,
      t = e.byteLength % 16,
      o = new Uint32Array(4),
      a = new Uint8Array(e, 16 * n, t);
    switch (t) {
      case 15:
        o[3] = o[3] ^ a[14] << 16;
      case 14:
        o[3] = o[3] ^ a[13] << 8;
      case 13:
        o[3] = o[3] ^ a[12] << 0, o[3] = Math.imul(o[3], s[3]), o[3] = l(o[3], 18), o[3] = Math.imul(o[3], s[0]), r[3] = r[3] ^ o[3];
      case 12:
        o[2] = o[2] ^ a[11] << 24;
      case 11:
        o[2] = o[2] ^ a[10] << 16;
      case 10:
        o[2] = o[2] ^ a[9] << 8;
      case 9:
        o[2] = o[2] ^ a[8] << 0, o[2] = Math.imul(o[2], s[2]), o[2] = l(o[2], 17), o[2] = Math.imul(o[2], s[3]), r[2] = r[2] ^ o[2];
      case 8:
        o[1] = o[1] ^ a[7] << 24;
      case 7:
        o[1] = o[1] ^ a[6] << 16;
      case 6:
        o[1] = o[1] ^ a[5] << 8;
      case 5:
        o[1] = o[1] ^ a[4] << 0, o[1] = Math.imul(o[1], s[1]), o[1] = l(o[1], 16), o[1] = Math.imul(o[1], s[2]), r[1] = r[1] ^ o[1];
      case 4:
        o[0] = o[0] ^ a[3] << 24;
      case 3:
        o[0] = o[0] ^ a[2] << 16;
      case 2:
        o[0] = o[0] ^ a[1] << 8;
      case 1:
        o[0] = o[0] ^ a[0] << 0, o[0] = Math.imul(o[0], s[0]), o[0] = l(o[0], 15), o[0] = Math.imul(o[0], s[1]), r[0] = r[0] ^ o[0];
    }
  }(e, t), function (e, r) {
    r[0] = r[0] ^ e.byteLength, r[1] = r[1] ^ e.byteLength, r[2] = r[2] ^ e.byteLength, r[3] = r[3] ^ e.byteLength, r[0] = r[0] + r[1] | 0, r[0] = r[0] + r[2] | 0, r[0] = r[0] + r[3] | 0, r[1] = r[1] + r[0] | 0, r[2] = r[2] + r[0] | 0, r[3] = r[3] + r[0] | 0, r[0] = c(r[0]), r[1] = c(r[1]), r[2] = c(r[2]), r[3] = c(r[3]), r[0] = r[0] + r[1] | 0, r[0] = r[0] + r[2] | 0, r[0] = r[0] + r[3] | 0, r[1] = r[1] + r[0] | 0, r[2] = r[2] + r[0] | 0, r[3] = r[3] + r[0] | 0;
  }(e, t);
  var o = new Uint8Array(t.buffer);
  return Array.from(o).map(function (e) {
    return e.toString(16).padStart(2, "0");
  }).join("");
}
function d(e, r) {
  return new Promise(function (n) {
    setTimeout(function () {
      return n(r);
    }, e);
  });
}
function h(e, r, n) {
  return Promise.all(e.map(function (e) {
    var t = performance.now();
    return Promise.race([e.then(function (e) {
      return {
        value: e,
        elapsed: performance.now() - t
      };
    }), d(r, n).then(function (e) {
      return {
        value: e,
        elapsed: performance.now() - t
      };
    })]);
  }));
}
function m(e, r, n) {
  return Promise.all(e.map(function (e) {
    return Promise.race([e, d(r, n)]);
  }));
}
function v() {
  return e(this, void 0, void 0, function () {
    var e, t, o, i, c;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return r.trys.push([0, 2,, 3]), e = u(), t = Object.keys(e), [4, m(Object.values(e), (null == n ? void 0 : n.timeout) || 1e3, a)];
        case 1:
          return o = r.sent(), i = o.filter(function (e) {
            return void 0 !== e;
          }), c = {}, i.forEach(function (e, r) {
            c[t[r]] = e;
          }), [2, p(c, n.exclude || [])];
        case 2:
          throw r.sent();
        case 3:
          return [2];
      }
    });
  });
}
function p(e, r) {
  var n = {},
    t = function (t) {
      if (e.hasOwnProperty(t)) {
        var o = e[t];
        if ("object" != typeof o || Array.isArray(o)) r.includes(t) || (n[t] = o);else {
          var a = p(o, r.map(function (e) {
            return e.startsWith(t + ".") ? e.slice(t.length + 1) : e;
          }));
          Object.keys(a).length > 0 && (n[t] = a);
        }
      }
    };
  for (var o in e) t(o);
  return n;
}
function g(n) {
  return e(this, void 0, void 0, function () {
    var e, t;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return r.trys.push([0, 2,, 3]), [4, v()];
        case 1:
          return e = r.sent(), t = f(JSON.stringify(e)), n ? [2, {
            hash: t.toString(),
            data: e
          }] : [2, t.toString()];
        case 2:
          throw r.sent();
        case 3:
          return [2];
      }
    });
  });
}
function w() {
  return e(this, void 0, void 0, function () {
    var e, t, o, i;
    return r(this, function (r) {
      switch (r.label) {
        case 0:
          return r.trys.push([0, 2,, 3]), e = u(), t = Object.keys(e), [4, h(Object.values(e), (null == n ? void 0 : n.timeout) || 1e3, a)];
        case 1:
          return o = r.sent(), i = {
            elapsed: {}
          }, o.forEach(function (e, r) {
            i[t[r]] = e.value, i.elapsed[t[r]] = e.elapsed;
          }), [2, i];
        case 2:
          throw r.sent();
        case 3:
          return [2];
      }
    });
  });
}
function y(e) {
  for (var r = 0, n = 0; n < e.length; ++n) r += Math.abs(e[n]);
  return r;
}
function b(e, r, n) {
  for (var t = [], o = 0; o < e[0].data.length; o++) {
    for (var a = [], i = 0; i < e.length; i++) a.push(e[i].data[o]);
    t.push(S(a));
  }
  var u = new Uint8ClampedArray(t);
  return new ImageData(u, r, n);
}
function S(e) {
  if (0 === e.length) return 0;
  for (var r = {}, n = 0, t = e; n < t.length; n++) {
    r[a = t[n]] = (r[a] || 0) + 1;
  }
  var o = e[0];
  for (var a in r) r[a] > r[o] && (o = parseInt(a, 10));
  return o;
}
function M() {
  if ("undefined" == typeof navigator) return {
    name: "unknown",
    version: "unknown"
  };
  for (var e = navigator.userAgent, r = {
      Edg: "Edge",
      OPR: "Opera"
    }, n = 0, t = [/(?<name>Edge|Edg)\/(?<version>\d+(?:\.\d+)?)/, /(?<name>(?:Chrome|Chromium|OPR|Opera|Vivaldi|Brave))\/(?<version>\d+(?:\.\d+)?)/, /(?<name>(?:Firefox|Waterfox|Iceweasel|IceCat))\/(?<version>\d+(?:\.\d+)?)/, /(?<name>Safari)\/(?<version>\d+(?:\.\d+)?)/, /(?<name>MSIE|Trident|IEMobile).+?(?<version>\d+(?:\.\d+)?)/, /(?<name>[A-Za-z]+)\/(?<version>\d+(?:\.\d+)?)/, /(?<name>SamsungBrowser)\/(?<version>\d+(?:\.\d+)?)/]; n < t.length; n++) {
    var o = t[n],
      a = e.match(o);
    if (a && a.groups) return {
      name: r[a.groups.name] || a.groups.name,
      version: a.groups.version
    };
  }
  return {
    name: "unknown",
    version: "unknown"
  };
}
i("audio", function () {
  return e(this, void 0, void 0, function () {
    return r(this, function (e) {
      return [2, new Promise(function (e, r) {
        try {
          var n = new (window.OfflineAudioContext || window.webkitOfflineAudioContext)(1, 5e3, 44100),
            t = n.createBufferSource(),
            o = n.createOscillator();
          o.frequency.value = 1e3;
          var a,
            i = n.createDynamicsCompressor();
          i.threshold.value = -50, i.knee.value = 40, i.ratio.value = 12, i.attack.value = 0, i.release.value = .2, o.connect(i), i.connect(n.destination), o.start(), n.oncomplete = function (r) {
            a = r.renderedBuffer.getChannelData(0), e({
              sampleHash: y(a),
              oscillator: o.type,
              maxChannels: n.destination.maxChannelCount,
              channelCountMode: t.channelCountMode
            });
          }, n.startRendering();
        } catch (e) {
          console.error("Error creating audio fingerprint:", e), r(e);
        }
      })];
    });
  });
});
var E = "SamsungBrowser" !== M().name ? 1 : 3,
  P = 280,
  A = 20;
"Firefox" != M().name && i("canvas", function () {
  return document.createElement("canvas").getContext("2d"), new Promise(function (e) {
    var r = Array.from({
      length: E
    }, function () {
      return function () {
        var e = document.createElement("canvas"),
          r = e.getContext("2d");
        if (!r) return new ImageData(1, 1);
        e.width = P, e.height = A;
        var n = r.createLinearGradient(0, 0, e.width, e.height);
        n.addColorStop(0, "red"), n.addColorStop(1 / 6, "orange"), n.addColorStop(2 / 6, "yellow"), n.addColorStop(.5, "green"), n.addColorStop(4 / 6, "blue"), n.addColorStop(5 / 6, "indigo"), n.addColorStop(1, "violet"), r.fillStyle = n, r.fillRect(0, 0, e.width, e.height);
        var t = "Random Text WMwmil10Oo";
        r.font = "23.123px Arial", r.fillStyle = "black", r.fillText(t, -5, 15), r.fillStyle = "rgba(0, 0, 255, 0.5)", r.fillText(t, -3.3, 17.7), r.beginPath(), r.moveTo(0, 0), r.lineTo(2 * e.width / 7, e.height), r.strokeStyle = "white", r.lineWidth = 2, r.stroke();
        var o = r.getImageData(0, 0, e.width, e.height);
        return o;
      }();
    });
    e({
      commonImageDataHash: f(b(r, P, A).data.toString()).toString()
    });
  });
});
var C,
  x = ["Arial", "Arial Black", "Arial Narrow", "Arial Rounded MT", "Arimo", "Archivo", "Barlow", "Bebas Neue", "Bitter", "Bookman", "Calibri", "Cabin", "Candara", "Century", "Century Gothic", "Comic Sans MS", "Constantia", "Courier", "Courier New", "Crimson Text", "DM Mono", "DM Sans", "DM Serif Display", "DM Serif Text", "Dosis", "Droid Sans", "Exo", "Fira Code", "Fira Sans", "Franklin Gothic Medium", "Garamond", "Geneva", "Georgia", "Gill Sans", "Helvetica", "Impact", "Inconsolata", "Indie Flower", "Inter", "Josefin Sans", "Karla", "Lato", "Lexend", "Lucida Bright", "Lucida Console", "Lucida Sans Unicode", "Manrope", "Merriweather", "Merriweather Sans", "Montserrat", "Myriad", "Noto Sans", "Nunito", "Nunito Sans", "Open Sans", "Optima", "Orbitron", "Oswald", "Pacifico", "Palatino", "Perpetua", "PT Sans", "PT Serif", "Poppins", "Prompt", "Public Sans", "Quicksand", "Rajdhani", "Recursive", "Roboto", "Roboto Condensed", "Rockwell", "Rubik", "Segoe Print", "Segoe Script", "Segoe UI", "Sora", "Source Sans Pro", "Space Mono", "Tahoma", "Taviraj", "Times", "Times New Roman", "Titillium Web", "Trebuchet MS", "Ubuntu", "Varela Round", "Verdana", "Work Sans"],
  T = ["monospace", "sans-serif", "serif"];
function k(e, r) {
  if (!e) throw new Error("Canvas context not supported");
  return e.font, e.font = "72px ".concat(r), e.measureText("WwMmLli0Oo").width;
}
function R() {
  var e,
    r,
    n,
    t,
    o = document.createElement("canvas"),
    a = null !== (e = o.getContext("webgl")) && void 0 !== e ? e : o.getContext("experimental-webgl");
  if (a && "getParameter" in a) {
    try {
      (r = a.getExtension("WEBGL_debug_renderer_info")) && (n = (a.getParameter(r.UNMASKED_VENDOR_WEBGL) || "").toString(), t = (a.getParameter(r.UNMASKED_RENDERER_WEBGL) || "").toString());
    } catch (e) {}
    return {
      vendor: (a.getParameter(a.VENDOR) || "").toString(),
      vendorUnmasked: n,
      renderer: (a.getParameter(a.RENDERER) || "").toString(),
      rendererUnmasked: t,
      version: (a.getParameter(a.VERSION) || "").toString(),
      shadingLanguageVersion: (a.getParameter(a.SHADING_LANGUAGE_VERSION) || "").toString()
    };
  }
  return "undefined";
}
function I() {
  var e = new Float32Array(1),
    r = new Uint8Array(e.buffer);
  return e[0] = 1 / 0, e[0] = e[0] - e[0], r[3];
}
function O(e, r) {
  var n = {};
  return r.forEach(function (r) {
    var t = function (e) {
      if (0 === e.length) return null;
      var r = {};
      e.forEach(function (e) {
        var n = String(e);
        r[n] = (r[n] || 0) + 1;
      });
      var n = e[0],
        t = 1;
      return Object.keys(r).forEach(function (e) {
        r[e] > t && (n = e, t = r[e]);
      }), n;
    }(e.map(function (e) {
      return r in e ? e[r] : void 0;
    }).filter(function (e) {
      return void 0 !== e;
    }));
    t && (n[r] = t);
  }), n;
}
function L() {
  var e = [],
    r = {
      "prefers-contrast": ["high", "more", "low", "less", "forced", "no-preference"],
      "any-hover": ["hover", "none"],
      "any-pointer": ["none", "coarse", "fine"],
      pointer: ["none", "coarse", "fine"],
      hover: ["hover", "none"],
      update: ["fast", "slow"],
      "inverted-colors": ["inverted", "none"],
      "prefers-reduced-motion": ["reduce", "no-preference"],
      "prefers-reduced-transparency": ["reduce", "no-preference"],
      scripting: ["none", "initial-only", "enabled"],
      "forced-colors": ["active", "none"]
    };
  return Object.keys(r).forEach(function (n) {
    r[n].forEach(function (r) {
      matchMedia("(".concat(n, ": ").concat(r, ")")).matches && e.push("".concat(n, ": ").concat(r));
    });
  }), e;
}
function _() {
  if ("https:" === window.location.protocol && "function" == typeof window.ApplePaySession) try {
    for (var e = window.ApplePaySession.supportsVersion, r = 15; r > 0; r--) if (e(r)) return r;
  } catch (e) {
    return 0;
  }
  return 0;
}
"Firefox" != M().name && i("fonts", function () {
  var n = this;
  return new Promise(function (t, o) {
    try {
      !function (n) {
        var t;
        e(this, void 0, void 0, function () {
          var e, o, a;
          return r(this, function (r) {
            switch (r.label) {
              case 0:
                return document.body ? [3, 2] : [4, (i = 50, new Promise(function (e) {
                  return setTimeout(e, i, u);
                }))];
              case 1:
                return r.sent(), [3, 0];
              case 2:
                if ((e = document.createElement("iframe")).setAttribute("frameBorder", "0"), (o = e.style).setProperty("position", "fixed"), o.setProperty("display", "block", "important"), o.setProperty("visibility", "visible"), o.setProperty("border", "0"), o.setProperty("opacity", "0"), e.src = "about:blank", document.body.appendChild(e), !(a = e.contentDocument || (null === (t = e.contentWindow) || void 0 === t ? void 0 : t.document))) throw new Error("Iframe document is not accessible");
                return n({
                  iframe: a
                }), setTimeout(function () {
                  document.body.removeChild(e);
                }, 0), [2];
            }
            var i, u;
          });
        });
      }(function (o) {
        var a = o.iframe;
        return e(n, void 0, void 0, function () {
          var e, n, o, i;
          return r(this, function (r) {
            return "Hello, world!", e = a.createElement("canvas"), n = e.getContext("2d"), o = T.map(function (e) {
              return k(n, e);
            }), i = {}, x.forEach(function (e) {
              var r = k(n, e);
              o.includes(r) || (i[e] = r);
            }), t(i), [2];
          });
        });
      });
    } catch (e) {
      o({
        error: "unsupported"
      });
    }
  });
}), i("hardware", function () {
  return new Promise(function (e, r) {
    var n = void 0 !== navigator.deviceMemory ? navigator.deviceMemory : 0,
      t = window.performance && window.performance.memory ? window.performance.memory : 0;
    e({
      videocard: R(),
      architecture: I(),
      deviceMemory: n.toString() || "undefined",
      jsHeapSizeLimit: t.jsHeapSizeLimit || 0
    });
  });
}), i("locales", function () {
  return new Promise(function (e) {
    e({
      languages: navigator.language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  });
}), i("permissions", function () {
  return e(this, void 0, void 0, function () {
    var t;
    return r(this, function (o) {
      return C = (null == n ? void 0 : n.permissions_to_check) || ["accelerometer", "accessibility", "accessibility-events", "ambient-light-sensor", "background-fetch", "background-sync", "bluetooth", "camera", "clipboard-read", "clipboard-write", "device-info", "display-capture", "gyroscope", "geolocation", "local-fonts", "magnetometer", "microphone", "midi", "nfc", "notifications", "payment-handler", "persistent-storage", "push", "speaker", "storage-access", "top-level-storage-access", "window-management", "query"], t = Array.from({
        length: (null == n ? void 0 : n.retries) || 3
      }, function () {
        return function () {
          return e(this, void 0, void 0, function () {
            var e, n, t, o, a;
            return r(this, function (r) {
              switch (r.label) {
                case 0:
                  e = {}, n = 0, t = C, r.label = 1;
                case 1:
                  if (!(n < t.length)) return [3, 6];
                  o = t[n], r.label = 2;
                case 2:
                  return r.trys.push([2, 4,, 5]), [4, navigator.permissions.query({
                    name: o
                  })];
                case 3:
                  return a = r.sent(), e[o] = a.state.toString(), [3, 5];
                case 4:
                  return r.sent(), [3, 5];
                case 5:
                  return n++, [3, 1];
                case 6:
                  return [2, e];
              }
            });
          });
        }();
      }), [2, Promise.all(t).then(function (e) {
        return O(e, C);
      })];
    });
  });
}), i("plugins", function () {
  var e = [];
  if (navigator.plugins) for (var r = 0; r < navigator.plugins.length; r++) {
    var n = navigator.plugins[r];
    e.push([n.name, n.filename, n.description].join("|"));
  }
  return new Promise(function (r) {
    r({
      plugins: e
    });
  });
}), i("screen", function () {
  return new Promise(function (e) {
    e({
      is_touchscreen: navigator.maxTouchPoints > 0,
      maxTouchPoints: navigator.maxTouchPoints,
      colorDepth: screen.colorDepth,
      mediaMatches: L()
    });
  });
}), i("system", function () {
  return new Promise(function (e) {
    var r = M();
    e({
      platform: window.navigator.platform,
      cookieEnabled: window.navigator.cookieEnabled,
      productSub: navigator.productSub,
      product: navigator.product,
      useragent: navigator.userAgent,
      hardwareConcurrency: navigator.hardwareConcurrency,
      browser: {
        name: r.name,
        version: r.version
      },
      applePayVersion: _()
    });
  });
});
var D,
  B = "SamsungBrowser" !== M().name ? 1 : 3,
  U = null;
i("webgl", function () {
  return e(this, void 0, void 0, function () {
    var e;
    return r(this, function (r) {
      "undefined" != typeof document && ((D = document.createElement("canvas")).width = 200, D.height = 100, U = D.getContext("webgl"));
      try {
        if (!U) throw new Error("WebGL not supported");
        return e = Array.from({
          length: B
        }, function () {
          return function () {
            try {
              if (!U) throw new Error("WebGL not supported");
              var e = "\n          attribute vec2 position;\n          void main() {\n              gl_Position = vec4(position, 0.0, 1.0);\n          }\n      ",
                r = "\n          precision mediump float;\n          void main() {\n              gl_FragColor = vec4(0.812, 0.195, 0.553, 0.921); // Set line color\n          }\n      ",
                n = U.createShader(U.VERTEX_SHADER),
                t = U.createShader(U.FRAGMENT_SHADER);
              if (!n || !t) throw new Error("Failed to create shaders");
              if (U.shaderSource(n, e), U.shaderSource(t, r), U.compileShader(n), !U.getShaderParameter(n, U.COMPILE_STATUS)) throw new Error("Vertex shader compilation failed: " + U.getShaderInfoLog(n));
              if (U.compileShader(t), !U.getShaderParameter(t, U.COMPILE_STATUS)) throw new Error("Fragment shader compilation failed: " + U.getShaderInfoLog(t));
              var o = U.createProgram();
              if (!o) throw new Error("Failed to create shader program");
              if (U.attachShader(o, n), U.attachShader(o, t), U.linkProgram(o), !U.getProgramParameter(o, U.LINK_STATUS)) throw new Error("Shader program linking failed: " + U.getProgramInfoLog(o));
              U.useProgram(o);
              for (var a = 137, i = new Float32Array(4 * a), u = 2 * Math.PI / a, c = 0; c < a; c++) {
                var s = c * u;
                i[4 * c] = 0, i[4 * c + 1] = 0, i[4 * c + 2] = Math.cos(s) * (D.width / 2), i[4 * c + 3] = Math.sin(s) * (D.height / 2);
              }
              var l = U.createBuffer();
              U.bindBuffer(U.ARRAY_BUFFER, l), U.bufferData(U.ARRAY_BUFFER, i, U.STATIC_DRAW);
              var f = U.getAttribLocation(o, "position");
              U.enableVertexAttribArray(f), U.vertexAttribPointer(f, 2, U.FLOAT, !1, 0, 0), U.viewport(0, 0, D.width, D.height), U.clearColor(0, 0, 0, 1), U.clear(U.COLOR_BUFFER_BIT), U.drawArrays(U.LINES, 0, 2 * a);
              var d = new Uint8ClampedArray(D.width * D.height * 4);
              return U.readPixels(0, 0, D.width, D.height, U.RGBA, U.UNSIGNED_BYTE, d), new ImageData(d, D.width, D.height);
            } catch (e) {
              return new ImageData(1, 1);
            } finally {
              U && (U.bindBuffer(U.ARRAY_BUFFER, null), U.useProgram(null), U.viewport(0, 0, U.drawingBufferWidth, U.drawingBufferHeight), U.clearColor(0, 0, 0, 0));
            }
          }();
        }), [2, {
          commonImageHash: f(b(e, D.width, D.height).data.toString()).toString()
        }];
      } catch (e) {
        return [2, {
          webgl: "unsupported"
        }];
      }
      return [2];
    });
  });
});
var F = function (e, r, n, t) {
  for (var o = (n - r) / t, a = 0, i = 0; i < t; i++) {
    a += e(r + (i + .5) * o);
  }
  return a * o;
};
function N() {
  return "0.16.0";
}
i("math", function () {
  return e(void 0, void 0, void 0, function () {
    return r(this, function (e) {
      return [2, {
        acos: Math.acos(.5),
        asin: F(Math.asin, -1, 1, 97),
        atan: F(Math.atan, -1, 1, 97),
        cos: F(Math.cos, 0, Math.PI, 97),
        cosh: Math.cosh(9 / 7),
        e: Math.E,
        largeCos: Math.cos(1e20),
        largeSin: Math.sin(1e20),
        largeTan: Math.tan(1e20),
        log: Math.log(1e3),
        pi: Math.PI,
        sin: F(Math.sin, -Math.PI, Math.PI, 97),
        sinh: F(Math.sinh, -9 / 7, 7 / 9, 97),
        sqrt: Math.sqrt(2),
        tan: F(Math.tan, 0, 2 * Math.PI, 97),
        tanh: F(Math.tanh, -9 / 7, 7 / 9, 97)
      }];
    });
  });
});
export { g as getFingerprint, v as getFingerprintData, w as getFingerprintPerformance, N as getVersion, t as setOption };
