import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { firstValueFrom, Observable } from 'rxjs';
import { TaxDepreciation } from '@wmt/shared';

const initialState = {
  taxDepreciations: [] as TaxDepreciation[],
};

export const taxDepreciationStore = createStore(
  { name: 'taxDepreciations' },
  withProps<{ taxDepreciations: TaxDepreciation[] }>(initialState),
);

export function loadTaxDepreciations(taxDepreciations: TaxDepreciation[]) {
  taxDepreciationStore.update(state => ({
    ...state,
    taxDepreciations,
  }));
}

export const taxDepreciationStoreQuery = {
  getTaxDepreciations: () => taxDepreciationStore.query(state => state.taxDepreciations),
  getTaxDepreciationsByVehicleType: (vehicleType: string) =>
    taxDepreciationStore.query(state =>
      state.taxDepreciations.filter(taxDepreciation =>
        taxDepreciation.depreciationFor === vehicleType)),
};

export async function initializeTaxDepreciationStore(fetchTaxDepreciationsFromDB: () => Observable<TaxDepreciation[]>) {
  const storedTaxDepreciations = taxDepreciationStoreQuery.getTaxDepreciations();
  if (storedTaxDepreciations.length === 0) {
    const taxDepreciations = await firstValueFrom(fetchTaxDepreciationsFromDB());
    loadTaxDepreciations(taxDepreciations);
  }
}

persistState(taxDepreciationStore, {
  key: 'wmt-tax-depreciation',
  storage: localStorageStrategy,
});
