<mat-card class="cardWithShadow wmt-bg-card-primary overflow-hidden">
  <mat-card-content>
    <div class="row justify-content-around">
      <div class="col-sm-12 dumb-card-padding">
        <!--<mat-card-title style="font-size: 24px">Find Your Perfect Accounting Partner</mat-card-title>-->
        <mat-card-subtitle style="font-size: 18px; line-height: 34px; opacity: 0.8" class="mat-body-1 m-t-16 m-b-24">
          That's why our company motto and it's three quintessential pillars are discuss, design and develop. Any
          undertaking by us starts with a sound discussion with the client spear headed by our management that makes a
          clear illustration of the problem statement and the expected resolution. This is then corroborated by a
          inclusive design involving our industry hardened experts and technical architects. The solution design is
          discussed and aligned with client stakeholders. Finally we use our trusted family of developers to build and
          deliver the end product. In Daedal we believe communication is the core of everything. You would witness this in
          through out our various products and services.<br><br>
          With a developer factory that consists of people experienced in container services, cloud platforms alongside
          the latest programming languages we are confident to crusade over any challenge that comes our away.
        </mat-card-subtitle>
        <div style="text-align: center">
          <p style="font-weight: bold;font-size: 18px; padding-top: 25px">Would you like to know more?</p>
          <div class="d-flex justify-content-center gap-30">
            <button routerLink="/contact-us" class="dumb-card-button" mat-flat-button color="primary">
              Drop us a Line
            </button>
            <button class="dumb-card-button" mat-flat-button color="primary" (click)="goToLinkedIn()">
              Connect via LinkedIn
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <div class="gradient-circle" id="circle1"></div>
  <div class="gradient-circle" id="circle2"></div>
  <div class="gradient-circle" id="circle3"></div>
</mat-card>
