import { AbstractControl, ValidatorFn } from '@angular/forms';

export function totalIncomeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control && control.value) {
      const totalIncome = +control.get('totalIncome')?.value || 0;
      const directorsSalary = +control.get('directorsSalary')?.value || 0;
      const companyExpenses = +control.get('companyExpenses')?.value || 0;
      const personalExpenses = +control.get('personalExpenses')?.value || 0;

      const totalExpenses = directorsSalary + companyExpenses + personalExpenses;

      if (totalIncome < totalExpenses) {
        return {
          totalIncomeError: {
            message: 'Revenue < total expenses. Increase revenue to see the output data.',
          },
        };
      }
    }

    return null;
  };
}
