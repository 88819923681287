import { createStore, withProps } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { Chat, Message } from '@wmt/shared';
import { Threads } from 'openai/resources/beta';
import RunStatus = Threads.RunStatus;

interface ChatState {
  sidebarOpen: boolean
  sendOnEnter: boolean
  chats: Chat[]
  currentChatId: string | null
}

const initialState = {
  sidebarOpen: true,
  sendOnEnter: false,
  chats: [] as Chat[],
  currentChatId: null,
};

export const chatStore = createStore(
  { name: 'chat' },
  withProps<ChatState>(initialState),
);

/* chatStore.subscribe(state => {
  console.log('Updated state:', state);
}); */

export function initializeChatStore() {
  // console.log('Chat store initialized with state:', chatStore.getValue());
}

export function toggleSidebar() {
  chatStore.update(state => ({
    ...state,
    sidebarOpen: !state.sidebarOpen,
  }));
}

export function setSidebarState(open: boolean) {
  chatStore.update(state => ({
    ...state,
    sidebarOpen: open,
  }));
}

export function setCurrentChatId(chatId: string | null) {
  chatStore.update(state => ({
    ...state,
    currentChatId: chatId,
  }));
}

export function clearCurrentChatId() {
  chatStore.update(state => ({
    ...state,
    currentChatId: null,
  }));
}

export function loadChats(chats: Chat[]) {
  chatStore.update(state => ({
    ...state,
    chats,
  }));
}

export function addNewChat(chat: Chat) {
  chatStore.update((state) => {
    // console.log(new Error('Trace update').stack); // This logs the stack trace
    return {
      ...state,
      chats: [...state.chats, chat],
    };
  });
}

export function addNewChatMessage(chatId: string, message: Message) {
  chatStore.update((state) => {
    const chats = state.chats.map((chat) => {
      if (chat.id === chatId) {
        return { ...chat, messages: [...chat.messages, message], updatedAt: new Date() };
      }
      return chat;
    });
    return { ...state, chats };
  });
}

export function updateChatMessage(chatId: string, messageId: string, contentChunk: string, runStatus?: RunStatus) {
  chatStore.update((state) => {
    const chats = state.chats.map((chat) => {
      if (chat.id === chatId) {
        const messages = chat.messages.map((message) => {
          if (message.id === messageId) {
            return { ...message, content: message.content + contentChunk, runStatus: runStatus ?? message.runStatus };
          }
          return message;
        });
        return { ...chat, messages };
      }
      return chat;
    });
    return { ...state, chats };
  });
}

export function updateChatTitle(chatId: string, newTitle: string) {
  chatStore.update((state) => {
    const chats = state.chats.map(chat => chat.id === chatId ? { ...chat, title: newTitle } : chat);
    return { ...state, chats };
  });
}

export function softDeleteChat(chatId: string) {
  chatStore.update((state) => {
    const chats = state.chats.filter(chat => chat.id !== chatId);
    return { ...state, chats };
  });
}

export const chatStoreQuery = {
  getChats: () => chatStore.query(state => state.chats),
  getCurrentChatId: () => chatStore.query(state => state.currentChatId),
  getChatById: (chatId: string) =>
    chatStore.query(state => state.chats.find(chat => chat.id === chatId)),
  getCurrentChat: () => chatStore.query((state) => {
    const chatId = state.currentChatId;
    return chatId ? state.chats.find(chat => chat.id === chatId) : undefined;
  }),
};

persistState(chatStore, {
  key: 'wmt-chat',
  storage: localStorageStrategy,
});
