<h2 class="text-center">Key components of Taxelos ΤΞ</h2>
<div class="row justify-content-center">
  @for (value of valueCards; track value.id) {
    <div class="col-sm-6 col-lg-4">
      <mat-card class="cardWithShadow card2 position-relative card-hover">
        <img mat-card-image src="{{ value.imgSrc }}" width="391" height="211" alt="Value 1" />
        <div class="card-overlay h-100 d-flex align-items-end justify-content-end">
        </div>

        <mat-card-content class="p-t-24" style="padding-bottom: 0">
          <mat-card-title>{{ value.title }}</mat-card-title>
          <mat-card-subtitle class="mat-body-1 m-t-16 lh-24 f-s-16 wmt-text-height" [innerHTML]="value.description">
          </mat-card-subtitle>
          <div class="d-flex align-items-center justify-content-end m-t-24 release-info-container">
            <div class="d-flex align-items-center release-info">
              <!--<i-tabler name="point" class="icon-14 m-r-4 d-flex"></i-tabler>-->
              {{ value.releaseName }}
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  }
</div>
