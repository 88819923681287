import {
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatError, MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';
import { IconsModule } from '../../../../ui/icons/icons.module';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { AccountantWithServices, states } from '@wmt/shared';
import { AccountantService } from '../../../../services/api/accountant.service';
import { FormValidationService } from '../../../../services/utility/form-validation.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { isPlatformBrowser, NgForOf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { FeedbackService } from '../../../../services/api/feedback.service';

@Component({
  selector: 'wmt-accountant-search',
  standalone: true,
  templateUrl: './accountant-search.component.html',
  imports: [
    MatCard,
    MatCardContent,
    MatFormField,
    IconsModule,
    MatSelect,
    MatOption,
    MatInput,
    MatLabel,
    FormsModule,
    MatError,
    MatPrefix,
    NgForOf,
    ReactiveFormsModule,
    MatTooltip,
    MatIcon,
    MatButtonToggleGroup,
    MatButtonToggle,
  ],
  styleUrls: ['./accountant-search.component.scss'],
})
export class AccountantSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  private accountantService = inject(AccountantService);
  private feedbackService = inject(FeedbackService);
  public fv = inject(FormValidationService);
  private platformId = inject(PLATFORM_ID);
  @Output() searchResults = new EventEmitter<{
    accountants: AccountantWithServices[]
    totalAccountants: number
    totalPages: number
  }>();

  private subs = new Subscription();

  searchForm: FormGroup;
  sortDirection: 'asc' | 'desc' = 'asc';
  services = ['Corporate Tax', 'Income Tax', 'Payroll', 'US Tax'];
  servicesMulti = ['All', ...this.services];
  states: string[] = ['All', ...states];

  currentPage = 1;
  pageSize = 10;

  constructor(private fb: NonNullableFormBuilder) {
    this.searchForm = this.fb.group({
      filterByAccountantName: ['', { validators: [Validators.minLength(3)] }],
      filterByServices: [['All'], { validators: [Validators.required] }],
      filterByState: [['All'], { validators: [Validators.required] }],
      sortByService: [''],
      sortByServicePrice: ['asc'],
    });
  }

  ngOnInit(): void {
    this.fv.setCurrentForm(this.searchForm);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.fetchAccountants();
      this.listenToFormChanges();
    }
  }

  listenToFormChanges() {
    this.subs.add(this.searchForm.get('filterByAccountantName')?.valueChanges.subscribe(() => {
      setTimeout(() => this.fetchAccountants(), 0);
    }));
    this.subs.add(this.searchForm.get('filterByState')?.valueChanges.subscribe((selectedStates) => {
      this.updateStateSelection(selectedStates);
    }));
    this.subs.add(this.searchForm.get('filterByServices')?.valueChanges.subscribe((selectedServices) => {
      this.updateServiceSelection(selectedServices);
    }));
    this.subs.add(this.searchForm.get('sortByService')?.valueChanges.subscribe(() => {
      setTimeout(() => this.fetchAccountants(), 0);
    }));
    this.subs.add(this.searchForm.get('sortByServicePrice')?.valueChanges.subscribe(() => {
      setTimeout(() => this.fetchAccountants(), 0);
    }));
  }

  fetchAccountants(page = this.currentPage, size = this.pageSize): void {
    const {
      filterByAccountantName,
      filterByServices,
      filterByState,
      sortByService,
      sortByServicePrice,
    } = this.searchForm.value;

    const accountantSubscription = this.accountantService.getAccountants(
      page,
      size,
      filterByAccountantName,
      filterByState,
      filterByServices,
      sortByService,
      sortByServicePrice,
    ).subscribe({
      next: (response) => {
        const totalPages = Math.ceil(response.totalAccountants / size);

        const accountantIds = response.accountants.map(accountant => accountant.accountantId)
          .filter(id => id !== undefined) as string[];

        const feedbackSubscription = this.feedbackService.getFeedbackByAccountantIds(accountantIds).subscribe({
          next: (feedbacks) => {
            feedbacks.forEach((feedback) => {
              const accountant = response.accountants.find(a => a.accountantId === feedback.accountantId);
              if (accountant) {
                accountant.feedback = feedback;
                accountant.feedback.averageStars = Math.round(accountant.feedback.averageStars);
                accountant.feedback.feedbacks.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
              }
            });
            this.searchResults.emit({
              accountants: response.accountants,
              totalAccountants: response.totalAccountants,
              totalPages: totalPages,
            });
          },
          error: (error) => {
            console.error('Error fetching feedbacks:', error);
          },
        });

        this.subs.add(feedbackSubscription);
      },
      error: (error) => {
        console.error('Error fetching accountants:', error);
      },
    });

    this.subs.add(accountantSubscription);
  }

  @Input() set pageChange(page: number) {
    if (isPlatformBrowser(this.platformId)) {
      this.currentPage = page;
      this.fetchAccountants();
    }
  }

  updateStateSelection(selectedStates: string[]) {
    const hasAll = selectedStates.includes('All');
    let updatedStates: string[];

    if (selectedStates.length === 0) {
      updatedStates = ['All'];
    }
    else if (hasAll && selectedStates.length > 1) {
      updatedStates = selectedStates.filter(state => state !== 'All');
    }
    else {
      updatedStates = selectedStates;
    }

    this.searchForm.get('filterByState')?.setValue(updatedStates, { emitEvent: false });
    this.fetchAccountants();
  }

  updateServiceSelection(selectedServices: string[]) {
    const hasAll = selectedServices.includes('All');
    let serviceSelection: string[];

    if (selectedServices.length === 0) {
      serviceSelection = ['All'];
    }
    else if (hasAll && selectedServices.length > 1) {
      serviceSelection = selectedServices.filter(service => service !== 'All');
    }
    else {
      serviceSelection = selectedServices;
    }

    this.searchForm.get('filterByServices')?.setValue(serviceSelection, { emitEvent: false });
    this.fetchAccountants();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
