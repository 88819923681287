<div class="pageWrapper maxWidth partner-form">
  <mat-card class="cardWithShadow theme-card">
    <mat-card-header class="justify-content-center">
      <mat-card-title class="m-b-0 f-s-24 text-center">Thank you for choosing to partner with us</mat-card-title>
      <mat-card-subtitle class="mat-body-1 p-t-12 p-b-24 text-center wmt-text-height">
        The form below consists of two sections. The first one is the firm's business information which we'll use to
        verify and the second section is the firm's chargeable rates for the various services they provide. Please fill
        the form below to the best of your abilities. Don't worry if you think you have missed something. We'll get in
        touch with you for a secondary confirmation before we publish your details in our portal. You can change any
        information until then. Furthermore, you can always reach out to our dedicated help desk for any amendments
        and/or deleting your details from our database. Our dedicated help desk details will be e-mailed to you when we
        reach out to you for secondary confirmation.
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card class="shadow-none text-center bg-light-primary shadow-none">
      <mat-card-content class="p-32">
        <mat-card-subtitle class="mat-body-1 lh-24 f-s-16 wmt-text-height">
          Please note that it may take upto 2 working days for us to reach out to you. Kindly
          <a [routerLink]="['/contact-us']"> contact us</a> if you don't hear from us by then.
        </mat-card-subtitle>
      </mat-card-content>
    </mat-card>

    <mat-card-content class="b-t-1">
      <mat-stepper #stepper [linear]="true" [orientation]="orientation">
        <ng-template matStepperIcon="edit">
          <i-tabler name="check" class="partner-step-icons"></i-tabler>
        </ng-template>
        <ng-template matStepperIcon="done">
          <i-tabler name="check" class="partner-step-icons"></i-tabler>
        </ng-template>
        <mat-step [stepControl]="companyProfileForm" [completed]="isCompanyProfileFormValid">
          <ng-template matStepLabel>Company profile</ng-template>
          <wmt-partner-form-company-profile [stepper]="stepper"></wmt-partner-form-company-profile>
        </mat-step>
        <mat-step [stepControl]="companyServicesForm" [completed]="isCompanyServicesFormValid">
          <ng-template matStepLabel>Company services</ng-template>
          <wmt-partner-form-company-services [stepper]="stepper"></wmt-partner-form-company-services>
        </mat-step>
        <mat-step [stepControl]="consentForm" [completed]="isConsentFormValid">
          <ng-template matStepLabel>Finish</ng-template>
          <wmt-partner-form-consent
            [stepper]="stepper"
            [submitted]="submitted"
            (formSubmit)="onFormSubmit()"></wmt-partner-form-consent>
        </mat-step>
      </mat-stepper>
    </mat-card-content>
  </mat-card>
</div>
